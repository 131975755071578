import { Divider, Box, Button, Grid } from '@mui/material'

const ControlsComponent = (props) => {
	const submitHandler = props.submitHandler
	const reset = props.resetHandler
	const resetform = props.resetform

	const {readonly, setReadonly} = props.readonly
	let visible = true

	if (props.hasOwnProperty('visible')) {
		visible = props.visible
	}

	const handleSubmit = () => {
		console.log("CONTROLS COMPONENT SUBMIT")
		submitHandler()
		setReadonly(true)
	}

	const handleEdit = () => {
		setReadonly(false)
	}

	const handleCancel = () => {
		if (reset) {reset()}
		setReadonly(true)
		if (resetform) {resetform()}		
	}

	return (
		<>
			{visible ? 
				<>
				{readonly ? (
					<Button color="primary" disabled={props.disabled} variant="outlined" onClick={handleEdit}>
						Edit
					</Button>
				) : (
					<Box>
						<Button
							color="primary"
							variant="outlined"
							onClick={handleCancel}
							sx={{mr:1}}
						>
							Cancel
						</Button>
						<Button
							color="primary"
							variant="outlined"
							onClick={ submitHandler ? handleSubmit : ()=>{} }
							type="submit"
						>
							Save changes
						</Button>
					</Box>
				)}
			
			</> : ''
			}
		</>
	)
}

export default ControlsComponent
