import { useEffect, useState, useContext } from 'react'
import {
	//Container,
	Grid,
	Typography,
	Box,
	//Paper,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	//Toolbar,
	Tabs,
	Tab,
	Divider,
	TextField,
	InputAdornment,
	SvgIcon,
	Collapse,
	Button,
	InputLabel,
	Input
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { Search as SearchIcon } from 'react-feather'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

import Sandbox from './Sandbox'

import { useHttp } from '../../hooks/http.hook'
import objectTools from '../../helpers/objectTools'

import methods from '../../__mocks__/methods'
import mediaTypes from '../../__mocks__/mediaTypes'

import OrgAppSelector from './OrgAppSelector'
import Preloader from '../../components/Preloader'

const { objectToQueryString } = objectTools()

const ProductEndpoints = (props) => {
	const theme = useTheme()
	const apicode = props.data.code

	const getContentType = (value) => {
		const found = mediaTypes.find((type) => {
			return type.id == value
		})
		if (found) {
			return found.headers
		} else {
			return {}
		}
	}

	const getMethod = (value) => {
		return methods.filter((method) => method._id === value)[0].title
	}

	const apiParameters = [
		{
			name: 'headers',
			title: 'Header parameters',
		},
		{
			name: 'required',
			title: 'Required parameters',
		},
		{
			name: 'optional',
			title: 'Optional parameters',
		},
	]
	const [ endpointsList, setEndpointsList ] = useState([{title: '',method: ''}])
	const [ selectedIndex, setSelectedIndex ] = useState('')
	const [ selectedAppKey, setSelectedAppKey ] = useState('')
	const [ selectedEndpoint, setSelectedEndpoint ] = useState({})
	const [ endpointIndex, setEndpointIndex ] = useState()
	const [ values, setValues ] = useState({
		appselected: '',
		headers: {},
		query: {},
		body: {}
	})
	const [ tempState, setTempState ] = useState(values)
	const [ isLoading, setIsLoading ] = useState(false)

	const [ sandbox, setSandbox ] = useState({
		tab: 0,
		results: 'Run test first...',
		code: null,
	})

	const setEndpoints = (data) => {
		console.log('ENDPOINTS PROPS DATA: ', data.data.endpoints)
		if (!props.data.endpoints) {
			console.log('No endpoints data')
			return
		}
		if (!!props.data.endpoints) {
			const endpointsdata = data.data.endpoints.map((endpoint) => {
				return {
					title: endpoint.title,
					method: getMethod(endpoint.method),
					headers: endpoint.headers,
					query: endpoint.query,
					baseurl: data.data.baseurl,
					path: endpoint.path,
					body: endpoint.body,
					code: endpoint.code
				}
			})
			console.log('ENDPOINTSDATA : ', endpointsdata)
			setEndpointsList(endpointsdata)
		}
	}
	
	useEffect(()=>{
		if (!!endpointsList[0] && !!endpointsList[0].title) {
			selectEndpoint(endpointsList[0],0)
		}
	}, [endpointsList])

	const selectEndpoint = (value, index) => {
		console.log("Select endpoint: ", value)
		const mediatype = value.body ? value.body.mediatype : ''
		setEndpointIndex(index)
		let queryString = {}
		const headersmap = value.headers.map((item)=>{
		if (!item.hidden) {
			return {[item.name]:item.example}
		}
		}
		).filter(i=>!!i)

		const querymap = value.query.map((item)=>{
			if (item.queryString) {
				queryString = {[item.name]:true}
			}
			return ({[item.name]:item.example})
		})
		let headers = {}
		let query = {}
		let body = {}
		
		headers = headersmap.reduce((result, item)=>{
			var key = Object.keys(item)[0];
			result[key] = item[key];
			return result;
		}, {});

		query = querymap.reduce((result, item)=>{
			var key = Object.keys(item)[0];
			result[key] = item[key];
			return result;
		}, {});

		if (value.body) {
			console.log("BODY", value.body)
			if (mediatype == 'stream') {
				body = {...value.body,example:value.body.example}
			} else {
				body = {...value.body,example:JSON.parse(value.body.example)}
			}
		}
		console.log("value.body", value.body)

		setSelectedEndpoint({...value, headers: headersmap, mediatype})
		setValues({...values, headers:{...headers},query:{...query},body:{...body}})
		setSandbox({ ...sandbox, code: prepareSandboxdata(value,queryString) })
	}

	const generateQueryString = (queryObject, queryString) => {
		let queryPathString = ''
		let queryMap = {}
			for (var queryname in queryObject) {
				if(queryString && queryString.hasOwnProperty(queryname)) {
					queryPathString = queryPathString + queryObject[queryname]
				} else {
					queryMap[queryname] = queryObject[queryname]
				}
			}
		return { queryPathString, queryMap }
	}

	const prepareSandboxdata = (data,queryString) => {
		console.log("PREPARE REQUEST: ", data)
			let headersObject = {}
			let queryObject = {}
			let bodyObject = {}

			var { baseurl, method, body, headers, query, path, code } = data

			if (headers) {
				headers.map((item) => {
					const result = {
						[item.name]: values.headers[item.name]? values.headers[item.name] : item.example,
					}
					headersObject[item.name] = values.headers[item.name]? values.headers[item.name] : item.example
					return result
				})
				console.log("HEADERS : ", headersObject)
			}

			if (query) {
				query.map((item) => {
					const result = {
						[item.name]: values.query[item.name]? values.query[item.name] : item.example,
					}
					queryObject[item.name] = values.query[item.name]? values.query[item.name] : item.example
					return result
				})
				console.log("PARAMETERS : ", queryObject)
			}

			if (body) {
				if (selectedEndpoint.mediatype == 'stream') {
					bodyObject = {example: body.example}
				} else {
					bodyObject = {example:JSON.parse(body.example)}
				}
			}

			const { queryPathString, queryMap } = generateQueryString(queryObject, queryString)
			const queryMapString = "?" + objectToQueryString(queryMap)
			const contenttype = body? getContentType(body.mediatype) : {}

		return {headersObject, queryMapString, queryObject, baseurl, method, body, bodyObject, path, queryString, queryPathString, queryMap, code, contenttype, apicode}
	}

	const testrequest = async (sandboxcode) => {
		try {
			console.log("Test request: Data: ", sandboxcode)
			setIsLoading(true)
			const { 
				headersObject, 
				method, 
				body, 
				bodyObject, 
				path, 
				queryPathString, 
				queryMap, 
				code, 
				contenttype, 
				queryMapString, 
				appkey
			} = sandboxcode
			
			const urlString = '/' + (path? path : '') + queryPathString + queryMapString
			
			const requestObject = {
				url: apicode + code + urlString,
				method: method,
				body: body && bodyObject? 
				body.mediatype == 'form' ? objectToQueryString(bodyObject.example) 
					// :
					// body.mediatype == 'stream' ? bodyObject.example
						: 
						JSON.stringify(bodyObject.example)
				: null,
				headers: {...headersObject, ...contenttype, appkey},
			}

			console.log('testrequest request: ', requestObject)
			
			const response = await fetch(`/hub/${requestObject.url}`, {
				method: requestObject.method,
				body: requestObject.body,
				headers: requestObject.headers,
				}
			)
			
			let result = {}
			let isJson = true
			try {
				result = await response.json()
				isJson = true;
			} catch(e) {
				result = !!response.body ? response.body : response
				isJson = false;
			}

			setIsLoading(false)
			console.log('testrequest result: ', result)

			setSandbox({
				...sandbox,
				results: !!isJson? JSON.stringify(result, null, 2) : result,
				tab: 1,
			})
		} catch (e) {
			console.log('ERROR', e)
		}
	}

	const handleClick = (index) => {
		if (selectedIndex === index) {
			setSelectedIndex('')
		} else {
			setSelectedIndex(index)
		}
	}

	const handleBlur = (event,part) => {
		setValues(tempState)
	}

	const handleChange = (event,part) => {
		try {
		const mediatype = selectedEndpoint.mediatype
		let headers = null
		let query = null
		let body = null
		if (part === 'headers') {
			headers = {...values.headers, [event.target.name]: event.target.value }
			setValues({...values, headers: {...values.headers, ...headers}})
		}
		if (part === 'query') {
			query = {...values.query, [event.target.name]: event.target.value }
			setValues({...values, query: {...values.query, ...query}})
		}
		if (part === 'body') {
				//console.log(mediatype)
				
			if (mediatype == 'stream') {
				console.log(event.target.files[0])
				body = {...values.body, [event.target.name]: event.target.files[0]}
				setTempState({...values, body: {...values.body, ...body}})
				//setValues({...values, body: {...values.body, ...body}})
			} else {
				body = {...values.body, [event.target.name]: JSON.parse(event.target.value)}
				setTempState({...values, body: {...values.body, ...body}})
				//setValues({...values, body: {...values.body, ...body}})
			}
		}
		} catch(e) {
			return
		}
	}

	const handleRuntest = () => {
		testrequest(sandbox.code)
	}

	useEffect(()=>{
		setValues({
			...values,
			appkey: selectedAppKey
		})
	}, [selectedAppKey])

	useEffect(()=>{
		if (!sandbox.code) {return}
		setTempState(values)
		const { queryPathString, queryMap } = generateQueryString(values.query, sandbox.code.queryString)
		const queryMapString = !!queryMap ? "?" + objectToQueryString(queryMap) : ''
		const forsandbox = {
			apicode,
			appkey: values.appkey,
			headersObject: values.headers,
			queryObject: values.query,
			bodyObject: values.body,
			queryMap: queryMap,
			queryPathString: queryPathString,
			queryMapString: queryMapString
		}
		setSandbox({ ...sandbox, code: {...sandbox.code,...forsandbox} })
	}, [values])

	useEffect(() => {
		setEndpoints(props)
	}, [props.data])

	return (
		<Grid container sx={{ display: 'flex', flexGrow: '1'}}>
			<Grid item md={3} xs={12}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						borderRight: 1,
						borderColor: theme.palette.divider,
					}}
				>
					<TextField
						sx={{ m: 0, p: 1 }}
						size="small"
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SvgIcon fontSize="small" color="action">
										<SearchIcon />
									</SvgIcon>
								</InputAdornment>
							),
						}}
						placeholder="Search Endpoints"
						margin="dense"
						variant="outlined"
						value=""
						//onChange="{onChangeSearchTitle}"
					/>
					<List sx={{ m: 0, p: 0 }}>
						{endpointsList.map((endpoint, index) => (
							<ListItem
								sx={{...(endpointIndex == index && theme.listitemactive)}}
								button
								key={index}
								onClick={() => {
									selectEndpoint(endpoint, index)
								}}
							>
								<ListItemIcon>
									<Typography variant="caption">
										{endpoint.method}
									</Typography>
								</ListItemIcon>
								<ListItemText primary={endpoint.title} />
							</ListItem>
						))}
					</List>
				</Box>
			</Grid>
			<Grid item md={4} xs={12}>
				{!!selectedEndpoint.title ? (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
							borderRight: 1,
							borderColor: theme.palette.divider,
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								height: '3em',
								alignItems: 'center',
								justifyContent: 'center',
								p: 1,
								pt:2
							}}
						>
							<ListItemIcon
								sx={{
									alignItems: 'center',
									height: '100%',
									justifyContent: 'center',
									backgroundColor: '#f7f7f7',
								}}
							>
								<Typography variant="caption">
									{selectedEndpoint.method}
								</Typography>
							</ListItemIcon>
							<Typography variant="body1" sx={{ px: 1 }}>
								{selectedEndpoint.title}
							</Typography>
							<Box sx={{ flexGrow: 1 }} />
							<Box marginRight={1} width="0"/>
							<Button
								className=""
								// sx={theme.customButtonOutline}
								sx={{
									borderRadius:5,
									border:'3px solid #555',
									p: 0.5,
									":hover": {
										borderColor:'#00eecc',
										background:'#fff'
										// fontColor:'#fff',
									},
									
								}}
								
								onClick={handleRuntest}
								children={
									!!isLoading ? <Preloader size="2em" thickness="5"/> :
									<Box sx={{display:'flex',alignItems:'center',justifyContent:'center',gap:0.25,px:0.5,pr:1}}>
										<Box sx={{w:24,h:24,display:'flex'}}>
											<PlayArrowIcon sx={{color:'#00eecc'}}/>
										</Box>
										<Box sx={{h:24,display:'flex',alignItems:'center'}}>
											<Typography variant="body1" sx={{fontWeight:550,textTransform:'none',pt:0.25,m:0}}>Test</Typography>
										</Box>
									</Box>
								}
							/>
						</Box>
						<OrgAppSelector setSelectedAppKey={setSelectedAppKey} selectedEndpoint={selectedEndpoint}/>
						<Divider />
						{apiParameters.map((item, index) => (
							<div key={index}>
								{
								((item.name == 'headers') && selectedEndpoint.headers.length ? true : false ||
								(item.name == 'required') && selectedEndpoint.query.filter((item) => item.required == true).length ? true : false ||
								(item.name == 'optional') && selectedEndpoint.query.filter((item) => item.required == false).length ? true : false)
								&&
									<ListItem
										button
										onClick={() => {
											handleClick(index)
										}}
									>
										<ListItemIcon>
											{index === selectedIndex ? (
												<ExpandLess />
											) : (
												<ExpandMore />
											)}
										</ListItemIcon>
										<ListItemText primary={item.title} />
									</ListItem>
								}
								<Collapse
									in={index === selectedIndex}
									unmountOnExit
								>
									<Grid item xs={12}>
										{item.name === 'headers'
											&& selectedEndpoint.headers.map((item, index) => {
													const name = Object.keys(item)[0]
													const value = Object.values(item)[0]
													return (
														<Box sx={{ m: 1 }} key={index}>
															<TextField
																size="small"
																fullWidth
																helperText={
																	item.required ? 'REQUIRED' : 'OPTIONAL'
																}
																label={name}
																name={name}
																onChange={(event) => {
																	handleChange(event,'headers')
																}}
																required={item.required}
																value={values.headers[name]}
																variant="outlined"
															/>
														</Box>
													)
											})
											}
										{item.name === 'required'
											&& selectedEndpoint.query
													.filter((item) => item.required == true)
													.map((item, index) => {
														return (
															<Box sx={{ m: 1 }} key={index}>
																<TextField
																	size="small"
																	fullWidth
																	helperText={
																		item.required ? 'REQUIRED' : 'OPTIONAL'
																	}
																	label={item.name}
																	name={item.name}
																	onChange={(event) => {
																		handleChange(event,'query')
																	}}
																	required={item.required}
																	value={values.query[item.name]}
																	variant="outlined"
																/>
															</Box>
														)
													})
											}
										{item.name === 'optional'
											&& !!selectedEndpoint.query.length && selectedEndpoint.query
													.filter((item) => item.required == false)
													.map((item, index) => {
														return (
															<Box sx={{ m: 1 }} key={index}>
																<TextField
																	size="small"
																	fullWidth
																	helperText={
																		item.required ? 'REQUIRED' : 'OPTIONAL'
																	}
																	label={item.name}
																	name={item.name}
																	onChange={(event) => {
																		handleChange(event,'query')
																	}}
																	required={item.required}
																	value={values.query[item.name]}
																	variant="outlined"
																/>
															</Box>
														)
													})
											}
									</Grid>
									<Divider />
								</Collapse>
							</div>
						))}
						{/* BODY REQUEST */}
						{!!selectedEndpoint.body && 
						<>
							<ListItem
								button
								onClick={() => {
									handleClick('body')
								}}
						>
							<ListItemIcon>
								{'body' === selectedIndex ? (
									<ExpandLess />
								) : (
									<ExpandMore />
								)}
							</ListItemIcon>
							<ListItemText primary='Request body' />
							</ListItem>
							<Collapse
								in={'body' === selectedIndex}
								unmountOnExit
							>
								<Box sx={{p:1}}>
								{selectedEndpoint.mediatype == 'stream' ?
								<InputLabel htmlFor="import-button">
									<Input
										id="import-button"
										inputProps={{
											accept:
											".jpg,.png",
										}}
										name="example"
										onChange={(event) => {
											handleChange(event,'body')
										}}
										type="file"
									/>
										Import Spreadsheet
								</InputLabel>
								:
								<TextField
									size="small"
									rows="5"
									multiline
									fullWidth
									label="Example"
									name="example"
									value={JSON.stringify(tempState.body.example,null,2)}
									onBlur={(event) => {
										handleBlur(event,'body')
									}}
									onChange={(event) => {
										handleChange(event,'body')
									}}
								/>}
								</Box>
							</Collapse>
						</>
						}

					</Box>
				) : (
					<Box
						alignItems="center"
						display="flex"
						justifyContent="center"
						sx={{ height: '100%', width: '100%' }}
					>
						<Box>
							<Typography>Select endpoint</Typography>
						</Box>
					</Box>
				)}
			</Grid>
			<Grid item md={5} xs={12}>
				{!!sandbox.code ? (
					<>
						<Tabs
							value={sandbox.tab}
							onChange={(event, newValue) => {
								setSandbox({ ...sandbox, tab: newValue })
							}}
						>
							<Tab label="Code snippets"></Tab>
							<Tab label="Results"></Tab>
						</Tabs>
						<Divider />
						<Box
							sx={{
								backgroundColor: '#fafafa',
								height: 'calc(100% - 48px)',
							}}
						>
							<Sandbox
								{...(sandbox.tab == 0
									? { code: sandbox.code }
									: { results: sandbox.results })}
							/>
						</Box>
					</>
				) : (
					''
				)}
			</Grid>
		</Grid>
		
	)
}

export default ProductEndpoints
