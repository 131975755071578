import PropTypes from 'prop-types'
import { useEffect, useContext, useState, useCallback } from 'react'
import {
	//Avatar,
	Box,
	//Button,
	Divider,
	Drawer,
	//List,
	//Typography,
} from '@mui/material'
import SidebarOrg from './SidebarOrg'
import SidebarApi from './SidebarApi'
import SidebarApp from './SidebarApp'
import SidebarMarket from './SidebarMarket'
import SidebarProduct from './SidebarProduct'
import SidebarRequest from './SidebarRequest'
import SidebarContest from './SidebarContest'
import SidebarChannels from './SidebarChannels'
import { NavContext } from '../context/NavContext'
import {useLocation} from 'react-router-dom'
import { useTheme } from '@mui/material/styles';


const DashboardSidebar = ({ onMobileClose, openMobile }) => {
	const theme = useTheme()
	
	let location = useLocation();
	const { selected, selectData } = useContext(NavContext)
	const [ sidebar, setSidebar ] = useState(null)
	const [ visible, setVisible ] = useState(false)


	const menus = [
		{
			label: 'apiedit',
			ref: <SidebarApi selectData={selectData} description={{title: 'APIs'}} onMobileClose={onMobileClose}/>,
		},
		{
			label: 'appedit',
			ref: <SidebarApp selectData={selectData} description={{title: 'APPs'}} onMobileClose={onMobileClose}/>
		},
		{
			label: 'requestedit',
			ref: <SidebarRequest selectData={selectData} description={{title: 'requests'}} onMobileClose={onMobileClose}/>
		},
		{
			label: 'contestedit',
			ref: <SidebarContest selectData={selectData} description={{title: 'contests'}} onMobileClose={onMobileClose}/>
		},
		{
			label: 'org',
			ref: <SidebarOrg selectData={selectData} description={{title: 'Orgs'}} onMobileClose={onMobileClose}/>
		},
		{
			label: 'channels',
			ref: <SidebarChannels selectData={selectData} onMobileClose={onMobileClose} />
		},
		{
			label: 'none',
			ref: false
		}
	]
	

	const contentlayout = (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				border: 0
			}}
		>
			{!!sidebar && sidebar}
		</Box>
	)

	const showSidebar = useCallback(() => {
		if (selected && selectData) {
			const getCandidate = () => {
				var candidate = menus.find(menu => menu.label === selected)
				if (!!candidate) {
					return candidate.ref
				} else {
					return null
				}
			}
			const sidebarcandidate = getCandidate()
			if (!!sidebarcandidate) {
				setSidebar(sidebarcandidate)
				setVisible(true)
			} else {
				setVisible(false)
			}
		}
	}, [selected, selectData])

	useEffect(() => {
		setSidebar(null)
		setVisible(false)
		showSidebar()
		
	}, [selected, selectData, location, showSidebar ])


	return (
		<Box sx={!visible ? {display: 'none'} : {zIndex:1}} >
			<Box sx={{ display: { xs: 'block', md: 'none', lg: 'none', xl: 'none' } }}>
				<Drawer
					anchor="left"
					onClose={onMobileClose}
					open={openMobile}
					variant="temporary"
					PaperProps={{
						sx: {
							width: 255,
						},
					}}
				>	
				{contentlayout}
				</Drawer>
			</Box>
			<Box sx={{ display: { xs: 'none', md: 'block', lg: 'block', xl: 'block' } }}>
				<Drawer
					anchor="left"
					open
					variant="persistent"
					PaperProps={{
						sx: {
							
							width: 255,
							top: 54,
							height: 'calc(100% - 48px)',
							borderBlockStart: '0',
							//borderInlineEnd: '1',
							border:'0',
							borderColor: theme.palette.divider
						},
					}}
				>
					{contentlayout}
				</Drawer>
			</Box>
		</Box>
	)
}

DashboardSidebar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
}

DashboardSidebar.defaultProps = {
	onMobileClose: () => {},
	openMobile: false,
}

export default DashboardSidebar
