import { useContext, useEffect } from 'react'
import { AuthContext } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'

const Logout = ({state}) => {
	const { logout } = useContext(AuthContext)
	const navigate = useNavigate()
	
	useEffect(() => {
		logout()
		navigate(-1,{replace:true})
	},[])
	return (<>
	</>)
}

export default Logout