import React, {useEffect} from 'react'

import { useRoutes, useParams } from 'react-router-dom'

import theme from './components/theme'
import GlobalStyles from './components/GlobalStyles'
import { ThemeProvider } from '@mui/material/styles'

import routes from './routes'

import { AuthContext } from './context/AuthContext'
import { useAuth } from './hooks/auth.hook'
import Preloader from './components/Preloader'
import {NavContext} from './context/NavContext'
import { useNav } from './hooks/nav.hook'

const App = () => {

	
	const { token, login, logout, userId, ready, owner, checkauth, isAuthenticated } = useAuth()
	const { 
		setnav, 
		selected, 
		selectData,
		setData,
		selectedApi,
		setSelectedApi,
		selectedApp,
		setSelectedApp,
		selectedRequest,
		setSelectedRequest,
		selectedContest,
		setSelectedContest,
		userdata,
		setUserdata,
		selectedOrg,
		setSelectedOrg,
		selectedChannel,
		setSelectedChannel,
		refresh,
		refreshCheck,
	} = useNav()

	
		
	const routing = useRoutes(routes(isAuthenticated))

	useEffect(() => {
		if (!!token) {
			checkauth(token)
		}
	}, [token,isAuthenticated])

	useEffect(()=>{
		document.title = "Ai Aggregator"
	},[])

	if (!ready) {
		return (
			<ThemeProvider theme={theme}>
				<GlobalStyles />
				<Preloader />
			</ThemeProvider>
		)
	}
	
	return (
		<AuthContext.Provider
			value={{
				token,
				login,
				logout,
				userId,
				isAuthenticated,
				owner,
				checkauth
			}}
		>
			<NavContext.Provider
				value={{
					setnav,
					selected,
					selectData,
					setData,
					selectedApi,
					setSelectedApi,
					selectedApp,
					setSelectedApp,
					selectedRequest,
					setSelectedRequest,
					selectedContest,
					setSelectedContest,
					userdata,
					setUserdata,
					selectedOrg,
					setSelectedOrg,
					selectedChannel,
					setSelectedChannel,
					refresh,
					refreshCheck,
				}}
			>
				<ThemeProvider theme={theme}>
					<GlobalStyles />
					{routing}
				</ThemeProvider>
			</NavContext.Provider>
		</AuthContext.Provider>
	)
}

export { App }
