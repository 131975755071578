
import {
	Typography,
	Box
} from '@mui/material'

const Logo = (props) => {
	const {ismobile} = props

	// console.log("LOGO PROPS: ", props)

	
	return(
	<Box sx={{p:0}}>
	
		{!!ismobile ?
			<Box>
				<img src="/ai_logo_v1.png" alt='Ai-aggregator' width='32'/>
			</Box>
		:
			!!props.long ?
			<Box sx={{width:"255",flexWrap:'nowrap',display:'flex',alignItems:'center', ...(!!props.long ? {justifyContent:'end'} : {justifyContent:'start'})}}>
				<img src="/ai_logo_v1.png" alt='Ai-aggregator' width='32'/>
				<Box flexGrow="1"/>
				<Typography sx={{pl:2}} fontWeight="fontWeightBold" variant="h6" color="#222">Ai-Aggregator</Typography>
			</Box>
			:
			<Box sx={{pl:4}}>
				<img src="/ai_logo_v1.png" alt='Ai-aggregator' width='32'/>
			</Box>
		}
		{//
		}
		
	
	</Box>
);
}

export default Logo;
