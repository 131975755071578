import {useEffect, useState, useContext, useRef, useCallback} from 'react'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'

import SchemaTools from '../../helpers/schematools'

import {
	Typography,
	TextField,
	Box,
	Button,
	Grid,
	Dialog,
	Checkbox
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import SwitchComponent from '../../components/SwitchComponent'
import FeedSlider from '../../components/Feed/FeedSlider'
import Preloader from '../../components/Preloader'

const FeedEditor = (props) => {
	const theme = useTheme()
	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const { refresh, refreshCheck } = useContext(NavContext)
	const [ feedState, setFeedState ] = useState()
	const [ dateState, setDateState ] = useState(new Date(Date.now() - 86400000))
	const initialstate = {
		title: '',
		text: '',
		hidden: ''
	}
	const [ initialValues, setInitialValues ] = useState(initialstate)
	const [ readonly, setReadonly ] = useState(true)
	const [ openEditor, setOpenEditor ] = useState(false)
	const [ changesState, setChangesState ] = useState()
	
	const pageSize = 3
	const [ page, setPage ] = useState(1)
	const [ count, setCount ] = useState(0)

	const { ownerId, code, _id, title } = props.data

	const fetchData = useCallback(async () => {
		try {
			console.log("fetchData: ", page)
			const response = await request(
				`/api/org/owner/${ownerId}/${code}/feed`,
				'POST',
				{
					orgId: _id,
					date: dateState,
					page: page,
					pagesize: pageSize
				},
				{Authorization: `Bearer ${token}`}
				)
			if (!!response.data) {
				console.log("FeedEditor: fetchData:", response.data)
				setFeedState(response.data.feed)
				setCount(response.data.count)
			}
		} catch(e) {
			console.log(e)
		}
	},[ token, ownerId, code, page ])

	const saveData = async (values) => {
		try {
			const response = await request(
				`/api/org/owner/${ownerId}/${code}/${!!values.event ? 'updateevent' : 'addevent'}`,
				'POST',
				{
					org: {
						_id,
						ownerId,
						code,
						title
					},
					post: {
						...(!!values.event && {_id: values.event._id}),
						eventtitle: values.title,
						eventtext: values.text,
						hidden: values.hidden
					},
					eventtype: (!!values.event && values.event.eventtype) || "ADD_POST"
				},
				{Authorization: `Bearer ${token}`}
				)
			if (!!response.data) {
				console.log("FeedEditor: saveData:", response.data)
				
			}
			refresh()
		} catch(e) {
			console.log(e)
		}
	}

	const deleteEvent = async () => {
		try {
			const response = await request(
				`/api/org/owner/${ownerId}/${code}/removeevent`,
				'DELETE',
				{
					org: {
						_id,
						ownerId,
						code,
						title
					},
					post: {
						_id: changesState.event._id
					},
					eventtype: "ADD_POST"
				},
				{Authorization: `Bearer ${token}`}
			)
			refresh()
		} catch(e) {
			console.log(e)
		}
	}

	const handleSubmit = (values) => {
		console.log("handleSubmit:", values)

		saveData(values)
	}

	const handleCancel = (resetForm) => {
		resetForm()
		setReadonly(true)
	}

	const handleAdd = () => {
		setInitialValues(initialstate)
		setReadonly(false)
	}

	const handleDelete = ({confirm,values}) => {
		if (!!confirm) {
			setOpenEditor(false)
			setInitialValues(initialstate)
			setReadonly(true)
			deleteEvent()
		} else {
			setChangesState(values)
			setOpenEditor(true)
		}
		
	}

	const handleEdit = (event) => {
		setReadonly(false)
		console.log(event)
		setInitialValues(event)
		
		// setOpenEditor(true)
	}

	useEffect(() => {
		if (!!props) {
			console.log("FeedEditor: ", props)
			fetchData()
		}
	},[props,page])

	if (!feedState) {return (<Preloader/>)}

	return (
		<>
			<Dialog open={openEditor}
				onClose={() => {setOpenEditor(false)}}
			>
				<Grid container sx={{minWidth:"300px"}}>
					<Grid item xs={12} sx={{p:3,display:'grid',justifyContent:'center',gap:1}}>
						<Typography>Вы собираетесь удалить это событие ("{!!changesState && changesState.event && changesState.event.eventtitle}") ?</Typography>
						<Button sx={theme.customButton} onClick={()=>{handleDelete({confirm:true})}}>Удалить</Button>
					</Grid>
				</Grid>
			</Dialog>

			<FeedSlider {...{feedState,callback:handleEdit,pagination: {page, count, setPage}}}/>

			<Box>
					<Button
						fullWidth
						sx={!readonly ? {display:'none'} : theme.customButton}
						onClick={handleAdd}
					>
						Добавить событие
					</Button>
				</Box>
			<Formik
				initialValues={initialValues}
				enableReinitialize
				validationSchema={Yup.object().shape({
					title: Yup.string().min(3).max(50, 'Max ${max}').required('Необходимое поле'),
					text: Yup.string().min(3).max(150, 'Max ${max}'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					handleSubmit(values, { setSubmitting })
				}}
			>{({ errors, handleBlur, handleChange, touched, resetForm, values, setFieldValue }) => 
			
			{	
				return (
				<Form autoComplete="off">
					<Grid item container xs={12} sx={readonly ? { display: 'none' } : {}}>
						<Grid item xs={12} sx={{ p: 3, display:'flex', gap:1, flexDirection: 'column'}}>
							<TextField
								error={Boolean(touched.title && errors.title)}
								fullWidth
								label={
									'Название ' +
									String(
										touched.title && errors.title ? errors.title + '. ' : ''
									)
								}
								name="title"
								onBlur={handleBlur}
								onChange={handleChange}
								required
								value={values.title}
								variant="outlined"
								disabled={readonly || (!!values.event && values.event.eventtype !== "ADD_POST")}
							/>
							<TextField
								error={Boolean(touched.text && errors.text)}
								fullWidth
								label={
									'Текст ' +
									String(
										touched.title && errors.text ? errors.text + '. ' : ''
									)
								}
								name="text"
								onBlur={handleBlur}
								onChange={handleChange}
								required
								multiline
								value={values.text}
								variant="outlined"
								disabled={readonly || (!!values.event && values.event.eventtype !== "ADD_POST")}
							/>
							<Box flexGrow="1" />
								<Box justifyContent="right" display="flex" gap={1}>
									{!!values.event &&
									<>
										<SwitchComponent 
											name="hidden"
											label={{ off: 'Показывать', on: 'Скрывать' }}
											checked={values.hidden}
											onBlur={handleBlur}
											onChange={(e) => {setFieldValue("hidden", e.target.checked)}}
											readonly={readonly}
										/>
										{values.event.eventtype === "ADD_POST" ?
											<Button
												variant="outlined"
												size="large"
												type="button"
												onClick={() => {handleDelete({values})}}
												disabled={readonly}
												sx={theme.customButton}
											>
												Удалить
											</Button>
											:
											<Box sx={{border:'1px solid #eee',borderRadius:1,p:1}}>
												<Typography variant="caption">
													Системные события нельзя удалить или изменить, но при необходимости можно скрыть
												</Typography>
											</Box>
										}
										<Box flexGrow="1"/>
									</>
									
									}
									<Button
										variant="outlined"
										type="submit"
										disabled={readonly}
										sx={theme.customButton}
									>
										Сохранить
									</Button>
									<Button
										variant="outlined"
										type="button"
										onClick={() => handleCancel(resetForm)}
										disabled={readonly}
										sx={theme.customButton}
									>
										Отмена
									</Button>
								</Box>
							</Grid>
						</Grid>
					</Form>
				)}}
			</Formik>
		</>
	)
}

export default FeedEditor
