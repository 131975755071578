import { Link as RouterLink, useNavigate,useParams } from 'react-router-dom';

import { useEffect, useContext, useState, useCallback } from 'react'
import { useTheme } from '@mui/material/styles';
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { NavContext } from '../../context/NavContext'

import { DiscordIcon, TelegramIcon } from '../../components/graphics/icons'

import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
	Box,
	Button,
	Checkbox,
	Container,
	FormHelperText,
	Link,
	TextField,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Grid,
	MenuItem,
	Fade,
	Collapse,
	IconButton
} from '@mui/material';

import MessageAlert from '../../components/MessageAlert'
import SubmitButton from '../../components/SubmitButton';


const Field = ({q,i,touched,values,handleChange,handleBlur,errors,qArray}) => {
	if (!q) {return (<></>)}
	console.log(errors)
	const getLink = () => {
		if (q.linkvariant == values[q.link]) {
			const link = qArray.find((item) => item.name == q.link) //возвращает объект на уровень выше
			if (!!link) {
					//console.log("qArray[q.link].link:", link.link)
					if (values[link.link] == link.linkvariant) {
						//console.log('link.linkvariant: ', link.linkvariant)
						return true
					}
			}
			if (!link.link) {
				return true
			}
		return false
		}
	}

	return (
		<Box key={i}>
		{((!!q.link && getLink()) || !q.link) &&
			<Collapse in={true} timeout={1000} appear={true} key={i}>
				<Fade in={true} timeout={2000} appear={true}>
					<Box sx={{py:3}}>
						<Typography variant="h6" sx={{fontWeight:300}}>
							{q.question}
						</Typography>
						<TextField 
							InputProps={{
								style: {fontWeight:300},
							}}
							InputLabelProps={{
								style: {fontWeight:300},
							}}
							error={Boolean(errors[`${q.name}`])}
							fullWidth
							helperText={errors[`${q.name}`] || q.required && "Обязательное поле"}
							{...(
								q.type=='multiline' && {multiline:true,rows:'5',variant:'outlined'} ||
								q.type=='select' && {select:true} ||
								{variant:'standard'}
							)}
							margin="normal"
							label={q.helpertext}
							name={q.name}
							onBlur={handleBlur}
							onChange={handleChange}
							value={values[`${q.name}`]}
						>
							{!!q.options && q.options.map((option) => (
								<MenuItem key={option.value} value={option}>
									{option}
								</MenuItem>
							))}
						</TextField>
					</Box>
				</Fade>
			</Collapse>
		}
		</Box>
	)
}

const SurveyView = () => {
	const { id } = useParams()
	const theme = useTheme()
	const navigate = useNavigate()
	const {settext, errortext} = useMessage()
	
	const message = settext
	const { setMessage, messageText } = useMessage()
	const { request, error, clearError } = useHttp()
	const { setnav } = useContext(NavContext)
	
	const [ surveyData, setSurveyData ] = useState()
	const [ Questions, setQuestions ] = useState([])
	const [ completed, setCompleted ] = useState(false)

	const transformQuestions = (data) => {
		const questions = data.questions
		let result = []
		if (!!questions.length) {
			result = questions.sort((a,b)=>a.order-b.order).map((q,i) => {
				let res = {
					type: q.type || "",
					name: q.variable || "",
					required: q.required || "",
					question: q.question || "",
					helpertext: q.type == 'select' ? '' : q.answer || "",
					options: (q.type == 'select') ? q.answer.split(',') : null,
					link: q.link  || "",
					linkvariant: q.linkvariant || ""
				}
				return res
			})
			setQuestions(result)
		}
	}

	const fetchData = async () => {
		try {
			const fetched = await request(
				`/api/research/survey/${id}`,
				'GET',
				null,
				{}
			)
			if (!!fetched && fetched.data) {
				setSurveyData(fetched.data)
				transformQuestions(fetched.data)
			}
		} catch(e) {
			console.log(e)
		}
	}

	const saveHandler = async (formArr) => {
		try {
			const data = await request(
				'/api/research/survey', 
				'POST',
				{ ...formArr },
				{ listid: id }
				)
			if (data.message == 'ok') {
				setCompleted(true)
			}
			setMessage(data.message)
		} catch (e) {}
	}
	
	const getValidateString = (v) => {
		let typemsg = null
		let result = Yup.string()

		if (v.type == "email") {
			typemsg = 'Must be a valid email'
			result = result.email(typemsg)
		}
		if (v.type == "singleline") {
			result = result.max(255)
		}
		if (v.type == "multiline") {
			result = result.max(1000)
		}
		if (v.required == true) {
			result = result.required('Обязательное поле')
		}
		return result
	}

	const initialValidate = Questions
	.reduce((a,v)=>({...a,[v.name]:getValidateString(v)}),{})

	const initialValues = Object.entries(initialValidate)
	.map(([key,value])=>key)
	.reduce((a,v)=>({...a,[v]:''}),{})

	console.log("initialValues: ", initialValues)
	console.log("initialValidate: ", initialValidate)

	const handleSubmit = (e,setSubmitting) => {
		console.log(e)
		console.log(message)
		saveHandler(e)
		setSubmitting(true)
	}

	const getById = useCallback(()=>{
		if (!!id) {fetchData()}
	}, [id])
	
	useEffect(() => {
		console.log(error)
		setMessage(error,'error')
	}, [error, setMessage, clearError])

	useEffect(() => {
		getById()
		setnav('survey')
	}, [setnav])

	return (
		<>
			<MessageAlert {...{messageText,setMessage,callback: clearError}}/>
			<Grid container sx={{py:10}}>
				<Grid item xs={0} md={3}></Grid>
				<Grid item xs sx={{[theme.breakpoints.down('md')]: {p:2}}}>
					<Box sx={{ mb: 3 }}>
						<Typography color="textPrimary" variant="h2" align="center">
							Опрос
						</Typography>
					</Box>
					<Box sx={{pb:1,pt:3}}>
						<Typography
							align="center"
							color="textSecondary"
							variant="h6"
							weight="slim"
						>
							{!!surveyData && surveyData.description}
						</Typography>
					</Box>
					<Collapse in={!completed}>
					<Formik
						initialValues={initialValues}
						validationSchema={
							Yup.object().shape(initialValidate)
						}
						onSubmit={(values, { setSubmitting }) => {
							handleSubmit(values)
						}}
					>
						{({
						errors,
						handleBlur,
						handleChange,
						handleSubmit,
						isSubmitting,
						touched,
						values
						}) => (
							<Form autoComplete='off'>
								{!!Questions.length && Questions.sort((a,b)=>a.order-b.order).map((q,i,qArray)=> 
									Field({q,i,touched,values,handleChange,handleBlur,errors,qArray})
								)}
								<Box sx={{height:theme.spacing(3)}}/>
								<Box sx={{ py: 2, display: 'flex', justifyContent: 'center', flexDirection:'column'}}>
									<Box sx={{ py: 2, display: 'flex', justifyContent: 'center'}}>
										<SubmitButton disabled={isSubmitting} title="Отправить" />
									</Box>
									<Box textAlign="center">
										<Typography align="center" weight="slim" variant="subtitle0">
											Нажимая на кнопку, вы даете согласие на обработку персональных данных и соглашаетесь c политикой конфиденциальности
										</Typography>
										<Typography align="center" weight="slim" variant="body2">
											С политикой конфиденциальности можно ознакомиться здесь:<br/>
										</Typography>
										<Button
											component={RouterLink}
											to="../privacy"
											target="_blank"
											sx={{...theme.textlinkstyle,textTransform:'none',fontWeight:300}}
										>
											https://ai-aggregator.ru/privacy
										</Button>
									</Box>
								</Box>
							</Form>
						)}
					</Formik>
					</Collapse>
					{!!completed &&
						<Fade in={completed} appear={true}>
							<Box sx={{
								background:"#ffffff",
								p:3,
								
								borderRadius:3,
								display:'flex',
								flexDirection:'column',
								gap:1
								}}>
								<Box sx={{background:"#e7fff5",p:3, borderRadius:3,textAlign:'center'}}>
									<Typography variant="h5" weight="slim">
										Ваша анкета отправлена
									</Typography>
									<Box height={10}/>
									<Typography weight="slim">
										Благодарим вас за участие в опросе!
									</Typography>
								</Box>
								<Box sx={{background:"#ffffff",p:3,borderRadius:3,textAlign:'center'}}>
									<Typography  weight="slim">
										Оцените возможнности нашей платформы, завершив регистрацию перейдя по ссылке из письма, которое мы отправили на указанный вами адрес
									</Typography>
								</Box>
							</Box>
						</Fade>
					}
				</Grid>
				<Grid item xs={0} md={3}></Grid>
			</Grid>
			{!completed &&
			<Grid container sx={{height:'500px'}}>
				<Grid item xs={12} sx={{display:'flex', justifyContent: 'center', alignItems: 'center', background: '#1e1e1e'}}>
					<Box textAlign="center" sx={{overflow:'visible'}}>
						<Typography color="#f5f5f5" weight="slim" variant="h5" marginBottom={3}>
							Присоединяйтесь к нашему сообществу<br/>
							чтобы быть в курсе последних событий
						</Typography>
						<Box textAlign="center" sx={{p:2}}>
							<IconButton 
								
								href="https://t.me/aiaggregatorsupport"
								target="_blank"
							>
								<TelegramIcon sx={{fontSize:52}}/>
							</IconButton>
							<IconButton 
								
								href="https://discord.gg/NeefTqgeGH"
								target="_blank"
							>
								<DiscordIcon sx={{fontSize:52}} />
							</IconButton>
						</Box>
					</Box>
				</Grid>
			</Grid>
			}
		</>
	)

}

export default SurveyView