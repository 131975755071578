import {useState, useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import { NavContext } from '../context/NavContext'

export const useHttp = () => {
    const { refresh } = useContext(NavContext)
    const auth = useContext(AuthContext)
	const Navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const request = useCallback(async (url, method = 'GET', body = null , headers = {}) => {
        setLoading(true)
		
        try {
            if (body) {
                if (Object.keys(headers).length == 0) {
                    
                }
                if (!headers['Content-Type']) {
                    headers['Content-Type'] = 'application/json'
                    body = JSON.stringify(body)
                }
                
				console.log("HTTP HOOK BODY:", body)
                
            }
			
        const response = await fetch(url, {method, body, headers})
        const data = await response.json()
	
		if (response.status === 401) {
			console.log("unauthorized")
			auth.logout()
            refresh()
		}

        if (response.status === 404) {
            Navigate('/404')
		}

        if (!response.ok) {
            throw new Error(data.message || 'Something wrong')
        }
		
        setLoading(false)

        return data

        } catch (e) {
            setLoading(false)
            setError(e.message)
            return {error: e.message}
        }
    }, [])

    const clearError = useCallback(() => setError(null), [])

    return { 
		loading, 
		request, 
		error, 
		clearError 
	}
}