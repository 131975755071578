import { useEffect, useState, useContext, useCallback } from 'react'
import {
	Box,
	Divider,
	Grid,
	responsiveFontSizes,
	TextField,
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Button,
	Table,
	TableContainer,
	TableBody,
	TableRow,
	TableCell,
	TableHead
} from '@mui/material'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SchemaTools from '../../helpers/schematools'
import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'
import ControlsComponent from '../../components/ControlsComponent'
import { useNavigate } from 'react-router-dom'
import Preloader from '../../components/Preloader'

let category = []

const RequestSummary = () => {
	const navigate = useNavigate()
	const { compareObjects } = SchemaTools()
	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const {
		selectedRequest,
		refresh,
		refreshCheck,
		setSelectedRequest,
		selectData,
	} = useContext(NavContext)
	const [readonly, setReadonly] = useState(true)
	const [isSubmitting, setSubmitting] = useState(false)
	const [selectedResponse, setSelectedResponse] = useState({
		description: '',
		deadline: '',
		cost: '',
	})
	const [currentRequest, setCurrentRequest] = useState({
		title: '',
		ownerId: '',
		code: '',
		private: false,
		published: false,
		longdescription: '',
	})

	const [initialState, setInitialDataState] = useState({
		title: '',
		shortdescription: '',
		website: '',
		longdescription: '',
		description: '',
		category: '',
		terms: '',
		response: '',
		responses: [
			{ createdby: '', description: '', userdata: { firstName: '' } },
		],
	})

	const [changesState, setChangesState] = useState(initialState)

	const fetchRequestDetails = async (value) => {
		try {
			const fetched = await request(
				`/api/request/owner/${value.ownerId}/${value.code}`,
				'GET',
				null,
				{
					Authorization: `Bearer ${token}`,
					part: 'overview',
				}
			)

			const requestresponsedata = { ...fetched.ResponseData }
			const requestdetaildata = compareObjects(initialState, {
				...fetched.RequestDetailData,
			})
			const requestdata = compareObjects(
				initialState,
				{ ...fetched.RequestData },
				{ keepold: true }
			)
			const data = compareObjects(initialState, {
				...requestdata,
				...requestdetaildata,
				...requestresponsedata,
			})

			setChangesState(data)
			setInitialDataState(data)
			setCurrentRequest(fetched.RequestData)
			setSelectedResponse(data.responses[0])
			console.log('FETCHED REQUEST DATA :', requestresponsedata)
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		setSelectedResponse()
		if (!selectedRequest.date) {
			return
		}
		fetchRequestDetails(selectedRequest)
		if (selectData.categories) {
			category = [{ _id: '', title: '' }, ...selectData.categories]
			console.log(category)
		}
	}, [selectedRequest])

	if (!changesState.title) {return <Preloader />}
	return (
		<>
			<Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
				<Box sx={{ p: 3 }}>
					<Typography variant="h4">
						Request Summary ({changesState.title}){' '}
					</Typography>
					<Typography>{changesState.shortdescription}</Typography>
				</Box>
				<Divider />
				<Box sx={{ p: 3 }}>
					<Typography>{changesState.longdescription}</Typography>
				</Box>
				<Divider />
				<Grid container>
					<Grid item xs={12} md={6}>
						<Box sx={{ p: 3 }}>
							<Typography variant="h6">Responses</Typography>
						</Box>
					</Grid>
				</Grid>
				<Divider />
				<Grid item xs={12}>
					<TableContainer>
							<Table aria-label="Endpoints list">
							<TableHead sx={{backgroundColor: '#fafafa'}}>
									<TableRow>
										<TableCell />
										<TableCell align="left">From</TableCell>
										<TableCell align="right">Message</TableCell>
										<TableCell align="right">Deadline</TableCell>
										<TableCell align="right">Cost</TableCell>
										<TableCell />
										<TableCell />
									</TableRow>
							</TableHead>
							<TableBody>
							{changesState.responses.map((item, index) => (
								<TableRow key={index}>
									<TableCell sx={{width:'0'}}/>
									<TableCell align="left">{item.userdata.name}</TableCell>
									<TableCell align="right">{item.description.length > 50 ? item.description.slice(0,50) + '...' : item.description}</TableCell>
									<TableCell align="right">{item.deadline}</TableCell>
									<TableCell align="right">{item.cost}</TableCell>
									<TableCell align="right"><Button variant="outlined" onClick={()=>{
										navigate('../responses')
									}}>Open</Button></TableCell>
									<TableCell sx={{width:'0'}}/>
								</TableRow>
							))}
							</TableBody>
							</Table>
					</TableContainer>
				</Grid>
				</Box>
		</>
	)
}

export default RequestSummary
