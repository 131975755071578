import {useState, useCallback, useContext, useEffect } from 'react'
import { useHttp } from './http.hook'
import { AuthContext } from '../context/AuthContext'
import { useNavigate, useLocation } from 'react-router-dom'

import ScatterPlotOutlinedIcon from '@mui/icons-material/ScatterPlotOutlined';
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined';
import SettingsInputComponentOutlinedIcon from '@mui/icons-material/SettingsInputComponentOutlined';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import CheckIcon from '@mui/icons-material/Check';

export const useTags = () => {
	const serverpath = '/api/tag'

	const { loading, request } = useHttp()
	const { token } = useContext(AuthContext)
	const [ tags, setTags ] = useState([])
	const location = useLocation()

	const iconprops = {color: 'action', sx:{fontSize:'14pt',p:0,m:0}}

	const categoryIcons = {
		'classify': ScatterPlotOutlinedIcon,
		'nlp': ThreePOutlinedIcon,
		'vision': RemoveRedEyeOutlinedIcon,
		'chatbots': SmartToyOutlinedIcon,
		'speech': RecordVoiceOverOutlinedIcon,
		'analysis': AnalyticsOutlinedIcon,
		'fintech': CalculateOutlinedIcon,
		'robotics': PrecisionManufacturingOutlinedIcon,
		'logistics': LocalShippingOutlinedIcon,
		'advert': AdjustOutlinedIcon,
		'business': AutoGraphOutlinedIcon,
		'security': SecurityOutlinedIcon,
		'health': MonitorHeartOutlinedIcon,
		'retail': StorefrontOutlinedIcon,
		'legaltech': BalanceOutlinedIcon,
		'iot': SettingsInputComponentOutlinedIcon,
		'industry': FactoryOutlinedIcon,
		'validate': CheckIcon
	}

	const fetchTags = useCallback(async () => {
		try {	
			const response = await request(
				`${serverpath}/getallwithstats`,
				'GET',
				null,
				{Authorization: `Bearer ${token}`}
			)
			if (!response || !response.data) {throw ''}
			console.log("useTags: ", response.data)
			return response.data
		} catch(e) {
			console.log(e)
		}
	},[])

	useEffect(async () => {
		setTags(await fetchTags())
	}, [])

	const getTags = async () => {
		if (!!tags && !!tags.length) {
			return tags
		} else {
			const res = await fetchTags()
			setTags(res)
			return res
		}
		//console.log(tags)
	}

	const getCategory = (product) => {
		
		//console.log("product.category : ", product.category)
		if (!!product && !!product.category && !Array.isArray(product.category)) { product.category = [product.category] }
		if (!!product && !!product.category && !!tags.length && Array.isArray(product.category)) {
		
			// console.log("product.category : ", product.category)
		const result = product.category.map((cat,index) => {
			const tag = tags.find((tag) => {
				const result = tag._id == cat
				return result
			})
			return {...tag, icon: {Element: categoryIcons[tag.title], props: {...iconprops}}}
		})
	
		return result
		} else {return null}
	}

	return {
		getTags,
		tags,
		getCategory
	}
}