import { useState } from 'react'
import {Button, Box, Dialog} from '@mui/material'
import AutoAwesomeOutlined from '@mui/icons-material/AutoAwesomeOutlined'

const MediaView = ({code, type}) => {
	let data = ''
	let audiodata = null
	
	const setPlayer = () => {
		var audiotype = !!code.toString().match(/soundbase64/gmsi)
		if (audiotype) {
			audiodata = code.toString().replace(/^\s*[\r\n]/gm,'').replace(/.*(soundBase64"?:?"?)/ms,'').match(/".+?"/,'').toString().replace(/"/g,'')
			console.log("audiodata", audiodata)
			data = audiodata
			var snd = new Audio(`data:audio/x-wav;base64, ${data}`);
			snd.play();
		}
	}
	
	const [ openState, setOpenState ] = useState(false)

	const handleOpen = () => {
		setPlayer()
	}
	const handleClose = () => {
		setOpenState(false)
	}

	return (
		<>{!openState ?
			<Box flexGrow="1" display="flex" justifyContent="flex-end" padding={1} height="0" >
				<Button 
					variant="outlined" 
					sx={{ position: 'relative', minHeight: '50px' }}
					onClick={handleOpen}
				>
					<AutoAwesomeOutlined />
				</Button>
			</Box>
			:
			<Dialog 
				open={openState}
				onClose={handleClose}
				>
				{data}
			</Dialog>
		}
		</>
	)
}

export default MediaView
