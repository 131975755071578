import {
	Grid,
	Typography,
	Box,
	Divider,
	Card,
	Paper,
	CardContent,
	CardHeader,
	List,
	ListItem,
	ListItemIcon,
	ListItemText
} from '@mui/material'

const ProductOverview = ({data}) => {
	return (
		<>
			<Box sx={{p:3}}>
			<Typography>{data.longdescription}</Typography>
			</Box>
		</>
	)
}

export default ProductOverview