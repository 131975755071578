const csv = require('csvtojson')

const csvjson = (file) => {
	return new Promise ((resolve, reject) => {
		var reader = new FileReader()
		reader.readAsText(file, 'UTF-8')
		reader.onload = async () => {
			csv()
			.fromString(reader.result)
			.then((jsonArrayObj) => {
				const keys = Object.keys(jsonArrayObj[0])
				resolve({jsonArrayObj,keys})
			})
		}
	})
}

export default csvjson