import { useState, useEffect } from 'react'
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	TextField,
	Typography,
	Avatar,
	CardActions
} from '@mui/material'
import {Link} from 'react-router-dom'
import moment from 'moment';
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import {Occupation} from '../../__mocks__/Occupation'

const city = [
	{
		value: 'alabama',
		label: 'Alabama',
	},
	{
		value: 'new-york',
		label: 'New York',
	},
	{
		value: 'san-francisco',
		label: 'San Francisco',
	},
]

const AccountProfileDetails = (props) => {
	const { occupationList, getOccupationLabel } = Occupation()
	const details = props.details
	const submitHandler = props.submitHandler
	console.log(props)
	const initialstate = {
		firstName: '',
		lastName: '',
		email: '',
		website: '',
		country: '',
		city: '',
		description: '',
		occupation: '',
		org: ''
	}
	//console.log("INIT: ",props)
	//const user = {...initialstate,...props}
	const [valuesstate, setValues] = useState(initialstate)
	//console.log("VALUES: ",{...values})

	useEffect(() => {
		console.log('details: ', { ...details })
		setValues({ ...initialstate, ...details })
	}, [details])

	//   const handleChange = (event) => {
	//     setValues({
	//       ...valuesstate,
	//       [event.target.name]: event.target.value
	//     });
	//   };

	const [readonly, setReadonly] = useState(true)

	const handleSubmit = (values, setSubmitting) => {
		console.log('message', values)
		submitHandler(values)
		handleEdit()
	}
	const handleEdit = () => {
		setReadonly(!readonly)
	}

	const handleCancel = (rf) => {
		rf()
		setReadonly(!readonly)
	}
	return (
			<Formik
				initialValues={valuesstate}
				enableReinitialize
				validationSchema={Yup.object().shape({
					firstName: Yup.string()
						.min(2,'Minimum symbols: ${min}')
						.max(30, 'Maximum symbols limit exceeded ${max}')
						.required('Short description is required'),
					lastName: Yup.string()
						.min(2,'Minimum symbols: ${min}')
						.max(30, 'Maximum symbols limit exceeded ${max}')
						.required('Short description is required'),
					description: Yup.string()
						.min(10,'Minimum symbols: ${min}')
						.max(2000, 'Maximum symbols limit exceeded ${max}')
						.required('Description is required'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					handleSubmit(values, { setSubmitting })
					setSubmitting(false)
				}}
			>
				{({
					errors,
					handleBlur,
					handleSubmit,
					handleChange,
					isSubmitting,
					touched,
					resetForm,
					values,
				}) => (
					<Form autoComplete="off">
						
							<Grid container spacing={3}>
								
								
								<Grid item  xs={12} container spacing={3} alignContent='start'>
									<Grid item md={6} xs={12}>
										<TextField
											error={Boolean(touched.firstName && errors.firstName)}
											fullWidth
											helperText={!!errors.firstName && errors.firstName}
											label="First name"
											name="firstName"
											onChange={handleChange}
											required
											value={values.firstName}
											variant="outlined"
											disabled={readonly}
										/>
									</Grid>
									<Grid item md={6} xs={12}>
										<TextField
											error={Boolean(touched.lastName && errors.lastName)}
											fullWidth
											helperText={!!errors.lastName && errors.lastName}
											label="Last name"
											name="lastName"
											onChange={handleChange}
											required
											value={values.lastName}
											variant="outlined"
											disabled={readonly}
										/>
									</Grid>
									<Grid item md={6} xs={12}>
									<TextField
										fullWidth
										label="Country"
										name="country"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										value={values.country}
										variant="outlined"
										disabled={readonly}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<TextField
										fullWidth
										label="Select City"
										name="city"
										onBlur={handleBlur}
										onChange={handleChange}
										SelectProps={{ native: true }}
										value={values.city}
										variant="outlined"
										disabled={readonly}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<TextField
										fullWidth
										label="Select occupation"
										name="occupation"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										select
										SelectProps={{ native: true }}
										value={values.occupation}
										variant="outlined"
										disabled={readonly}
									>
										{occupationList.map((option) => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</TextField>
								</Grid>
								<Grid item md={6} xs={12}>
									<TextField
										fullWidth
										label="Company"
										name="org"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.org}
										variant="outlined"
										disabled={readonly}
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<TextField
										error={Boolean(touched.website && errors.website)}
										fullWidth
										helperText={!!errors.website && errors.website}
										label="Website"
										name="website"
										onChange={handleChange}
										value={values.website}
										variant="outlined"
										disabled={readonly}
									/>
								</Grid>
								</Grid>
								
								
								
								
								<Grid item xs={12}>
									<TextField
										error={Boolean(touched.description && errors.description)}
										helperText={
											'Расскажите о себе. ' +
											String(
												touched.description && errors.description
													? errors.description + '. '
													: ''
											) +
											(' всего символов: ' +
												values.description.toString().length +
												' из ' +
												2000)
										}
										fullWidth
										multiline
										rows={10}
										label="About"
										name="description"
										onChange={handleChange}
										value={values.description}
										variant="outlined"
										disabled={readonly}
									/>
								</Grid>
								
							</Grid>
						<Divider />
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-start',
								p: 2,
							}}
						>
							{readonly ? (
								<Button
									variant="outlined"
									onClick={handleEdit}
								>
									Edit
								</Button>
							) : (
								<>
									<Button
										variant="outlined"
										onClick={() => {
											handleCancel(resetForm)
										}}
									>
										Cancel
									</Button>
									<Button
										variant="outlined"
										disabled={isSubmitting}
										type="submit"
									>
										Save details
									</Button>
								</>
							)}
						</Box>
					</Form>
				)}
			</Formik>
	)
}

export default AccountProfileDetails
