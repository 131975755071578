import { useState, useCallback, useContext, useEffect } from 'react'
import { Link as RouterLink, Navigate } from 'react-router-dom'

import {
	Grid,
	Box,
	Avatar,
	Typography,
	Tabs,
	Tab,
	Button,
	IconButton
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { useParams } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'
import { useHttp } from '../../hooks/http.hook'
import Preloader from '../../components/Preloader'
import PageTitle from '../../components/PageTitle';

import OrgViewOverview from './OrgViewOverview'
import OrgViewApi from './OrgViewApi'
import OrgViewRequest from './OrgViewRequest'
import OrgViewContest from './OrgViewContests'
import AvatarComponent from '../../components/AvatarComponent'
import ViewCategory from '../../components/tags/view.category'
import OrgViewStatsblock from './OrgViewStatsblock'
import ComponentOutlet from '../../components/template/ComponentOutlet'

const OrgView = (props) => {

	const parts = [
		{
			label: 'Описание',
			path: 'overview',
			element: OrgViewOverview,
			disabled: false,
		},
		{
			label: 'API',
			path: 'api',
			element: OrgViewApi,
			disabled: false,
		},
		{
			label: 'Конкурсы',
			path: 'contest',
			element: OrgViewContest,
			disabled: false,
		},
		{
			label: 'Запросы',
			path: 'request',
			element: OrgViewRequest,
			disabled: false,
		},
	]

	const basepath = "/marketplace/organizations"
	const path = props.path
	const params = useParams()
	const theme = useTheme()
	const { user, org } = params
	console.log("user:", user, "org: ", org)
	console.log(path)

	const { setnav,refresh,refreshCheck } = useContext(NavContext)
	const { token, userId } = useContext(AuthContext)
	const { request } = useHttp()
	const [ partIndex, setPartIndex ] = useState(parts.map(i=>i.path).indexOf(path) || 0)
	
	const [ isLoading, setIsloading ] = useState(false)
	const [ orgdetails, setOrgdetails ] = useState()

	

	const fetchDetails = useCallback(async () => {
		setIsloading(true)
		try {
			const fetched = await request(`/api/org/view/${user}/${org}`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			console.log('Fetched: ', fetched)
			if (!!fetched.data) {
				setOrgdetails({...fetched.data})
				console.log(fetched.data)
				setIsloading(false)
			}
		} catch (e) {
			console.log(e)
		}
		
	}, [token, request, refreshCheck, user, org])

	const handleChange = (event, newValue) => {
		setPartIndex(newValue)
	}

	useEffect(()=>{
		setnav('orgview')
		fetchDetails()
		return () => {}
	},[])

	useEffect(() => {
		fetchDetails()
		return () => {}
	},[refreshCheck,user,org])



	if (!orgdetails || !!isLoading) {
		return <Box sx={{display: 'flex', height:'100%'}}><Preloader /></Box>
	}

	return (
		<>
			<Grid container sx={{height: '100%'}}>
				<Grid
					item
					xs={12}
					sx={{
						background: theme.palette.bgGray0,
						minHeight: '400px',
						//borderBottom: `1px solid ${theme.palette.borderColorLight}`,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				><Box flexGrow="1" />
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							width:'100%'
						}}
					>	<Box sx={{pb:1}}>
							<AvatarComponent avatar={orgdetails.avatar} raised={true} text={orgdetails.title} size="big" />
						</Box>
						{!!orgdetails && !!orgdetails.title &&
						<Typography
							{...(orgdetails.title.length > 12 ? {variant:"h4"} : {variant:"h3"})}
							align="center"
							sx={{pb:1,px:1}}
						>
								{orgdetails.title}
						</Typography>
						}
						<Box sx={{display:'flex',flexWrap:'nowrap',alignItems:'center',gap:3,pb:0}}>
							<Typography variant="body2">
								{!!orgdetails.country && 'Страна: ' + orgdetails.country}
								{!!orgdetails.city && 'Город:' +  orgdetails.city}
							</Typography>
							{orgdetails.website && 
								<Box sx={{display:'inherit',flexWrap:'nowrap',alignItems:'center'}}>
									<Typography variant="body2">Сайт:</Typography>
									<Typography variant="body2" onClick={()=>{window.open(orgdetails.website, '_blank')}} component={Button} sx={{textTransform:'none'}}>
										{orgdetails.website}
									</Typography>
								</Box>
							}
						</Box>
						{orgdetails.category && 
							<ViewCategory {...{category: orgdetails.category}}/>
						}
					</Box>
					<Box flexGrow="0.5" />
					<Box sx={{mb:1}}>
						<OrgViewStatsblock {...{user,org,request,token,userId,refresh}} orgdetails={orgdetails}/>
					</Box>

					<Box>
						{!!parts.filter((part)=>(orgdetails[part.path] && orgdetails[part.path].length)).length &&
							<Tabs value={partIndex} onChange={handleChange} sx={{height:0}} >
								{parts.map((part, index) => {	
									if (!part.disabled && part.path !== 'overview' && (!orgdetails[part.path] || !orgdetails[part.path].length)) {
										return
									} 
									
									return (
									<Tab 
										style={{textTransform: 'none'}}
										component={RouterLink}
										disabled={part.disabled}
										label={part.label}
										value={index}
										key={index}
										to={{
											pathname: `${basepath}/${params.user}/${params.org}/` + part.path,
										}}
									/>
								)}
								)}
							</Tabs>
						}
					</Box>
				</Grid>
				<ComponentOutlet path={path} parts={parts} data={orgdetails} />
			</Grid>
			<PageTitle title={orgdetails.title}/>
		</>
	)
} 

export default OrgView