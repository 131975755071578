import {useEffect, useState, useContext} from 'react'

import {
	Box,
	Typography,
	Popper,
	Fade,
	Paper,
	ClickAwayListener,
	InputBase,
	Button
} from '@mui/material'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { useHttp } from '../../hooks/http.hook'

import { AuthContext } from '../../context/AuthContext'

import CategoryEditor from './editor.category'

const CategorySelector = ({data,readonly,stateValues,handleGather}) => {
	const serverpath = '/api/tag'
	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const [ searchName, setSearchName ] = useState('')

	const [ tagList, setTagList ] = useState([])
	const [ selectedTags, setSelectedTags ] = useState(!!stateValues && stateValues.category ? stateValues.category : [])

	const [ anchorEl, setAnchorEl ] = useState(null);
	const [ open, setOpen ] = useState(false)
	const anchorElcontainer = document.getElementById('popcontainertag')

	const fetchData = async () => {
		try {
			const queryString = new URLSearchParams({searchname: searchName})
			const fetched = await request(
				`${serverpath}/search?${queryString.toString()}`,
				'GET',
				null,
				{Authorization: `Bearer ${token}`}
			)
			if (!fetched || !fetched.data) {throw ''}
			setTagList(fetched.data)
		} catch(e) {
			console.log(e)
		}
	}

	const fetchCategoryById = async (category) => {
		try {
			const queryString = new URLSearchParams({...category})
			const response = await request(
				`${serverpath}/getbyid?${queryString.toString()}`,
				'GET',
				null,
				{Authorization: `Bearer ${token}`}
			)
			if (!response || !response.data) {throw ''}
				console.log("response: ", response)
				setSelectedTags(response.data)
		} catch(e) {

		}
	}

	const clickAwayHandler = () => {
		setOpen(false)
	}

	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget)
		setOpen(true)
	}

	const handleAddCategory = (item) => {
		const candidate = selectedTags.find((tag)=>{
			return tag.title == item.title
		})
		if (!candidate) {
			setSelectedTags([...selectedTags,item])
		}
	}

	const deleteTag = (tag) => {
		const filter = selectedTags.filter((item) => {
			return item.title !== tag.title
		})
		setSelectedTags(filter)
	}

	const onChangeSearchTitle = (e) => {
		setOpen(true)
		setSearchName(e.target.value)
		if (!e.target.value) {
			setTagList([])
			setOpen(false)
		}
	}

	useEffect(() => {
		if (!stateValues) {setSelectedTags([])}
	},[stateValues])

	useEffect(() => {
		if (!!selectedTags.length) {
			handleGather({values:selectedTags,type:'category'})
		}
	}, [selectedTags])

	useEffect(() => {
		if(!!searchName) {fetchData()}
	}, [searchName])

	useEffect(() => {
		if (!!data && data.category) {
			fetchCategoryById(data.category)
		}
	}, [data])

	useEffect(() => {
		if (!stateValues) {
			fetchCategoryById(data.category)
		}
	}, [stateValues])

	return (
		<Box sx={{width:'100%',height:'100%',minWidth:'200pt',display:'flex',flexDirection:'column',gap:1}}>
			<Typography>Категории</Typography>
			<Box>
			<Box sx={{p:1,border:'1px solid #eee',borderRadius:'5pt',display:'flex',gap:0.5,flexWrap:'wrap',	}}>
				{!!selectedTags && !!selectedTags.length && selectedTags.map((tag,index) => (
					<Box key={index} sx={{border:'1px solid #e0e0e0',borderRadius:'10pt',p:0.5,px:2,background:'#f9f9f9',alignItems:'center',display:'inline-flex',gap:1}}>
						<Typography variant="caption" noWrap>
							{!!tag.variations && tag.variations.ru || tag.title}
						</Typography>
						{!readonly &&
						<ClearOutlinedIcon sx={{fontSize:'10pt',cursor:'pointer'}} onClick={()=>deleteTag(tag)}/>
						}
					</Box>
				))}
			</Box>
			</Box>
			{!readonly && <>
			<Box>
				<Box sx={{p:1,border:'1px solid #eee',borderRadius:'5pt',zIndex:1999}}>
					<InputBase
						fullWidth
						placeholder="Категория"
						value={searchName}
						onChange={onChangeSearchTitle}
						onClick={handleOpen} aria-describedby={'transitions-poppertag'}
						sx={{zIndex:1999}}
					/>
				</Box>
				<Box id="popcontainertag">
					{open && 
					<ClickAwayListener onClickAway={clickAwayHandler}>
						<Popper
							
							id={'transitions-poppertag'}
							open={open}
							anchorEl={!!anchorEl && anchorEl }
							transition={open}
							container={anchorElcontainer}
							className="popper"
							style={{ zIndex: 1000 , p:1, width: !!anchorElcontainer && anchorElcontainer.offsetWidth}}

						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={250}>
									<Paper elevation={5} square style={{ width: '100%'}} sx={{p:1}}>
										{!!tagList && !!tagList.length ? tagList.map((item,index) => (
											<Box key={index} sx={{cursor:'pointer'}} onClick={() => {handleAddCategory(item, index)}}>
												<Typography>{item.title}</Typography>
											</Box>
										))
										:
											<Typography>Ничего не найдено</Typography>
										}
									</Paper>
								</Fade>
							)}
						</Popper>
					</ClickAwayListener>
					}
				</Box>
			</Box>
			<CategoryEditor/>
			</>}
		</Box>
	)
}

export default CategorySelector