import { useState, useEffect, useContext, useCallback, useRef,useLayoutEffect } from 'react'

import { Box, Grid, Typography, Button, Collapse } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery';
import Pagination from '@mui/material/Pagination';
import ProductListToolbar from '../../components/product/ProductListToolbar'
import ProductCard from '../../components/product/ProductCard'
import ContestCard from '../../components/product/ContestCard'

import { useNavigate, useLocation } from 'react-router-dom'
import { useHttp } from '../../hooks/http.hook'
import { useTags } from '../../hooks/tag.hook'


import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'

import Preloader from '../../components/Preloader'

const ProductsGrid = ({products, getCategory, productType}) => {
    const ContainerRef = useRef()
    const [ width, setWidth ] = useState(0);
    const [ quantity, setQuantity ] = useState(4)    

    const getSize = useCallback(node => {
        if (node !== null) {
            setWidth(node.current.getBoundingClientRect().width);
        }
      }, [ContainerRef]);

    const handleResize = useCallback(() => {
        if (ContainerRef !== null) {
            getSize(ContainerRef)
        }
    },[])

    useLayoutEffect(() => {
        const element = ContainerRef.current
        if (!element) return
        handleResize()
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [handleResize]);

    useEffect(() => {
        setQuantity((width/1.333333) > 221 ? Math.floor((width/1.333333)/221) : 1)
    },[width])

    if (!products) return null

    return (
        <>
        <Box ref={ContainerRef} sx={{width:'100%',  display:'grid', gridTemplateColumns: `repeat(${quantity}, 1fr)`, gap:3}}>
            {products.map((product,index) => (
                <ProductCard 
                    key={index}
                    product={product}
                    category={getCategory(product)}
                    productType={productType}
                    parentwidth={width}
                />
            ))}
        </Box>
        </>
    )
}

export default ProductsGrid