import { useState,useEffect } from 'react'
import {
	Typography,
	Box,
	Collapse,
	Table,
	TableRow,
	TableCell,
	TextField,
	Button
	
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import InputFile from './InputFile';

import csvjson from '../helpers/csvjson'

const csvTypes = [
	{
		title: 'id (String)',
		value: 'id'
	},
	{
		title: 'Expected (Double)',
		value: 'target'
	}
]

const SelectMapCsv = (props) => {
	const [ Keys, setKeys ] = useState()
	const [ Mapping, setMapping ] = useState()
	const [ KeysOpen, setKeysOpen ] = useState(false)
	const [ JsonArray, setJsonArray ] = useState()
	const {
		type,
		readonly,
		setFieldValue,
		formik,
		label
	} = props

	let { values } = props
	const { handleBlur, handleChange } = formik

	const handleMapping = (event,key) => {
		console.log(event.target.value, ' . ', key)
		setMapping({...Mapping, [key]: event.target.value})
		setFieldValue(`${type}.mapping`, {...Mapping, [key]: event.target.value})
		console.log(values)
	}

	const changeHandler = async (event) => {
		if (event.target.files.length) {
			console.log(event.target.files[0].name)
			setFieldValue(`${type}.setname`, event.target.files[0].name)
			const result = await csvjson(event.target.files[0])
			setFieldValue(`${type}.${type}set`, result.jsonArrayObj)
			setJsonArray(result.jsonArrayObj)
			setKeys(result.keys)
			setKeysOpen(true)
			setMapping(result.keys.map((key)=>({[key]:'none'})).reduce((a,b)=>({...a,...b}),{}))
			console.log(result.jsonArrayObj)
		} else {
			setFieldValue(type, '')
			setKeys(null)
			setKeysOpen(false)
			setJsonArray(null)
			setMapping(null)
		}
	}
	
	useEffect(()=> {
		
		if (!values[`${type}`][`${type}set`] || !values[`${type}`]['setname']) {
			setKeys()
			setJsonArray()
			setMapping()
		} else {
			console.log(values)
			setJsonArray(values[`${type}`][`${type}set`])
			var keysarr = Object.keys(values[`${type}`][`${type}set`].reduce((a,b)=>({...a,...b}),{}))
			if (!!values[`${type}`].mapping) {
				setMapping(values[`${type}`].mapping)
			} else {
				setMapping(keysarr.map((key)=>({[key]:'none'})).reduce((a,b)=>({...a,...b}),{}))
			}
			setKeys(keysarr)
		}
	}, [values])

	return (
		<>
			<InputFile 
				values={values}
				disabled={readonly}
				label={label}
				changeHandler={changeHandler}
				name={type}
				filetype={'.csv'}
			/>
			{!!Keys && JsonArray && !readonly && (type=='solution') && (
				<Box sx={{display:'flex', p:2, border:'1px solid', borderColor:'#bbb', borderRadius:1, my:1}}>
				<Typography variant="body2">
					The set has {JsonArray.length} rows. <br/>
					Define public leaderboard percentage:
				</Typography>
				<Box flexGrow="1"/>
					<TextField
						size="small"
						type="number"
						variant="outlined"
						name="publicpercent"
						onBlur={handleBlur}
						onChange={handleChange}
						value={values.publicpercent}
						sx={{width:'5em'}}
						inputProps={{ maxLength: 2,min: 0, max: 100, defaultValue: 0}}
					/>{/*onChange={(event)=>{setFieldValue("publicpercent",event.target.value)}*/}
				</Box>
			)}
			{!KeysOpen
				? !!Keys && !readonly && (
						<Box
							fullWidth
							sx={{	p: 1, justifyContent: 'flex-start'}}
							component={Button}
							onClick={() => {setKeysOpen(true)}}
						>
							<ExpandMore />
							<Typography variant="caption">Open columns</Typography>
						</Box>
				)
				: !!Keys && !readonly && (
						<Box
							fullWidth
							sx={{	p: 1, justifyContent: 'flex-start'}}
							component={Button}
							onClick={() => {setKeysOpen(false)}}
						>
							<ExpandLess />
							<Typography variant="caption">Close columns</Typography>
						</Box>
				)}
			<Collapse in={!!Keys && KeysOpen && !readonly}>
				{!!Keys && Keys.length
					? Keys.map((key,index) => {
							return (
								<Table fullWidth>
									<TableRow size="small" sx={{ p: 0, m: 0 }}>
										<TableCell width="50%">
											<Typography>{key}</Typography>
										</TableCell>
										<TableCell width="50%">
											<TextField
												select
												SelectProps={{ native: true, padding: 1 }}
												fullWidth
												variant="standard"
												InputProps={{
													disableUnderline: true,
												}}
												value={Mapping[key]}
												onChange={(event) => {handleMapping(event,key)}}
											>
												{[{ title: 'Not mapped',value: 'none' }, ...csvTypes].map(
													(type, index) => (
														<option
															key={index}
															disabled={index === 0}
															value={type.value}
														>
															{type.title}
														</option>
													)
												)}
											</TextField>
										</TableCell>
									</TableRow>
								</Table>
							)
						})
					: ''}
			</Collapse>
		</>
	)
}

export default SelectMapCsv
