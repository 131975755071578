import { useCallback, useEffect, useState, useContext } from 'react'
import {
	Tabs,
	Tab,
	Divider,
	Box
} from '@mui/material'

import { Link as RouterLink, useOutlet, useNavigate, useParams, useLocation, useRoutes, matchPath } from 'react-router-dom'

import Overview from './Overview'
import Settings from './Settings'
import Endpoints from './Endpoints'
import Plans from './Plans'
import Announcements from './Announcements'

import { NavContext } from '../../context/NavContext'

import ComponentOutlet from '../../components/template/ComponentOutlet'

const Definition = (props) => {
	const path = props.path
	const params = useParams()
	const { selectData } = useContext(NavContext)

	const parts = [
		{
			path: 'overview',
			element: Overview,
			disabled: false,
		},
		{
			path: 'settings',
			element: Settings,
			disabled: false,
		},
		{
			path: 'endpoints',
			element: Endpoints,
			disabled: false,
		},
		{
			path: 'plans',
			element: Plans,
			disabled: false,
		},
		{
			path: 'announcements',
			element: Announcements,
			disabled: true
		},
	]

	const [partIndex, setPartIndex] = useState(0)

	const handleChange = (e, newValue) => {
		setPartIndex(newValue)
	}
	

	return (
		<><Box sx={{position: 'sticky', top:0, background: '#fff', zIndex: '10'}}>
			<Tabs value={partIndex} onChange={handleChange}>
				{parts &&
					parts.map((item, index) => (
						<Tab
							style={{ 
								minWidth: `${item.path.length * 0.5}` + `em`,
								paddingInline:'1em'
							}}
							disabled={item.disabled}
							label={item.path}
							value={index}
							key={index + 1}
							to={{
								pathname: `/dash/apiedit/${params.user}/${params.product}/` + item.path,
							}}
							component={RouterLink}
						/>
					))}
			</Tabs>
			<Divider />
			</Box>
			<ComponentOutlet path={path} parts={parts} data={selectData}/>
		</>
	)
}

export default Definition
