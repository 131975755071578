import { useState, useContext, useCallback, useEffect } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
	Box,
	Drawer,
	Divider,
	ListItemIcon,
	ListItemText,
	ListItem,
} from '@mui/material'
import mainmenu from '../__mocks__/mainmenu'
import NavItem from './NavItem'
import SidebarMarket from './SidebarMarket'
import SidebarProduct from './SidebarProduct'
import { NavContext } from '../context/NavContext'
import { AuthContext } from '../context/AuthContext'
import { useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material/styles';

const MainSidebar = ({ onMobileClose, openMobile }) => {
	const theme = useTheme()
	let location = useLocation()
	const { selected, selectData } = useContext(NavContext)
	const { token, logout, userId } = useContext(AuthContext)
	const isAuthenticated = !!token
	const [visible, setVisible] = useState(false)
	var items = mainmenu

	const [sidebar, setSidebar] = useState('')

	const menus = [
		{
			label: 'marketplace',
			ref: (
				<SidebarMarket selectData={selectData} onMobileClose={onMobileClose} />
			),
		},
		{
			label: 'product',
			ref: (
				<SidebarProduct selectData={selectData} onMobileClose={onMobileClose} />
			),
		},
		
	]

	const contentlayout = (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
			}}
		>
			{sidebar}
		</Box>
	)

	const showSidebar = useCallback(() => {
		//console.log("SHOWSIDEBAR SELECT: ", selected)
		if (selected) {
			const sidebar = () => {
				var filter = menus.filter((menu) => menu.label === selected)
				if (filter.length) {
					return filter[0].ref
				} else {
					console.log(selected)
					setVisible(false)
				}
			}
			//console.log("SIDEBAR PROPS:", sidebar.props.selectData)
			if (sidebar) {
				setSidebar(sidebar)
				setVisible(true)
			} else {
				setVisible(false)
			}
			//console.log("SHOW SIDEBAR: ", visible)
		}
	}, [selected, selectData])

	useEffect(() => {
		setSidebar(null)
		setVisible(false)
		showSidebar()
	}, [selected, selectData, location, showSidebar])

	return (
			<Box sx={!visible ? { display: 'none' } : {}}>
				<Box
					sx={{ display: { xs: 'block', md: 'none', lg: 'none', xl: 'none' } }}
				>
					<Drawer
						anchor="left"
						onClose={onMobileClose}
						open={openMobile}
						variant="temporary"
						PaperProps={{
							sx: {
								width: 220,
							},
						}}
					>

						{selected !== 'homepage' ? 
							contentlayout 
						:
							items.map((item, index) => (
								<ListItem
									onClick={onMobileClose}
									key={index}
									button
									to={{ pathname: item.basepath + item.pathname }}
									component={RouterLink}
								>
									<ListItemIcon>{' '}</ListItemIcon>
									<ListItemText primary={item.title} />
								</ListItem>
							))
						}
					</Drawer>
				</Box>
				<Box
					sx={{ display: { xs: 'none', md: 'block', lg: 'block', xl: 'block' } }}
				>
					<Drawer
						anchor="left"
						open
						variant="persistent"
						PaperProps={{
							sx: {
								width: 255,
								top: 54,
								height: 'calc(100% - 48px)',
								borderBlockStart: '0',
								borderInlineEnd: '1',
								borderColor: theme.palette.borderColor
							},
						}}
					>
						{contentlayout}
					</Drawer>
				</Box>
			</Box>
	)
}

MainSidebar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
}

MainSidebar.defaultProps = {
	onMobileClose: () => {},
	openMobile: false,
}

export default MainSidebar
