import {useEffect, useState, useContext, useRef, useCallback} from 'react'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'

import Preloader from '../../components/Preloader'
import FeedSlider from "./FeedSlider"


const FeedViewer = ({orgdetails,callback}) => {

	const { ownerId, code, _id, date } = orgdetails
	const [ dateState, setDateState ] = useState(new Date(Date.now() - 86400000))

	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const { refresh, refreshCheck } = useContext(NavContext)
	const [ isLoading, setIsloading ] = useState(false)

	const [ feedState, setFeedState ] = useState()

	const pageSize = 3
	const [ page, setPage ] = useState(1)
	const [ count, setCount ] = useState(0)

	const fetchData = useCallback(async () => {
		try {
			setIsloading(true)
			console.log("fetchData: ", page)
			const response = await request(
				`/api/org/view/${ownerId}/${code}/feed`,
				'POST',
				{
					orgId: _id,
					date,
					page: page,
					pagesize: pageSize
				},
				{Authorization: `Bearer ${token}`}
				)
			if (!!response.data) {
				console.log("FeedEditor: fetchData:", response.data)
				setFeedState(response.data.feed)
				setCount(response.data.count)
				callback(!!response.data.feed.length)
			}
			setIsloading(false)
		} catch(e) {
			console.log(e)
		}
	},[ token, ownerId, code, page ])

	useEffect(() => {
		fetchData()
	}, [page])


if (!feedState || !!isLoading) {return <Preloader />}

return (

		<FeedSlider {...{feedState,pagination: {page, count, setPage}}} />

)

}

export default FeedViewer