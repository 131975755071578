import {useState, useEffect} from 'react'
import { makeStyles } from '@mui/styles'

import {
	IconButton, 
	Icon, 
	Box, 
	StepConnector, 
	Button, 
	Paper,
	Typography,
	StepContent, 
	StepLabel, 
	Step, 
	Stepper,
	Divider,
	Collapse,
	Grid
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		padding: theme.spacing(3),
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	resetContainer: {
		padding: theme.spacing(3),
	},
}))

function getSteps() {
	return ['Создайте API', 'Endpoints', 'Маркетплейс']
}

function getStepContent(step) {
	switch (step) {
		case 0:
			return [
				'Перейдите в раздел API',
				'Создайте свой первый сервис нажав на "Создать новый API"',
				'Выбрав из появившегося списка созданный API вы попадете в раздел редактирования API',
				'Укажите BASEURL для вашего API'
			]
		case 1:
			return [
				'В разделе редактирования API выберите подраздел Endpoints',
				'Здесь вы увидите список Endpoints',
				'Нажмите на кнопку Добавить',
				'Откроется редактор Endpoints. Укажите все необходимые параметры такие как путь, headers, query, method и пр.',
				'Нажмите сохранить. Теперь вы можете протестировать ваши Endpoints перейдя в подраздел Preview'
			]
		case 2:
			return [
				'В подразделе Settings вы можете изменить видимость вашего API изменив настройки публикации',
				'Перейдите в раздел Маркетплейс и найдите свое приложение чтобы убедиться, что данные API правильно отображаются для сообщества'
			]
		default:
			return ['Unknown step']
	}
}

const ApiGuide = (props) => {
	const [ guideOpenState, setGuideOpenState ] = useState(props.open)
	const classes = useStyles()
	const [activeStep, setActiveStep] = useState(0)
	const steps = getSteps()

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1)
	}

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}

	const handleStep = (step) => {
		setActiveStep(step)
	} 

	const handleReset = () => {
		setActiveStep(0)
	}

	useEffect(() => {
		setGuideOpenState(props.open)
	}, [props.open])

	return (
		<>
			{!guideOpenState ?
			<Box fullWidth sx={{m:0,p:0,pl:3,borderRadius:'0', justifyContent: "flex-start"}} component={Button} onClick={()=>{setGuideOpenState(true)}}>
				<ExpandMore />
				<Typography variant="caption">Открыть гайд</Typography>
			</Box>
			:
			<Box fullWidth sx={{m:0,p:0,pl:3,borderRadius:'0', justifyContent: "flex-start"}} component={Button} onClick={()=>{setGuideOpenState(false)}}>
				<ExpandLess />
				<Typography variant="caption">Закрыть гайд</Typography>
			</Box>
			}
			<Divider />
			<Collapse in={guideOpenState}>
			<Grid item xs={12} md={6}>
			<div className={classes.root}>
			<Stepper activeStep={activeStep} orientation="vertical">
				{steps.map((label, index) => (
					<Step key={label}>
						<StepLabel
						sx={{p:0}}
						onClick={() => handleStep(index)}
						
						>
							<Typography variant="h6" component={Button} sx={{textTransform:'none'}}>{label}</Typography>
						</StepLabel>
						<StepContent>
							<Typography component="span"  sx={{py:3}}>{getStepContent(index).map((item,index) => {
								return (
									<Box key={index} display="flex" alignItems="start">
										<Typography variant="caption" sx={{pr:1}}>●</Typography>
										<Typography key={index} variant="body2" sx={{pb:1}}>{item}</Typography>
									</Box>
								)
							})}</Typography>
							
								<div>
									<Button
										disabled={activeStep === 0}
										onClick={handleBack}
										className={classes.button}
									>
										Назад
									</Button>
									<Button
										variant="outlined"
										color="primary"
										onClick={handleNext}
										className={classes.button}
									>
										{activeStep === steps.length - 1 ? 'Закончить' : 'Вперед'}
									</Button>
								</div>
							
						</StepContent>
						
					</Step>
				))}
			</Stepper>
			{activeStep === steps.length && (
				<Paper square elevation={0} className={classes.resetContainer}>
					<Typography sx={{pb:3}}>Поздравляем! Вы опубликовали свой первый сервис!</Typography>
					<Button onClick={handleReset} variant="outlined">
						Заново
					</Button>
				</Paper>
			)}
			</div>
		<Divider />
		</Grid>
		</Collapse>
	</>
	)
}

export default ApiGuide