import {useState, useContext, useEffect} from 'react'
import {
	Box,
	Grid,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	TextField,
	InputAdornment,
	SvgIcon,
	Typography,
} from '@mui/material'
import { Search as SearchIcon } from 'react-feather'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined'
import SubjectOutlinedIcon from '@mui/icons-material/SubjectOutlined'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'

import NavItem from './NavItem'
import AccountSelector from './AccountSelector'
import { NavContext } from '../context/NavContext'

const contestLinks = [
	{
		title: 'Сводка',
		path: 'summary',
		disabled: false,
		icon: SubjectOutlinedIcon,
		links: ['overview','leaderboard'],
		submenu: 'summary',
	},
	{
		title: 'Редактировать',
		path: 'overview',
		disabled: false,
		icon: EditOutlinedIcon,
		links: ['overview','timeline','data','sandbox','settings']
	},
	{
		title: 'Статистика',
		path: 'statistics',
		disabled: true,
		icon: ShowChartOutlinedIcon
	},
]

const SidebarContest = (props) => {
	const selectData = props.selectData
	const { title } = props.description
	const setVisible = props.setVisible
	const [ contestList, setContestList ] = useState(null)
	const [ ListTitle, setListTitle ] = useState('Personal')

	const [ selectedIndex, setSelectedIndex ] = useState('')
	const [ selectedContestState, setSelectedContestState ] = useState('')
	const { setSelectedContest, selectedOrg, selected } = useContext(NavContext)

	const handleClick = (item,index) => {
		if (selectedIndex === index) {
			return setSelectedIndex('')
		} else {
			return setSelectedIndex(index)
		}
	}

	useEffect(() => {
		setContestList(null)
		console.log('SIDEBAR . SELECTDATA: ', selectData)
		console.log('selectedOrg: ', selectedOrg)
		if (!!selectedOrg.title) {
			setListTitle(selectedOrg.title + ' ' + title)
		} else {
			setListTitle('Personal' + ' ' + title)
		}
		if (selectData) {			
			if (selectData.filterdata) {
				const preparedData = selectData.filterdata
					.map((item, index) => {
						if (selectedOrg._id) {
							if (item.orgowner === selectedOrg._id) {
								return item
							}
						} else {
							if (!item.orgowner) {
								return item
							}
						}
					}).filter(i=>!!i)
				if (preparedData) {
					setContestList(preparedData)
				}
			} else {
				setContestList([])
			}
		}
	}, [selected, selectData, selectedOrg])

	useEffect(() => {
		//console.log(" SideBar: Selected Api : ", selectedApi)
		setSelectedContest(selectedContestState)
	}, [selectedContestState])


	return (
		<><Grid container>
			<Grid item xs={12}>
			<AccountSelector />
					<NavItem title="Добавить" href='contestedit/addnew' basepath='dash'/>
					<NavItem title="Все конкурсы" href="contestedit" basepath='dash'/>
					<NavItem disabled={true} title="Поддержка" href="contestedit/support" basepath='dash'/>
			</Grid>
			</Grid>
			<Box sx={{ flexGrow: 0.5 }} />
			<Grid container>
				<Grid item xs={12} sx={{p: 1}}>
						<TextField
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SvgIcon fontSize="small" color="action">
											<SearchIcon />
										</SvgIcon>
									</InputAdornment>
								),
							}}
							placeholder="Search"
							variant="outlined"
							value=""
							size="small"
						/>
						<Typography sx={{ p: 1,pt:2}} variant="h6">
							{ListTitle}
						</Typography>
				</Grid>
			</Grid>
			
			{/* CONTESTS LIST */}

			<Grid container overflow='auto'>
				<Grid item xs={12}>
					{!!contestList && contestList.map((item, index) => {
						return (
							<div key={index}>
								<ListItem
										button
										onClick={() => {
											handleClick('contestlist', index)
										}}
									>
										<ListItemIcon>
											{index === selectedIndex ? (
												<ExpandLess />
											) : (
												<ExpandMore />
											)}
										</ListItemIcon>
										<Box flexGrow={1}/>
										<ListItemText sx={{ textAlign: 'end', pr:1}} primary={item.title} />
									</ListItem>
									<Divider />
									<Collapse
										in={index === selectedIndex}
										timeout="auto"
										unmountOnExit
										sx={{position:'relative', top:'-1px', backgroundColor: '#fff'}}
									>
										<Grid item xs={12}>
											{contestLinks.map((i, index) => (
												<div key={index}>
													<NavItem
														disabled={!!i.disabled}
														onClick={() => {
															setSelectedContestState(item)
														}}
														basepath={'dash'}
														end={false}
														key={i.index}
														title={i.title}
														icon={i.icon}
														endpath={i.path}
														links={i.links}
														submenu={i.submenu}
														href={
															'contestedit/' +
															item.ownerId +
															'/' +
															item.code +
															'/' +
															i.path
														}
													/>
												</div>
											))}
										</Grid>
										<Divider />
									</Collapse>
							</div>
						)
					}
					)}
				</Grid>
			</Grid>
		
		</>
	)
}	

export default SidebarContest