import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'row',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'inherit',
    width: '100%',
    height: '100%'
  },
}));

const CircularIndeterminate = ({size, thickness}) => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress variant="indeterminate" thickness={thickness || 2} size={size || "4em"} />
    </div>
  );
}

export default CircularIndeterminate