import {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { 
	Box, 
	Typography,
	Grid,
	Divider,
	Paper,
	Card
} from '@mui/material'
import ContestGuide from './ContestGuide'


const ContestCard = ({item,index,onClick}) => {
	const [ raised, setRaised ] = useState(false)
	return (
			<Grid item>
				<Card 
					onMouseOver= {()=> {setRaised(true)}}
					onMouseOut={()=> {setRaised(false)}}
					onClick={() => onClick(item)} 
					sx={{
						cursor: 'pointer',
						width:'20em',
						p: 3,
					}}
					raised={raised}
				>
					<Box sx={{pb:3}}>
						<Typography variant="h6" sx={{wordWrap:'break-word'}}>{item.title}</Typography>
						<Typography variant="caption">{item.published ? 'Published' : 'Draft'}</Typography>
					</Box>
					<Typography variant="body2">No data yet...</Typography>
				</Card>
			</Grid>
	)
}

const ContestDash = (props) => {
	const title = "Contest summary"
	const Navigate = useNavigate()
	const ContestList = props.data
	const [ guideOpen, setGuideOpen ] = useState(true) 

	console.log("ContestList: ", props)

	const handleClick = (item) => {
		Navigate(`/dash/contestedit/${item.ownerId}/${item.code}/summary/overview`)
		console.log("CLICK: ", item)
	}

	useEffect(() => {
		setGuideOpen(!ContestList)
	})
	return (
		<>
			<Box sx={{ p: 3 }}>
				<Typography variant="h4">{title}</Typography>
			</Box>
			<Divider />
			<ContestGuide open={guideOpen}/>
			<Grid container spacing={3} direction="column" sx={{p:3}}>
				{ContestList.length &&
					ContestList.map((item, index) => {
						return (
							<ContestCard key={index} {...{item,index,handleClick}} onClick={handleClick}/>
						)
					})}
			</Grid>
		</>
	)
}

export default ContestDash