import React, { useState, useRef } from "react";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const Carousel = ({ items, cwidth, quantity }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const carouselRef = useRef(null);

  const handlePrevClick = () => {
    setActiveIndex(activeIndex === 0 ? items.length - 1 : activeIndex - 1);
  };

  const handleNextClick = () => {
    setActiveIndex(activeIndex === items.length - 1 ? 0 : activeIndex + 1);
    console.log(cwidth);
  };

  const handleTouchStart = (event) => {
    setTouchStart(event.targetTouches[0].clientX);
  };

  const handleTouchMove = (event) => {
    setTouchEnd(event.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 50) {
      handleNextClick();
    }

    if (touchStart - touchEnd < -50) {
      handlePrevClick();
    }
  };

  let itemWidth = '0px'
  let transform = 'translateX(0px)'

  if (!!cwidth) {
  itemWidth = `${(cwidth/quantity)}px`;
  transform = `translateX(-${activeIndex * (cwidth/quantity)}px)`;
  } else {return null}

  return (
    <div style={{display:'flex',width:'100%',p:0,m:0}}>
        <div style={{width:'0px'}}>
            <button
            style={{
                cursor: 'pointer',
                background:'#fff',
            border:'none',
            borderRadius: '5px',
            width:'30px',
            height:'100%',
            position: "relative",
            top: "50%",
            left: "-40px",
            transform: "translateY(-50%)",
            zIndex: "2",
            }}
            onClick={handlePrevClick}
        >
            <ArrowBackIosNewIcon/>
        </button>
        </div>
    
    <div
      style={{
        
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        overflow: "hidden",
        position: "relative",
        height: "100%",
        width: "100%",
      }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      ref={carouselRef}
    >
      
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "100%",
          transition: "transform 0.5s ease-in-out",
          transform: transform,
          width: `${items.length * itemWidth}px`,
        }}
      >
        {items.map((item, index) => (
          <div
            key={index}
            style={{
              flex: "0 0 auto",
              width: itemWidth,
              textAlign: "center",
            }}
          >
            {item}
          </div>
        ))}
      </div>
      
    </div>
    <div style={{width:'0px'}}>
        <button
            style={{
                cursor: 'pointer',
            background:'#fff',
            border:'none',
            borderRadius: '5px',
            position: "relative",
            top: "50%",
            left: "0px",
            width: '30px',
            height:'100%',
            transform: "translateY(-50%)",
            zIndex: "2",
            }}
            onClick={handleNextClick}
        >
            <ArrowForwardIosIcon />
        </button>
      </div>
    </div>
  );
};

export default Carousel;