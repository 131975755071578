import {
	Input,
	InputLabel,
	Typography,
	Box,

} from '@mui/material'
import { FileUploadOutlined as UploadIcon } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles';

const InputFile = ({values,disabled,changeHandler,name,filetype,label,content}) => {
	
	const theme = useTheme()
	if (!name) {return (<></>)}
	return (
		<InputLabel htmlFor={`import-${name}`} sx={{ overflow: 'hidden'}}>
			<Input
				type="file"
				style={{display:'none'}}
				disabled={disabled}
				id={`import-${name}`}
				inputProps={{
					accept: filetype,
				}}
				name={name}
				onClick={(e) => {e.target.value=''}}
				onChange={(event) => {
					changeHandler(event)
				}}
			/>
			<Box
				{ ...(!content 
					?	{
							sx:
								!disabled
									?	theme.boxButton
									:	{ ...theme.boxButton, ...theme.boxButton.disabled }
						}
					:	{
							sx: 
								!disabled
									?	{cursor: 'pointer', display:'table'}
									:	theme.boxButton.disabled
						}
				)}
			>
				
				{!!content
					?	content
					:	<><UploadIcon />
							<Typography>
								{values[name] ? values[name].setname : label}
							</Typography>
						</>
				}
			</Box>
		</InputLabel>
	)
}

export default InputFile