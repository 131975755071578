import { useState, useContext, useEffect, useRef } from 'react'
import { Link as RouterLink, useNavigate,useParams } from 'react-router-dom';

import {
	Box,
	Divider,
	Grid,
	Typography,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Button,
	TextField,
	Tooltip,
	IconButton,
	Dialog
} from '@mui/material'

import {
	AddCircleOutlineOutlined as AddIcon,
	EditOutlined as EditIcon,
	DeleteForeverOutlined as DeleteIcon,
	TrendingUpRounded,
} from '@mui/icons-material'

import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook'

import Preloader from '../../components/Preloader'
import shortText from '../../helpers/shortText'
import timeAgo from '../../helpers/timeAgo'

import ConfirmDialog from '../../components/ConfirmDialog'

const SurveysList = () => {
	const { id } = useParams()
	const { token } = useContext(AuthContext)
	const { request } = useHttp()
	const [ isLoading, setIsLoading ] = useState(true)
	const [ fields, setFields ] = useState([])
	const [ DataList, setDataList ] = useState([])

	const [ openEditor, setOpenEditor ] = useState({open:false,data:{}})
	const [ confirmState, setConfirmState] = useState({open:true})

	const fetchData = async () => {
		try {
			setIsLoading(true)
			const authorization = !!token ? `Bearer ${token}` : ''
			const fetched = await request(
				`/api/research/surveylist/${id}`,
				'GET',
				null,
				{ Authorization: authorization }
				)
			if (fetched && fetched.data) {
				setIsLoading(false)
				const { surveylist, surveys } = fetched.data

				if (surveylist) {
					const fields = surveylist.questions
						.map((item) => item.variable)
					setFields(['_id',...fields])
				}

				if (surveys) {
					const preparedList = surveys.map((item,index) => {
						const {__v,_id,answers,...rest} = item
						return {_id,...answers}
					})
					setDataList(preparedList)
				}
			}
		} catch(e) {
			console.log(e)
		}
	}

	const deleteData = async ({_id}) => {
		try {
			setIsLoading(true)
			const authorization = !!token ? `Bearer ${token}` : ''
			const response = await request(
				`/api/research/survey/${_id}`,
				'DELETE',
				null,
				{ Authorization: authorization }
				)
			await fetchData()
			setIsLoading(false)
		} catch(e) {
			console.log(e)
		}
	}

	const handleEdit = (value) => {
		setOpenEditor({open: true, data: value})
	}

	const handleDelete = (item) => {
		setConfirmState({open:true,setOpen:setConfirmState,callback:deleteData,item,validatename:'_id'})
	}

	useEffect(()=>{
		fetchData()
	},[id])

	if (isLoading) {return (<Preloader />)}

	return (
				<>
					<ConfirmDialog {...confirmState} />
					<Dialog open={openEditor.open} onClose={() => {setOpenEditor({open:false,data:{}})}}>
						<Grid container sx={{minWidth:"300px",p:3}} spacing={1}>
							{Object.entries(openEditor.data).map(([key,value]) => (
								<Grid item xs={12}>
									<Typography variant="caption">{key}</Typography>
									<Box sx={{border: '1px solid #ccc',p:1}}>
										<Typography>
											{value}
										</Typography>
									</Box>
								</Grid>
							))
							}
						</Grid>
					</Dialog>
					<TableContainer>
						<Table aria-label="Endpoints list">
							<TableHead sx={{ backgroundColor: '#fafafa' }}>
								<TableRow>
									<TableCell sx={{ width: 0, p: 0, m: 0, pl: 3 }} />
									<TableCell sx={{ width: 0, p: 0, m: 0, pr: 3 }} />
										{fields.length ? fields.map((item,index) => (
											<TableCell align="left" key={index}>
												<Typography variant="caption">{item}</Typography>
											</TableCell>
										))
										: null
										}
									
								</TableRow>
							</TableHead>
							<TableBody>
							{DataList.map((item, index) => (
								<TableRow key={index}>
									<TableCell width="1" nowrap="true" align="center" sx={{backgroundColor:'#f5f5f5',m:0,p:0}}>
									<Box sx={{flexWrap:'nowrap', display:'flex'}}>
										<IconButton onClick={()=>{handleEdit(item)}}>
											<EditIcon />
										</IconButton>
										<IconButton onClick={()=>{handleDelete(item)}}>
											<DeleteIcon />
										</IconButton>
									</Box>
									</TableCell>
									<TableCell sx={{ width: 0, p: 0, m: 0 }} />
									{fields.length ? fields.map((key,i) => (
										<TableCell align="left" key={i}>
											<Tooltip title={item[key]||''} placement="bottom">
												<Typography variant="caption">
												{(key == "date") && timeAgo(item[key]) || shortText(item[key] || '',key == '_id'? 5: 20)}
												</Typography>
											</Tooltip>	
											</TableCell>
									))
									: null
									}
									
									<TableCell sx={{ width: 0, p: 0, m: 0 }} />
								</TableRow>
							))}
						</TableBody>
						</Table>
					</TableContainer>
				</>
	)
}

export default SurveysList