import { useEffect, useState, useContext, useCallback } from 'react'
import {
	Box,
	Divider,
	Grid,
	TextField,
	Typography,
	Button
} from '@mui/material'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import SchemaTools from '../../helpers/schematools'
import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'
import ControlsComponent from '../../components/ControlsComponent'
import Preloader from '../../components/Preloader'

import TextEditor from '../../components/texteditor/TextEditor'
import ContestDefinitionHeader from './ContestDefinitionHeader'

let category = []

const ContestOverview = (props) => {
	const { selectedpart } = props
	const serverpath = "/api/contest"
	const [current, setCurrent] = useState({
		title: '',
		ownerId: '',
		code: '',
		private: false,
		published: false,
		longdescription: '',
	})
	const [initialState, setInitialDataState] = useState({
		title: '',
		shortdescription: '',
		website: '',
		longdescription: '',
		category: '',
		terms: '',
	})
	const { compareObjects } = SchemaTools()
	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const { selectedContest, refresh, refreshCheck,	setSelectedContest, selectData } = useContext(NavContext)
	const [ readonly, setReadonly] = useState(true)
	const [ isSubmitting, setSubmitting ] = useState(false)
	const [ changesState, setChangesState ] = useState(initialState)

	
	const fetchDetails = useCallback(async (value) => {
		try {
			const fetched = await request(
				`${serverpath}/owner/${value.ownerId}/${value.code}`,
				'GET',
				null,
				{
					Authorization: `Bearer ${token}`,
					part: 'overview',
				}
			)
			console.log(fetched)
			//return 
			const data = compareObjects(initialState, {...fetched.ContestData},{keepold:true})
			const detaildata = compareObjects(initialState, {...fetched.ContestDetailData})
			const resultdata = compareObjects(initialState, {...data,...detaildata})

			setChangesState(resultdata)
			setInitialDataState(resultdata)
			setCurrent({...current,...fetched.ContestData})
			console.log(resultdata)
		} catch (e) {
			console.log(e)
		}
	},[selectedContest])

	const saveSettings = async (value) => {
		try {
			if (!!selectedContest && selectedContest.published) {throw 'is published'}
			setSubmitting(true)
			var newdata = compareObjects(changesState,value,{minified: true})

			await request(
				`${serverpath}/update`,
				'POST',
				{ _id: current._id, ...newdata },
				{ Authorization: `Bearer ${token}` }
			)
			refresh()
			setSubmitting(false)
		} catch(e) {
			console.log(e)
		}
	}

	const resetHandler = () => {
		var resetdata = compareObjects(changesState,initialState)
		setChangesState({ ...resetdata })
	}

	const handleSubmit = (e) => {
		saveSettings(e)
	}

	useEffect(() => {
		if (selectData.categories) {
			category = [{_id: '', title: ''}, ...selectData.categories]
			console.log(category)
		}
	}, [selectData.categories])

	useEffect(() => {
		if (!selectedContest.date) {return}
		fetchDetails(selectedContest)
		
	}, [selectedContest, refreshCheck])

	if (changesState.title == '') {return <Preloader />}
	
	return (
		<>
			<ContestDefinitionHeader {...{current,selecteddata: selectedContest,selectedpart}} />
			<Formik
				initialValues={changesState}
				enableReinitialize
				validationSchema={Yup.object().shape({
					title: Yup.string().min(3).max(255).required('Enter title'),
					shortdescription: Yup.string()
						.min(3, 'Minimum symbols limit ${min}')
						.max(200, 'Maximum symbols limit exceeded ${max}')
						.required('This field is required'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					handleSubmit(values, { setSubmitting })
					setReadonly(true)
				}}
			>
				{({ errors, handleBlur, handleChange, touched, resetForm, values, setFieldValue }) => (
					<Form autoComplete="off">
							<Grid item md={12} xs={12} sx={{p:2}}>
								<Box sx={{display: 'flex'}}>
									<Box sx={{ flex: 2, p: 1 }}>
									<TextField
										error={Boolean(touched.title && errors.title)}
										fullWidth
										helperText={touched.title && errors.title}
										label="Название конкурса"
										name="title"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										value={values.title}
										variant="outlined"
										disabled={readonly}
									/>
									</Box>
									<Box sx={{ flex: 1, p: 1 }}>
												<TextField
													fullWidth
													error={Boolean(touched.category && errors.category)}
													helperText={touched.category && errors.category}
													label="Категория"
													name="category"
													onBlur={handleBlur}
													onChange={handleChange}
													select
													SelectProps={{ native: true }}
													value={values.category}
													variant="outlined"
													disabled={readonly}
												>
													{category.map((option, index) => 
													(
														<option disabled={index===0} key={option._id} value={option._id}>
															{option.title}
														</option>
													))}
												</TextField>
									</Box>
								</Box>
								<Box sx={{ p: 1 }}>
										
										<TextField
											fullWidth
											error={Boolean(
												touched.shortdescription && errors.shortdescription
											)}
											helperText={String(touched.shortdescription && errors.shortdescription ? errors.shortdescription + '. ' : '' ) + ("Total Symbols: " + values.shortdescription.toString().length + " of " + 150)}
											label={"Короткое описание"}
											name="shortdescription"
											onBlur={handleBlur}
											onChange={(event) => {console.log("React.ChangeEvent :" , event);handleChange(event)}}
											required
											value={values.shortdescription}
											variant="outlined"
											disabled={readonly}
										/>
									</Box>
									<Box sx={{p:1}}>
										<TextEditor
											disabled={readonly}
											label={"Подробное описание"}
											value={values.longdescription}
											name="longdescription"
											onBlur={handleBlur}											
											onChange={handleChange}
										/>
										
										{/*
										<TextField
											multiline
											fullWidth
											rows={8}
											error={Boolean(touched.longdescription && errors.longdescription)}
											helperText={String(touched.longdescription && errors.longdescription ? errors.longdescription + '. ' : '' ) + ("Total Symbols: " + values.longdescription.toString().length + " of " + 1000)}
											label={"Long description"}
											name="longdescription"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.longdescription}
											variant="outlined"
											disabled={readonly}
										/>
										*/}

									</Box>
							</Grid>
							<Divider />
							<Box sx={{p:3}}>
							{!!selectedContest && !selectedContest.published ?
								<ControlsComponent
									readonly={{ setReadonly, readonly }}
									disabled={isSubmitting}
									resetHandler={resetHandler}
									resetform={resetForm}
								/>
							:
							<Typography>You cannot edit the Contest while it is published.</Typography>
							}
							</Box>
					</Form>
				)}
			</Formik>
		</>
	)

}

export default ContestOverview