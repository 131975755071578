import { useState } from 'react'

import {
	Divider,
	Grid,
	Typography,
	Box
} from '@mui/material'

import FeedViewer from '../../components/Feed/FeedViewer'

const OrgViewOverview = (props) => {

	const [ hasFeed, setHasFeed ] = useState(false)

	const orgdetails = props.data
	return (
		<Grid item xs={12} sx={{ justifyContent: 'center', alignItems: 'start',height: '50%'}} container>
			<Grid item xs={12} md={7}>
				<Box sx={{pt:2,pb:4,...(!hasFeed && {display:'none'})}}>
					<Typography variant="h6">
						События
					</Typography>
					{/* <Divider/> */}
					<FeedViewer {...{orgdetails,callback:setHasFeed}} />
					{/* <Divider/> */}
				</Box>
				<Box>
					<Typography variant="h6">
						О компании
					</Typography>
					{/* <Divider/> */}
				</Box>
				<Box sx={{pt:1,pb:4}}>
					<Typography variant="body2">{orgdetails.shortdescription}</Typography>
				</Box>
				{orgdetails.prod_info && !!orgdetails.prod_info.length ? orgdetails.prod_info.map((prod,index) => {

					return (
					<Box key={index}>
						{!!prod.title &&
						<Typography variant="h6" sx={{pt:1,pb:1}}>
							{prod.title}
						</Typography>
						}
						{/* <Divider/> */}
						<Box sx={{pb:4}}>
							<Typography variant="body2" dangerouslySetInnerHTML={{__html: prod.text}} >
							
							</Typography>
						</Box>
					</Box>
				)}
				): ''}
				<Box sx={{mb:20}}/>
			</Grid>
		</Grid>
	)
}

export default OrgViewOverview