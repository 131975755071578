import {useState, useEffect} from 'react'
import {
	Grid,
	Typography,
	Box,
	Divider,
	Button,
	TextField,
	IconButton
} from '@mui/material'
import {Link} from 'react-router-dom'
import { useTheme } from '@mui/material/styles';
import {FileDownloadOutlined as DownloadIcon} from '@mui/icons-material'
import ContestHeader from './Contest.header'

const ContestData = (props) => {
	const theme = useTheme()
	const productData = props.data
	const disabled = false
	console.log("productData : ", productData)
	let samplefile = ''
	var csvData = ''
	let samplefilename = ''
	if (!!productData && !!productData.dataset) {
	samplefilename = productData.dataset.sample.setname
	const sampledataset = productData.dataset.sample.sampleset
	let samplearray = []
	sampledataset.map((item,index) => {
		if (index == 0) {
			samplearray.push(Object.keys(item).reduce((a,b) => {return a + ',' + b}))
			samplearray.push(Object.values(item).reduce((a,b) => {return a + ',' + b}))
			return
		}
		samplearray.push(Object.values(item).reduce((a,b) => {return a + ',' + b}))
		return
	})
	samplefile = samplearray.reduce((a,b) => {return a + '\n' + b})
	csvData = 'data:application/txt;charset=utf-8,' + encodeURIComponent(samplefile);
	}

	return (
		<>
		{!!productData &&
		<Grid container>
			<Grid item xs={12}>
				<Box padding={3}>
					<Typography variant="h5">Data</Typography>
				</Box>
				<Divider/>
			</Grid>
			{!!productData.dataset ? 
			<>
			<Grid item xs={12} md={4} padding={3}>
					<a href={csvData}
						target='_blank'
						download={samplefilename}
						>
						<Box sx={
								!disabled
									? theme.boxButton
									: { ...theme.boxButton, ...theme.boxButton.disabled }
							}
							>
							<DownloadIcon />
							<Typography variant="body2">Sample</Typography>
						</Box>
					</a>
			</Grid>
			{!!productData.dataset.traindatalink &&
			<Grid item xs={12} md={4} padding={3}>
					<Box sx={
							!disabled
								? theme.boxButton
								: { ...theme.boxButton, ...theme.boxButton.disabled }
						}
						component={Link}
						to={productData.dataset.traindatalink}
						target='_blank'
						>
						<DownloadIcon />
						<Typography variant="body2">Train data</Typography>
					</Box>
			</Grid>
			}
			{!!productData.dataset.testdatalink &&
			<Grid item xs={12} md={4} padding={3}>
					<Box sx={
							!disabled
								? theme.boxButton
								: { ...theme.boxButton, ...theme.boxButton.disabled }
						}
						component={Link}
						to={productData.dataset.testdatalink}
						target='_blank'
						>
						<DownloadIcon />
						<Typography variant="body2">Test data</Typography>
					</Box>
			</Grid>
			}
			</>
			:
			<Grid item xs={12} md={4} padding={3}>
				<Typography variant="body2">You must accept rules to get access to the dataset</Typography>
			</Grid>
			}
		</Grid>
		}
		</>
	)
}

export default ContestData