import { useEffect, useState,useCallback } from 'react';
import {
	Box,
	Typography,
	Button
} from '@mui/material'
import { SendIcon } from '../../components/graphics/icons'
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import { NotificationsOffOutlined } from '@mui/icons-material';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useNavigate } from 'react-router-dom'


const OrgViewStatsblock = ({user,userId,org,request,orgdetails,token,refresh}) => {

	const showMessages = false
	const Navigate = useNavigate()
	const [ orgdetailsState, setOrgdetailsState ] = useState()
	const [ isFollower, setIsFollower ] = useState(false)

	const handleFollow = useCallback(async () => {
		if (!token) {Navigate('/login')}
		try {
			const response = await request(
				`/api/org/follower/${user}/${org}`, 
				!isFollower ? 'POST' : 'DELETE', 
				null,
				{Authorization: `Bearer ${token}`}
			)
			console.log('Fetched: ', response)
			if (!!response.data) {
				
			}
			refresh()
		} catch (e) {
			console.log(e)
		}
	}, [user,org,request,isFollower])

	const handleMessage = () => {
		console.log('Message')
	}

	useEffect(() => {
		let candidate
		if (!!orgdetails && !!orgdetails.statistics.isFollower) {
			candidate = true
		}
		setIsFollower(!!candidate)
		setOrgdetailsState(orgdetails)
	},[orgdetails])


	return (
		<Box sx={{display:'flex',alignItems:'center',justifyContent:'center',width:'100%',px:3,gap:0.5,height:'30pt'}}>
			<Box sx={{width:'50%',display:'flex',justifyContent:'right',height:'100%',gap:0.5}}>
				<Box sx={{
					// border:'1px solid #eee',
					// borderRadius:'5pt',
					display:'flex',alignItems:'center',p:1,px:2,gap:1,flexWrap:'nowrap'}}>
					<RemoveRedEyeOutlinedIcon sx={{fontSize:'14pt',color:'#555'}}/>
					<Typography variant="body2" noWrap >Просмотры: {!!orgdetailsState && !!orgdetailsState.statistics.visitors ? orgdetailsState.statistics.visitors : 0}</Typography>
				</Box>
				<Box sx={{
					// border:'1px solid #eee',
					// borderRadius:'5pt',
					display:'flex',alignItems:'center',p:1,px:2,gap:1,flexWrap:'nowrap'}}>
					<PersonOutlinedIcon sx={{fontSize:'14pt',color:'#555'}}/>
					<Typography variant="body2" noWrap >Подписчики: {!!orgdetailsState && !!orgdetailsState.statistics.followers ? orgdetailsState.statistics.followers : 0}</Typography>
				</Box>
			</Box>
			<Box sx={{width:'50%',display:'flex',justifyContent:'left',height:'100%',gap:0.5}}>
				<Box component={Button} onClick={handleFollow} sx={{textTransform:'none',border:'2px solid #eee',borderRadius:'5pt',display:'flex',alignItems:'center',p:1,px:2,gap:1,flexWrap:'nowrap'}}>
					{!isFollower && <>
						<NotificationAddOutlinedIcon sx={{fontSize:'14pt',color:'#555'}}/>
						<Typography variant="body2">Отслеживать</Typography>
					</>}
					{!!isFollower && <>
						<NotificationsOffOutlined sx={{fontSize:'14pt',color:'#555'}}/>
						<Typography variant="body2">Отписаться</Typography>
					</>}
				</Box>
				{!!showMessages &&
				<Box component={Button} onClick={handleMessage} sx={{textTransform:'none',border:'2px solid #aaffcc',borderRadius:'5pt',display:'flex',alignItems:'center',p:1,px:2,gap:1,flexWrap:'nowrap'}}>
					<SendIcon sx={{fontSize:'14pt',color:'#555'}}/>
					<Typography variant="body2">Сообщение</Typography>
				</Box>
				}
			</Box>
		</Box>
	)

}

export default OrgViewStatsblock