import { default as JavascriptFetch } from './javascript.fetch.sdk'
import { default as PythonHttp } from './python.http.sdk'

const rsdkList = [
	{
		modulename: 'JavascriptFetch',
		module: JavascriptFetch,
		title: 'javascript fetch',
	},
	{
		modulename: 'PythonHttp',
		module: PythonHttp,
		title: 'python http'
	}
]

export default function (module) {
	console.log("IMPORT RSDK")
	console.log(module)
	const rsdk = rsdkList.find((rsdk) => {
		return rsdk.modulename == module
	})
	if (rsdk) {
		console.log(rsdk.module)
		return rsdk.module
	}
}

export {rsdkList}