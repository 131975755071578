import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useEffect, useContext, useState } from 'react'
import { useTheme } from '@mui/material/styles';
import { useHttp } from '../hooks/http.hook'
import { useMessage } from '../hooks/message.hook'
import { NavContext } from '../context/NavContext'

import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid
} from '@mui/material';

import { Alert } from '@mui/lab';
import Snackbar from '@mui/material/Snackbar';

import terms from '../__mocks__/terms'

const RegisterPage = () => 	{
  const theme = useTheme()
	const navigate = useNavigate()
	const {settext, errortext} = useMessage()
  const { setMessage, messageText } = useMessage()
	const message = settext
	const { request, error, clearError } = useHttp()
  const { setnav } = useContext(NavContext)
  const [ openTerms, setOpenTerms ] = useState(false)
	
	const registerHandler = async (formArr) => {
		try {
			const data = await request('/api/auth/register', 'POST', { ...formArr })
			setMessage(data.message)
			navigate('/dash')
		} catch (e) {}
	}

	useEffect(() => {
      setMessage(error,'error')
		
        clearError()
    }, [error, setMessage, clearError])

	const handleSubmit = (e,setSubmitting) => {
		
		console.log(message)
		registerHandler(e)
		setSubmitting(true)
	}

  useEffect(() => {
		setnav('login')
	}, [setnav])

  
  const handleTermsClose = () => {
    setOpenTerms(false)
  }

	return (
    <>
      <Helmet>
        <title>Регистрация</title>
      </Helmet>
      <Dialog
        open={openTerms}
        onClose={handleTermsClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
        >
          <Typography>Terms & Conditions</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2">
              {terms}
            </Typography>  
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Grid container
      
        sx={{
          backgroundColor: 'background.default',
          
          p:3,
          justifyContent: 'center'
        }}
      >
        <Grid item xs={12} md={6}>
			
		{errortext && <Snackbar open={!!errortext} autoHideDuration={6000}>
			<Alert severity="error">{errortext}</Alert>
    	</Snackbar>}
          <Formik
            initialValues={{
              email: '',
              firstName: '',
              lastName: '',
              password: '',
              policy: false
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                firstName: Yup.string().max(255).required('First name is required'),
                lastName: Yup.string().max(255).required('Last name is required'),
                password: Yup.string()
                .max(255)
                .required('password is required')
                .matches(/^.*(?=.{6,})(?=.*[A-Z])(?=.*[!_@#$&*])(?=.*[0-9])(?=.*[a-z]).*$/,"Password must content 1 uppercase, 1 lowercase, 1 special, 1 numeric symbol. And must be length 6+"),
                policy: Yup.boolean().oneOf([true], 'This field must be checked')
              })
            }
            onSubmit={(values, { setSubmitting }) => {
				handleSubmit(values)
			  }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Создайте аккаунт
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    используйте ваш email для регистрации
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label="First name"
                  margin="normal"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label="Last name"
                  margin="normal"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    ml: -1
                  }}
                >
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    I have read the
                    {' '}
                    <Typography
                      sx={theme.textlinkstyle}
                      component={Link}
                      href="#"
                      onClick={()=>{setOpenTerms(true)}}
                    >
                      Terms and Conditions
                    </Typography>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="outlined"
                  >
                    Sign up now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in 
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
          </Grid>
      </Grid>
    </>
  );
};

export default RegisterPage;
