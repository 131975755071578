import { default as RMSE } from './rmse.metric'
// import { default as PythonHttp } from './python.http.sdk'

const metricList = [
	{
		modulename: 'RMSE',
		module: RMSE,
		title: 'RMSE',
	},
	// {
	// 	modulename: 'PythonHttp',
	// 	module: PythonHttp,
	// 	title: 'python http'
	// }
]

export default function (module) {
	console.log("IMPORT METRIC")
	console.log(module)
	const metric = metricList.find((metric) => {
		return metric.modulename == module
	})
	if (metric) {
		console.log(metric.module)
		return metric.module
	}
}

export {metricList}