import { useEffect, useState } from 'react'
import {
	Typography,
	FormControlLabel,
	Switch
} from '@mui/material'
import {withStyles} from '@mui/styles'

const IOSSwitch = withStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		marginLeft: 12,
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#555',
				opacity: 1,
				border: 'none',
			},
		},
		'&$focusVisible $thumb': {
			color: '#333',
			border: '1px solid #fff',
		},
	},
	thumb: {
		width: 24,
		height: 24,
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border']),
	},
	caption: false,
	checked: {},
	focusVisible: {},
}))(({ classes, ...props }) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked,
			}}
			{...props}
		/>
	)
})

const SwitchComponent = (props) => {
	const [state, setState] = useState({
		//initial
		checked: false,
		label: {on:'on',off:'off',caption: false},
		readonly: true,
		name: '',
		changeState: ()=>{},
		value: false,
	})

	const caption = state.label.caption

	const onoff = (value) => {
		return value ? state.label.on : state.label.off
	}

	const handleChange = (event) => {
		if (props.onChange) {
			props.onChange(event)
		} else {
		console.log(state.checked)
		//setState({ ...state, checked: event.target.checked })
		state.changeState({ [state.name]: event.target.checked })
		}
	}

	useEffect(() => {
		if (typeof props.checked !== 'undefined') {
			setState({...state,...props})
		}
	}, [props, setState])


	return (
		<>
			<FormControlLabel sx={ state.label.caption ? {position:'relative',top: '-0.5em'} : {} }
				control={
					<IOSSwitch
						checked={state.checked}
						onChange={handleChange}
						name="checked"
						disabled={state.readonly}
					/>
				}
				label={<Typography sx={!caption?{p:1}:{}} variant={state.label.caption ? 'caption' : 'body1'}>{onoff(state.checked)}</Typography>}
				labelPlacement={caption ? 'top' : 'end'}
			/>
		</>
	)
}

export default SwitchComponent
