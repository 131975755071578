const SchemaTools = () => {

	// RETURN OBJECT THAT HAVE ONLY o1 FIELDS with o2 values if they differ or only o1 field with modified flag
	var compareObjects = function (o1, o2, options) {
		// console.log('compare :', options)
		// console.log('o1 :', o1)
		// console.log('o2 :', o2)
		var res = {}
		if (options) {
			var { minified, keepold } = options
		}
		for (var p in o1) {
			if (o2.hasOwnProperty(p)) {
				if (o1[p] !== o2[p]) {
					// console.log(o1[p] ,' !== ',o2[p])
					res[p] = o2[p]
				} else {
					if (!minified) {
						res[p] = o1[p]
					}
				}
			} else {
				if (!!keepold) {
					res[p] = o1[p]
				}
			}
		}
		return res
	}

	return {
		compareObjects,
	}
}

export default SchemaTools
