import { Outlet } from 'react-router-dom'
import { experimentalStyled } from '@mui/material/styles'
import MainNavbar from './MainNavbar'
import MainSidebar from './MainSidebar'
import {useState, useContext} from 'react'
import {NavContext} from '../context/NavContext'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DashboardBottombar from './DashboardBottombar'

const MainLayoutRoot = experimentalStyled('div')(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	display: 'flex',
	height: '100%',
	overflow: 'hidden',
	width: '100%',
}))

const MainLayoutWrapper = experimentalStyled('div')(
	({ theme }) => {
		return ({
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden',

	paddingTop: 48,
	[theme.breakpoints.up('md')]: {
		paddingBottom: 0,
	}
	})
	}
);

const MainLayoutContainer = experimentalStyled('div')({
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden',
})

const MainLayoutContent = experimentalStyled('div')({
	flex: '1 1 auto',
	height: '100%',
	overflow: 'auto',
})

const MainLayout = () => {
	const theme = useTheme()
	console.log("UP MD", useMediaQuery(theme.breakpoints.down('md')))
	const themedownMD = useMediaQuery(theme.breakpoints.down('md'))
	const [isMobileNavOpen, setMobileNavOpen] = useState(false)
	const Nav = useContext(NavContext)
	console.log("NAV SELECTED: ", Nav.selected)

	

	return (
		<MainLayoutRoot>
			<MainNavbar onMobileNavOpen={() => setMobileNavOpen(true)}/>
			<MainSidebar
				onMobileClose={() => setMobileNavOpen(false)}
				openMobile={isMobileNavOpen}
			/>
			{/*sx={['homepage','docs'].indexOf(Nav.selected) == -1 && {pl:'220px',pb:0}}*/}
			<MainLayoutWrapper sx={
				{ 
					pl: ['marketplace','product'].indexOf(Nav.selected) !== -1 && !themedownMD ? '255px' : '',
					paddingBottom: ['survey'].indexOf(Nav.selected) == -1 ? '48px' : 0,
				}
				
				} >
				<MainLayoutContainer>
					<MainLayoutContent>
						<Outlet />
					</MainLayoutContent>
				</MainLayoutContainer>
			</MainLayoutWrapper>
			{themedownMD ? 
		['survey'].indexOf(Nav.selected) == -1 && <DashboardBottombar onMobileNavOpen={() => setMobileNavOpen(true)} />
		: null
	}
		</MainLayoutRoot>
	)
}

export default MainLayout
