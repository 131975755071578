import {useContext, useEffect, useState} from 'react'
import {
	Typography,
	Button,
} from '@mui/material'
import NavItem from './NavItem'
import {useLocation, useParams} from 'react-router-dom'
import {NavContext} from '../context/NavContext'

const solutionMenu = [
	// {
	// 	title: 'Overview',
	// 	path: 'overview',
	// 	disabled: false,
	// },
	{
		title: 'Эндпоинты',
		path: 'endpoints',
		disabled: false,
	},
	{
		title: 'Документация',
		path: 'docs',
		disabled: true,
	},
	{
		title: 'Планы подписки',
		path: 'plans',
		disabled: false,
	},
	{
		title: 'Спецификация',
		path: 'specs',
		disabled: true,
	},
]

const requestMenu = [
	{
		title: 'Обзор',
		path: 'overview',
		disabled: false
	},
	{
		title: 'Отклик',
		path: 'response',
		disabled: true
	}
]

const contestMenu = [
	{
		title: 'Обзор',
		path: 'overview',
		disabled: false
	},
	{
		title: 'Данные',
		path: 'data',
		disabled: false
	},
	{
		title: 'Лидерборд',
		path: 'leaderboard',
		disabled: false
	},
	{
		title: 'Мои решения',
		path: 'submissions',
		disabled: false
	},
	{
		title: 'Правила',
		path: 'rules',
		disabled: false
	},
]


const SidebarProduct = (props) => {
	console.log("SidebarProduct: ", props)
	const productType = props.selectData.productType
	const [ menuarray, setMenuarray ] = useState(solutionMenu)
	const [ selected, setSelected ] = useState({})
	const nav = useContext(NavContext)
	
		
	useEffect(() => {
		if (!!productType) {
			if (productType == 'solutions') {
				setMenuarray(solutionMenu)
			}
			if (productType == 'requests') {
				setMenuarray(requestMenu)
			}
			if (productType == 'contests') {
				setMenuarray(contestMenu)
			}
		}
		setSelected({})
		if (nav.selectData.user) {
			console.log("Product view. Selected Api: ", nav.selectData, nav.selected)
			setSelected(nav.selectData)
		}
	}, [nav.selectData, productType])
	
	return (
		<>
		
			{menuarray.map((item,index) => (
				
				<NavItem onClick={()=>{
					if (!item.disabled) {
					props.onMobileClose()}}}
				disabled={item.disabled}
				key={index}
				title={item.title}
				href={`marketplace/${productType}/` + selected.user + "/" + selected.product + "/" + item.path}
				/>

			))}
		</>
	)
}

export default SidebarProduct