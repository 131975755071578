import { useState, useEffect, useContext, useCallback, useRef, useLayoutEffect } from 'react'
import { Box, Grid, Typography, Button, Collapse } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import ProductCard from './product/ProductCard'

import { useTags } from '../hooks/tag.hook'
import { useProducts } from '../hooks/products.hook'

import SwipeCarousel from '../components/SwipeCarousel'


const Carousel = (props) => {

    const ContainerRef = useRef()
    const Navigate = useNavigate()
    const ismobile = props.ismobile
    const productType = props.type
    
    const [ width, setWidth ] = useState(0);
    const [ quantity, setQuantity ] = useState(4)
    
    const getSize = useCallback(node => {
        if (node !== null) {
            setWidth(node.current.getBoundingClientRect().width);
        }
      }, [ContainerRef]);

    const handleResize = () => {
        if (ContainerRef !== null) {
            getSize(ContainerRef)
            }
    }

    const { getCategory } = useTags()
    const { getProducts } = useProducts()
    const [ products, setProducts ] = useState()
    const [ CarouselState, setCarouselState ] = useState({sort:"byrel"})

    const queryParams = {
        page: 1,
        pagesize: productType == 'solutions' ? 11 : 32,
        sort: CarouselState.sort,
        shortinfo: 1,
    }
    const basepath = "./marketplace/"
    
    const handleCarouselSort = (event) => {
		setCarouselState({
			sort: event.target.name
		})
	}
    const handleButton = (event) => {
		Navigate(basepath + productType)
	}

    useEffect(async () => {
        const fetched = await getProducts(productType, queryParams)
        if (!!fetched && !!fetched.length) {
            console.log("UE: ", fetched)
            setProducts(fetched)
        }
    }, [CarouselState])

    useLayoutEffect(() => {
        const element = ContainerRef.current
        if (!element) return
        handleResize()
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [handleResize]);

    useEffect(() => {
        setQuantity((width/1.333333) > 221 ? Math.floor((width/1.333333)/221) : 1)
    },[width])

    if (!products) return null

    return (
        <Box sx={{width:'100%'}}>
            <Box sx={{display:'flex',justifyContent: !!ismobile ? 'center' : 'left' , width:'100%'}}>
                <Box sx={{borderRadius:4, background:'#fff', px:2, gap:1, my:1}}>
                    <Button sx={{textTransform:'none'}} name="byrel" onClick={handleCarouselSort}>Популярные</Button>|
                    <Button sx={{textTransform:'none'}} name="bydate" onClick={handleCarouselSort}>Новые</Button>|
                    <Button sx={{textTransform:'none'}} name="./marketplace" onClick={handleButton}>Посмотреть все</Button>
                </Box>
            </Box>

            <Box sx={{display:'flex',width:'100%'}}>

        
            <Box sx={{p:4,display:'flex',width:'100%'}}>
                <Box ref={ContainerRef} sx={{width:"100%"}}>
                    {productType == "solutions" &&

                        <SwipeCarousel 
                            cwidth={width} 
                            quantity={quantity}
                            items=
                            {products.map((product, index) => {
                                return (
                                    <ProductCard 
                                        key={index}
                                        product={product}  
                                        category={getCategory(product)}
                                        productType={productType}
                                        basepath={basepath}
                                        parentwidth={width}
                                    />
                                )

                            })
                            }
                        />
                        // <Box sx={{display:'inline-grid', gridTemplateColumns: `repeat(${quantity}, 1fr)`, gap:1}}>
                        //             {products.map((product, index) => {
                        //                 if (!!quantity && index < quantity) {
                        //                     console.log("quantity: " + quantity)
                        //                 return (
                        //                     <ProductCard 
                        //                         key={index}
                        //                         product={product}  
                        //                         category={getCategory(product)}
                        //                         productType={productType}
                        //                         basepath={basepath}
                        //                         parentwidth={width}
                        //                     />
                        //                 )
                        //                 }
                        //             })
                        //             }
                        // </Box>
                    
                    }
                    {productType == "organizations" && 
                    <Box sx={{display:'grid', gridTemplateColumns: `repeat(${quantity}, 1fr)`, gap:3}}>
                                {products.map((product, index) => {
                                    if (!!quantity && index < quantity * 4) {
                                        console.log("quantity: " + quantity)
                                    return (
                                        <ProductCard 
                                            key={index}
                                            product={product}  
                                            category={getCategory(product)}
                                            productType={productType}
                                            basepath={basepath}
                                            parentwidth={width}
                                        />
                                    )
                                    }
                                })
                                }
                    
                    </Box>
                    }
                </Box>
            </Box>

            </Box>
        </Box>
    )
}

export default Carousel