const MockPlans = [
	{
		title: 'Free',
		cost: '0',
		amount: '10',
		requests: '865925',
		quantity: '1305',
		value: '',
	},
	{
		title: 'Pro',
		cost: '10',
		amount: '10000',
		requests: '1024510',
		quantity: '1532',
		value: '',
	},
	{
		title: 'Business',
		cost: '500',
		amount: '600000',
		requests: '409951',
		quantity: '466',
		value: '',
	},
	{
		title: 'Premium',
		cost: '1000',
		amount: '1500000',
		requests: '120146',
		quantity: '138',
		value: '',
	},
]

export default MockPlans