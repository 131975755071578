import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useEffect, useContext, useState,useCallback } from 'react'
import { useTheme } from '@mui/material/styles';
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { NavContext } from '../../context/NavContext'
import { AuthContext } from '../../context/AuthContext'

import * as Yup from 'yup';
import { Formik, Form, Field, useField } from 'formik';

import {
	Box,
	Button,
	Checkbox,
	Container,
	FormHelperText,
	Link,
	TextField,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Grid,
	MenuItem,
	Fade,
	Collapse,
	IconButton,
	FormControlLabel
} from '@mui/material';

import {
	AddCircleOutlineOutlined as AddIcon,
	EditOutlined as EditIcon,
	DeleteForeverOutlined as DeleteIcon,
} from '@mui/icons-material'

import { Alert } from '@mui/lab';
import Snackbar from '@mui/material/Snackbar';

import MessageAlert from '../../components/MessageAlert'
import {CheckboxField} from '../../components/CheckboxField.tsx'


const FieldEditor = ({setEditor,setQuestions,questions,setEditorState,editorState,setField}) => {

	const initialState = {
		order: (editorState && editorState.order) || questions.length,
		type: '',
		question: '',
		variable: '',
		answer: '',
		condition: '',
		link: '',
		linkvariant: '',
		required: false
	}
	const [ valuesState, setValuesState ] = useState({...initialState,...editorState})
	const types = [
		"singleline",
		"multiline",
		"select",
		"email"
	]
	
	const handleSave = (values) => {
		const filtered = questions.filter((v,i)=>v.variable !== editorState.variable)
		setQuestions([...filtered,values])
		setEditorState({})
		setEditor(false)
		setField([...filtered,values])
	}

	const handleCancel = () => {
		setEditor(false)
	}
	
	return (
		<Box sx={{p:3, border:'1px solid #eee'}}>
		<Collapse in={true} appear={true}>
		<Formik
			initialValues={valuesState}
			enableReinitialize
			onSubmit={(values, { setSubmitting, resetForm }) => {
				handleSave(values, { setSubmitting })
				resetForm({})
				setValuesState(initialState)
			}}
		>{({
			errors,
			handleBlur,
			handleChange,
			touched,
			resetForm,
			values,
			setFieldValue
		}) => 
			{const getOrder = (values) => {
				const link = questions.find((q,i)=>q.variable==values.link)
				if (!!link && link.order !== undefined ) {
					return link.order
				}
				return (
					""
				)
			}
			return (
			<Form autoComplete="off">
			<Box sx={{display:'flex',flexDirection:'column',gap:1}}>
			<TextField
				value={values.question}
				name="question"
				label="question"
				onChange={handleChange}
				onBlur={handleBlur}
				required
			/>
			<TextField
				value={values.variable}
				name="variable"
				label="variable"
				onChange={handleChange}
				onBlur={handleBlur}
				required
			/>
			<TextField
				select
				value={values.type}
				name="type"
				label="type"
				onChange={handleChange}
				onBlur={handleBlur}
				required
			>
				{types.map((option, index) => (
					<MenuItem key={index} value={option}>
						{option}
					</MenuItem>
				))}
			</TextField>
			<TextField 
				multiline
				name="answer"
				label={values.type == 'select' ? "Варианты через запятую" : 'Пример ответа'}
				value={values.answer}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
			{values.type !== 'select' ? '' : ""}
			<Box sx={{gap:1,display:'flex'}}>
				<TextField
					value={values.link}
					name="link"
					label="Связан с (имя переменной вопроса)"
					onChange={handleChange}
					onBlur={handleBlur}
					fullWidth
				/>
				<TextField
					value={values.linkvariant}
					name="linkvariant"
					label="Связан с (вариант ответа связанного вопроса)"
					onChange={handleChange}
					onBlur={handleBlur}
					fullWidth
					required={!!values.link}
				/>
			</Box>
			
			<Box display="flex" alignItems="center">
				<Typography>С условием</Typography>
				<CheckboxField
					name="condition"
					onChange={handleChange}
					onBlur={handleBlur}
					value={values.condition}
				/>
			</Box>
			
			<Box display="flex" alignItems="center">
				<Typography>Обязательное поле</Typography>
				<CheckboxField
					name="required"
					onChange={handleChange}
					onBlur={handleBlur}
					value={values.required}
				/>
				<TextField
					value={values.order}
					name="order"
					label="Порядковый номер"
					helperText={!!values.link && "Номер списка: " + getOrder(values)}
					onChange={handleChange}
					onBlur={handleBlur}
					fullWidth
				/>
			</Box>

			<Box sx={{display:'flex',gap:1,flexWrap:'nowrap'}}>
				<Button variant="outlined" onClick={handleCancel}>
					Cancel
				</Button>
				<Button variant="outlined" type="submit">
					Save
				</Button>
			</Box>
			</Box>
			</Form>
			)}}
		</Formik>
		</Collapse>
		</Box>
	)
}

const SurveyEditor = ({listSelect,refresh,setListSelect,initialList,setEditorOpen}) => {

	const theme = useTheme()
	const navigate = useNavigate()
	const {settext, errortext} = useMessage()
	const { setMessage, messageText } = useMessage()
	const message = settext
	const { request, error, clearError } = useHttp()
	const { token } = useContext(AuthContext)
	const { setnav } = useContext(NavContext)
	
	const [ editor, setEditor ] = useState(false)
	const [ questions, setQuestions ] = useState([])
	const initialListState = {listname:'',questions,description:''}
	const [ listState, setListState ] = useState(!!listSelect.listname ? listSelect : initialListState)

	const [ setField, setSetField ] = useState(()=>{})
	const [ editorState, setEditorState ] = useState()
	const [ showAllQuestions, setShowAllQuestions ] = useState(false)

	

	const SaveData = async (values) => {
		const urlpath = values._id ? 'updatelist' : "createlist"
		try {
			const fetched = await request(
				`/api/research/${urlpath}`,
				'POST',
				values,
				{ Authorization: `Bearer ${token}`}
			)
			if (!!fetched && !!fetched.message) {
				setMessage(fetched.message)
			}
			refresh()
		} catch(e) {
			console.log(e)
			setMessage(e)
		}
	}

	const handleAdd = () => {
		setEditorState({})
		setEditor(!editor)
	}

	const handleDelete = (variable) => {
		const res = questions.filter((v,i) => v.variable !== variable)
		setQuestions(res)
	}

	const handleCancel = () => {
		setListSelect(initialList)
		setListState(initialListState)
		setEditorOpen(false)
	}

	const handleEdit = (q) => {
		setEditor(false)
		setEditorState(q)
		setEditor(true)
	}

	const handleSave = (values) => {
		setListState(values)
		SaveData(values)
	}

	const	handleShowAllQuestions = () => {
		setShowAllQuestions(!showAllQuestions)
	}

	useEffect(()=>{
		if (!!listSelect) {
			setListState(listSelect)
			setQuestions(listSelect.questions)
		}
		return () => {}
	},[listSelect])

	useEffect(()=>{
		if (!!listState.listname) {
			setListState({...listState,questions:questions})
		}
		return () => {}
	},[questions])


	return (
		<>
		<Grid container justifyContent="center">
			<Grid item xs={12} md={6}>
		<MessageAlert {...{messageText,setMessage,callback: clearError}}/>
					<Box>
						<Formik
							initialValues={listState}
							enableReinitialize
							onSubmit={(values, { setSubmitting, resetForm }) => {
								console.log(values)
								handleSave(values, { setSubmitting })
								resetForm({})
							}}
							
							
							validationSchema={Yup.object().shape({
								listname: Yup.string()
								.required("Название списка обязательно"),
								questions: Yup.array()
								.required('Список вопросов пуст'),
								description: Yup.string()
								.required("Описание обязательно")
							})}
						>
						{({values,errors,touched,handleChange,handleBlur}) => (
							<>
								<Form autoComplete='off'>
									<Box sx={{position:'absolute',zIndex:"-1000",top:-100}}>
										<Field 
											name="questions"
											value={questions}
										/>
									</Box>
									<Box sx={{display:'flex',mb:1,gap:1,width:'100%'}}>
										<TextField 
											size="small"
											variant="outlined"
											name="listname"
											label="Название анкеты"
											error={Boolean(touched.listname && errors.listname)}
											value={values.listname}
											onBlur={handleBlur}
											onChange={(event) => {handleChange(event);setListState({...listState,[event.target.name]:event.target.value})}}
											required
											fullWidth
										/>
										
										<Button variant="outlined" type="submit">Сохранить</Button>
										<Button variant="outlined" type="button" onClick={handleCancel}>Отмена</Button>
										
									</Box>
									<Box>
									<TextField 
											size="small"
											multiline
											variant="outlined"
											name="description"
											label="Описание"
											error={Boolean(touched.description && errors.description)}
											value={values.description}
											onBlur={handleBlur}
											onChange={(event) => {handleChange(event);setListState({...listState,[event.target.name]:event.target.value})}}
											required
											fullWidth
										/>
									</Box>
								</Form>
								
								{!!Object.keys(errors).length && 
									<Box sx={{p:1,borderRadius:1,my:1,background:'#fff0f0'}}>
										{Object.entries(errors).map(([k,v],i) => (
											<Box key={i} display="flex" flexDirection="column" sx={{gap:1}}>
												<Typography variant="caption" key={i}>{v}</Typography>
											</Box>
											)
										)}
									</Box>
								}
							</>
						)}
						</Formik>
					</Box>
					<Box sx={{pt:3}}>
						<Button variant="outlined" fullWidth type="button" onClick={handleShowAllQuestions}>
							{!!showAllQuestions ? "Скрыть зависимые" : "Показать все"}
						</Button>
					</Box>
					{!!questions.length &&
					<Formik
						initialValues={questions.reduce((p,c) => ({...p,[c.variable]:c.answer}),{})}
					>
						{({values,errors,touched,handleChange,handleBlur})=>(
							<Form>
							<Box sx={{pb:3}} display="flex" flexDirection="column">
								
								{!!questions.length && questions.sort((a,b)=>a.order-b.order).map((q,i,qArray)=> {
									if (!q) {return (<></>)}
									const getLink = () => {
										if (q.linkvariant == values[q.link]) {
											const link = qArray.find((item) => {console.log(item.variable, '==', q.link); return (item.variable == q.link)}) //возвращает объект на уровень выше
											
											if (!!link) {
													if (values[link.link] == link.linkvariant) {
														return true
													}
											}
											if (!link || !link.link) {
												return true
											}
										return false

										}
									}
									return (
									<Box key={i}>
									{((!!q.link && getLink()) || (!!q.link && !!showAllQuestions) || !q.link) &&
									<Box key={i} sx={{display:'flex',mt:3,p:1,borderBottom:'1px solid #eee'}}>
										<Box sx={{display:'flex',flexDirection:'column',width:'100%'}}>
											<Typography variant="body1">{q.question}</Typography>
											<Box sx={{display:'flex',mt:1}}>
												<Box width="100%">
													<TextField 
														fullWidth
														variant="outlined"
														name={q.variable}
														InputProps={{
															style: {fontSize:10},
														}}
														InputLabelProps={{
															style: {fontSize:10},
														}}
														onChange={handleChange}
														label={values[q.variable]}
														{...(
															q.type=='multiline' && {multiline:true,rows:'2',variant:'outlined'} ||
															q.type=='select' && {select:true} ||
															{variant:'standard'}
														)}
													>
														{q.type=="select" &&
															!!q.answer && q.answer.split(',').map((option, index) => (
																	<MenuItem key={index} value={option}>
																		{option}
																	</MenuItem>
																)
															)
														}
													</TextField>
													<Typography sx={{fontSize:10}}>{q.order + ' | ' + q.variable + ' | link: ' + q.link + ' = ' + q.linkvariant}</Typography>
												</Box>
												<Box sx={{display:'flex',flexWrap:'nowrap'}}>
													<Box>
														<IconButton onClick={() => {handleEdit(q)}}>
															<EditIcon />
														</IconButton>
														</Box>
													<Box>
														<IconButton onClick={() => {handleDelete(q.variable)}}>
															<DeleteIcon/>
														</IconButton>
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
									}
									</Box>
								)})
								}
							</Box>
						</Form>
						)}
					</Formik>
					}
					<Box sx={{mt:1}}>
						{!!editor && 
							<FieldEditor 
								setEditor={setEditor}
								editorState={editorState}
								setEditorState={setEditorState}
								setQuestions={setQuestions} 
								questions={questions}
							/>
						}
						{!editor && <Button variant="outlined" onClick={handleAdd}>Add</Button>}
						
					</Box>
			</Grid>
			</Grid>
		</>
	)
}

export default SurveyEditor