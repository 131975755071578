import {useEffect, useState} from 'react'
import {
	Snackbar,
	Alert
} from '@mui/material'

const MessageAlert = ({messageText,setMessage,callback}) => {
	return (
		<>
			{!!messageText && messageText.text &&
				<Snackbar open={!!messageText.text} autoHideDuration={2500} onClose={()=>{setMessage(null);if(callback){callback()}}}>
						<Alert severity={messageText.type}>{messageText.text}</Alert>
				</Snackbar>
			}
		</>
	)
}

export default MessageAlert