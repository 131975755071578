import { useState, useEffect, useContext, useCallback, useRef,useLayoutEffect } from 'react'

import { Box, Grid, Typography, Button, Collapse } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery';
import Pagination from '@mui/material/Pagination';
import ProductListToolbar from '../../components/product/ProductListToolbar'
import ProductCard from '../../components/product/ProductCard'
import ContestCard from '../../components/product/ContestCard'

import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useHttp } from '../../hooks/http.hook'
import { useTags } from '../../hooks/tag.hook'


import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'

import Preloader from '../../components/Preloader'
import ProductsGrid from './ProductsGrid';
import ViewCategory from '../../components/tags/view.category';
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import PageTitle from '../../components/PageTitle';

const ProductList = (props) => {
	const pagetitle = "Маркетплейс"
	const containerRef = useRef(null);
	const productType = props.path
	const theme = useTheme()
	const breakpointDownMD = useMediaQuery(theme.breakpoints.down('md'))
	const Navigate = useNavigate()
	const location = useLocation()
	//console.log("location:" , location.search)
	var locationQuery = location.search.substring(1);

	function recursiveParse(jsonString) {
		const parsedObject = JSON.parse(jsonString);
		for (let key in parsedObject) {
		  if (typeof parsedObject[key] === 'string') {
			try {
			  parsedObject[key] = recursiveParse(parsedObject[key]);
			} catch (e) {
			  console.log(e)
			}
		  }
		}
		return parsedObject;
	  }

	const filterParser = useCallback((filtersString) => {
		try {
		//console.log("filterParser: ", filtersString)
		const parsed = !!filtersString && JSON.parse('{"'+filtersString.replace(/&/g,'","').replace(/=/g,'":"')+'"}',(key,value)=>{return key===""?value:decodeURIComponent(value)})
		let { title, page, pagesize, sort, filters } = parsed
		
		const obj = { title, page, pageSize: pagesize, sort, filters }
		const res = Object.keys(obj)
			.map((key)=>!!obj[key]?{[key]:obj[key]}:null)
			.filter(i=>!!i)
			.reduce((a,b)=>({...a,...b}),{})
		//console.log("filterParser Res: ", res)
		return res
		} catch(e) {
			console.log(e)
		}
	},[locationQuery])

	let parsed = filterParser(locationQuery)
	let { filters } = parsed
	let parsedfilters = !!filters && Object.keys(recursiveParse(filters).category)
	

	const NAV = useContext(NavContext)

	const [ prodType, setProdType ] = useState()
	const { loading, request } = useHttp()
	const [products, setProducts] = useState([])
	const [count, setCount] = useState(0)
	const [productsCount, setProductsCount] = useState(0)
	
	const pageSizes = [24, 24, 32]
	
	const [searchTitle, setSearchTitle] = useState(parsed.title || '')
	const [page, setPage] = useState()
	const [pageSize, setPageSize] = useState(parsed.pagesize || pageSizes[0])
	const [sort, setSort] = useState(parsed.sort || 'byrel')
	const [fetch, setFetch] = useState('initial')
	const [tags, setTags] = useState([])
	const [tagsCollapse, setTagsCollapse] = useState(false)

	const { getTags, getCategory } = useTags()
	
	const categoryClickHandler = (title) => {
		if (!title) {
			//return
			Navigate(`/marketplace/organizations`)
		} else {
		//console.log("FILTERS: ", filters)
		//console.log("FILTERS: ", title)
		const queryString = new URLSearchParams({filters: JSON.stringify({category:JSON.stringify({[`${title}`]: true})})})
		Navigate(`/marketplace/organizations?${queryString}`)
		}
	}

	const onChangeSearchTitle = (e) => {
		const searchTitle = e.target.value
		setSearchTitle(searchTitle)
		
		if (searchTitle==='') {
			setFetch('initial')
		}
	}

	const onChangeSort = (e) => {
		setSort(e.target.value)
	}

	const handlePageSizeChange = (event) => {
		setPageSize(event.target.value)
		setPage(1)
		//console.log('Handle: ', pageSize, event.target.value)
	}

	const handlePageChange = (event, value) => {
		setPage(value)
		//console.log('CLIENT COUNT: ', event.target.count)
	}

	const getRequestParams = ({searchTitle, page, pageSize, sort, filters}) => {
		let params = {}
		if (!!searchTitle) {
			params['title'] = searchTitle
			setFetch('search')
		}
		if (!!page) {
			params['page'] = page
		}
		if (!!pageSize) {
			params['pagesize'] = pageSize
		}
		if (!!sort) {
			params['sort'] = sort
		}
		if (!!filters) {
			params['filters'] = filters
		}
		return params
	}

	const fetchProducts = useCallback(async (QueryParams) => {
		//console.log(QueryParams)
		setProducts([])
		const params = getRequestParams(QueryParams)
		//console.log("params: ", params)
		const queryString = new URLSearchParams({...params})
		//console.log(queryString.toString())
		try {
			const fetched = await request(
				`/api/market/${productType}/p?${queryString.toString()}`,
				'GET',
				null,
				{}
			)
			//console.log(JSON.stringify(params))
			console.log(fetched)
			if (!!fetched && fetched.products) {
				
				setProducts(fetched.products)
				setCount(Math.ceil(fetched.count / pageSize))
				setProductsCount(fetched.count)
			}
			if (!!fetched && !fetched.products) {
				setProducts([])
				setCount(Math.floor(0 / pageSize))
			}
		} catch (e) {
			console.log("ERROR", e)
		}
	},[locationQuery])

	const handleChangeLocation = () => {
		//alert("handleChangeLocation" + searchTitle)
		const params = getRequestParams({ searchTitle, page, pageSize, sort, filters })
		const queryString = new URLSearchParams({...params})
		Navigate(`/marketplace/${productType}?${queryString}`)
	}

	const handleSearch = (value) => {
		console.log("handleSearch : " + value)
		if (!value) {return}
		setPage(1)
		setFetch('search')
		// //alert(value)
		const queryString = new URLSearchParams({title: searchTitle, page: 1})
		// console.log("queryString : " + queryString)
		Navigate(`/marketplace/organizations?${queryString}`)
		// // fetchProducts({ searchTitle, page: 1, pageSize, sort, filters })
	}

	const handleTagsCollapse = (e) => {
		setTagsCollapse(!tagsCollapse)
	}

	useEffect(() => {
		if (!parsed.page) {
			setPage(1)
		} else {
			if (parsed.page !== page) {
				setPage(parsed.page)
			}
		}
	}, [filters])

	useEffect(() => {
		setPageSize(pageSizes[0])
		handleChangeLocation()
		
	}, [pageSize, fetch, productType, sort, filters ])


	useEffect(() => {
		if (!!page) {
			handleChangeLocation()
		}
	},[page])

	useEffect(() => {
		//console.log(parsed.page, "!==", page)
		if (!page) {
			if (!!parsed.page) {
				setPage(parsed.page)
			} else {
				setPage(1)
			}
			//console.log(parsed.page, "!==", page)
		} else {
			console.log("searchTitle: " + searchTitle)
			// let currentPage = page || 1
			// if (!!parsed && !!parsed.title) {
			// 	currentPage = 1
			// }
			const options = { searchTitle, page, pageSize, sort, filters }
			// console.log("options: " + JSON.stringify(options))
			fetchProducts(options)
		}
	},[locationQuery])

	useEffect(() => {
		if (!prodType) {setProdType(props.path);return}
		if (!!prodType && prodType !== props.path) {
			setProdType(props.path)
			setPage(1)
			return
		}
	}, [props.path])

	useEffect(async () => {
		NAV.setnav('marketplace')
		setTags(await getTags())
	}, [NAV])

	 // Fetch new items when user scrolls to bottom of container element

	//  const handleScroll = (event) => {
	// 	// alert("handleScroll")
	// 	console.log("handleScroll: ")
	// 	if(event.target.scrollTop > 125){
		   
	// 	} else if(event.target.scrollTop < 125) {
	// 	//    setScrolled(false);
	// 	}
	// }
	 //  const handleScroll = () => {
	// 	alert("handleScroll")
	// 	console.log("handleScroll")
	// 	const container = containerRef.current;
	// 	if (!container) {return}
        
	// 	const { scrollTop, scrollHeight, clientHeight } = container;
	// 	console.log(scrollTop, scrollHeight, clientHeight)
	// 	if (scrollTop + clientHeight === scrollHeight) {
	// 	  setPage(prevPage => prevPage + 1);
	// 	}
	//   };

	// useEffect(() => {
	// 	const container = containerRef.current;
	// 	if (!container) {return}
	// 	console.log(container)
	// 	window.addEventListener('scroll', handleScroll,{capture: true});
	// 	return () => window.removeEventListener('scroll', handleScroll,{capture: true});
	//   }, [containerRef]);
	
	// if (loading) {return <Preloader />}

	return (
		<Box ref={containerRef}>

			<Box sx={{position: 'sticky', top:0, background: '#fff', zIndex: '1000', borderBottom: `1px solid ${theme.palette.borderColor}`}}>
				<Grid item xs={12} md={6} sx={{p:3,pb:1,pt:2}}>
					<ProductListToolbar
						searchtitle={searchTitle}
						changesearchtitle={onChangeSearchTitle}
						sortparam={sort}
						changesort={onChangeSort}
						retrieve={handleSearch}
						productsCount={productsCount}
					/>
				</Grid>
			</Box>
			
				<Box sx={{minHeight:'40%',p:3,pt:1}}>
					{productType == 'solutions' && <>
						<Typography variant="h3">API</Typography>
						<Typography variant="body2">доступных решений с тестовой витриной и снипетом для интеграции</Typography>
						<Typography variant="body2">Чтобы посмотреть весь каталог решений предоставляемых участниками платформы перейдите в раздел  <Link style={{color: "#3300FF"}} to='/marketplace/organizations'>Организации</Link></Typography>
					</>}

					{productType == 'organizations' && <>					
						<Typography variant="h3">Организации</Typography>
						<Box sx={{py:1}}>
							<Typography variant='body2'>Компании и организации участников платформы предоставляющие услуги и решения в области исскусственного интеллекта.</Typography>
							<Typography variant='body2'>Чтобы протестировать и подключить доступные решения перейдите в раздел <Link style={{color: "#3300FF"}} to='/marketplace/solutions'>API Решений</Link></Typography>

						</Box>
						{!tags || !tags.length ? <Preloader />
						: <>
						<Box sx={{py:1,pt:2}}>
							<Typography variant='body2'>Популярные категории:</Typography>
						</Box>
						<Collapse in={tagsCollapse} collapsedSize={`${!breakpointDownMD ? 50 : 110}px`}>
						{!!tags && !!tags.length &&
							<ViewCategory
								ismobile={breakpointDownMD}
								category={tags.map((tag, index) => {
										if (tag.count && 
											!!tagsCollapse ? true 
											:
											index < 10
											) {
											//console.log("tag: " + JSON.stringify(tag))
											return tag._id
										}
									}).filter(tag =>!!tag)
								} 
								type="market" 
								clickHandler={categoryClickHandler}
								activeItem={!!parsedfilters ? parsedfilters : null}
							/>
						}
						</Collapse>
						<Button onClick={handleTagsCollapse} sx={{width:'100%',justifyContent:'left'}}>
							{!tagsCollapse ?
							<><ExpandMore/>Показать все</>
							:
							<><ExpandLess/>Скрыть</>
							}
						</Button>
						</>}
					</>}
				</Box>

			{!!loading && <Preloader/> || <>
				<Box sx={{ p:3,pt:1,pb:15}} >
					{!products.length && <Typography>Ничего не нашлось...</Typography>}
					<ProductsGrid products={products} getCategory={getCategory} productType={productType}/>
					{/* <Grid container spacing={2}>
						{products &&
							products.map((product, index) => {
								if(!!productType && productType == 'contests') {
									return (
										<Grid 
											key={index}
											item
											xs={12}
											onClick={() => setActiveProduct(product, productType)}
											onMouseOver={() => {}}
										>
											<ContestCard product={product} key={index} />
										</Grid>
									)
								}
								}
								)}
					</Grid> */}
				</Box>
				<Box
					sx={{
						display: 'flex',
						//pl:3,
						pt:0,
						justifyItems: 'center'
					}}
				>
					{((count >= 1) || (count <= pageSize) ? true : false ) &&
					<Box sx={ (count <= 1) ? {display: 'none'} : {display: 'flex',position:'absolute',justifyItems: 'center',
					zIndex:2,bottom: !breakpointDownMD ? 20 : 50}}>
						<Box display="flex" width="100%" alignItems="center" sx={{
							border:`1px solid #fafafa`,
							borderRadius:10,
							boxShadow:"5px 5px 10px rgba(0, 0, 0, 0.05)",
							background:'#fff',
							p:1
							}}>
							{!!page &&
							<Pagination
								shape="circular"
								siblingCount={0}
								boundaryCount={1}
								size="large"
								count={count * 1}
								page={page * 1}
								onChange={handlePageChange}
							/>
							}
						</Box>
					</Box>
					}
				</Box>
			</>}
			<PageTitle title={pagetitle}/>
		</Box>
	)
}

export default ProductList
