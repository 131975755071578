import {useEffect, useState, useContext, useRef, useCallback} from 'react'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'

import SchemaTools from '../../helpers/schematools'

import { 
	Grid, 
	Typography,
	Divider,
	Button,
	Box
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import Preloader from '../../components/Preloader'

const OrgEditorStats = (props) => {

	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const { refresh, refreshCheck } = useContext(NavContext)
	const [ orgState, setOrgState ] = useState()

	const { ownerId, code } = props.data
	
	const fetchData = useCallback(async () => {
		try {
			const response = await request(
				`/api/org/owner/${ownerId}/${code}`,
				'GET',
				null,
				{Authorization: `Bearer ${token}`}
				)
			if (!!response.data) {
				console.log("OrgEditorStats: fetchData:", response.data)
				setOrgState(response.data)
			}
		} catch(e) {
			console.log(e)
		}
	},[ token, ownerId, code ])

	useEffect(() => {
		if (!!props) {
			console.log("OrgEditorStats: ", props)
			fetchData()
		}
	},[props])

	if (!orgState) {return (<Preloader/>)}

	return (
		<>
			<Box sx={{py:1}}>
				<Typography variant="body2">
					Посещения: {orgState.statistics.visitors}<br/>
					Подписчики: {orgState.statistics.followers}<br/>
					Участники: {orgState.members.length}<br/>
					API: {orgState.api.length}<br/>
					Запросы: {orgState.request.length}<br/>
					Конкурсы: {orgState.contest.length}<br/>
				</Typography>
			</Box>
		</>
	)
}

export default OrgEditorStats