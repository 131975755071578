import {useEffect, useState} from 'react'
import { Outlet } from 'react-router'
import { useNavigate, useOutlet } from 'react-router-dom'
import {Box} from '@mui/material'

const Marketplace = (props) => {
	const currentOutlet = useOutlet()
	const Navigate = useNavigate()
	
	useEffect(() => {
		if (!currentOutlet) {Navigate('solutions',{replace: true})}
	})

	return (
		//{/* <Box sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', overflow: 'auto'}}>*/}
			<Outlet />
		//{/* </Box> */}
		
	)
}

export default Marketplace