import {useContext} from 'react'
import { 
	Box, 
	Typography,
	Grid,
	Divider
} from '@mui/material'
import OrgList from './OrgList'

const OrgDash = (props) => {
	const orgdata = props.data
	
	return (
		<>
			<Box sx={{ p: 3 }}>
				<Typography variant="h4">Organizations</Typography>
				<Typography>Some Cool Analtytics for organizations</Typography>
			</Box>
			<Divider />
			<Box>
				<Grid container spacing={3}>
					<OrgList {...{orgdata}} />
				</Grid>
			</Box>

		</>
	)
}

export default OrgDash
