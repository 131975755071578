import { useEffect, useState, useContext } from 'react'
import Preloader from '../../components/Preloader'
import {
	Box,
	Divider,
	Grid,
	Typography,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Button,
	TextField,
} from '@mui/material'
import { NavContext } from '../../context/NavContext'
import MockPlans from '../../__mocks__/mockPlans'
import GenerateSubscribeData from '../../__mocks__/GenerateSubscribeData'


const ApiSummary = () => {
	const [initialState, setInitialDataState] = useState({
		title: '',
		shortdescription:
			'Throughout the day, we had a number of other great surprises introduced by our awesome live emcees, Viktor Gamov and Christie James. The day’s surprises included a live improvised rap by Larry Dorsey, Jr., as well as a live artist making a custom Kong Summit painting, and workouts from a live fitness instructor.',
		website: '',
		longdescription: '',
		description: '',
		category: '',
		subscribers: []
	})

	const plansInitialState = [
		{
			publishDate: '',
			subscriptionsPerMonth: ''
		}
	]

	const [ plansData, setPlansData ] = useState(plansInitialState)
	const [ chartPoints, setChartPoints ] = useState([])


	const [ Plans, setPlans ] = useState({data: MockPlans})

	const getDays = (date) => {
		return Math.floor(((new Date(Date.now())).getTime() - (new Date(date)).getTime())/86400000)
	}

	const handleGenerate = async () => {
		const publishDate = plansData.publishDate || selectedApi.date
		const subscriptionsPerMonth = plansData.subscriptionsPerMonth
		const newdata = await GenerateSubscribeData({
			publishDate,
			subscriptionsPerMonth
		})
		
		setPlans({data: newdata, days: getDays(publishDate)})

		setChartPoints(newdata.map((item) => {
				
				function compareNumbers(a, b) {
					return a - b;
				}

				const label = item.title
				let data = []

				const subscribers = item.subscribers.map((item) => {
					return new Date(item.date)
				})
				
				const sortsubscribers = subscribers.sort(compareNumbers)

				data = sortsubscribers.map((item, index) => {
					return [Math.round((item - sortsubscribers[0])/86400000), index]
				})

	// 		var requests = []
	// 		function compareNumbers(a, b) {
	// 			return a[0] - b[0];
	// 		}
	// 		const data = item.subscribers.map((item) => {
	// 			for (var i=0; i < item.requests.length; i++) {
	// 				requests.push([
	// 					(new Date(item.requests[i].date).getTime())
	// 					,
	// 					item.requests[i].requestsperday
	// 				])
	// 			}
	// 		})
	// 		const sortrequests = requests.sort(compareNumbers)
	// 		const sortedrequests = sortrequests.map((item)=> {
	// 			return [Math.round((item[0] - sortrequests[0][0])/86400000), item[1]]
	// 		})

	// 		console.log("sortedrequests", sortedrequests)
		
	// 		let reducedmap = []
			
	// 		const maprequests = sortedrequests.map((item) => {

	// 			let candidate = reducedmap.find((i) => {
	// 				return i[0] == item[0]
	// 			})

	// 			if (!candidate) {
	// 				reducedmap.push(item)
	// 				//console.log("item: ", item)
	// 				//console.log("reduceditem: ", reducedmap[item[0]])
	// 			} else {
	// 				//console.log(Number(item[0]))
	// 				if (reducedmap[item[0]]) {
	// 				reducedmap[Number(item[0])][1] += item[1]
	// 				}
	// 			}
	// 		})
			
			
				var output = {label, data}
				console.log("requests: ", output)
				return output
	}))
	}


	const { selectedApi, refresh, refreshCheck, setSelectedApi, selectData } =
		useContext(NavContext)

	const [changesState, setChangesState] = useState(initialState)

	// const data = setTimeout(() => {setChangesState({...changesState,title:'test'})}, 500)
	// useEffect(() => {
	// 	setChangesState(initialState)
	// }, [])

	useEffect(() => {
		console.log(changesState)
	}, [changesState])

	useEffect(() => {
		if (selectedApi.date) {
			
			setChangesState({ ...changesState, ...selectedApi, days: getDays(selectedApi.date)})
			handleGenerate()
		}
		console.log('selectedApi: ', selectedApi, '\n')
	}, [selectedApi])

	if (!changesState.title) {
		return <Preloader />
	}
	return (
		<Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="h4" sx={{ p: 3, pb:0 }}>
						Api Summary ({changesState.title}){' '}
					</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<Box sx={{ p: 3 }}>
						<Typography>{changesState.shortdescription}</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} md={6} container spacing={3} sx={{p:3,justifyContent:'end'}}>
					<Grid item textAlign='end'>
						<Typography>
							Latency
						</Typography>
						<Typography variant="h6">
							0.1342
						</Typography>
					</Grid>
					<Grid item textAlign='end'>
						<Typography>
							Followers
						</Typography>
						<Typography variant="h6">
							152
						</Typography>
					</Grid>
					<Grid item textAlign='end'>
						<Typography>
							Rating
						</Typography>
						<Typography variant="h6">
							1230
						</Typography>
					</Grid>
					<Grid item textAlign='end'>
						<Typography>
							Accuracy
						</Typography>
						<Typography variant="h6">
							90/100
						</Typography>
					</Grid>
					
				</Grid>
			</Grid>
			<Divider />
			<Grid container>
				<Grid item xs={12} md={6}>
					<Box sx={{ p: 3 }}>
						<Typography>Income per month:</Typography>
						<Typography variant="h4">
							{'$ ' +
								Plans.data.reduce((a, b) => {return a + b.quantity * b.cost}, 0).toLocaleString()}
						</Typography>
						<Typography>
							{'Total requests: ' +
								Plans.data.reduce((a, b) => {
									return a + b.requests * 1
								}, 0).toLocaleString()}
						</Typography>
						
					</Box>
				</Grid>
				<Grid item xs={12} md={6}>

					
				{!0 ? '' : <>
					<Box display="flex">
						<Button 
							variant="outlined"
							onClick={()=>{
							handleGenerate()
						}}>
							Generate
						</Button>
						
						<TextField
							label="Publish Date"
							value={plansData.publishDate}
							onChange={(event) => {
								setPlansData({...plansData,publishDate: event.target.value})
							}}
						/>
						<TextField
							label="Subscriptions per month"
							value={plansData.subscriptionsPerMonth}
							onChange={(event) => {
								setPlansData({...plansData,subscriptionsPerMonth: event.target.value})
							}}
						/>
					</Box>

					</>
					}
				</Grid>
				<TableContainer>
					<Table aria-label="Endpoints list">
						<TableHead sx={{ backgroundColor: '#fafafa' }}>
							<TableRow>
								<TableCell sx={{ width: 0, p: 0, m: 0, pr: 3 }} />
								<TableCell align="left">Plan</TableCell>
								<TableCell align="right">Quantity</TableCell>
								<TableCell align="right">Cost/req</TableCell>
								<TableCell align="right">Requests</TableCell>
								<TableCell align="right">Value</TableCell>
								<TableCell sx={{ width: 0, p: 0, m: 0, pr: 3 }} />
							</TableRow>
						</TableHead>
						<TableBody>
							{Plans.data.map((item, index) => (
								<TableRow key={index}>
									<TableCell sx={{ width: 0, p: 0, m: 0 }} />
									<TableCell align="left">{item.title}</TableCell>
									<TableCell align="right" sx={{ color: '#aaa' }}>
										{(item.quantity * 1).toLocaleString()}
									</TableCell>
									<TableCell align="right" sx={{ color: '#aaa' }}>
										{item.cost.toLocaleString() +
											'/' +
											Math.round(
												(item.cost / item.amount.toLocaleString()) * 10000
											) /
												10000}
									</TableCell>
									<TableCell align="right" sx={{ color: '#aaa' }}>
										{(item.requests * 1).toLocaleString()}
									</TableCell>
									<TableCell align="right">
										<Typography variant="subtitle">
											{(item.quantity * item.cost).toLocaleString()}
										</Typography>
									</TableCell>
									<TableCell sx={{ width: 0, p: 0, m: 0 }} />
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Box>
	)
}

export default ApiSummary
