import { useState, useContext, useEffect, useCallback, createRef } from 'react'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import {
	Typography,
	TextField,
	Button,
	Grid,
	Avatar,
	IconButton,
	Divider,
	Box
} from '@mui/material'

import Preloader from '../../components/Preloader'
import timeAgo from '../../helpers/timeAgo'

import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { SendIcon } from '../../components/graphics/icons'

const querystring = require('querystring')

const Channels = (props) => {
	
	const subject = props.subject
	const fixedMessage = props.fixedMessage
	const { token, userId } = useContext(AuthContext)
	const { request } = useHttp()
	
	const [channelData, setChannelData] = useState()
	const [initialMessage, setInitialMessage] = useState(false)
	const [initialData, setInitialData] = useState()
	const [changesState, setChangesState] = useState({
		messagetext: '',
		date: ''
	})

	const [ scrollable, setScrollable ] = useState()

	const convertDate = (value) => {
		const date = timeAgo(value)
		return date.toLocaleString()
	}

	const fetchChannel = useCallback(async () => {
		try {
			if (!subject) {return console.log("No subject!")}
			const recipientsubject = querystring.encode({
				recipient: subject.recipient,
				objectId: subject.objectId,
				subjecttitle: subject.channelname,
				subjecttype: subject.type,
				channelId: subject.channelId
			})
			console.log("recipientsubject", recipientsubject)
			const fetched = await request(
				`/api/channel/${recipientsubject}`,
				'GET',
				null,
				{
					Authorization: `Bearer ${token}`,
				}
			)
			console.log('fetched: ', fetched.data)
			if (!fetched.data.notexist) {
				setChannelData(fetched.data)
				scrollableid(fetched.data.messages.length)
				
				return fetched.data
			} else {
				if (fetched.data.notexist) {
					console.log('notexist', fetched.data.notexist)
					setInitialMessage(fetched.data.notexist)
					setInitialData(fetched.data)
				}
			}
		} catch (e) {
			console.log("\nChannels error: \n", e)
		}
	}, [subject])

	const sendMessage = useCallback(async (values) => {
		console.log("subject", subject)
		console.log('Message to send: ', values)
		try {
			let notexist = false
			if (!!initialData) {
				if (!initialData.notexist) {
					return
				} else {
					notexist = initialData.notexist
				}
				if (subject.recipient !== initialData.recipient) {
					return
				}
				if (subject.objectId !== initialData.objectId) {
					return
				}
				if (subject.type !== initialData.subjecttype) {
					return
				}
			}

			const recipientsubject = querystring.encode({
				recipient: subject.recipient,
				objectId: subject.objectId,
				subjecttype: subject.type,
				subjecttitle: subject.channelname,
				channelId: subject.channelId
			})
			const data = await request(
				`/api/channel/${recipientsubject}/create`,
				'POST',
				values,
				{
					Authorization: `Bearer ${token}`,
					initialMessage: notexist
				}
				)
		} catch(e) {
			console.log(e)
		}

	}, [initialData,subject])

	const handleSubmit = (values, {resetForm, setSubmitting}) => {
		
		console.log('handleSubmit : ', values)
		setSubmitting(true)
		resetForm()
		sendMessage({...values, date: Date.now()})
		.then(() => {
			resetChannel()
			setSubmitting(false)}
		)
	}


	const resetChannel = () => {
		setInitialMessage(false)
		setInitialData(null)
		setChannelData(null)
		fetchChannel().then((channel) => {})
	}

	useEffect(() => {
		if (!!scrollable) {
			console.log("scrollable", scrollable)
			scrollable.scrollIntoView({block: "end", behavior: "smooth"})
		}
	}, [scrollable])
	
	useEffect(() => {
		if (!!props.subject) {
			console.log('props: ', props.subject)
			resetChannel()
		}
	}, [props.subject])

	// useEffect(() => {
	// 	if (!!props.subject) {
	// 		console.log('CHANNELS PROPS : ', props.subject)
	// 	}
	// }, [props.subject])


	const scrollableid = (value) => {
		console.log("\nscrollableid : ", Number(value) - 1)
		const id = document.getElementById("scrollable" + (Number(value) - 1))
		setScrollable(id)
	}

	const getId = (index,length) => {
			if (index == (length - 1)) {
				return "scrollable" + index
			}
	}

	
	if (!channelData && !initialData) {return <Preloader />}
	return (
		<>
			<Box sx={{flexGrow: '1'}}>
				<Box sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
					<Box sx={{
								flex: '1 1 auto',
								overflowY: 'auto',
								height: '0px',
								overflowX: 'hidden'
							}}
							
							>
								
					{!!fixedMessage && fixedMessage()}
					<Divider />
					
					{!!channelData &&
						channelData.messages &&
						channelData.messages.map((message, index) => {
							
							
							return (
								<Grid
									container
									key={index}
									id={ getId(index, channelData.messages.length) }
									justifyContent={
										message.owner.member == userId ? 'right' : 'left'
									}
								>
									<Grid
										item
										xs={6}
										sx={{
											backgroundColor: '#fafafa',
											m: 1,
											p: 1.5,
											pl: 3,
											borderRadius: 1,
										}}
									>
										<Typography sx={{ wordWrap: 'break-word' }}>
											{message.message}
										</Typography>
										<Typography variant="caption">
											{convertDate(message.date)}
										</Typography>
									</Grid>
								</Grid>
							)
						})
					}
					
					</Box>
					<Divider />
					<Formik
				initialValues={changesState}
				enableReinitialize
				validationSchema={Yup.object().shape({
					messagetext: Yup.string()
						.min(2, 'Minimum symbols limit ${min}')
						.max(1000, 'Maximum symbols limit exceeded ${max}')
						.required()
				})}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					console.log('SUBMIT')
					handleSubmit(values, { setSubmitting, resetForm })
				}}
				>
				{(formikProps) => {
					var { errors, handleBlur, handleChange, touched, resetForm, values, submitForm, isSubmitting } = formikProps

					return (
						<Form autoComplete="off">
							<Grid container display="flex" alignItems="center" sx={{ p: 1 }}>
								<Grid item flexGrow={1}>
									<TextField
										disabled={isSubmitting}
										fullWidth
										variant="outlined"
										name="messagetext"
										value={values.messagetext}
										sx={{ pr: 1 }}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
								</Grid>
								<Avatar
									variant="outlined"
									disabled={isSubmitting}
									sx={{
										
										width: 48,
										height: 48,
										border: '0.1px solid #ccc',
										backgroundColor: 'transparent',
										color: 'primary.main',
										p: 1,
									}}
									component={IconButton}
									onClick={submitForm}
								>
									<SendIcon />
								</Avatar>
							</Grid>
						</Form>
					)
				}}
			</Formik>
				</Box>

				
			</Box>


			
			
			
		</>
	)
}

export default Channels
