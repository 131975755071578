import { Grid, Box, Typography, Divider } from '@mui/material'

const AppStatistics = (props) => {
	console.log('APP STATISTICS :', props)
	return (
		<>
		<Grid container direction="column">
				<Grid item sx={{p:3}}>
					<Typography variant="h5">Statistics </Typography>
				</Grid>
				<Divider />
				<Grid item sx={{p:3}}>123</Grid>
			</Grid>
		</>
	)
}

export default AppStatistics