export default function(props) {
// var solarr = [0.5,0,1]
// var subarr = [0.5,1,1]

// var values = solarr.map((sol,index) => {
// return {actual:sol,predicted:subarr[index]}
// })

var RMSE = {};

RMSE.error = function(val) {
return val.actual - val.predicted;
}

RMSE.squaredError = function(vals) {
var errors = [];
for (var i = vals.length - 1; i >= 0; i--) {
	errors.push(Math.pow(RMSE.error(vals[i]), 2));
};
return errors;
}

RMSE.mean = function(vals) {
var total = 0;
for (var i = vals.length - 1; i >= 0; i--) {
	total += vals[i];
}
return total / vals.length;
}

RMSE.mse = function(vals) {
return RMSE.mean(RMSE.squaredError(vals));
}

RMSE.rmse = function(vals) {
return Math.sqrt(RMSE.mse(vals));
}

// const mse = (n) => {
//   for (var i=1, i <= n ,i++) {
	
//   }
// }

return RMSE

}