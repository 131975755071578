import { useContext, useEffect, useState, useCallback } from 'react'
import { Outlet } from 'react-router'
import { useOutlet, useNavigate, useParams } from 'react-router-dom'
import { Typography, Box, Grid, Divider } from '@mui/material'
import { NavContext } from '../../context/NavContext'
import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook'
import AppDash from './AppDash'

const AppEditor = () => {
	const currentPart = 'appedit'
	const params = useParams()
	const currentOutlet = useOutlet()
	const navigate = useNavigate()
	const { setData, setnav, selectedApp, refreshCheck, setSelectedApp, selectData } = useContext(NavContext)
	const { token, owner } = useContext(AuthContext)
	const { request } = useHttp()
	const [ unfilteredData, setUnfilteredData ] = useState({})
	const [initialized, setInitialized] = useState(0)

	const fetchApps = useCallback(async () => {
		try {
			const fetched = await request(`/api/productapp/owner`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})
			const { data, categories } = fetched
			const filterdata = data.map((item) => ({
				_id: item._id,
				title: item.title,
				ownerId: item.ownerId,
				code: item.code,
				orgowner: item.orgowner
			}))
			setData({ filterdata, categories })
			setUnfilteredData(data)
		} catch (e) {}
	}, [token, request])

	const ProductCheck = (e) => {
		if (e.user !== owner) {
			return {
				ok: false,
				data: e,
				message: ' APP EDITOR: You are not an onwer ',
			}
		} else {
			return { ok: true, data: e, message: ' APP EDITOR: Welcome back master' }
		}
	}

	const fillSelectedApp = useCallback(
		(value) => {
			setSelectedApp(value)
		},
		[selectedApp]
	)

	useEffect(() => {
		//subscribe to change of Selected App in NavContext
		//Change selectedApp object to unfiltered App data

		if (unfilteredData.length && selectedApp) {
			let currentUnfiltered = unfilteredData.find(
				(item) => item._id === selectedApp._id
			)
			console.log(' APP EDITOR:  currentUnfiltered : ', currentUnfiltered)
			// console.log(" API EDITOR:  selectedApp : ", setnav.selectedApp)
			// console.log(" API EDITOR:  Unfiltered Data : ", unfilteredData)
			fillSelectedApp(currentUnfiltered)
		}
	}, [selectedApp, unfilteredData])

	const initialsetselectedApp = useCallback(
		(value) => {
			if (initialized === 0) {
				setInitialized(1)
				console.log('APPEDITOR : INITIALIZED')
				setSelectedApp(value)
			}
		},
		[initialized]
	)

	useEffect(() => {
		if (!currentOutlet) {
			return
		}
		if (initialized === 1) {
			return
		}
		if (!!params.user) {
			const result = ProductCheck(params)
			if (!result.ok) {
				return navigate('/dash/appedit')
			} else {
				const current =
					selectData.filterdata &&
					selectData.filterdata.find((item, index) => {
						if (
							item.ownerId === result.data.user &&
							item.code === result.data.product
						) {
							return item
						}
					})
				if (current) {
					initialsetselectedApp(current)
				}
				// console.log('Current: ', current)
				// console.log(setnav.selectData)
				// console.log(currentOutlet.props)
				// console.log(result.message)
			}
		}
	}, [ProductCheck, currentOutlet, fillSelectedApp, initialized])

	useEffect(() => {
		fetchApps()
		setnav(currentPart)
	}, [refreshCheck])

	return (
		<>
			{!currentOutlet && <AppDash data={!!unfilteredData.length && unfilteredData.map(item => ({title:item.title})).filter(i => !!i)} />}
			<Outlet />
		</>
	)
}

// const console = {
// 	log: (value) => {
// 		return value
// 	}
// }
// {console.log(123)}

export default AppEditor
