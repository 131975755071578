import {useState, useEffect} from 'react'
import { 
	Box, 
	Typography,
	Grid,
	Divider,
	Paper
} from '@mui/material'
import AppGuide from './AppGuide'


const AppDash = (props) => {
	const AppList = props.data
	const [ guideOpen, setGuideOpen ] = useState(true)  

	useEffect(() => {
		setGuideOpen(!AppList)
	})

	return (
		<>
			<Box sx={{ p: 3 }}>
				<Typography variant="h4">App summary</Typography>
				<Typography>Some Cool Analtytics for apps</Typography>
			</Box>
			<Divider />
			<Box>
				<AppGuide open={guideOpen}/>
			</Box>
			<Box sx={{p:3}}>
				<Grid container spacing={3} direction="column">
				{AppList.length &&
					AppList.map((item, index) => {
						return (
							<Grid key={index} item sx={{width:'20em'}}>
								<Paper sx={{p:3}}>
									<Typography variant="h6" sx={{pb:3, wordWrap:'break-word'}}>{item.title}</Typography>
									<Typography variant="body2">No data yet...</Typography>
								</Paper>
							</Grid>
						)
					})}
				</Grid>
			</Box>

		</>
	)
	
}

export default AppDash
