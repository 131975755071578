import {useEffect, useState} from 'react'

import { 
	Dialog,
	Paper,
	Typography,
	Button,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	TextField
} from '@mui/material'

import * as Yup from 'yup'
import { Formik, Form } from 'formik'


const CautionDialog = ({cautionState, setCautionState}) => {
	const [ state, setState ] = useState(cautionState || {open: false})

	const handleSubmit = (values) => {
		state.action(state.data)
		setCautionState({...cautionState,open:false})
	}

	const handleClose = () => {
		setCautionState({...cautionState,open:false})
	}
	
	useEffect(() => {
		setState(cautionState)
	}, [cautionState])
	return (
		<Paper>
			<Formik
				initialValues={{
					title: '',
				}}
				validationSchema={Yup.object().shape({
					title: Yup.string()
						.matches(new RegExp(`^${cautionState.validate}$`), {
							message: 'Not equal',
							excludeEmptyString: false,
						})
						.max(255)
						.required('Enter title'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					handleSubmit(values, { setSubmitting })
				}}
			>
				{({
					errors,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					touched,
					values,
				}) => (
					<Form autoComplete="off">
						<Dialog open={state.open} onClose={handleClose}>
							<Typography variant="h4" padding={3} >DELETE</Typography>
							<DialogContent>
								<Typography paddingBottom={3}>{state.text}</Typography>
								<TextField
									error={Boolean(touched.title && errors.title)}
									name="title"
									margin="dense"
									label={"Type the title" && errors.title}
									fullWidth
									value={values.title}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleClose} variant="outlined">
									Cancel
								</Button>
								<Button onClick={handleSubmit} type="submit" variant="outlined">
									Delete
								</Button>
							</DialogActions>
						</Dialog>
					</Form>
				)}
			</Formik>
		</Paper>
	)
}

export default CautionDialog