import { useEffect, useState, useContext } from 'react'
import {
	Typography,
	Grid,
	Box,
	Dialog,
	Button,
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	DialogActions,
	Divider,
} from '@mui/material'

import { useHttp } from '../../../hooks/http.hook'
import { AuthContext } from '../../../context/AuthContext'
import ControlsComponent from "../../../components/ControlsComponent"

const ChangeOwner = (props) => {
    
    const { request } = useHttp()
	const { token } = useContext(AuthContext)
    
    const {selectedApi,refresh} = props
    console.log(selectedApi)
    const orgowner = selectedApi.orgowner

    const [ changesState, setChangesState ] = useState({orgowner: orgowner || ''})
    const [ readonly, setReadonly ] = useState(true)
    const handleClickChange = () => {
        setReadonly(false)
    }
    // 64048d8cbe068d3d887dcca6
    const saveChanges = async (value) => {
		try {
			// setSubmitting(true)
			//console.log(" Settings. Selected Api : ", selectedApi)
			//console.log('Save Settings: ', value)
			console.log('Settings to save: ', changesState)
			const data = await request(
				'/api/product/update/changeorgowner',
				'POST',
				{ _id: selectedApi._id, ...changesState },
				{ Authorization: `Bearer ${token}` }
			)
			//console.log({...currentApi,...changesState})
			console.log('DATA: ', data)
			//setSelectedApi({...currentApi,...changesState})
			//setSubmitting(false)
			refresh()
		} catch (e) {
			console.log('Save settings error: ', e)
		}
	}
    
    const submitHandler = () => {
        if (!!changesState.orgowner) {
            saveChanges()
        }
        
    }

    const resetHandler = () => {

    }

    // useEffect(
    //     console.log(props)
    // ,[])

    return (
        <>
            <Typography variant="h6">
                Передать в другую организацию
            </Typography>
            <TextField
                disabled={readonly}
                fullWidth
                label={"Организация"}
                name="org"
                value={changesState.orgowner}
                onChange={(event) => {setChangesState({orgowner:event.target.value})}}
                variant="outlined"	
            />
            <ControlsComponent 
                submitHandler = {submitHandler}
                resetHandler = {resetHandler}
                resetform = {()=>{}}
                readonly = {{readonly, setReadonly}}
                disabled = {!readonly}
            />
        </>
    )

}

export default ChangeOwner