
import MockPlans from './mockPlans'

const GenerateSubscribeData = (props) => {
	const publishDate = props.publishDate || '01-01-2021'
	const subscriptionsPerMonth = props.subscriptionsPerMonth || '100'

	const randomDate = (date1, date2) => {
		function randomValueBetween(min, max) {
			return Math.random() * (max - min) + min
		}
		var date1 = date1 || '01-01-1970'
		var date2 = date2 || new Date().toLocaleDateString()
		if (date2 > Date.now()) {
			date2 = Date.now()
		}
		date1 = new Date(date1).getTime()
		date2 = new Date(date2).getTime()
		if (date1 > date2) {
			return new Date(randomValueBetween(date2, date1))
		} else {
			return new Date(randomValueBetween(date1, date2))
		}
	}

	const monthDiff = (dateFrom, dateTo) => {
		if (dateFrom && dateTo) {
		return (
			dateTo.getMonth() -
			dateFrom.getMonth() +
			12 * (dateTo.getFullYear() - dateFrom.getFullYear())
		)
		}
	}
	

	const generateData = () => {
		
		const plansWeights = [
			{
				title: 'free',
				weight: 0.3,
			},
			{
				title: 'pro',
				weight: 0.4,
			},
			{
				title: 'business',
				weight: 0.2,
			},
			{
				title: 'premium',
				weight: 0.1,
			},
		]
		
		const totalmonths = monthDiff(new Date(publishDate), new Date(Date.now()))
		const plans = MockPlans.map((item) => {
			let subscribers = []
			const weight = plansWeights.find((element) => {
					return element.title == item.title.toLowerCase()
			}).weight
			console.log(weight)
			const SubscriptionsByPlan = subscriptionsPerMonth * weight
			// console.log("SubscriptionsByPlan",SubscriptionsByPlan)
			for (var i=0; i <= totalmonths; i++) {
				// console.log("i: ", i)
				for (var s=0; s <  Math.round(Math.random() * SubscriptionsByPlan); s++) {
					
					var date = new Date(publishDate)
					const currentmonth = date.setMonth(date.getMonth() + i)
					const nextmonth = date.setMonth(date.getMonth() + i + 1)
					const subscribeDate = randomDate(new Date(currentmonth), new Date(nextmonth))

					const generateRequests = () => {
						let requests = []
						//console.log(new Date(Date.now()).getTime())
						//console.log(Date.parse(subscribeDate))
				
						const totaldays = Math.floor(((new Date(Date.now())).getTime() - (new Date(subscribeDate)).getTime())/86400000)
						//console.log("totaldays: ", totaldays)
						let amount = item.amount
						let daysleft = totaldays
						let md = 0
						for(var d=0; d < totaldays; d++) {
							
							if (md <= 30) {
								md++
								let requestsperday
								if (amount >= 1) {
									requestsperday = Math.floor(Math.random() * (item.amount / 30))
									amount = amount - requestsperday
									
								} else {
									requestsperday = 0
								}
									//console.log("subscribeDate: ", subscribeDate)
									//console.log("currentDate: ", new Date(subscribeDate.setDate(subscribeDate.getDate() + d)))
									
									const currentday = new Date(subscribeDate.setDate(subscribeDate.getDate() + d))
									requests.push({
										date: new Date(currentday),
										requestsperday
									})
							} else {
								md = 0
								amount = item.amount
							}
						}
						return requests
					}
					

					// console.log("currentmonth", new Date(currentmonth).toLocaleDateString())
					// console.log("nextmonth", new Date(nextmonth).toLocaleDateString())
					subscribers.push(
						{
							date: subscribeDate,
							requests: generateRequests()
						}
					)
				}
			}
			// console.log("subscribers", Math.floor(Math.random() * item.amount) * totalmonths)
			// console.log("amount", item.amount)
			// console.log("totalmonths", totalmonths)

			// console.log(subscribers.reduce((a, b) => {return a + b.requests * 1}, 0))
			                 //Plans.reduce((a, b) => {return a + b.quantity * b.cost}, 0).toLocaleString()
			return {
				...item,
				subscribers,
				quantity: subscribers.length,
				requests: subscribers.reduce((a,b) => {return a + b.requests.reduce((a,b) => {return a + b.requestsperday}, 0) * 1}, 0),
				
			}
		})
		
		console.log('plans: \n', plans , '\n')
		return plans
		
		const subscribe = {
			date: '',
		}
		const days = ''
	}

	return generateData()
}

export default GenerateSubscribeData
