import { 
	useState, 
	useEffect,
	useContext, 
	useCallback 
} from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'
import {
	AppBar,
	Badge,
	Box,
	IconButton,
	Toolbar,
	Tabs,
	Tab,
	Grid
	//Avatar
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import InputOutlinedIcon from '@mui/icons-material/InputOutlined';
//import Logo from './Logo'
import PopupMenu from './PopupMenu'
//import { Login, NavigateNextOutlined } from '@mui/icons-material'
import { useDetails } from '../hooks/details.hook'
import AccountButton from './AccountButton'
import { AuthContext } from '../context/AuthContext'
import { NavContext } from '../context/NavContext'
import mainmenu from '../__mocks__/mainmenu'
import PopupAccount from './PopupAccount';

const items = [
	{
		pathname: '/marketplace',
		title: 'Market',
		authorized: true,
		icon: ''
	},
	{
		pathname: '/dash/apiedit',
		title: 'API',
		authorized: true,
		icon: ''
	},
	{
		pathname: '/dash/appedit',
		title: 'APP',
		authorized: true,
		icon: ''
	},
	{
		pathname: '/dash/requestedit',
		title: 'REQUEST',
		authorized: true,
		icon: ''
	},

]

const DashboardBottombar = ({ onMobileNavOpen, ...rest }) => {
	const { 
		token, 
		logout, 
		userId 
	} = useContext(AuthContext)
	const { selected, setnav } = useContext(NavContext)
	const isAuthenticated = !!token
	const [notifications] = useState([])
	const [ tabvalue, setTabvalue ] = useState(10)
	const { fetchDetails } = useDetails()
	const [details, setDetails] = useState()
	const navigate = useNavigate()

	useEffect(() => {
		const fetchD = async (userId, token) => {
			try {
				console.log('USR:', userId, token)
				const fetched = await fetchDetails(userId, token)

				console.log('fetch:', fetched)
				setDetails(fetched)
			} catch (e) {
				console.log('fetch Error', e)
			}
		}

		//console.log("ID:", userId, "Token: ", token)
		if (userId) {
			console.log('USR:', userId)
			fetchD(userId, token)
		}
	}, [userId, token])

	const loginHandler = () => {
		navigate('/login')
	}

	const handleChange = (event, newValue) => {
		setTabvalue(newValue)
	}
	
	const setInitialIndex = useCallback(() => {
		items.find((item, index) => {
		if (item.pathname === selected) {
			setTabvalue(index)
		}
	})}, [selected])

	useEffect(() => {
		if (selected) {
			setInitialIndex()
		}
	}, [selected,setInitialIndex])

	return (
		<>
		<AppBar variant="dense" elevation={0} {...rest}  color="default" sx={{bottom:0, top: 'auto',display: { xs: 'block', lg: 'none', xl: 'none' } }}>
			
				<Grid container>
				<Grid item xs={10}>
				<Tabs
					value={tabvalue}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					variant="fullWidth"
					flex="2"
				>
					{mainmenu
						.map((item, index) => {
							if (((item.authorized == isAuthenticated) || (item.authorized == false) && (item.homeonly == false)) && (item.bottom == true))
							return (
							<Tab
								style={{
									minWidth: `${item.mobiletitle.length}` + `em`,
									paddingInline:'1em'
								}}
								label={item.mobiletitle}
								value={index}
								key={index}
								onClick={()=>{setnav(item.pathname)}}
								to={{ pathname: item.basepath + item.pathname }}
								component={RouterLink}
							/>
						)}
						)}
						<Tab sx={{display:'none'}} value={10}></Tab>
				</Tabs>
				</Grid>
				<Grid item xs={2}>
				<Box alignItems="center" justifyContent="center" height="100%" sx={{ display: { xs: 'flex', lg: 'none', xl: 'block' } }}>
				{!!details ? (
						<PopupAccount 
							user={{firstName: details.firstName, ownerId: details.ownerId}}
						/>
						
					) : (
						<IconButton color="inherit" onClick={loginHandler}>
							<InputOutlinedIcon />
						</IconButton>
					)}
				</Box>
				</Grid>
				</Grid>
		</AppBar>
		
		</>

	)
}
//<AccountButton user={details.firstName} sx={{backgroundColor:"#888"}} bottom={true}/>
DashboardBottombar.propTypes = {
	onMobileNavOpen: PropTypes.func,
}

export default DashboardBottombar
