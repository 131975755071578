import {useState, useEffect} from 'react'
import { makeStyles, styled } from '@mui/styles'
import {
	Box, 
	Button, 
	Paper,
	Typography,
	StepContent, 
	StepLabel, 
	Step, 
	Stepper,
	Divider,
	Collapse,
	Grid,
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'


const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#f5f5f5',
color: ownerState.active ? theme.palette.secondary.main : theme.palette.primary.main,
zIndex: 1,
width: 36,
height: 36,
left: -5,
display: 'flex',
borderRadius: '50%',
justifyContent: 'center',
alignItems: 'center',
...(ownerState.active && {
	...theme.palette.progressbar.active,
}),
...(ownerState.completed && {
	...theme.palette.progressbar.completed,
}),
}));


function ColorlibStepIcon(props) {
const { active, completed, className } = props;
return (
	<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
		<Typography variant="h6">{props.icon}</Typography>
	</ColorlibStepIconRoot>
);
}

const StepperStyled = styled(Stepper)(({theme}) => ({
	marginLeft: 4,
	"& .MuiStepConnector-line": {
		borderLeftWidth: 3,
		borderColor: theme.palette.border,
	},
	"& .MuiStepContent-root": {
		borderLeftWidth: 3,
		paddingLeft: 40,
		borderColor: theme.palette.border,
	},
}))

const StepLabelStyled = styled(StepLabel)({
	marginLeft: -4,
	cursor: "pointer !important"
	,
	"& .MuiStepLabel-label": {
		marginLeft: 20
	},
	"& .MuiStepLabel-label.Mui-active": {
	},
	"& .MuiStepLabel-label.Mui-completed": {
	},
	"& .MuiStepLabel-label.Mui-disabled": {
		color: '#555'
	}
});

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		padding: theme.spacing(3),
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	resetContainer: {
		padding: theme.spacing(3),
	},
}))

function getSteps() {
	return ['Создайте APP', 'Маркетплейс', 'Подключение']
}

function getStepContent(step) {
	switch (step) {
		case 0:
			return ['Перейдите в раздел APP',
			'Создайте свое первое приложение нажав на "Создать новый APP". Заполните все поля и нажмите создать.',
			'Выберите APP из списка > definition. Как вы можете увидеть теперь у вас появился уникальный идентификатор APP KEY с помощью которого вы сможете подключать решения представленные в маркетплейс']
		case 1:
			return [
				'Перейдите в раздел Маркетплейс',
				'Выберите интересующее вас решение',
				'В подразделе Endpoints вы можете просмотреть возможные ответы сервиса и необходимые параметры',
				'Выберите один Endpoint и укажите в списке ваше приложение APP; измените другие параметры',
				'Нажмите RUN TEST',
				'В окне Results вы увидите ответ сервиса по вашим параметрам'
			]
		case 2:
			return [
				'В подразделе Endpoints выбранного решения',
				'Скопируюте Snippet из соответствующего окна и вставьте в свой код',
				'Поздравляем! Вы подключили свой первый сервис!'
			]
		default:
			return ['Unknown step']
	}
}

const AppGuide = (props) => {
	const [ guideOpenState, setGuideOpenState ] = useState(props.open)
	const classes = useStyles()
	const [activeStep, setActiveStep] = useState(0)
	const steps = getSteps()

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1)
	}

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1)
	}

	const handleStep = (step) => {
		setActiveStep(step)
	} 

	const handleReset = () => {
		setActiveStep(0)
	}

	useEffect(() => {
		setGuideOpenState(props.open)
	}, [props.open])

	return (
		<>
		{!guideOpenState ?
			<Box fullWidth sx={{m:0,p:0,pl:3,borderRadius:'0', justifyContent: "flex-start"}} component={Button} onClick={()=>{setGuideOpenState(true)}}>
				<ExpandMore />
				<Typography variant="caption">Открыть гайд</Typography>
			</Box>
			:
			<Box fullWidth sx={{m:0,p:0,pl:3,borderRadius:'0', justifyContent: "flex-start"}} component={Button} onClick={()=>{setGuideOpenState(false)}}>
				<ExpandLess />
				<Typography variant="caption">Закрыть гайд</Typography>
			</Box>
			}
			<Divider />
			<Collapse in={guideOpenState}>
			<Grid item xs={12} md={6}>
			<div className={classes.root}>
			<StepperStyled activeStep={activeStep} orientation="vertical">
				{steps.map((label, index) => (
					<Step key={label}>
						<StepLabelStyled
							sx={{p:0}}
							icon={index + 1}
							onClick={() => handleStep(index)}
							StepIconComponent={ColorlibStepIcon}
						>
							<Typography variant="h6">{label}</Typography>
						</StepLabelStyled>
						<StepContent>
							<Typography  sx={{py:3}}>{getStepContent(index).map((item,index) => {
								return (
									<Box key={index} display="flex" alignItems="start">
										<Typography variant="caption" sx={{pr:1}}>●</Typography>
										<Typography key={index} variant="body2" sx={{pb:1}}>{item}</Typography>
									</Box>
								)
							})}</Typography>
							
								<div>
									<Button
										disabled={activeStep === 0}
										onClick={handleBack}
										className={classes.button}
									>
										Назад
									</Button>
									<Button
										variant="outlined"
										color="primary"
										onClick={handleNext}
										className={classes.button}
									>
										{activeStep === steps.length - 1 ? 'Закончить' : 'Вперед'}
									</Button>
								</div>
							
						</StepContent>
						
					</Step>
				))}
			</StepperStyled>
			{activeStep === steps.length && (
				<Paper square elevation={0} className={classes.resetContainer}>
					<Typography sx={{pb:3}}>Поздравляем! Вы подключили свой первый сервис!</Typography>
					<Button onClick={handleReset} variant="outlined">
						Заново
					</Button>
				</Paper>
			)}
		</div>
		</Grid>
		</Collapse>
		</>
	)
}

export default AppGuide