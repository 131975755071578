
import { useState, useEffect, useCallback, useContext } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'
import {
	AppBar,
	Badge,
	Box,
	Icon,
	IconButton,
	Toolbar,
	Tabs,
	Tab,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import InputIcon from '@mui/icons-material/Input';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';


import Logo from './Logo'
import PopupMenu from './PopupMenu'
import { SendIcon } from './graphics/icons'

import { useDetails } from '../hooks/details.hook'
import { NavContext } from '../context/NavContext'
import { AuthContext } from '../context/AuthContext'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import mainmenu from '../__mocks__/mainmenu'
import PopupAccount from './PopupAccount';
import PopupNotification from './PopupNotification';

const MainNavbar = ({ onMobileNavOpen, ...restprops }) => {
	const navigate = useNavigate()
	const { token, logout, userId, isAuthenticated } = useContext(AuthContext)
	const [ details, setDetails ] = useState()
	const [ tabvalue, setTabvalue ] = useState(10)
	const { fetchDetails } = useDetails()
	const [ checkDetails, setCheckDetails ] = useState(false)
	const { selected, setnav } = useContext(NavContext)

	const theme = useTheme()
	const breakpointDownMD = useMediaQuery(theme.breakpoints.down('md'))

	useEffect(() => {
		const getDetails = async (userId, token) => {
			try {
				const fetched = await fetchDetails(userId, token)
				setDetails(fetched)
			} catch (e) {
				return console.log('fetch Error', e)
			}
		}
		if (userId) {
			getDetails(userId, token)
		}
	}, [userId, token, checkDetails])

	const loginHandler = () => {
		navigate('/login')
	}
	const handleChange = (event, newValue) => {
		setTabvalue(newValue)
	}

	const messagesHandler = () => {
			setTabvalue(10)
			setnav('channels')
			navigate('/dash/channels')
	}

	const setInitialIndex = useCallback(() => {
		mainmenu.find((item, index) => {
		if (item.pathname === selected) {
			setTabvalue(index)
		}
	})}, [selected])

	useEffect(() => {
		
		if (selected) {
			console.log('selected: ', selected)
			setInitialIndex()
		}
		return () => {}
	}, [selected,setInitialIndex])

	return (
		<AppBar color="secondary" elevation={0} {...restprops} sx={{p:0,borderBottom:1,borderColor:theme.palette.borderColor}}>
			<Box sx={{height:'5px'}}/>
			<Toolbar variant="dense" >
				<RouterLink to="/" onClick={()=>{setTabvalue(10)}}>
					<Logo long={selected!=='homepage' && !breakpointDownMD} ismobile={!!breakpointDownMD}/>
				</RouterLink>
				
				<Box sx={{ flexGrow: 1 }} />
				
				<Tabs
					value={tabvalue}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					variant="fullWidth"
					
				>
					{mainmenu
						.map((item, index) => {
							if (
								(
									(item.authorized == isAuthenticated) 
									|| 
									((item.authorized == false) && (item.homeonly == false))
								)
								&&
								(breakpointDownMD ? (item.bottom == false) : true)
								&&
								!item.disabled
								)
							return (
							<Tab
								style={{
									minWidth: `${
										breakpointDownMD ? 
										item.mobiletitle.length
									:
										item.title.length * 0.8
									}` + `em`,
									paddingInline:'1em'
								}}
								label={
									breakpointDownMD ? 
										item.mobiletitle
									:
										item.title}
								value={index}
								key={index}
								onClick={()=>{setnav(item.pathname)}}
								to={{ pathname: item.basepath + item.pathname }}
								component={RouterLink}
							/>
						)}
						)}
						<Tab sx={{position:'absolute',left:-100}} value={10}></Tab>
				</Tabs>
				
				<Box sx={{ display: { xs: 'none', md: 'inherit', lg: 'inherit', xl: 'inherit' } }}>
					{!!details && token ? (
						<Box sx={{display:'flex', gap:1}}>
							<PopupNotification 
								user={{firstName: details.firstName, ownerId: details.ownerId, token}}
								actions={[]}
							/>
							<IconButton
								onClick={messagesHandler}
							>
								<SendIcon sx={{fontSize:'18pt'}}/>
							</IconButton>
							<PopupAccount 
								user={{firstName: details.firstName, ownerId: details.ownerId, token}}
							/>
						</Box>
					) : (
						<IconButton 
							color="inherit" 
							onClick={()=>{setTabvalue(10);loginHandler()}}
						>
							<InputIcon />
						</IconButton>
					)}
				</Box>
				<Box sx={{ display: { xs: ['survey'].indexOf(selected) == -1 ? 'block' : 'none', md: 'none', lg: 'none', xl: 'none' } }}>
					<IconButton 
						color="inherit"
						onClick={()=>{setTabvalue(10);onMobileNavOpen()}}>
						<MenuIcon />
					</IconButton>
				</Box>
			</Toolbar>
		</AppBar>
	)
}

MainNavbar.propTypes = {
	onMobileNavOpen: PropTypes.func,
}

export default MainNavbar
