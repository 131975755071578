import { useEffect, useState, useContext, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Typography, Box, Container, Button, Icon, IconButton, Tooltip } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { NavContext } from '../context/NavContext'
import HomeRequestForm from './Home/HomeRequestForm'
import TelegramIcon from '@mui/icons-material/Telegram'
import { SendIcon } from '../components/graphics/icons'
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined'
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined'
import GuideContest from './Home/GuideContest'
import Guide from './Home/Guide'
import ScrollTop from '../components/ScrollTop'
import Carousel from '../components/CarouselComponent'


const useStyles = makeStyles((theme) => ({
	"@keyframes fadeIn": {
		"0%": {
			'background-position': '40% 1577%',
		},
		"100%": {
			'background-position': '1567% 50%',
		}
	},
	"@keyframes rotate": {
		"0%": {
			'transform': 'rotate(45deg)',
		},
		"100%": {
			'transform': 'rotate(405deg)',
		}
	},
	headerbodyBg: {
		content: "",
		position: "absolute",
		width: "100%",
		height: "100%",
		top: "-50%",
		left: "-50%",
		"z-index": "-1",
		backgroundBlendMode: `multiply`,
		// background: '#ccc'
		background: `
			
			linear-gradient(-45deg,
			rgb(250, 250, 250)10%,
			rgb(0, 240, 240)50%,
			rgb(250, 250, 250)90%
			)`,
		// animation: "$rotate 10s linear infinite"
	},
	headerBG: {
		'&::before':{
				content:'""',
				backgroundBlendMode: `multiply`,
				background: `
					linear-gradient(-45deg,
					rgba(250, 250, 250,0)35%,
					rgba(0, 0, 0,0.03)50%,
					rgba(250, 250, 250,0)75%
					)`,
				//animation: "$rotate 30s linear infinite",
				position: 'absolute',
           		width: '200%',
				top:0,
				height: '400%',
				top: '-150%',
				left: '-50%',
				overflow: 'hidden',
				display: 'block',
				"z-index": "-1",
			},
		position: 'relative',
		overflow: 'hidden',
		"z-index": "0",
		backgroundSize: '100%',
		backgroundPosition: '40% 50%',
		backgroundBlendMode: `lighten`,
		background: `
			url(${"mlauto_1.gif"}),
			repeating-linear-gradient(45deg,rgba(0,0,0,0),rgba(0,0,0,0) 2px,rgba(255,255,255,0.0) 2px,rgba(255,255,255,0.7) 7px),
		 	repeating-linear-gradient(-45deg,rgba(0,0,0,0),rgba(0,0,0,0) 2px,rgba(255,255,255,0.0) 2px,rgba(255,255,255,0.7) 7px)
		`,
		// //animation: "$fadeIn 120s linear infinite"
		// repeating-linear-gradient(45deg,rgba(0,0,0,0),rgba(0,0,0,0) 2px,rgba(255,255,255,0.0) 2px,rgba(255,255,255,0.7) 7px),
		// 	repeating-linear-gradient(-45deg,rgba(0,0,0,0),rgba(0,0,0,0) 2px,rgba(255,255,255,0.0) 2px,rgba(255,255,255,0.7) 7px)
	},
	headerBody: {
		[theme.breakpoints.down('md')]: {
			minHeight: '100%'
		},
		[theme.breakpoints.up('md')]: {
			height: 'inherit',
			minHeight: '38em'
		},		
	},

	root: {
		width: '100%',
		padding: theme.spacing(3),
	},
	bodyBg: {
		backgroundColor:'#f6f6f6',
	},
	subheaderHeader: {
		'&::before':{
			content:'""',
			backgroundBlendMode: `multiply`,
			background: `
				linear-gradient(-45deg,
				rgba(250, 250, 250,0)35%,
				rgba(0, 0, 0,0.03)50%,
				rgba(250, 250, 250,0)75%
				)`,
			//animation: "$rotate 30s linear infinite",
			position: 'absolute',
			width: '200%',
			top:0,
			height: '400%',
			top: '-150%',
			left: '-50%',
			overflow: 'hidden',
			display: 'block',
			"z-index": "-1",
		},
		//backgroundColor:'#f6f6f6',
		width:'100%',
		display: 'flex',
		alignItems: 'center',
		height:'100%',
		
		position: 'relative',
		overflow: 'hidden',
		"z-index": "0",
		backgroundSize: '100%',
		backgroundPosition: '40% 50%',
		backgroundBlendMode: `lighten`,
		background: `
			url(${"mlauto_1.gif"}),
			repeating-linear-gradient(45deg,rgba(0,0,0,0),rgba(0,0,0,0) 2px,rgba(255,255,255,0.0) 2px,rgba(255,255,255,0.7) 7px),
		 	repeating-linear-gradient(-45deg,rgba(0,0,0,0),rgba(0,0,0,0) 2px,rgba(255,255,255,0.0) 2px,rgba(255,255,255,0.7) 7px),
			linear-gradient(90deg,
				rgba(255, 255, 255,1)15%,
				rgba(250, 250, 250,0)75%
				)
		`,
		
		[theme.breakpoints.down('md')]: {
			minHeight: '10em',
			padding: theme.spacing(3)
		},
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(6),
			paddingLeft: theme.spacing(9),
		},

	},
	subheaderBody: {
		backgroundColor: '#fafafb',
		[theme.breakpoints.down('md')]: {
			minHeight: '100%'
		},
		[theme.breakpoints.up('md')]: {
			//minHeight: '50%'
			padding: theme.spacing(6),
			paddingTop: theme.spacing(3),
			paddingBottom: theme.spacing(3)
		},

	},
	orgCarouselContainer: {
		background: `
					linear-gradient(-45deg,
					rgba(255, 255, 255,1)20%,
					rgba(0, 0, 0,0.03)50%,
					rgba(252, 252, 252,1)80%
					)`,
		[theme.breakpoints.down('md')]: {
			minHeight: '100%',
			padding: theme.spacing(1),
		},
		[theme.breakpoints.up('md')]: {
			//minHeight: '50%'
			padding: theme.spacing(6),
			paddingTop: theme.spacing(6),
			paddingBottom: theme.spacing(6)
		},

	},
	guideBody: {
		backgroundColor: '#f9f9fa',
		[theme.breakpoints.down('md')]: {
			minHeight: '100%'
		},
		[theme.breakpoints.up('md')]: {
			//minHeight: '50%'
		},
	},
	footerBody: {
		background: '#eeecee',
		[theme.breakpoints.down('md')]: {
			minHeight: '100%'
		},
		[theme.breakpoints.up('md')]: {
			minHeight: '60%'
		},
		padding: theme.spacing(6),
		paddingTop: theme.spacing(3),
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	resetContainer: {
		padding: theme.spacing(3),
	},

	headerBigContainer: {
		[theme.breakpoints.down('md')]: 
			{
				minHeight: '200%'
			},
		minHeight: '100%'
	},
	headerContainer: {

	},
	headerFeatures: {
		[theme.breakpoints.down('md')]:
			{
				padding: theme.spacing(3),
			},
		padding: theme.spacing(6),
		paddingRight: theme.spacing(12),
		background: `linear-gradient(90deg,
			rgba(253, 253, 253,1)00%,
			rgba(250, 250, 250,0.7)100%
			)`,
	},
	headerSocial: {
		border: '1px solid #e0e0e0',
		borderInlineEnd: '0',
		padding: theme.spacing(1),
		paddingRight: theme.spacing(1),
		gap: theme.spacing(0.5),
		background: `linear-gradient(180deg,
			rgba(255, 255, 255,1)0%,
			rgba(250, 250, 250,0.5)100%
			)`,
		borderRadius: "23px 0 0 23px",
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',

		// color: '#FFF',
		// background: '#FFF',
		// backgroundClip: 'padding-box', /* !importanté */
		// border: 'solid 5px transparent', /* !importanté */
		// borderRadius: '1em',
		
		// '&::before': {
		// 	content:'""',
		// 	position: 'relative',
		// 	top: 0,

		// 	// zIndex: -1,
		// 	margin: 5, /* !importanté */
		// 	borderRadius: 'inherit', /* !importanté */
		// 	background: `#000`,
		// }
		
	},
	headerTitle: {
		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(3),
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
		[theme.breakpoints.up('md')]: {
			//minHeight: '100%'
			padding: theme.spacing(6),
			paddingLeft: theme.spacing(9),
		},
		
		background: `linear-gradient(90deg,
			rgba(255, 255, 255,1)30%,
			rgba(250, 250, 250,0.7)100%
			)`,
		width:'100%',
	},
	
	

}))

const telegramlink = 'https://t.me/aiaggregatorsupport'

const mockFeatures = [
	{
		title: 'Алгоритмы по подписке',
		content: 'Тестируйте и подключайте популярные готовые решения представленные на нашей платформе',
		tag: 'api',
		link: '',
		linklabel: 'В РАЗРАБОТКЕ',
		disabled: true
	},
	{
		title: 'Конкурсы',
		content: 'Заявки на разработку можно оформить в виде конкурса и выбрать наиболее успешное решение',
		tag: 'contest',
		link: '',
		linklabel: 'В РАЗРАБОТКЕ',
		disabled: true
	},
	{
		title: 'Заявки на разработку',
		content: 'Обратитесь к сообществу разработчиков создав заявку на нашей платформе и получите решение по заданным параметрам',
		tag: 'request',
		link: '',
		linklabel: 'В РАЗРАБОТКЕ',
		disabled: false
	},
]



const mockContest = [
	{
		title: 'Создавайте',
		type: 'contestcreate',
		description: `Всего за несколько действий вы можете создать свой конкурс ИИ.
		Опишите задачу и загрузите данные,
		чтобы вместе с сообществом разработчиков найти оптимальное решение.
		Узнайте подробнее, посмотрев быстрый гайд.`,
		buttonText: `Гайд "Создание конкурса"`,
		buttonToText: `Создать конкурс`,
		buttonLink: './dash/contestedit',
		guide: [
			{
				title: 'Новый конкурс',
				description: 'В разделе КОНКУРС нажмите создать новый. Укажите название и краткое описание.'
			},
			{
				title: 'Dataset',
				description: 'В подразделе Dataset укажите ссылки на test и train данные. Выберите метрику. Загрузите файл с решением "*_solution.csv" и основанный на нем пример ("*_sample.csv")'
			},
			{
				title: 'Timeline',
				description: 'В подразделе Timeline настройте этапы конкурса и даты.'
			},
			{
				title: 'Publish',
				description: 'В подразделе Settings нажмите на чеклист чтобы проверить готовность конкурса к публикации.'
			}
		],
	},
	{
		title: 'Участвуйте',
		type: 'contestjoin',
		description: `Участвуйте в конкурсах на нашей платформе. Выигрывайте призы. Тестируйте свои решения. 
		Публикуйте алгоритмы тут же в нашем маркетплейс и получайте вознаграждение. ${`\n`}
		Узнайте подробнее посмотрев быстрый гайд.`,
		buttonText: `Гайд "Участие в конкурсе"`,
		buttonToText: `Участвовать`,
		buttonLink: './marketplace/contests',
		guide: [
			{
				title: 'Маркетплейс',
				description: 'В разделе Маркетплейс выберите конкурсы. Перейдите на страницу конкурса.'
			},
			{
				title: 'Участие',
				description: 'Чтобы принять участие вам нужно принять правила проведения в подразделе Rules. После этого вам станут доступны датасет и отправка результатов.'
			},
			{
				title: 'Отправка',
				description: 'В подразделе My submissions загрузите полученное при помощи вашего алгоритма решение в виде файла "*_submission.csv". Здесь же появится публичный результат проверки. Вы можете выбрать один из уже отправленных файлов тот, который пойдет в финальный лидерборд'
			},
			{
				title: 'Результаты',
				description: 'Подразделе Leaderboard вы можете наблюдать за результатами конкурса.'
			},
		],
	},
]

const mockApi = [
	{
		title: 'Подключайте',
		type: 'apisubscribe',
		description: `Получите доступ к решениям в области исскуственного интеллекта.
		Если вы хотите интегрировать решение в свою инфраструктуру.
		Узнайте подробнее, посмотрев быстрый гайд.`,
		buttonText: `Гайд "Подключение АПИ"`,
		buttonToText: `Маркетплейс`,
		buttonLink: './marketplace/solutions',
		guide: [
			{
				title: 'Первые шаги',
				description: [
					'Пройдите регистрацию',
					'Укажите свой e-mail, после чего подтвердите свою почту перейдя по ссылке в письме',
					'Теперь вам доступны все разделы платформы'
				],
			},
			{
				title: 'Маркетплейс',
				description: [
					'Перейдите в раздел Маркетплейс',
					'Выберите интересующее вас решение',
					'В подразделе Endpoints вы можете просмотреть возможные ответы сервиса и необходимые параметры',
					'Выберите один из Endpoint`ов и выберите в списке ваше приложение APP; измените другие параметры',
					'Нажмите RUN TEST',
					'В окне Results вы увидите ответ сервиса по вашим параметрам'
				],
			},
			{
				title: 'Создайте APP',
				description: [
					'Перейдите в раздел APP',
					'Создайте свое первое приложение нажав на "Создать новый APP"',
					'Теперь у вас появился уникальный идентификатор APP KEY с помощью которого вы сможете подключать решения представленные в маркетплейс'
				],
			},
			{
				title: 'Подключение',
				description: [
					'В подразделе Endpoints выбранного решения',
					'Скопируюте Snippet из соответствующего окна и вставьте в свой код',
					'Поздравляем! Вы подключили свой первый сервис!'
				],
			}
		],
	},
	{
		title: 'Публикуйте',
		type: 'apicreate',
		description: `Опубликуйте своё решение на платформе. Если вы рабработчик и заинтересованы в привлечении клиентов. ${`\n`}
		Узнайте подробнее посмотрев быстрый гайд.`,
		buttonText: `Гайд "Публикация АПИ"`,
		buttonToText: `Опубликовать АПИ`,
		buttonLink: './dash/apiedit',
		guide: [
			{
				title: 'Первые шаги',
				description: [
					'Пройдите регистрацию',
					'Укажите свой e-mail, после чего подтвердите свою почту перейдя по ссылке в письме',
					'Теперь вам доступны все разделы платформы'
				],
			},
			{
				title: 'Создайте API',
				description: [
					'Перейдите в раздел API',
					'Создайте свой первый сервис нажав на "Создать новый API"',
					'Выбрав из появившегося списка созданный API вы попадете в раздел редактирования API',
					'Укажите BASEURL для вашего API'
				],
			},
			{
				title: 'Endpoints',
				description: [
					'В разделе редактирования API выберите подраздел Endpoints',
					'Здесь вы увидите список Endpoints',
					'Нажмите на кнопку Добавить',
					'Откроется редактор Endpoints. Укажите все необходимые параметры такие как путь, headers, query, method и пр.',
					'Нажмите сохранить. Теперь вы можете протестировать ваши Endpoints перейдя в подраздел Preview'
				],
			},
			{
				title: 'Маркетплейс',
				description: [
					'В подразделе Settings вы можете изменить видимость вашего API изменив настройки публикации',
					'Перейдите в раздел Маркетплейс и найдите свое приложение чтобы убедиться, что данные API правильно отображаются для сообщества',
					'Поздравляем! Вы опубликовали свой первый сервис!'
				],
			},
		],
	},
]

const mockRequest = [
	{
		title: 'Запрашивайте',
		type: 'requestcreate',
		description: `Обратитесь к сообществу разработчиков создав заявку на нашей платформе и получите решение по заданным параметрам.
		Узнайте подробнее, посмотрев быстрый гайд.`,
		buttonText: `Гайд "Создание Запроса на разработку"`,
		buttonToText: `Создать запрос`,
		buttonLink: './dash/requestedit',
		guide: [
			{
				title: 'Первые шаги',
				description: [
					'Пройдите регистрацию',
					'Укажите свой e-mail, после чего подтвердите свою почту перейдя по ссылке в письме',
					'Теперь вам доступны все разделы платформы'
				],
			},
			{
				title: 'Создайте Запрос',
				description: [
					'Перейдите в раздел Запросы',
					'Создайте нажав на "Создать новый Request"',
					'Выбрав из появившегося списка созданный Запрос вы попадете в раздел редактирования',
					'В подразделе Settings вы можете изменить видимость вашего запроса изменив настройки публикации'
				],
			},
			{
				title: 'Маркетплейс',
				description: [
					'Перейдите в раздел Маркетплейс далее подраздел Запросы (requests)',
					'Здесь вы можете найти ваш запрос и посмотреть как его видят другие пользователи платформы',
					'Разработчики могут откликаться на ваш запрос заполнив форму "Предложить решение"'
				],
			},
			{
				title: 'Взаимодействие',
				description: [
					'Перейдите в раздел Запросы и выберите ваш запрос из списка',
					'В раскрывшемся меню выберите Ответы (Responses)',
					'Здесь вы можете увидеть отклики участников в формате чата',
					'Обсудите параметры решения с разработчиком',
					'Назначте исполнителем кандидата с подходящим вам решением или отклоните заведомо неподходящие',
				],
			},
			{
				title: 'Завершение',
				description: [
					'Когда работа завершена исполнитель пришлет запрос на принятие работы',
					'Если требуется доработка, укажите необходимые правки и нажмите "Отправить на доработку"',
					'Обратите внимание на то, что вам необходимо дать ответ в течении двух суток, в противном случае работа будет автоматически принята и будет считаться завершенной',
					'Дождитесь пока исполнитель внесет необходимые правки и снова пришлет запрос на принятие работы',
					'Если все обговоренные правки внесены нажмите "Принять работу". После чего работа будет считаться завершенной  ',
				],
			},
		],
	},
	{
		title: 'Предлагайте',
		type: 'requestresponse',
		description: `Если вы рабработчик.
		Узнайте подробнее посмотрев быстрый гайд.`,
		buttonText: `Гайд "Предложить решение"`,
		buttonToText: `Предложить решение`,
		buttonLink: './marketplace/requests',
		guide: [
			{
				title: 'Первые шаги',
				description: [
					'Пройдите регистрацию',
					'Укажите свой e-mail, после чего подтвердите свою почту перейдя по ссылке в письме',
					'Теперь вам доступны все разделы платформы'
				],
			},
			{
				title: 'Маркетплейс',
				description: [
					'Перейдите в раздел Маркетплейс далее подраздел Запросы (requests)',
					'Просматривайте, выберите один из запросов',
					'При нажатии на кнопку "Предложить решение" вы увидете форму, заполните её',
					'Через какое-то время, когда заказчик ознакомиться с вашим предложением, вам придет сообщение от заказчика в общем разделе сообщения',
				],
			},
			{
				title: 'Взаимодействие',
				description: [
					'Перейдите в раздел сообщения',
					'Здесь вы можете вести переписку с заказчиком по проекту',
					'Если ваши переговоры с заказчиком будут успешны вас назначат исполнителем, о чем вам придет уведомление в чат',
					'Старайтесь разбивать задачу на этапы и сообщать об их статусе заказчику чтобы он понимал статус проекта',
					
				],
			},
			{
				title: 'Завершение',
				description: [
					'Когда вы считаете что работа завершена. Нажмите на кнопку "Сдать работу"',
					'Через какое-то время максимум двое суток. Заказчик примет работу или попросит доработать если параметры не соответствуют требоваиям обговоренным в чате и в задании',
					'Если требуется доработка, внесите необходимые правки и снова сдайте работу',
					'Если работа принята, вам придет уведомление',
				],
			},
		],
	},
]


const Homepage = (props) => {
	
	const theme = useTheme()
	const Navigate = useNavigate()
	const classes = useStyles()
	const featuresArray = mockFeatures
	const Nav = useContext(NavContext)
	

	const from = props.from
	if (from && from == 'verify') {
		alert('Succesfully verified!')
	}

	const path = props.path

	const handleCopyEmail = (message) => {
		var textArea = document.createElement("textarea");
		textArea.value = message;
		textArea.style.opacity = "0"; 
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		try {
				var successful = document.execCommand('copy');
				var msg = successful ? 'successful' : 'unsuccessful';
				alert('Адрес email скопирован в буфер обмена: ' + message);
		} catch (err) {
				alert('Ошибка');
		}
		document.body.removeChild(textArea)
	}
	
	const breakpointDownMD = useMediaQuery(theme.breakpoints.down('md'))
	const adaptive = {
		h2: breakpointDownMD ? 'h3': 'h1',
		h3: breakpointDownMD ? 'h4': 'h3',
		h5: breakpointDownMD ? 'h5': 'h5',
	}

	const [ contestGuide, setContestGuide ] = useState(mockContest)
	const [ apiGuide, setApiGuide ] = useState(mockApi)
	const [ requestGuide, setRequestGuide ] = useState(mockRequest)
	const [ guideState, setGuideState ] = useState()

	const handleGuideOpen = (type,part) => {
		const guide = [...apiGuide,...contestGuide,...requestGuide].find((item) => item.type == type) 
		
		const ref = document.querySelector(`#${type}`)
		if (!!part && !!ref) {
			setTimeout(() => {
				ref.scrollIntoView({block: "start", behavior: 'smooth' })
				}, 100);
		}
		setGuideState({open:true,type,guide,part})
	}

	const handleButton = (event) => {
		
		Navigate(event.target.name)
	}

	const handleFeatureClick = (tag) => {
		const ref = document.querySelector(`#${tag}`)
		setTimeout(() => {
			ref.scrollIntoView({block: "start", behavior: 'smooth' })
		}, 100);
	}

	useEffect(() => {
		Nav.setnav(path? path : 'homepage')
		return () => {}
	}, [Nav])

	
	return (
		<Box height="100%" id="back-to-top-anchor">
			<ScrollTop/>
			<Grid container className={classes.headerBigContainer}>
			<Grid container className={`${classes.headerBody} ${classes.headerBG}`}>
				<Grid alignItems="center" justify="center" container>
						<Grid item container xs={12}>
						<Box display="flex" flexDirection="column" sx={{width:'100%'}}>
							<Box sx={breakpointDownMD ? {flexGrow:'0.5'} : {flexGrow:'1'}}/>

							<Grid container className={classes.headerContainer}>
								<Grid item xs={12} md className={classes.headerTitle}>
										<Box display="flex" flexDirection="column" sx={{height:'100%'}}>
											{!breakpointDownMD && <Typography variant={adaptive.h2} sx={{fontWeight:"400",letterSpacing:'-3.7pt'}} color="#181818" noWrap>Ai Aggregator</Typography>}
											<Box flexGrow="0.5"/>
											<Typography variant="body1">Маркетплейс алгоритмов искусственного интеллекта</Typography>
										</Box>
								</Grid>
								<Grid item xs md className={classes.headerFeatures} >
									<Box flexGrow="1" display="flex" flexDirection="column" >
										<Typography variant="h5" >Алгоритмы ИИ - быстро, просто, недорого</Typography>
										<Box height="1em"/>
										<Typography variant="body2">Создайте аккаунт чтобы получить доступ ко всем возможностям платформы</Typography>
										<Box paddingTop="1em" sx={{display:"flex",gap:'1em', ...(!!breakpointDownMD && {flexDirection:'column'})}}>
											<Button sx={theme.customButton} name="./marketplace" onClick={handleButton}>Перейти в маркетплейс</Button>
											<Button sx={theme.customButtonOutline} name="./register" onClick={handleButton}>Создать аккаунт</Button>
										</Box>
									</Box>
								</Grid>
								<Grid item>
									<Box className={classes.headerSocial} >
										<Tooltip title="Telegram: @aiaggregatorsupport" placement="left">
											<IconButton
												variant="text"
												onClick={()=>{window.open(telegramlink, '_blank')}}
												children={
													<TelegramIcon color="primary"/>
												}
												sx={{
													textTransform:'none',
													':hover': 
														{
															background:'#f0f0f0',
														},
													borderRadius: '50%'
												}}
												>
											</IconButton>
										</Tooltip>
										<Tooltip title="team@ai-aggregator.ru Copy to buffer" placement="left">
											<IconButton
												variant="text"
												onClick={() => {handleCopyEmail('team@ai-aggregator.ru')}}
												children={
													<AlternateEmailOutlinedIcon color="primary"/>
												}
												sx={{
													textTransform:'none',
													':hover': 
														{
															background:'#f0f0f0',
														},
													borderRadius: '50%'
												}}
												>
											</IconButton>
										</Tooltip>
									</Box>
								</Grid>
							</Grid>
							
							
							
						</Box>
						</Grid>
						{!breakpointDownMD &&
						<Grid container justifyContent="center" className={classes.subheaderBody} sx={{py:0,background:'inherit'}}>
							<Carousel type={'solutions'} ismobile={!!breakpointDownMD} />
						</Grid>
						}
				</Grid>
			</Grid>
			{!!breakpointDownMD &&
				<Grid container justifyContent="center" className={classes.subheaderBody} sx={{p:0,background:'inherit'}}>
					<Carousel type={'solutions'} ismobile={!!breakpointDownMD}/>
				</Grid>
			}
			<Grid container justifyContent="center" className={classes.orgCarouselContainer}>
				<Grid item md="12" xs="12" sx={{p:4}}>
					<Typography variant='h2'>1000+ <br/> компаний</Typography> 
				</Grid>
				<Grid item md="12" xs="12">
					<Carousel type={'organizations'} ismobile={!!breakpointDownMD}/>
				</Grid>
			</Grid>
			
			<Grid container justifyContent="center" className={classes.subheaderBody}>
				{featuresArray.map((item, index) => {
					return (
						<Grid key={index} item xs={12} md sx={{p:3}} display="flex" flexDirection="column">
							<Box display="flex">
								<Typography variant="h5" sx={{pb:3}}>{item.title}</Typography>
							</Box>
							
							<Typography variant="body1">{item.content}</Typography>
							<Box sx={{flexGrow:1, minHeight:20}}/>
							<Box>
								<Button sx={theme.customButton} onClick={() => {handleFeatureClick(item.tag)}}>Подробнее</Button>
							</Box>
						</Grid>
					)
				})
				}
			</Grid>
			</Grid>
			
			<Grid container sx={{minHeight:'100%'}}>
				<Grid item xs={12}>
					<Box id="api" sx={{position:"relative",height:0}}/>
					<Box className={classes.subheaderHeader}>
						<Typography variant={adaptive.h3}>Алгоритмы по подписке</Typography>
						
					</Box>
				</Grid>
				<Grid container className={classes.subheaderBody}>
					{!!apiGuide && apiGuide.map((item,index) => (
						<Grid item key={index} xs={12} md display="flex" flexDirection="column">
							<Grid sx={{p:3}} flexGrow="1" display="flex" flexDirection="column">
								<Typography variant="h5" sx={{pb:3}}>{item.title}</Typography>
								<Typography variant="body1" sx={{pb:3}}>{item.description}</Typography>
								<Box flexGrow="1"/>
								<Box display="flex" sx={{overflow:'hidden',gap: theme.spacing(0.25)}}>
									<Button sx={theme.customButtonOutline} name={item.buttonLink} onClick={handleButton}>{item.buttonToText}</Button>
									<Box flexGrow="1"/>
									<Button sx={theme.customButton} id={item.type} onClick={() => handleGuideOpen(item.type,'api')}>{item.buttonText}</Button>
								</Box>
							</Grid>
							{breakpointDownMD &&
								<Grid xs={12}>
									<Guide classes={classes} part="api" type={item.type} guide={guideState}/>
								</Grid>
							}
						</Grid>
					)) }
				</Grid>
			</Grid>
			{!breakpointDownMD && 
				<Guide classes={classes} part="api" guide={guideState}/>
			}

			<Grid container sx={{minHeight:'100%'}}>
				<Grid item xs={12}>
						<Box id="contest" sx={{position:"relative",height:0}}/>
						<Box className={classes.subheaderHeader}>
							<Typography variant={adaptive.h3}>Конкурсы</Typography>
						</Box>
				</Grid>
				<Grid container className={classes.subheaderBody}>
					{!!contestGuide && contestGuide.map((item,index) => (
						<Grid item key={index} xs={12} md display="flex" flexDirection="column">
							<Box sx={{p:3}} flexGrow="1" display="flex" flexDirection="column">
								<Typography variant="h5" sx={{pb:3}}>{item.title}</Typography>
								<Typography variant="body1" sx={{pb:3}}>{item.description}</Typography>
								<Box flexGrow="1"/>
								<Box display="flex" sx={{overflow:'hidden',gap: theme.spacing(0.25)}}>
									<Button sx={theme.customButtonOutline} name={item.buttonLink} onClick={handleButton}>{item.buttonToText}</Button>
									<Box flexGrow="1"/>
									<Button id={item.type} sx={theme.customButton} onClick={() => handleGuideOpen(item.type,'contest')}>{item.buttonText}</Button>
								</Box>
							</Box>
							{breakpointDownMD &&
								<Grid xs={12}>
									<Guide classes={classes} part="contest" type={item.type} guide={guideState}/>
								</Grid>
							}
						</Grid>
					)) }
				</Grid>
			</Grid>
			{!breakpointDownMD && 
				<Guide classes={classes} part="contest" guide={guideState}/>
			}

{/*
			<Grid container sx={{minHeight:'100%'}}>
				<Grid item xs={12}>
						<Box id="request" sx={{position:"relative",height:0}}/>
						<Box className={classes.subheaderHeader}>
							<Box>
								<Typography variant={adaptive.h3}>Заявки на разработку</Typography>
							</Box>
							
						</Box>
				</Grid>
				<Grid container className={classes.subheaderBody}>
					{!!requestGuide && requestGuide.map((item,index) => (
						<Grid item key={index} item xs={12} md display="flex" flexDirection="column">
							<Box sx={{p:3}} flexGrow="1" display="flex" flexDirection="column">
								<Typography variant="h5" sx={{pb:3}}>{item.title}</Typography>
								<Typography variant="body1" sx={{pb:3}}>{item.description}</Typography>
								<Box flexGrow="1"/>
								<Box display="flex">
									<Button sx={theme.customButtonOutline} name={item.buttonLink} onClick={handleButton}>{item.buttonToText}</Button>
									<Box flexGrow="1"/>
									<Button id={item.type} sx={theme.customButton} onClick={() => handleGuideOpen(item.type,'request')}>{item.buttonText}</Button>
								</Box>
							</Box>
							{breakpointDownMD &&
								<Grid xs={12}>
									<Guide classes={classes} part="request" type={item.type} guide={guideState}/>
								</Grid>
							}
						</Grid>
					)) }
				</Grid>
			</Grid>
			{!breakpointDownMD && 
				<Guide classes={classes} part="request" guide={guideState}/>
			}
*/}

			<Grid container sx={{minHeight:'100%'}}>
				
				<Grid container className={classes.guideBody}>
					<Grid item xs={12} display="flex" alignItems="end" sx={{[theme.breakpoints.up('md')]:{p:6},p:3}}>
							<Typography id="request" variant={adaptive.h3}>Отправить заявку</Typography>
					</Grid>
					<Grid item xs={12} container justifyContent="center" sx={{[theme.breakpoints.up('md')]:{p:3}}}>
						<HomeRequestForm classes={classes} />
					</Grid>
				</Grid>
			</Grid>
			
			{/*
			<Grid container  justifyContent="center" align="center" display="flex" className={classes.footerBody}>
				{mockSitemap.map((item,index) => (
						<Grid item xs={12} md={2} sx={{pt: 3}} key={index}>
							<Typography variant="h6" maxWidth={150}>{item.title}</Typography>
							<Box alignItems="center" flexDirection="column" display="flex" paddingTop="1em">
							{item.links.map((item, index) => (
									<Button 
										disabled={item.disabled}
										key={index}
										onClick={()=>{
											!!item.blank ? 
											window.open(item.path, '_blank') : 
											Navigate(item.path)
										}}
										sx={{textTransform: 'none'}}>
											<Typography variant="body2" sx={{p:0.5}}>{item.title}</Typography>
									</Button>
								)
							)}
							</Box>
						</Grid>
					)
				)}
			</Grid>
			*/}

			<Box
				sx={{
					backgroundColor: '#e3e2e4',
					minHeight: '64px',
					p: 3,
				}}
			>
				<Grid item md={12}>
					<Typography variant="body2"  align="center">AI AGGREGATOR 2021</Typography>
				</Grid>
			</Box>

		</Box>
	)
}

export default Homepage
