import { useState, useEffect } from 'react'
import {
	Box,
	Grid,
	Typography,
	Button,
	TableRow,
	TableCell,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	IconButton,
	Collapse,
	TextField
} from '@mui/material'

import {
	AddCircleOutlineOutlined as AddIcon,
	EditOutlined as EditIcon,
	DeleteForeverOutlined as DeleteForeverIcon,
} from '@mui/icons-material'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import SwitchComponent from '../../components/SwitchComponent'
import { remove } from 'lodash'
import paramTypes from '../../__mocks__/paramTypes'

const ParametersEditor = (props) => {
	const initialState = 
		{
			name: '',
			type: 0,
			example: '',
			required: false,
			description: '',
			_id: '',
			queryString: false,
			hidden: false,
		}
	
	const typeOptions = paramTypes

	const [changesState, setChangesState] = useState(initialState)
	const [editor, setEditor] = useState(false)
	const [readonly, setReadonly] = useState()
	const [isSubmitting, setSubmitting] = useState(false)

	const [parametersList, setParametersList] = useState([])

	const harvestChanges = props.harvester
	const name = props.name

	const saveSettings = async (value) => {
		try {
			setSubmitting(true)
			let parameterswithid
			if (value._id) {
				console.log("Existing parameter to save: ", value)
				const updateindex = parametersList.map(function(item) { return item._id; }).indexOf(value._id)
				let newarr = [...parametersList]
				newarr[updateindex] = {...newarr[updateindex], ...value}
				parameterswithid = newarr
			} else {
			const id = (Math.round(Math.random()*1000000)).toString()
			console.log('Parameter Add: ', id)
			parameterswithid = [...parametersList, {...value, _id: id} ]
			}
			setParametersList(parameterswithid)
			harvestChanges({[name]: parameterswithid})
			setSubmitting(false)
			setEditor(false)
		} catch (e) {}
	}

	const handleSubmit = (e, setSubmitting) => {
		console.log(' Handle submit : ', e)
		saveSettings(e)
	}

	const Sh = (value) => {
		return value.length > 20 ? value.substr(0,20) + "..." : value
	}

	const parameterEdit = (value) => {
		const index = parametersList.map(function(item) { return item._id; }).indexOf(value);
		setChangesState({...changesState,...parametersList[index]})
		setEditor(true)
		setReadonly(false)
	}

	const parameterDelete = (value) => {
		console.log("paramList: ", parametersList)
		console.log("parameter to delete: ", value)
		const removeIndex = parametersList.map(function(item) { return item._id; }).indexOf(value);
		console.log('rmindex: ', removeIndex)
		let newparamlist = [...parametersList]
		newparamlist.splice(removeIndex,1)
		setParametersList(newparamlist)
		harvestChanges({[name]: newparamlist})
	}

	// clear parameters on editor cancel/submit
	useEffect(() => {
		if (!props.editor) {
			setParametersList([])
		}
	},[props.editor])

	useEffect(() => {
		if (props.changesState) {
			setParametersList(props.changesState)
		} 
	}, [props.changesState])


	return (
		<>
			<Grid item>
				
			</Grid>
			<Collapse in={editor} timeout={700}>
				<Formik
					initialValues={changesState}
					enableReinitialize
					validationSchema={Yup.object().shape({
						name: Yup.string().min(1).max(200).required('Enter title'),
						type: Yup.string()
							.required('Select method'),
						example: Yup.string()
							.min(1)
							.max(1000, 'Maximum symbols limit exceeded ${max}')
							.required('This field is required')
					})}
					onSubmit={(values, { setSubmitting, resetForm }) => {
						handleSubmit(values, { setSubmitting })
						setReadonly(true)
						resetForm({})
						setChangesState(initialState)
						//console.log("FORMIK SUBMIT")
					}}
				>
					{({
						errors,
						handleBlur,
						handleChange,
						touched,
						resetForm,
						values,
						setFieldValue
					}) => (
						<>
							<Grid item>
								<Form autoComplete="off">
									<Box sx={{ display: 'flex' }}>
										<Box sx={{ flex: 2 }}>
											<Box sx={{ display: 'flex' }}>
												<Box sx={{ flex: 2, pb: 0.5, pr: 0.5 }}>
													<TextField
														size="small"
														error={Boolean(touched.name && errors.name)}
														fullWidth
														label="Parameter name"
														name="name"
														onBlur={handleBlur}
														onChange={handleChange}
														required
														value={values.name}
														variant="outlined"
														disabled={readonly}
													/>
												</Box>
												<Box sx={{ flex: 1, pb: 0.5, pr: 0.5 }}>
													<TextField
														size="small"
														fullWidth
														error={Boolean(
															touched.type && errors.type
														)}
														label="Type"
														name="type"
														onBlur={handleBlur}
														onChange={handleChange}
														required
														select
														SelectProps={{ native: true }}
														value={values.type}
														variant="outlined"
														disabled={readonly}
													>
														{typeOptions.map((option, index) => (
															<option
																disabled={index === 0}
																key={option.id}
																value={option.id}
															>
																{option.title}
															</option>
														))}
													</TextField>
												</Box>
												{name === 'headers' &&
												<Box sx={{ overflow: 'visible'}}>
													<SwitchComponent
														error={touched.hidden && errors.hidden}
														name="hidden"
														label={{
															on: 'Hidden',
															off: 'Hidden',
															caption: true,
														}}
														onBlur={handleBlur}
														onChange={(e) => {setFieldValue("hidden", e.target.checked)}}
														checked={values.hidden}
														readonly={readonly}
														changeState={setChangesState}
													/>
												</Box>
												}
												{name === 'query' &&
												<Box sx={{ overflow: 'visible' }}>
													<SwitchComponent
														error={touched.queryString && errors.queryString}
														name="queryString"
														label={{
															on: 'queryString',
															off: 'queryString',
															caption: true,
														}}
														onBlur={handleBlur}
														onChange={(e) => {setFieldValue("queryString", e.target.checked)}}
														checked={values.queryString}
														readonly={readonly}
														changeState={setChangesState}
													/>
												</Box>
												}
												<Box sx={{ overflow: 'visible' }}>
													<SwitchComponent
														error={touched.required && errors.required}
														name="required"
														label={{
															on: 'Required',
															off: 'Required',
															caption: true,
														}}
														onBlur={handleBlur}
														onChange={(e) => {setFieldValue("required", e.target.checked)}}
														checked={values.required}
														readonly={readonly}
														changeState={setChangesState}
													/>
												</Box>
												
											</Box>
											<Box>
												<Box sx={{ flex: 2, pb: 0.5, pr: 0.5 }}>
													<TextField
														
														size="small"
														error={Boolean(touched.example && errors.example)}
														fullWidth
														label="Example"
														name="example"
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.example}
														variant="outlined"
														disabled={readonly}
													/>
												</Box>
											</Box>
										</Box>
										<Box sx={{ flex: 0.1 }}>
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'center',
													flexDirection: 'column',
													height: '100%',
												}}
											>
												<Button 
													variant="outlined" 
													sx={{ m: 0.5, mr: 0, mt: 0 }}
													type="submit"
												>
													Save
												</Button>
												<Button 
													variant="outlined" 
													sx={{ m: 0.5, mr: 0, mb: 0 }}
													onClick={() => {
														resetForm()
														setChangesState(initialState)
														setEditor(false)
														setReadonly(true)
														
													}}	
												>
													Cancel
												</Button>
											</Box>
										</Box>
									</Box>
								</Form>
							</Grid>
						</>
					)}
				</Formik>
			</Collapse>

				

			<TableContainer>
				<Table aria-label="Endpoints list" size="small">
					{!!parametersList.length &&
					<TableHead sx={{ backgroundColor: '#fafafa' }}>
						<TableRow>
							
							<TableCell component="th" scope="row">
								<Typography variant="caption">Name</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography variant="caption">Type</Typography>
							</TableCell>
							<TableCell align="right">
								<Typography variant="caption">Example</Typography>
							</TableCell>
							{name === 'query' &&
							<TableCell align="right">
								<Typography variant="caption">queryString</Typography>
							</TableCell>
							}
							<TableCell align="right">
								<Typography variant="caption">Required</Typography>
							</TableCell>
							{name === 'headers' &&
							<TableCell align="right">
								<Typography variant="caption">Hidden</Typography>
							</TableCell>
							}
							<TableCell size="small" align="center">
								<Typography variant="caption">Actions</Typography>
							</TableCell>
							
						</TableRow>
					</TableHead>
					}
					<TableBody>
						{parametersList.map((parameter, index) => (
							<TableRow key={index}>
								<TableCell align="left">{parameter.name}</TableCell>
								<TableCell align="right">{typeOptions[parameter.type].title}</TableCell>
								<TableCell align="right"><Typography variant="caption" sx={{wordBreak:'break-all'}}>{Sh(parameter.example)}</Typography></TableCell>
								{name === 'query' &&
								<TableCell align="right">{parameter.queryString && parameter.queryString.toString().toUpperCase()}</TableCell>
								}
								<TableCell align="right">{parameter.required.toString().toUpperCase()}</TableCell>
								{name === 'headers' &&
								<TableCell align="right">{parameter.hidden.toString().toUpperCase()}</TableCell>
								}
								<TableCell width="1" nowrap="true" align="center" sx={{backgroundColor:'#f5f5f5',m:0,p:0}}>
									<Box sx={{flexWrap:'nowrap', display:'flex'}}>
										<IconButton onClick={() => {
											parameterEdit(parameter._id)
										}}
										>
											<EditIcon />
										</IconButton>
										<IconButton onClick={() => {
											parameterDelete(parameter._id)
										}}>
											<DeleteForeverIcon />
										</IconButton>
									</Box>
								</TableCell>
								
							</TableRow>
						))}
						
					</TableBody>
				</Table>
				<Collapse in={!editor}>
				{//!editor ? (
				<Table size="small" >
				<TableRow >
							<TableCell sx={{p:0,m:0}}>
									<Button
										fullWidth
										variant="outlined"
										onClick={() => {
											setEditor(true)
											setReadonly(false)
										}}
									>
										Add parameter
									</Button>
								
							</TableCell>
				</TableRow>
				</Table>
				//) : (
				//	''
				//)
				}
				</Collapse>
			</TableContainer>

		</>
	)
}

export default ParametersEditor
