import { useState, useCallback, useEffect, useContext } from 'react'
import { NavContext } from '../context/NavContext'

const storageName = 'userData'

export const useAuth = () => {
    const { refresh } = useContext(NavContext)
    const [token, setToken] = useState(null)
    const [ready, setReady] = useState(false)
    const [userId, setUserId] = useState(null)
	const [owner, setOwner] = useState(null)
    
    const [isAuthenticated, setIsAuthenticated ] = useState(false)
	
    const login = useCallback((jwtToken, id, owner)=>{
        setToken(jwtToken)
        setUserId(id)
		setOwner(owner)
        setIsAuthenticated(true)

        localStorage.setItem(storageName, JSON.stringify({
            userId: id, token: jwtToken, owner: owner
        }))
    }, [])

    const logout = useCallback(()=>{
        setIsAuthenticated(false)
        setToken(null)
        setUserId(null)
		setOwner(null)
        localStorage.removeItem(storageName)
    }, [])

	const checkauth = useCallback(async (token)=>{
		try {
		const response = await fetch('/api/auth/check', {method:'GET', body:null, headers:{ Authorization: `Bearer ${token}`,userId:userId }})
		const data = await response.json()
		if (response.status === 401) {
            await logout()
            await refresh()
			return 
		}
		} catch(e) {
			console.log("ERROR: ", e)
        }
	}, [])

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem(storageName))
		
        if (data && data.token) {
            login(data.token, data.userId, data.owner)
        }
        setReady(true)
    }, [login])



	

    return { login, logout, token, userId, ready, owner, checkauth, isAuthenticated }

}