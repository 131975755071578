import { useState, useEffect } from 'react'
import {
	Box,
	SvgIcon,
	InputBase,
	IconButton,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	Typography,
	Grid,
	Collapse
} from '@mui/material'
import { Search as SearchIcon } from 'react-feather'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const CountInfo = ({productsCount}) => (
	<Box sx={{px:2,pl:0,display:'flex',alignItems:'center',gap:1}}>
		<Typography variant="body1" noWrap color="#aaa" >
			Найдено: {'  '}
		</Typography>
		<Typography variant="h5" color="#555">
			{'  ' + productsCount}
		</Typography>
	</Box>
)

const SortSelect = ({sortparam,onChangeSort,sortlist}) => (
	<FormControl sx={{ maxWidth: 200 }}>
		<Select
			labelId="sort-label"
			fullWidth
			size="small"
			variant="outlined"
			value={sortparam}
			onChange={onChangeSort}
			id="demo-simple-select-filled"
			sx={{
				fontSize: '10pt',
				'.MuiOutlinedInput-notchedOutline': {
					borderColor: 'borderColorIntensive',
				},
			}}
			MenuProps={{
				PaperProps: {
				sx: {
					'& .MuiMenuItem-root': {
						fontSize: '10pt'
					},
				},
				},
			}}
		>
			{sortlist.map((option, index) => (
				<MenuItem key={index} value={option.value}>
					{option.title}
				</MenuItem>
		))}
		</Select>
	</FormControl>
)

const ProductListToolbar = (props) => {
	const theme = useTheme()
	const breakpointDownMD = useMediaQuery(theme.breakpoints.down('md'))

	const searchTitle = props.searchtitle
	const productsCount = props.productsCount
	const sortparam = props.sortparam
	const onChangeSearchTitle = props.changesearchtitle
	const onChangeSort = props.changesort
	const retrieve = props.retrieve
	const sortlist = [
		{
			title: 'по релевантности',
			value: 'byrel'
		},
		{
			title: 'по популярности',
			value: 'bypop'
		},
		{
			title: 'по дате',
			value: 'bydate'
		},
	]

	const [ off, setOff ] = useState(0)
	
	const handleScroll = (event) => {
		setOff(event.target.scrollTop)
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!!retrieve) {
			retrieve(searchTitle)
		}
	}

	useEffect(() => {
			window.addEventListener('scroll', handleScroll,{capture: true});
		return function cleanup() {
			window.removeEventListener('scroll', handleScroll,{capture: true});
		}
	}, [])

	return (
		<Collapse in={!off}>
		<Box sx={{display:'flex',width:'100%',flexDirection:'column'}}>
			<Grid container sx={{
				width:'100%',
				gap:'10pt', 
				borderRadius: 25, 
				...(!breakpointDownMD && {borderTopRightRadius:1,borderBottomRightRadius:1}), 
				...(!!breakpointDownMD && {gap:0,pr:0}),
				border: '1px solid', 
				borderColor: 'borderColorIntensive', 
				display: 'flex', 
				p:0.5, 
				pl: 3, 
				justifyContent: 'left', 
				alignItems: 'center'
				}}>
				<Grid item xs sx={{width:'100%'}}>
					<Box sx={{display:'flex'}} component="form"  onSubmit={handleSubmit} >
						
						<InputBase
							fullWidth
							placeholder="Поиск по названию"
							value={searchTitle}
							onChange={onChangeSearchTitle}
						/>
						<Box flexGrow="1"/>
						{!!productsCount && !breakpointDownMD && <CountInfo {...{productsCount}} />}
						<IconButton
							sx={{border:'1px solid', borderColor:'borderColorIntensive'}}
							type="submit"
							onClick={handleSubmit}
						>
							<SvgIcon fontSize="small">
								<SearchIcon color="#333" />
							</SvgIcon>
						</IconButton>
						
					</Box>
				</Grid>
				<Grid item sx={{display:'flex'}}>
					{!breakpointDownMD && <SortSelect {...{sortparam,onChangeSort,sortlist}} />}
				</Grid>
			</Grid>
			{!!breakpointDownMD &&
			
				<Box sx={{display:'flex',pl:3,py:1,justifyContent:'space-between'}}>
				{!!productsCount && <CountInfo {...{productsCount}} />}
				<SortSelect {...{sortparam,onChangeSort,sortlist}} />
				</Box>
			
			}
		</Box>
		</Collapse>
		
	)
}

export default ProductListToolbar
