export default function(props) {
	return (
	`fetch('${props.url}', 
	{
		"method": ${props.method},
		"headers": ${props.headers},
		"body": ${props.body}
	})
	.then(response => {
		console.log(response);
	})
	.catch(err => {
		console.error(err);
	});`
	)
}