import { useEffect, useState, useContext } from 'react'
import {
	Box,
	Grid,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	TextField,
	InputAdornment,
	SvgIcon,
	Typography,
} from '@mui/material'

import { Search as SearchIcon } from 'react-feather'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined'
import SubjectOutlinedIcon from '@mui/icons-material/SubjectOutlined'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'

import NavItem from './NavItem'
import { NavContext } from '../context/NavContext'

import AccountSelector from './AccountSelector'

const requestLinks = [
	{
		title: 'Summary',
		path: 'summary',
		disabled: false,
		icon: SubjectOutlinedIcon
	},
	{
		title: 'Responses',
		path: 'responses',
		disabled: false,
		icon: ChatOutlinedIcon
	},
	{
		title: 'Definition',
		path: 'overview',
		disabled: false,
		icon: EditOutlinedIcon,
		links: ['overview','settings','statistics'],
	},
	{
		title: 'Statistics',
		path: 'statistics',
		disabled: true,
		icon: ShowChartOutlinedIcon
	},
]

const SidebarRequest = (props) => {
	const selectData = props.selectData
	const { title } = props.description
	const [ productList, setProductList ] = useState([])
	const [ ListTitle, setListTitle ] = useState('Personal Apps')

	const [ selectedIndex, setSelectedIndex ] = useState('')
	const [ selectedRequestState, setSelectedRequestState ] = useState('')
	const { setSelectedRequest, selectedOrg } = useContext(NavContext)

	const handleClick = (item,index) => {
		if (selectedIndex === index) {
			return setSelectedIndex('')
		} else {
			return setSelectedIndex(index)
		}
	}

	useEffect(() => {
		setProductList([])
		console.log('SIDEBAR . SELECTDATA: ', selectData)
		console.log('selectedOrg: ', selectedOrg)
		if (!!selectedOrg.title) {
			setListTitle(selectedOrg.title + ' ' + title)
		} else {
			setListTitle('Personal ' + title)
		}
		if (selectData) {
			if (selectData.filterdata) {
				const preparedData = selectData.filterdata
					.map((item, index) => {
						if (selectedOrg._id) {
							if (item.orgowner === selectedOrg._id) {
								return item
							}
						} else {
							if (!item.orgowner) {
								return item
							}
						}
					})
					.filter((i) => !!i)
				if (preparedData) {
					console.log('Prepared data: ', preparedData)
					setProductList(preparedData)
				}
			}
		}
	}, [selectData, selectedOrg])

	useEffect(() => {
		//console.log(" SideBar: Selected Api : ", selectedApi)
		setSelectedRequest(selectedRequestState)
	}, [selectedRequestState])


	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<AccountSelector />
					<NavItem title="Add New Request" href='requestedit/addnew' basepath='dash'/>
					<NavItem disabled={true} title="Support" href="requestedit/support" basepath='dash'/>
					<NavItem title="Summary" href="requestedit" basepath='dash'/>
				</Grid>
			</Grid>

			<Box sx={{ flexGrow: 0.5 }} />

			<Grid container>
				<Grid item xs={12} sx={{p: 1}}>
						<TextField
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SvgIcon fontSize="small" color="action">
											<SearchIcon />
										</SvgIcon>
									</InputAdornment>
								),
							}}
							placeholder="Search"
							variant="outlined"
							value=""
							size="small"
						/>
						<Typography sx={{ p: 1,pt:2}} variant="h6">
							{ListTitle}
						</Typography>
				</Grid>
			</Grid>

			{/* REQUESTS LIST */}

			<Grid container overflow='auto'>
				<Grid item xs={12}>
					{!!productList && productList.map((item, index) => {
						return (
							<div key={index}>
								
								<ListItem
										key={index}
										button
										onClick={() => {
											handleClick('requestlist', index)
										}}
									>
										<ListItemIcon>
											{index === selectedIndex ? (
												<ExpandLess />
											) : (
												<ExpandMore />
											)}
										</ListItemIcon>
										<Box flexGrow={1}/>
										<ListItemText sx={{ textAlign: 'end', pr:1}} primary={item.title} />
									</ListItem>
									<Divider />
									<Collapse
										in={index === selectedIndex}
										timeout="auto"
										unmountOnExit
										sx={{position:'relative', top:'-1px', backgroundColor: '#fff'}}
									>
										<Grid item xs={12}>
											{requestLinks.map((i, index) => (
												<div key={index}>
													<NavItem
														disabled={!!i.disabled}
														onClick={() => {
															setSelectedRequestState(item)
														}}
														basepath={'dash'}
														end={false}
														key={i.index}
														title={i.title}
														icon={i.icon}
														links={i.links}
														endpath={i.path}
														href={
															'requestedit/' +
															item.ownerId +
															'/' +
															item.code +
															'/' +
															i.path
														}
													/>
												</div>
											))}
										</Grid>
										<Divider />
									</Collapse>
								
							</div>
						)
					}
					)}
				</Grid>
			</Grid>

		</>
	)
}

export default SidebarRequest
