import { useEffect, useState, useContext } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import Preloader from '../../components/Preloader'
import {
	Box,
	Divider,
	Grid,
	Typography,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Button,
	TextField,
	IconButton
} from '@mui/material'
import Pagination from '@mui/material/Pagination';
import {
	EditOutlined as EditIcon,
	DeleteForeverOutlined as DeleteForeverIcon
} from '@mui/icons-material'
import ScrollTop from '../../components/ScrollTop'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'
import ConfirmDialog from '../../components/ConfirmDialog'

const OrgList = (props) => {
	const Navigate = useNavigate()
	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const { refresh } = useContext(NavContext)
	const [ confirmState, setConfirmState ] = useState({open:false})

	
	const { userdata, selectedOrg, setSelectedOrg } = useContext(NavContext)

	let orgdata = userdata.org

	const [ listState, setListState ] = useState({page:1,pageSize:30,count:0})
	const [ content, setContent ] = useState([])
	// console.log("OrgList orgdata: ", orgdata)

	const deleteData = async (org) => {
		try {
			console.log(' Settings. Delete ORG : ', org)
			if (!org.title) {
				return
			}
			const data = await request(
				'/api/org/remove',
				'DELETE',
				{ ...org },
				{ Authorization: `Bearer ${token}` }
			)
			setSelectedOrg({index:0})
			refresh()
			
			console.log('DATA: ', data)
		} catch(e) {
			console.log('Delete... Error:', e)
		}
		console.log(org)
	}

	const deleteHandler = (item) => {
		setConfirmState({open:true,setOpen:setConfirmState,callback:deleteData,item,validatename:'title'})
		// setDeleteDialogOpen(true)
	}

	const handleEdit = (item) => {
		console.log('EDIT: ', item)
		setSelectedOrg(item)
		Navigate(`/dash/org/${item.ownerId}/${item.code}`)
	}

	const handlePageChange = (e,value) => {
		console.log('PAGE: ', value)
		setListState({...listState, page: value})
	}

	useEffect(() => {
		if (!!orgdata && !!orgdata.length) {
			setListState({...listState,count: Math.floor(orgdata.length/listState.pageSize)})
		}
	}, [orgdata])

	useEffect(() => {
		if (!!orgdata && !!orgdata.length) {
			setContent(orgdata.slice((listState.page - 1)*listState.pageSize,listState.page*listState.pageSize))
		}
	}, [listState])

	if (!orgdata) {return <><Preloader /></>}

	return (
		<>
			<ScrollTop />
			<ConfirmDialog {...confirmState}/>
			<TableContainer>
				<Table aria-label="Organizations list">
					<TableHead sx={{ backgroundColor: '#fafafa' }}>
						<TableRow>
							<TableCell sx={{ width: 0, p: 0, m: 0, pr: 3 }} />
							<TableCell align="left">Avatar</TableCell>
							<TableCell align="left">Org</TableCell>
							<TableCell align="right">Followers</TableCell>
							<TableCell align="right">Views</TableCell>
							<TableCell align="right">Category</TableCell>
							<TableCell align="right">Paytype</TableCell>
							<TableCell align="right">Website</TableCell>
							<TableCell align="right">Apis</TableCell>
							<TableCell align="right">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{orgdata && !!orgdata.length && content.map((item, index) => (
							<TableRow key={index}>
								<TableCell sx={{ width: 0, p: 0, m: 0 }} />
								<TableCell align="left">{JSON.stringify(!!item.avatar)}</TableCell>
								<TableCell align="left">{item.title}</TableCell>
								<TableCell align="right" sx={{ color: '#aaa' }}>
									{JSON.stringify(item.followers)}
								</TableCell>
								<TableCell align="right" sx={{ color: '#aaa' }}>
									{JSON.stringify(item.clicks)}
								</TableCell>
								<TableCell align="right" sx={{ color: '#aaa' }}>
									{item.category && JSON.stringify(item.clicks)}
								</TableCell>
								<TableCell align="right" sx={{ color: '#aaa' }}>
									{JSON.stringify(item.paytype)}
								</TableCell>
								<TableCell align="right" sx={{ color: '#aaa' }}>
									{JSON.stringify(!!item.website)}
								</TableCell>
								<TableCell align="right" sx={{ color: '#aaa' }}>
									{JSON.stringify(!!item.api && item.api.length)}
								</TableCell>
								<TableCell size="small" align="right" width="1" sx={{backgroundColor:'#f9f9f9'}}>
										<Box sx={{flexWrap:'nowrap', display:'flex'}}>
										<IconButton 
											// component={Link}
											// to={{pathname:`/dash/org/${item.ownerId}/${item.code}`}}
											onClick={() => {handleEdit(item)}}
											>
											<EditIcon/>
										</IconButton>
										<IconButton onClick={()=>{deleteHandler(item)}}>
											<DeleteForeverIcon/>
										</IconButton>
										</Box>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Box style={{content:'""',display:'block',height:"60pt",width:'100%'}}/>
			<Box sx={ (listState.count <= 1) ? 
				{display: 'none'} 
				: 
				{display: 'flex',position:'absolute',justifyItems: 'center',zIndex:2,bottom: 20}}>
					<Box display="flex" width="100%" alignItems="center" sx={{
						border:`1px solid #fafafa`,
						borderRadius:10,
						boxShadow:"5px 5px 10px rgba(0, 0, 0, 0.05)",
						background:'#fff',
						p:1
						}}>
						{!!listState.page &&
							<Pagination
								shape="circular"
								siblingCount={0}
								boundaryCount={1}
								size="large"
								count={listState.count * 1}
								page={listState.page * 1}
								onChange={handlePageChange}
							/>
						}
					</Box>
			</Box>
		</>
	)
}

export default OrgList