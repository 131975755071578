import { useEffect, useState, useContext, useCallback } from 'react'
import {
	Box,
	Divider,
	Grid,
	TextField,
	Typography,
} from '@mui/material'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import SchemaTools from '../../helpers/schematools'
import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'
import ControlsComponent from '../../components/ControlsComponent'
import PreviewButton from '../../components/PreviewButton'


let category = []
const { compareObjects } = SchemaTools()

const Overview = (props) => {
	//console.log(" Overview: Props: ", props)

	const [values, setValues] = useState({
		title: 'Api Title',
		lastName: 'Smith',
		website: 'www.testapi.io',
		phone: '',
		state: 'Deep learn',
		country: 'USA',
		longdescription: '',
	})

	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const { selectedApi, refresh, refreshCheck, setSelectedApi, selectData } = useContext(NavContext)
	const [ readonly, setReadonly ] = useState(true)
	const [ isSubmitting, setSubmitting ] = useState(false)

	

	
	const [initialState, setInitialDataState] = useState({
		title: '.',
		shortdescription: '.',
		website: '.',
		longdescription: '.',
		category: '.',
		terms: '.',
	})

	const [changesState, setChangesState] = useState(initialState)

	const [currentApi, setCurrentApi] = useState({
		title: '',
		ownerId: '',
		code: '',
		private: false,
		published: false,
		longdescription: '',
		type: 'solutions'
	})

	const fetchApiDetails = useCallback( async (value) => {
		
		console.log("Overview. Fetch details : ", value)
		try {
			const fetched = await request(
				`/api/product/owner/${value.ownerId}/${value.code}`,
				'GET',
				null, 
				{
					Authorization: `Bearer ${token}`,
					part: 'overview'
				}
			)
			// ON FETCH FAIL RETURN INITIAL DATA SUMMED WITH SELECTED API DATA
			if (!fetched.ApiData) {
				const notfetched = compareObjects(initialState, selectedApi, {keepold: true})
				console.log("NF: ", notfetched , " . " , selectedApi, ' . init: ', initialState)
				setChangesState(notfetched)
				return
			}

			const newapidetaildata = compareObjects(initialState,{...fetched.ApiDetailData})
			const newapidata = compareObjects(initialState,{...fetched.ApiData})
			const newdata = compareObjects(initialState,{...newapidata,...newapidetaildata})
			
			setChangesState(newdata)
			setInitialDataState(newdata)
			setCurrentApi({...currentApi,...fetched.ApiData})

			// console.log(" Overview . Fetch . New data : ", newapidetaildata, ' . ', fetched.ApiData)
			// console.log( compareObjects(initialState,{...newapidata,...newapidetaildata}))
			// console.log(" Overview . newdata : ", newdata)
		} catch (e) {
			console.log("Overview. Fetch details error: ", e)
		}
	}, [selectedApi])

	const resetHandler = () => {
		var newdata = compareObjects(changesState,initialState)
		setChangesState({ ...newdata })
	}

	
	const saveSettings = async (value) => {
		try {
			setSubmitting(true)
			console.log('Overview . Save Settings: ', value)
			console.log('Current API: ', currentApi)
			var newdata = compareObjects(changesState,value,{minified: true})
						
			console.log('Settings to save: ', newdata)
			const data = await request(
				'/api/product/update/details',
				'POST',
				{ _id: currentApi._id, ...newdata },
				{ Authorization: `Bearer ${token}` }
			)

			console.log('Result Data : ', data)
			console.log("Readonly : ", readonly)

			if (data.apiData) {
				setSelectedApi({...currentApi,...data.apiData})
			}
			console.log("Current api : ", currentApi)

			refresh()
			setSubmitting(false)
		} catch (e) {}
	}

	const handleChange = (event) => {
		setValues({
			...values,
			[event.target.name]: event.target.value,
		})
	}

	const handleSubmit = (e, setSubmitting) => {
		console.log(' Handle submit : ', e)
		saveSettings(e)
	}

	//SELECTEDAPI data attach to this state
	const attachFetched = useCallback((values) => {
			const newdata = compareObjects(changesState,values)
			console.log('attach fetched: ', newdata)
			setChangesState({ ...newdata })
		},
		[selectedApi]
	)

	const setCategory = (data) => {
		console.log("CATEGORY : ", data)
		category = [{_id: '', title: ''}, ...data]
	}

	const [ lengthData, setLength ] = useState({
		sd: 0,
		ld: 0
	})
		
	useEffect(() => {
		if (selectData.categories) {
		setCategory(selectData.categories)}
	}, [selectData])

	useEffect(() => {
		console.log('Overview. Selected Api : ', selectedApi)
		if (!selectedApi.date) {return}
		
		fetchApiDetails(selectedApi)
	}, [selectedApi.date, attachFetched, setCurrentApi, refreshCheck])

	return (
		<Formik
			initialValues={changesState}
			enableReinitialize
			validationSchema={Yup.object().shape({
				title: Yup.string().min(3).max(255).required('Enter title'),
				category: Yup.string().required('Select category'),
				shortdescription: Yup.string()
				.min(3)
				.max(200,"Maximum symbols limit exceeded ${max}")
				.required("This field is required"),

				longdescription: Yup.string()
				.min(3)
				.max(1000,"Maximum symbols limit exceeded ${max}")
			})}

			onSubmit={(values, { setSubmitting }) => {
				handleSubmit(values, { setSubmitting })
				setReadonly(true)
				//console.log("FORMIK SUBMIT")
			}}
			
		>
			{({
				errors,
				handleBlur,
				handleChange,
				
				touched,
				resetForm,
				values,
			}) => (
				<Form autoComplete="off">
						<Box sx={{display:'flex', p:3}}>
							<Typography variant='h5'>Описание {currentApi.title}</Typography>
							<Box flexGrow="1"/>
							<PreviewButton product={{...currentApi}} />
						</Box>
						<Divider />
						
							<Grid container spacing={1} sx={{p:2}}>
								<Grid item md={6} xs={12}>
									<Box sx={{ display: 'flex' }}>
										<Box sx={{ flex: 2, p: 1 }}>
											<TextField
												error={Boolean(touched.title && errors.title)}
												fullWidth
												helperText={touched.title && errors.title }
												label="API title"
												name="title"
												onBlur={handleBlur}
												onChange={handleChange}
												required
												value={values.title}
												variant="outlined"
												disabled={readonly}
											/>
										</Box>
										<Box sx={{ flex: 1, p: 1 }}>
											<TextField
												fullWidth
												label="Category"
												name="category"
												onBlur={handleBlur}
												onChange={handleChange}
												required
												select
												SelectProps={{ native: true }}
												value={values.category}
												variant="outlined"
												disabled={readonly}
											>
												{category.map((option, index) => 
												(
													<option disabled={index===0} key={option._id} value={option._id}>
														{option.title}
													</option>
												))}
											</TextField>
										</Box>
									</Box>
									<Box sx={{ p: 1 }}>
										
										<TextField
											fullWidth
											error={Boolean(
												touched.shortdescription && errors.shortdescription
											)}
											helperText={String(touched.shortdescription && errors.shortdescription ? errors.shortdescription + '. ' : '' ) + ("Total Symbols: " + values.shortdescription.toString().length + " of " + 200)}
											label={"Short description"}
											name="shortdescription"
											onBlur={handleBlur}
											onChange={handleChange}
											required
											value={values.shortdescription}
											variant="outlined"
											disabled={readonly}
										/>
									</Box>
									<Box sx={{p:1}}>
										<TextField
											multiline
											fullWidth
											rows={8}
											error={Boolean(touched.longdescription && errors.longdescription)}
											helperText={String(touched.longdescription && errors.longdescription ? errors.longdescription + '. ' : '' ) + ("Total Symbols: " + values.longdescription.toString().length + " of " + 1000)}
											label={"Long description"}
											name="longdescription"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.longdescription}
											variant="outlined"
											disabled={readonly}
											
											
										/>
									</Box>
								</Grid>

								<Grid item md={6} xs={12}>
								<Box sx={{p:1}}>
									<TextField
										fullWidth
										label="Website"
										name="website"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.website}
										variant="outlined"
										disabled={readonly}
									/>
								</Box>
								<Box sx={{p:1}}>
									<TextField
										fullWidth
										label="Terms & conditions"
										name="terms"
										multiline
										rows={4}
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.terms}
										variant="outlined"
										disabled={readonly}
									/>
								</Box>
								</Grid>

							</Grid>
							
						<Divider />
						<Box sx={{p:3}}>
						<ControlsComponent
							readonly={{ setReadonly, readonly }}
							disabled={isSubmitting}
							resetHandler={resetHandler}
							resetform={resetForm}
						/>
						</Box>
					
				</Form>
			)}
			
		</Formik>
		
	)
}

export default Overview
