import { useState, useContext, useEffect, useRef } from 'react'
import {
	Grid,
	Typography,
	Box,
	Divider,
	Avatar,
	Button,
	Collapse,
	TextField
} from '@mui/material'

import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery';

import { useLocation, useParams, Outlet, Link, useNavigate } from 'react-router-dom'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import { NavContext } from '../../context/NavContext'
import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook'
import timeAgo from '../../helpers/timeAgo'

import ProductPlans from './ProductPlans'
import ProductOverview from './ProductOverview'
import ProductEndpoints from './ProductEndpoints'
import RequestResponseCreate from './RequestResponseCreate'
import ClearIcon from '@mui/icons-material/Clear';
import shortText from '../../helpers/shortText'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import AvatarComponent from '../../components/AvatarComponent'

import Preloader from '../../components/Preloader'

import ComponentOutlet from '../../components/template/ComponentOutlet';

import PageTitle from '../../components/PageTitle';

const initialState = {
	title: '',
	owner: '',
	update: '',
	views: '',
	description: '',
	longdescription: '',
}

const useStyles = makeStyles((theme) => ({
	subheaderBody: {
		backgroundColor: '#fafafb',
		[theme.breakpoints.down('md')]: {
			minHeight: '100%'
		},
		[theme.breakpoints.up('md')]: {
			minHeight: '50%'
		},
		padding: theme.spacing(6),
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(7)
	}
}))



const ProductView = (props) => {
	
	const Navigate = useNavigate()
	const { token } = useContext(AuthContext)

	const theme = useTheme()
	const classes = useStyles()
	const breakpointDownMD = useMediaQuery(theme.breakpoints.down('md'))

	const productType = props.producttype
	const path = props.path
	console.log('Product view : ', path)

	const basepath = 'marketplace/organizations'

	const parts = [
		// {
		// 	path: 'overview',
		// 	element: <ProductOverview data={props.data}/>,
		// },
		{
			path: 'endpoints',
			element: ProductEndpoints,
		},
		{
			path: 'plans',
			element: ProductPlans,
		},
		{
			path: 'docs',
			element: ProductPlans,
		},
		{
			path: 'specs',
			element: ProductPlans,
		},
	]

	const [ error, setError ] = useState()
	const { request } = useHttp()
	const setnav = useContext(NavContext)
	const [ productData, setProductData ] = useState(initialState)
	const [ overviewOpen, setOverviewOpen ] = useState(false)
	const [ isLoading, setIsLoading ] = useState(true)

	let { user, product } = useParams()
	console.log('PARAMS: ', useLocation())
	//console.log("Product view : ", location, " Params: ", user, product)
	const fetchProduct = async () => {
		try {
			setIsLoading(true)
			const authorization = !!token ? `Bearer ${token}` : ''
			const fetched = await request(
				`/api/market/type/${productType}/id?ownerId=${user}&product=${product}`,
				'GET',
				null,
				{ Authorization: authorization, page: 'productview'}
			)
			if (fetched.title) {
				console.log('FETCHED PRODUCTVIEW . SUCCESS : ', fetched)
				setProductData({...fetched, code: user + product})
				setnav.setData({ user, product, productType })
				setIsLoading(false)
			}
		} catch (e) {
			console.log('FETCHED PRODUCTVIEW . ERROR : ', e)
			setError(e)
			
		}
	}

	const handleOpen = () => {
		setOverviewOpen(!overviewOpen)
	}

	useEffect(() => {
		fetchProduct()
	}, [user, product])

	useEffect(() => {
		setnav.setnav('product')
	}, [setnav])

		
	if (error) {
		return (
			<Box sx={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
				<Typography variant="h4">Ничего не найдено...</Typography>
			</Box>
		)
	}

	if (!productData.title) {return (<Preloader />)}

	return (
		<Box sx={{height:'100vh',display:'flex',flexDirection:'column'}}>
				<Box>
					<Grid container spacing={3} sx={{p:3}} wrap={!breakpointDownMD ? 'nowrap' : ''}>
						<Grid item display="flex" alignItems="center">
							<AvatarComponent text={productData.title} avatar={
								productData.avatar || 
								!!productData.orgowner && productData.orgowner.avatar
								} code={productData.code}/>
						</Grid>
						<Grid item xs={8} md={12} container spacing={1.5} direction="column">
							<Grid item>
								<Typography variant="h5">{shortText(productData.title,50)}</Typography>
								<Typography variant="body2">
									{!!productData.orgowner ? 
											<Typography 
												component={Link} 
												to={{pathname:`/${basepath}/${productData.owner.ownerId}/${productData.orgowner.code}`}}
												target="_blank"
												sx={theme.textlinkstyle}
											>
												{productData.orgowner.title}
											</Typography>
										: 
										productData.owner.firstName &&
											<Typography
												component={Link} 
												to={{pathname:'/dash/user/' + productData.owner.ownerId}}
												target="_blank"
												sx={theme.textlinkstyle}
											>
												{productData.owner.firstName + ' ' + productData.owner.lastName}
											</Typography>
									}
									
									. {productData.category}. Updated:{' '}
									{timeAgo(productData.date)}
								</Typography>
							</Grid>
						</Grid>
						<Grid item xs={12} display="flex" flexGrow="1">
							<Grid item container spacing={3} justifyContent="right" wrap="nowrap">
								<Grid item>
									<Button
										variant="outlined"
										size="medium"
										style={!!(path == 'plans') ? {'display':'none'} : {}}
										sx={{}}
										onClick={()=>{
											console.log("PATH: ", path)
											!(path == 'plans') &&
											Navigate('../plans')
										}}
										>
										SUBSCRIBE
									</Button>
								</Grid>
								<Grid item >
									<Typography>{productData.clicks}</Typography>
									<Typography variant="caption">Views</Typography>
								</Grid>
								{productData.followers && 
									<Grid item>
										<Typography>123</Typography>
										<Typography variant="caption">Followers</Typography>
									</Grid>
								}
								<Grid item>
									<Typography>{productData.latency}</Typography>
									<Typography variant="caption">Latency</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				<Box>
					<Box sx={{p:3,pt:0,pb:0}}>
						<Typography variant="body2">
						{productData.shortdescription}
						</Typography>
					</Box>
					<Collapse in={overviewOpen}>
						<Box sx={{p:3,pt:3}}>
							<Typography variant="body1">
								{productData.longdescription}
							</Typography>
						</Box>
					</Collapse>
					<Box 
						fullWidth 
						component={Button} 
						onClick={handleOpen} 
						sx={{
							'&:hover': {
								borderBottom:'1px solid #0055FF',
							},	
							cursor:'pointer',
							width:'100%',
							height:'2em',
							pl:3,
							borderBottom:'1px solid #eee',
							borderRadius: 0,
							display:'flex',
							alignItems:'center',
							
						}}>
							<Box>
								<Typography sx={{fontSize:'6pt'}}>
									●●●
								</Typography>
							</Box>
							<Box flexGrow="1"/>
								{!!overviewOpen? <ExpandLess sx={{fontSize:'16pt'}}/> : <ExpandMore sx={{fontSize:'16pt'}}/>}
					</Box>
					<Divider />
				</Box>
				<ComponentOutlet path={path} parts={parts} data={productData}/>
				<PageTitle title={productData.title}/>
		</Box>
	)
}

export default ProductView
