import { useCallback, useEffect, useState, useMemo } from 'react'
import {
	Stack,
	Stepper,
	Step,
	StepLabel,
	Typography,
	Box,
	Collapse
} from '@mui/material'
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import RuleIcon from '@mui/icons-material/Rule';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MobileStepper, { mobileStepperClasses } from '@mui/material/MobileStepper';
import timeAgo from '../../../helpers/timeAgo';

const isActive = (stage) => {
	var today = Date.now()
	return (new Date(stage.startdate) < today) &&  (today < new Date(stage.enddate))
}


const TimelineView = (props) => {
	const {
		stageListState,
	} = props

	const theme = useTheme()

	const [ open, setOpen ] = useState(false)
	const [ activeStep, setActiveStep ] = useState(0)
	const [ steps, setSteps ] = useState([])

	//console.log("TimelineView", stageListState)

	const setsteps = (stageListState) => {
	if (!!stageListState) {
		var steps = stageListState.map((item,index) => {
			if (!!isActive(item)) {setActiveStep(index)} else {
				if (activeStep == 0 && (index == stageListState.length - 1)) {
					setActiveStep(stageListState.length - 1)
				}
			}
			return {
				id: Math.floor(Math.random() * 100),
				title: item.title,
				startdate: item.startdate,
				enddate: item.enddate
			}
		})
		
		setSteps(steps)
	}
	//console.log("STEPS:", steps)
}
	

	useEffect(() => {
		if (!!props.stageListState) {setsteps(props.stageListState)}
	}, [props])
	

	const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => 
	{	//console.log(ownerState)
		return {
		backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 42,
		height: 42,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
		...(ownerState.active && {
			...theme.palette.progressbar.active,
			boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
		}),
		...(ownerState.completed && theme.palette.progressbar.completed),
		}
	}	
	);

	const ColorlibStepIconRootSmall = styled('div')(({ theme, ownerState }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 10,
		height: 10,
		padding: 0,
		margin: 0,
		borderRadius: '50%',
		...(ownerState.active && {
			...theme.palette.progressbar.active,
		}),
		...(ownerState.completed && theme.palette.progressbar.completed),
		}));

	const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
		[`&.${stepConnectorClasses.alternativeLabel}`]: {
			top: 22,
		},
		[`&.${stepConnectorClasses.root}`]: {
			
		},
		[`&.${stepConnectorClasses.active}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				...theme.palette.progressbar.active,
			},
		},
		[`&.${stepConnectorClasses.completed}`]: {
			[`& .${stepConnectorClasses.line}`]: {
				...theme.palette.progressbar.completed,
			},
		},
		[`& .${stepConnectorClasses.line}`]: {
			height: 3,
			border: 0,
			
			backgroundColor:
				theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
			borderRadius: 1,
		},
		}));

	function ColorlibStepIcon(props) {
		const { active, completed, className, variant } = props;
		//console.log("variant",variant)
		const icons = {
			1: <GroupAddIcon />,
			2: <RuleIcon />,
			3: <LockOpenIcon />,
			4: <EmojiEventsOutlinedIcon />
		};
		
		if (variant == 'dot') {
			return (
				<ColorlibStepIconRootSmall ownerState={{ completed, active }}>
				</ColorlibStepIconRootSmall>
			)
		}

		if (!variant) { return (
			<ColorlibStepIconRoot ownerState={{ completed, active }}>
				{icons[String(props.icon)]}
			</ColorlibStepIconRoot>
		)}
		}

	
	const MobileStepperStyled = useMemo(() => { return styled(MobileStepper)((props) => 
	{
		// console.log(mobileStepperClasses[`progress`])
		// console.log("MobileStepperStyled:",props)
		return {
			...(!!Boolean(props.minified) ? {
				padding: 0,
				width: "100%",
				"& .MuiMobileStepper-progress": {
					borderRadius: 4,
					width: "100%",
					minWidth: 100,
					flexGrow: 1,
					height: 10,
					backgroundImage: 
					'linear-gradient( 95deg,rgb(255,255,255),rgb(250,250,250) 50%,rgb(240,240,240) 100%)'
					,
				}
			} : {
				padding: 8,
				width: "100%",
				"& .MuiMobileStepper-progress": {
					width: "100%",
					minWidth: 100,
					flexGrow: 1,
					height: 3,
				}
			}
			),
			"& .MuiLinearProgress-barColorPrimary": {
					...(props.activeStep < 9 ?
					theme.palette.progressbar.active
				: 	theme.palette.progressbar.completed)
				,
			}
	}
	}
	)}
	,[props])

	function getProgressStep(step) {
		const startdate = new Date(step.startdate)
		const enddate = new Date(step.enddate)
		
		const today = new Date(Date.now())

		// console.log("startdate:",startdate)
		// console.log("enddate:",enddate)
		// console.log("today:",today)

		const total = enddate.getTime() - startdate.getTime()
		const current = today.getTime() - startdate.getTime()
		var result = Math.floor((current/total) * 10)
		console.log(result)
		// console.log("getProgressStep:", result )
		return result > 9 ? 9 : result < 0 ? 0 : result
	}

	function DateSteps(props,step,index) {
		// console.log(props)
		const ProgressStep = getProgressStep(step)
		// console.log(ProgressStep)
		return (
			<>
				<div style={{display:'flex',flexDirection:'column', alignItems:'center',alignContent:'start'}}>
					<ColorlibStepIcon {...props}/>
					<MobileStepperStyled
						variant="progress"
						steps={10}
						position="static"
						activeStep={ProgressStep}
					/>
					<Box sx={{wordWrap:'nowrap',display:'flex',flexDirection:'column',alignItems:'center'}}>
						<Typography>
							{step.title}
						</Typography>
						<Typography variant="caption">
							{timeAgo(step.startdate, true,true)} - {timeAgo(step.enddate, true,true)}
						</Typography>
					</Box>
				</div>
			</>
		)
	}

	function MinifiedTimeline({step}) {
		const ProgressStep = getProgressStep(step)
		return (
			<><Box sx={{px:3,py:1.5,cursor: 'pointer',}}>
					<Box 
						sx={{
							height: 10,
							textAlign:'center', 
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							
							marginBottom: 0.5
						}}
					>
						<Typography variant="caption">
							{timeAgo(step.startdate,true,true)}
						</Typography>
						<Box flexGrow="1"/>
						<Typography variant="caption">
							{step.title.toUpperCase()}
						</Typography>
						<Box flexGrow="1"/>
						<Typography variant="caption">
							{timeAgo(step.enddate,true,true)}
						</Typography>
					</Box>
					<MobileStepperStyled 
						variant="progress"
						steps={10}
						position="static"
						activeStep={ProgressStep}
						minified="true"
					/>
				</Box>
			</>
		)
	}

return (
	<>
		{!!stageListState &&
			<>
			<Collapse in={!open}>
				<Box onClick={()=>{setOpen(!open)}}>
					<MinifiedTimeline step={stageListState[activeStep]}/>
				</Box>
			</Collapse>
			<Collapse in={open}>
				<Box paddingY={3} onClick={()=>{setOpen(!open)}} sx={{p:3}}>
					<Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
					{steps.map((step,index) => (
						<Step key={index}>
							<StepLabel StepIconComponent={(props) => DateSteps(props,step,index)} />
						</Step>
					))}
					</Stepper>
				</Box>
		</Collapse>
		</>
	}
	</>
)
}

export default TimelineView