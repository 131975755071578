import { useState, useContext, useCallback, useEffect } from 'react'
import {
	Typography,
	Grid,
	Box,
	Dialog,
	Button,
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	DialogActions,
	Divider,
} from '@mui/material'
import Wrapper from '../../components/Wrapper'
import SwitchComponent from '../../components/SwitchComponent'
import ControlsComponent from '../../components/ControlsComponent'
import SchemaTools from '../../helpers/schematools'
import { NavContext } from '../../context/NavContext'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { useData } from '../../hooks/userdata.hook'

const OrgSettings = () => {
	const navigate = useNavigate()
	const { request } = useHttp()
	const { fetchOrg } = useData()
	const { token } = useContext(AuthContext)
	const { compareObjects } = SchemaTools()
	const [ currentOrg, setCurrentOrg ] = useState({})
	const { selectedOrg, setSelectedOrg, refresh, refreshCheck, selectData } = useContext(NavContext)

	const [readonly, setReadonly] = useState(true)
	const [isSubmitting, setSubmitting] = useState(false)
	const [changesState, setChangesState] = useState({
		private: false,
	})
	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}
	const handleClose = () => {
		setOpen(false)
	}
	const resetHandler = () => {
		var newdata = compareObjects(changesState, selectedOrg)
		setChangesState({ ...newdata })
	}
	const harvestChanges = (value) => {
		console.log('Harvested Changes: ', value)
		setChangesState({ ...changesState, ...value })
	}
	const handleSubmit = (e) => {
		console.log(' Handle submit for delete : ', e, ' = ', selectedOrg.title)
		if (e.title !== selectedOrg.title) {
			return console.log('Mismatch app to delete')
		}
		deleteOrg(selectedOrg)
	}

	const deleteOrg = async (value) => {
		try {
			console.log(' Settings. Delete ORG : ', selectedOrg)
			if (!value.title) {
				return
			}
			const data = await request(
				'/api/org/remove',
				'DELETE',
				{ ...value },
				{ Authorization: `Bearer ${token}` }
			)
			setSelectedOrg({index:0})
			refresh()
			navigate('/dash/org')
			
			console.log('DATA: ', data)
		} catch (e) {
			console.log('Delete... Error:', e)
		}
	}

	const saveSettings = async (value) => {
		try {
			
			setSubmitting(true)
			console.log('Save Settings: ', value)
			console.log('Settings to save: ', changesState)
			const data = await request(
				`/api/org/update/${currentOrg.ownerId}/${currentOrg.code}`,
				'POST',
				{ ...changesState },
				{ Authorization: `Bearer ${token}` }
			)
			console.log('DATA: ', data)
			setSelectedOrg({...currentOrg,...changesState})
			setSubmitting(false)
			refresh()
		} catch (e) {
			console.log('Save settings error: ', e)
		}
	}

		//ORG data to this state
		const attachFetched = useCallback(
			(values) => {
				const newdata = compareObjects(changesState, values)
				console.log('Settings. CompareObjects: ', newdata)
				console.log('attach fetched: ', values)
				setChangesState({ ...newdata })
			},
			[selectedOrg]
		)
	
		useEffect(() => {
			console.log('Settings. Selected Org : ', selectedOrg)
			if (!selectedOrg.date) {
				return
			}
			setCurrentOrg(selectedOrg)
			attachFetched(selectedOrg)
		}, [selectedOrg, setCurrentOrg, attachFetched, selectData])
	
	return (
		<>
			<Grid container direction="column">
				<Grid item>
				<Box sx={{ height: '100%', p: 3 }}>
						<Grid container direction="column">
							<Grid item>
								<Typography variant="h5">
									Settings {currentOrg.title}
								</Typography>
							</Grid>
							<Divider />
							<Grid item>
								<Box sx={{ p: 3 }}>
								<Typography variant="h6">Publication</Typography>
									<SwitchComponent
										name="private"
										label={{ off: 'Public', on: 'Private' }}
										checked={changesState.private}
										readonly={readonly}
										changeState={harvestChanges}
									/>
									<ControlsComponent
										readonly={{ setReadonly, readonly }}
										disabled={isSubmitting}
										submitHandler={saveSettings}
										resetHandler={resetHandler}
									/>
								</Box>
							</Grid>
						</Grid>
				</Box>
				</Grid>
				<Grid item xs={12}>
					<Box sx={{ height: '100%', p: 3 }}>
						<Grid container>
							<Grid item>
								<Typography variant="h6">Danger Zone</Typography>
								<Typography>Sensitive settings</Typography>
								<Divider />
								<Wrapper />
								<Typography variant="body1">
									Permanently Delete this ORG.
								</Typography>
								<Typography>This action is not reversible.</Typography>
								<Button
									variant="outlined"
									color="primary"
									onClick={handleClickOpen}
								>
									DELETE
								</Button>

								<Dialog
									open={open}
									onClose={handleClose}
									aria-labelledby="form-dialog-title"
								>
									<Formik
										initialValues={{
											title: '',
										}}
										validationSchema={Yup.object().shape({
											title: Yup.string()
												.matches(currentOrg.title, {
													message: 'not equal',
													excludeEmptyString: false,
												})
												.max(255)
												.required('Enter title'),
										})}
										onSubmit={(values, { setSubmitting }) => {
											handleSubmit(values, { setSubmitting })
										}}
									>
										{({
											errors,
											handleBlur,
											handleChange,
											handleSubmit,
											isSubmitting,
											touched,
											values,
										}) => (
											<Form autoComplete="off">
												<DialogTitle
													id="form-dialog-title"
													sx={{ background: '#CC0000' }}
												>
													<Typography component={'span'} variant="h4">DELETE</Typography>
												</DialogTitle>
												<DialogContent>
													<DialogContentText>
														<Typography component={'span'} variant="subtitle1">
															Are you sure you want to delete this ORG?
															<br />
															This action CANNOT be undone.
															<br />
															This will permanently delete the "{' '}
															{currentOrg.title}" ORG.
															<br />
															<br />
															<strong>you will permanently lose:</strong>
															<br />
															Your ORG users & subscribers
															<br />
															Your ORG data & analytics
															<br />
															Your ORG documentation
															<br />
														</Typography>
														<br />
														<Typography  component={'span'}>Confirm Delete:</Typography>
													</DialogContentText>

													<TextField
														error={Boolean(touched.title && errors.title)}
														helperText={touched.title && errors.title}
														name="title"
														margin="dense"
														id="title"
														label="Type the name of your ORG to delete"
														type="text"
														fullWidth
														value={values.title}
														onBlur={handleBlur}
														onChange={handleChange}
													/>
												</DialogContent>
												<DialogActions>
													<Button onClick={handleClose} color="primary">
														Cancel
													</Button>
													<Button type="submit" color="primary">
														Delete
													</Button>
												</DialogActions>
											</Form>
										)}
									</Formik>
								</Dialog>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</>
	)
}

export default OrgSettings