import { useEffect, useState, useContext } from 'react'
import AccountSelector from './AccountSelector'
import {
	Grid,
	Box,
	List,
	ListItem,
	ListItemIcon,
	ListItemText
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import NavItem from './NavItem'
import { NavContext } from '../context/NavContext'
import shortText from '../helpers/shortText'

const SidebarChannels = (props) => {
	
	const [ channelsList, setChannelsList ] = useState()
	const [ selectedIndex, setSelectedIndex ] = useState('')
	const { setSelectedChannel } = useContext(NavContext)

	const handleClick = (item,index) => {
		console.log(item)
		setSelectedChannel(item)
		if (selectedIndex === index) {
			return setSelectedIndex('')
		} else {
			return setSelectedIndex(index)
		}
	}

	useEffect(() => {
		if (!props.selectData) {
			return
		} else {
			console.log(props.selectData)
			setChannelsList(props.selectData)
		}
	}, [props.selectData])

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<AccountSelector />
					{/*
					<NavItem title="Add New Request" href="requestedit/addnew" />
					<NavItem disabled="true" title="Support" href="" />
					<NavItem title="Summary" href="requestedit" />
					*/}
				</Grid>
			</Grid>
			{/*<Box sx={{ flexGrow: 0.5 }} />*/}
			<Grid container overflow='auto'>
				<Grid item xs={12}>
					<Box
						sx={{
							backgroundColor: 'background.default',
							display: 'flex',
							flexDirection: 'column',
							
						}}
					><List sx={{ m: 0, p: 1 }}>
						{!!channelsList && channelsList.memberchannels && channelsList.memberchannels.map((item,index) => {
							return (
								
								<ListItem
										sx={
											selectedIndex == index
												? { border: 1, borderRadius: 1 }
												: {}
										}
										key={index}
										button
										onClick={() => {
											handleClick(item, index)
										}}
									>
										
										<ListItemText sx={{ textAlign: 'start', pr:2}} primary={shortText(item.channelname)} />
										<Box flexGrow={1}/>
										{selectedIndex == index && (
											<ListItemIcon sx={{ justifyContent: 'flex-end' }}>
												<ChevronRightIcon />
											</ListItemIcon>
										)}
									</ListItem>
									
							)
						})}
						</List>
					</Box>
				</Grid>
			</Grid>
		</>
	)
}

export default SidebarChannels