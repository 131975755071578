import { useState, useEffect, useContext } from 'react'
import {
	Typography,
	Box
} from '@mui/material'
import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'
import SchemaTools from '../../helpers/schematools'
import TextEditor from '../../components/texteditor/TextEditor'
import Subheader from '../../components/Subheader'
import ControlsComponent from '../../components/ControlsComponent'
import ContestDefinitionHeader from './ContestDefinitionHeader'

const ContestPrizes = ({data, ...props}) => {
	const { selectedpart } = props
	const serverpath = "/api/contest"
	console.log("Prizes: ", data)
	var title = 'Prizes'
	var product = data
	const initialState = {values:{}}
	const { compareObjects } = SchemaTools()
	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const { selectedContest, refresh, refreshCheck } = useContext(NavContext)
	const [ values, setValues ] = useState(initialState)
	const [ readonly, setReadonly ] = useState(true)
	const [ isSubmitting, setSubmitting ] = useState(false)

	const fetchData = async(value) => {
		try {
			const fetched = await request(
				`${serverpath}/prizes/id?ownerId=${value.ownerId}&code=${value.code}`,
				'GET',
				null,
				{
					Authorization: `Bearer ${token}`,
				}
			)
			if (!!fetched.data) {
				console.log("fetched data:", fetched.data)
				console.log("fetched:", compareObjects(values,fetched.data))
				setValues(compareObjects(values,fetched.data))
			}
		} catch(e) {
			console.log(e)
		}
	}

	const saveData = async (values) => {
		try {
			if (!!selectedContest && selectedContest.published) {throw 'is published'}
			console.log("saveData selectedContest: ", selectedContest)
			console.log("Data to save: ", values)
			
			setSubmitting(true)
			//var newdata = compareObjects(changesState,value,{minified: true})

			await request(
				`${serverpath}/prizes/id?ownerId=${selectedContest.ownerId}&code=${selectedContest.code}`,
				'POST',
				{ _id: selectedContest._id, ...values },
				{ Authorization: `Bearer ${token}` }
			)
			//refresh()
			setSubmitting(false)
		} catch(e) {
			console.log(e)
		}
	}
	
	const handleChange = (event) => {
		setValues({[event.target.name]: event.target.value})
	}

	const resetHandler = () => {

	}

	const resetForm = () => {

	}

	const submitHandler = () => {
		console.log(title," submitHandler:", values)
		saveData(values)
	}

	useEffect(() => {
		if (!selectedContest.date) {return}
		fetchData(selectedContest)
		
	}, [selectedContest, refreshCheck])

	return (
		<>
			<ContestDefinitionHeader {...{selecteddata: selectedContest,selectedpart}} />
			<Box sx={{p:3}}>
				<TextEditor
					disabled={readonly}
					value={values.prizes}
					name="prizes"
					onChange={handleChange}
				/>
				{!!selectedContest && !selectedContest.published ?
				<ControlsComponent
					readonly={{ setReadonly, readonly }}
					disabled={isSubmitting}
					resetHandler={resetHandler}
					resetform={resetForm}
					submitHandler={submitHandler}
				/>
				:
				<Typography>You cannot edit the Contest while it is published.</Typography>
				}
			</Box>
		</>
	)
}

export default ContestPrizes