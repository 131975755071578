import {useState, useContext, useEffect} from 'react'
import {
	Grid,
	Box,
} from '@mui/material'
import NavItem from './NavItem'
import { NavContext } from '../context/NavContext'
import AccountSelector from './AccountSelector'

import {
	useNavigate,
} from 'react-router-dom'

const SidebarOrg = (props) => {

	const initialorg = {
		title: '',
		code: '',
	}

	const orgmenu = [
		{
			title: 'Обзор',
			name: 'definition',
			href: ``,
			basepath: 'dash',
			disabled: false
		},
		{
			title: 'Страница в Маркете',
			name: 'publicpage',
			href: ``,
			basepath: 'dash',
			target: '_blank',
			disabled: false
		},
	]

	const orgmenucommon = [
		{
			title: 'Общая сводка',
			name: 'summary',
			href: 'org',
			basepath: 'dash',
			disabled: false
		},
		{
			title: 'Создать',
			name: 'addnew',
			href: 'org/addnew',
			basepath: 'dash',
			disabled: false
		},
	]

	const navigate = useNavigate()
	const { selectedOrg, userdata } = useContext(NavContext)
	const [ selectedOrgState, setSelectedOrgState ] = useState(initialorg)

	useEffect(() => {
		console.log('selectData: ', props.selectData)
		console.log(userdata)
		setSelectedOrgState(selectedOrg)
	}, [selectedOrg])
	console.log("sidebar . selectedOrg: ", selectedOrg)

	useEffect(() => {
		if (!selectedOrgState.code || selectedOrgState.code == 0) {
			navigate('./org')
		} else {
			navigate(`org/${selectedOrgState.ownerId}/${selectedOrgState.code}`)
		}
	}, [selectedOrgState])

	return(
		<>
			<Grid container>
				<Grid item xs={12} sx={{overflow:'hidden'}}>
					<AccountSelector/>
					{!!selectedOrgState.code && orgmenu.map((item,index) => (
						<NavItem key={index} {...{...item,
							...(
								item.name=="definition" &&
								{href:`org/${selectedOrgState.ownerId}/${selectedOrgState.code}`}
								||
								item.name=="publicpage" && 
								{href:`orgview/${selectedOrgState.ownerId}/${selectedOrgState.code}`}
								||
								item.name=="summary" && 
								{href:`orgview/${selectedOrgState.ownerId}/${selectedOrgState.code}/summary`}
								)
							}}
						/>
					))
					}
					{!!selectedOrgState.code && <Box sx={{mt:3,borderBottom:'1px solid #eee'}}/>}
					{orgmenucommon.map((item,index) => (
						<NavItem key={index} {...{...item}} />
					))}
				</Grid>
			</Grid>
			<Box sx={{ flexGrow: 0.5 }} />
		</>
	)
}

export default SidebarOrg