import { useState, useContext, useCallback, useEffect } from 'react'
import {
	Typography,
	Grid,
	Box,
	Dialog,
	Button,
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	DialogActions,
	Divider,
} from '@mui/material'
import Wrapper from '../../components/Wrapper'
import SwitchComponent from '../../components/SwitchComponent'
import ControlsComponent from '../../components/ControlsComponent'
import SchemaTools from '../../helpers/schematools'
import { NavContext } from '../../context/NavContext'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import Preloader from '../../components/Preloader'
import Subheader from '../../components/Subheader'
import Checklist from './ContestChecklist'
import ContestDefinitionHeader from './ContestDefinitionHeader'

const ContestSettings = ({data,...props}) => {
	const { selectedpart } = props
	var title = 'Settings'
	var product = data
	const editorpath = '/dash/contestedit'
	const serverpath = '/api/contest'
	const navigate = useNavigate()
	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const { compareObjects } = SchemaTools()
	const [ current, setCurrent ] = useState({})
	const { selectedContest, setSelectedContest, refresh, selectData } = useContext(NavContext)

	const [ verifyListState, setVerifyListState ] = useState()

	const [readonly, setReadonly] = useState(true)
	const [isSubmitting, setSubmitting] = useState(false)
	const [changesState, setChangesState] = useState({
		private: false,
		published: false,
	})
	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}
	const handleClose = () => {
		setOpen(false)
	}
	const resetHandler = () => {
		setChangesState(compareObjects(changesState, selectedContest))
	}
	const harvestChanges = (value) => {
		console.log('Harvested Changes: ', value)
		setChangesState({ ...changesState, ...value })
	}
	const handleSubmit = (e) => {
		console.log(' Handle submit for delete : ', e, ' = ', selectedContest.title)
		if (e.title !== selectedContest.title) {
			return console.log('Mismatch Request to delete')
		}
		deleteRequest(selectedContest)
	}

	const deleteRequest = async (value) => {
		try {
			console.log(' Settings. Delete : ', selectedContest)
			if (!value.title) {
				return
			}
			const result = await request(
				`${serverpath}/remove`,
				'DELETE',
				{ ...value },
				{ Authorization: `Bearer ${token}` }
			)
			navigate(editorpath)
			refresh()
			console.log('result: ', result)
		} catch (e) {
			console.log('Delete... Error:', e)
		}
	}

	const validateChecklist = async () => {
		try {
			const fetchedVerify = await request(
				`${serverpath}/validateChecklist/id?ownerId=${current.ownerId}&code=${current.code}`,
				'GET',
				null,
				{ Authorization: `Bearer ${token}` }
			)
			if (!!fetchedVerify && fetchedVerify.contestSummary) {
				setVerifyListState(fetchedVerify.contestSummary)
				return fetchedVerify.contestSummary.verified
			}
			
		} catch (e) {
			console.log(e)
		}
	}

	const saveSettings = useCallback(async () => {
		try {
			let verified = false
			if (changesState.published === true) {
				verified = await validateChecklist()
				if (verified !== true) {return}
			}
			
			console.log('Settings to save: ', changesState)

			setSubmitting(true)
			const data = await request(
				`${serverpath}/update`,
				'POST',
				{ _id: current._id, ...changesState },
				{ Authorization: `Bearer ${token}` }
			)
			console.log('DATA: ', data)
			setSubmitting(false)
			refresh()
		} catch (e) {
			console.log('Save settings error: ', e)
		}
	}, [current,changesState])

	const attachFetched = useCallback(
		(values, changesState) => {
			const newdata = compareObjects(changesState, values)
			console.log('Settings. CompareObjects: ', newdata)
			console.log('attach fetched: ', values)
			setChangesState({ ...newdata })
		},
		[]
	)

	useEffect(() => {
		console.log('Settings. Selected : ', selectedContest)
		if (!selectedContest.date) {
			return
		}
		setCurrent(selectedContest)
		attachFetched(selectedContest, changesState)
	}, [selectedContest, setCurrent, attachFetched, selectData])

	if (!current.title) {return <Preloader />}

	return (
		<>
			<ContestDefinitionHeader {...{selecteddata: selectedContest,selectedpart}} />
			<Checklist verifyListState={verifyListState} getVerifyList={validateChecklist}/>
			<Grid container>
				<Grid item>
					<Box sx={{ p: 3 }}>
						<Typography variant="h6">Publication</Typography>
						<SwitchComponent
							name="private"
							label={{ on: 'Private', off: 'Public' }}
							checked={changesState.private}
							readonly={readonly}
							changeState={harvestChanges}
						/>
						<SwitchComponent
							name="published"
							label={{ on: 'Published', off: 'Draft' }}
							checked={changesState.published}
							readonly={readonly}
							changeState={harvestChanges}
						/>
						<ControlsComponent
							readonly={{ setReadonly, readonly }}
							disabled={isSubmitting}
							submitHandler={saveSettings}
							resetHandler={resetHandler}
						/>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box sx={{ height: '100%', p: 3 }}>
								<Typography variant="h6">Danger Zone</Typography>								
								<Typography variant="body1">
									Permanently Delete.
								</Typography>
								<Typography>This action is not reversible.</Typography>
								<Wrapper />
								<Button
									variant="outlined"
									color="primary"
									onClick={handleClickOpen}
								>
									DELETE
								</Button>
								<Dialog
									open={open}
									onClose={handleClose}
									aria-labelledby="form-dialog-title"
								>
									<Formik
										initialValues={{
											title: '',
										}}
										validationSchema={Yup.object().shape({
											title: Yup.string()
												.matches(current.title, {
													message: 'not equal',
													excludeEmptyString: false,
												})
												.max(255)
												.required('Enter title'),
										})}
										onSubmit={(values, { setSubmitting }) => {
											handleSubmit(values, { setSubmitting })
										}}
									>
										{({
											errors,
											handleBlur,
											handleChange,
											handleSubmit,
											isSubmitting,
											touched,
											values,
										}) => (
											<Form autoComplete="off">
												<DialogTitle
													id="form-dialog-title"
													sx={{ background: '#CC0000' }}
												>
													<Typography variant="h4">DELETE</Typography>
												</DialogTitle>
												<DialogContent>
													<DialogContentText>
														<Typography variant="subtitle1">
															Are you sure you want to delete this Request?
															<br />
															This action CANNOT be undone.
															<br />
															This will permanently delete the "{current.title}".
															<br />
															<br />
															<strong>you will permanently lose:</strong>
															{/*
															<br />
															Your Request users (0) & subscribers (0)
															<br />
															Your Request data & analytics
															<br />
															Your Request documentation
															<br />
															*/}
														</Typography>
														<br />
														<Typography>Confirm Delete:</Typography>
													</DialogContentText>
													<TextField
														error={Boolean(touched.title && errors.title)}
														helperText={touched.title && errors.title}
														name="title"
														margin="dense"
														id="title"
														label="Type the name"
														type="text"
														fullWidth
														value={values.title}
														onBlur={handleBlur}
														onChange={handleChange}
													/>
												</DialogContent>
												<DialogActions>
													<Button onClick={handleClose} color="primary">
														Cancel
													</Button>
													<Button type="submit" color="primary">
														Delete
													</Button>
												</DialogActions>
											</Form>
										)}
									</Formik>
								</Dialog>
					</Box>
				</Grid>
			</Grid>
		</>
	)
}

export default ContestSettings