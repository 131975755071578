import { useState, useCallback, useContext, useEffect } from 'react'

import { Tabs, Tab, Typography, Divider, Box } from '@mui/material'

import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'

import AppOverview from './AppOverview'
import AppStatistics from './AppStatistics'
import AppSettings from './AppSettings'

import SchemaTools from '../../helpers/schematools'

import { NavContext } from '../../context/NavContext'

import ComponentOutlet from '../../components/template/ComponentOutlet'

const AppDefinition = (props) => {
	const path = props.path
	const params = useParams()
	const { compareObjects } = SchemaTools()
	const { selectedApp, setSelectedApp, refresh, refreshCheck, selectData } = useContext(NavContext)
	const [ currentApp, setCurrentApp ] = useState({})
	const [ changesState, setChangesState ] = useState({})

	const parts = [
		{
			path: 'overview',
			element: AppOverview,
			disabled: false,
		},
		{
			path: 'settings',
			element: AppSettings,
			disabled: false,
		},
		{
			path: 'statistics',
			element: AppStatistics,
			disabled: true,
		},
	]

	const [partIndex, setPartIndex] = useState(0)

	const handleChange = (event, newValue) => {
		setPartIndex(newValue)
	}

	//SELECTEDAPP data to this state
	const attachFetched = useCallback(
		(values) => {
			const newdata = compareObjects(changesState, values)
			console.log('App Definition. CompareObjects: ', newdata)
			console.log('attach fetched: ', values)
			setChangesState({ ...newdata })
		},
		[selectedApp]
	)

	useEffect(() => {
		if (!selectedApp.date) {
			return
		}
		setCurrentApp(selectedApp)
		attachFetched(selectedApp)
	}, [selectedApp, setCurrentApp, attachFetched, selectData])

	return (
		<><Box sx={{position: 'sticky', top:0, background: '#fff', zIndex: '10000'}}>
			<Tabs value={partIndex} onChange={handleChange}>
				{parts &&
					parts.map((item, index) => (
						<Tab
							disabled={item.disabled}
							label={item.path}
							value={index}
							key={index + 1}
							to={{
								pathname: `/dash/appedit/${params.user}/${params.product}/` + item.path,
							}}
							component={RouterLink}
						/>
					))}
			</Tabs>
			<Divider />
			</Box>
			<ComponentOutlet path={path} parts={parts} data={selectedApp}/>
		</>
	)
}

export default AppDefinition
