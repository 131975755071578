import { useCallback, useContext, useEffect } from 'react'
import { useHttp } from './http.hook'
import { AuthContext } from '../context/AuthContext'

export const useProducts = () => {

    const { loading, request } = useHttp()
    const { token } = useContext(AuthContext)


    const fetchProducts = useCallback(async (options) => {
        try {
            const { productType, queryParams } = options
            const queryString = new URLSearchParams(queryParams)
            const fetched = await request(
				`/api/market/${productType}/p?${queryString.toString()}`,
				'GET',
				null,
				{}
            )
            console.log(fetched.products)
            if (!!fetched.products) {
                return fetched.products
            }
            
        } catch (e) {
            console.log(e)
        }
    },[])

    const getProducts = async (productType, queryParams) => {
        const options = {
            productType,
            queryParams
        }
        const result = await fetchProducts(options)
        console.log("R: ", result)
        return result
    }

    return {
        getProducts
    }
}