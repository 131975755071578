import {
	Grid,
	Box
} from '@mui/material'

import ProductCard from '../../components/product/ProductCard'
import { useTags } from '../../hooks/tag.hook'

const OrgViewApi = (props) => {

	const { getCategory } = useTags()
	const products = props.data.api.map((item, index) => {
		return {...item, avatar: item.avatar || props.data.avatar, orgowner: {title: props.data.title} }
	})

	console.log(products)

	return (
		<>
			<Grid item xs={12} sx={{ justifyContent: 'center', p: 3 }} container>
				
				<Box sx={{ p: 3,pt:0 }}>
					<Grid container spacing={2.5} justifyContent="center">
						{products &&
							products.map((product, index) => (
								<Grid
									key={index}
									item
								>
									<ProductCard 
										product={product} 
										basepath={`/marketplace/`}
										category={getCategory(product)}
										productType={'solutions'}
									/>
								</Grid>
							))}
					</Grid>
				</Box>
				
			</Grid>
		</>
	)
}

export default OrgViewApi