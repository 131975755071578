import { useCallback } from 'react'
import { useHttp } from './http.hook'

export const useDetails = () => {
	const { request } = useHttp()

	const fetchDetails = useCallback(async (userId, token) => {

		try {
			console.log("FD")
			const response = await request(`/users/`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			//const data = await response.json()
			console.log("DATA:", response)
		
			return response

		} catch (e) {}
	},[ request])

	

	return {
		fetchDetails,
	}
}
