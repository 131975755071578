import {useState, useEffect,useCallback} from 'react'
import {useNavigate} from 'react-router-dom'
import {
	Grid,
	Typography,
	Box,
	Divider,
	Button
} from '@mui/material'

import TextViewer from '../../../components/texteditor/TextViewer'
import TimelineView from './TimelineView'

const ContestOverview = (props) => {
	
	const [ productData, setProductData ] = useState(props.data)
	console.log("productData", productData)
	let offset = 0
	
	const Navigate = useNavigate()
	const ParticipateHandler = () => {
		Navigate('../rules')
	}
	

	const getOffset = () => {
		console.log("offset", offset)
		return offset
	}

	
	useEffect(() => {
		if (productData !== props.data) {
			setProductData(props.data)
		}
	}, [props.data])

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
						<TimelineView stageListState={productData.timeline}/>
						<Divider/>
				</Grid>
				<Grid item xs={12} container spacing={0}>
					<Grid item xs={12} md={6} sx={{m:0,p:0}}>
						<Box sx={{px:3,py:0.5,m:0}}>
								<Typography variant="caption">Description</Typography>
						</Box>
						<Divider/>
						<Grid item sx={{p:3,py:1.5}}>					
							<TextViewer storedState={productData.longdescription}/>
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box sx={{px:3,py:0.5}}>
							<Typography variant="caption">Prizes</Typography>
						</Box>
						<Divider/>
						<Box  sx={{p:3,py:1}}>
							<TextViewer storedState={productData.prizes}/>
						</Box>
						<Box sx={{px:3,py:0.5}}>
							<Typography variant="caption">Evaluation</Typography>
						</Box>
						<Divider/>
						<Box  sx={{p:3,py:1}}>
							<TextViewer storedState={productData.evaluation}/>
						</Box>
					</Grid>
					
				</Grid>
				
			</Grid>
		</>
	)
}

export default ContestOverview