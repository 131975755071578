import { useEffect, useState, useContext } from 'react'
import {
	Box,
	Grid,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	TextField,
	InputAdornment,
	SvgIcon,
	Typography,
} from '@mui/material'

import { Search as SearchIcon } from 'react-feather'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

import NavItem from './NavItem'
import { NavContext } from '../context/NavContext'
import AccountSelector from './AccountSelector'

const appLinks = [
	{
		title: 'Definition',
		path: 'overview',
		disabled: false,
		links: ['overview', 'settings', 'statistics']
	},
	{
		title: 'Summary',
		path: 'summary',
		disabled: false
	},
]

const SidebarApp = (props) => {
	const selectData = props.selectData
	const [appList, setAppList] = useState([])
	const [appListTitle, setAppListTitle] = useState('Personal Apps')

	const [selectedIndex, setSelectedIndex] = useState('')
	const [ selectedAppState, setSelectedAppState] = useState('')
	const { setSelectedApp, selectedOrg } = useContext(NavContext)

	const handleClick = (item, index) => {
		if (item === 'applist') {
			if (selectedIndex === index) {
				return setSelectedIndex('')
			} else {
				return setSelectedIndex(index)
			}
		}
	}

	useEffect(() => {
		console.log('APPEDITOR . SIDEBAR . SELECTDATA: ', props)
		console.log('selectedOrg: ', selectedOrg)
		if (!!selectedOrg.title) {
			setAppListTitle(selectedOrg.title + ' APPS')
		} else {
			setAppListTitle('Personal APPS')
		}
		if (selectData) {
			if (selectData.filterdata) {
				const preparedData = selectData.filterdata
					.map((item, index) => {
						if (selectedOrg._id) {
							if (item.orgowner === selectedOrg._id) {
								return item
							}
						} else {
							if (!item.orgowner) {
								return item
							}
						}
					})
					.filter((i) => !!i)

				if (preparedData) {
					console.log('Prepared data: ', preparedData)
					setAppList(preparedData)
				}
			}
		}
	}, [selectData, selectedOrg])

	useEffect(() => {
		//console.log(" SideBar: Selected Api : ", selectedApi)
		setSelectedApp(selectedAppState)
	}, [selectedAppState])

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<AccountSelector />
					<NavItem title="Add New APP" href="appedit/addnew" basepath="dash" />
					<NavItem disabled={true} title="Support" href="appedit/support" basepath="dash" />
					<NavItem title="Summary" href="appedit" basepath="dash"/>
				</Grid>
			</Grid>

			<Box sx={{ flexGrow: 0.5 }} />

			<Grid container>
				<Grid item xs={12}>
					<Box
						sx={{
							backgroundColor: 'background.default',
							display: 'flex',
							flexDirection: 'column',
							p: 1,
						}}
					>
						<TextField
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SvgIcon fontSize="small" color="action">
											<SearchIcon />
										</SvgIcon>
									</InputAdornment>
								),
							}}
							placeholder="Search Api"
							variant="outlined"
							value=""
							size="small"
						/>

						<Typography sx={{ p: 1 }} variant="h6">
							{appListTitle}
						</Typography>
					</Box>
				</Grid>
			</Grid>
			{/* APPLIST */}
			<Grid container overflow='auto'>
				<Grid item xs={12}>
					{!!appList &&
						appList.map((item, index) => {
							return (
								<div key={index}>
									<ListItem
										key={index}
										button
										onClick={() => {
											handleClick('applist', index)
										}}
									>
										<ListItemIcon>
											{index === selectedIndex ? (
												<ExpandLess />
											) : (
												<ExpandMore />
											)}
										</ListItemIcon>
										<Box flexGrow={1}/>
										<ListItemText sx={{ textAlign: 'end', pr:2}} primary={item.title} />
									</ListItem>
									<Divider />
									<Collapse
										in={index === selectedIndex}
										timeout="auto"
										unmountOnExit
										sx={{position:'relative', top:'-1px', backgroundColor: '#fff'}}
									>
										<Grid item xs={12}>
											{appLinks.map((i, index) => (
												<div key={index}>
													<NavItem
														disabled={i.disabled}
														onClick={() => {
															setSelectedAppState(item)
														}}
														key={i.index}
														basepath={'dash'}
														end={false}
														title={i.title}
														links={i.links}
														endpath={i.path}
														href={
															'appedit/' +
															item.ownerId +
															'/' +
															item.code +
															'/' +
															i.path
														}
													/>
												</div>
											))}
										</Grid>
										<Divider />
									</Collapse>
								</div>
							)
						})}
				</Grid>
			</Grid>
		</>
	)
}

export default SidebarApp
