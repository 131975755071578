import { useEffect, useState } from 'react'

import {
	TextField,
	Grid,
	Box,
	Collapse,
	Button,
	Typography,
	Avatar
} from '@mui/material'

import DatePickerComponent from '../../components/DatePicker'
import SwitchComponent from '../../components/SwitchComponent'
import ClearIcon from '@mui/icons-material/Clear';

const StageEditor = (props) => {
	const {
		open,
		setOpenEditor,
		editState,
		saveHandler
	} = props
	console.log("StageEditor props: ", props)

	const [ state, setState ] = useState(editState)

	const handleChange = (event) => {
		console.log(event)
		setState({...state, [event.target.name]:event.target.value})
	}

	const handleCancel = () => {
		setOpenEditor(false)
	}

	const handleSave = () => {
		setOpenEditor(false)
		if (!!saveHandler) {
			const { error, ...rest } = state
			saveHandler(rest)
		}
	}

	useEffect(() => {
		if (!!editState) {
			setState(editState)
		}
	}, [props])

	return(
		<>
		{!!state && !!state.error &&
			<Box sx={{pl:3, display:'flex', gap:1}}>
				<Avatar sx={{width:18,height:18,background:`#e88`}}>
					<ClearIcon sx={{fontSize:'11pt'}}/>
				</Avatar>
				<Typography variant="caption" sx={{color:`#e88`}}>{state.error.stage.toUpperCase() + ' STAGE: ' + state.error.error}</Typography>
			</Box>
		}
		{!!state && !!state.title &&
		<Collapse in={open}>
		<Grid container spacing={1} padding={3}>
			<Grid item xs={10} container sx={{display:'flex'}} spacing={1}>
				<Grid item flexGrow="1">
					<TextField
						fullWidth
						label='Stage title'
						name='title'
						value={state.title}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item>
					<DatePickerComponent
						label='Start date'
						name='startdate'
						value={state.startdate}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item>
					<DatePickerComponent
						label='End date'
						name='enddate'
						value={state.enddate}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
				<TextField
					fullWidth
					autoComplete="off"
					label='Description'
					name='description'
					value={state.description}
					onChange={handleChange}
				/>
				</Grid>
			</Grid>
			<Grid item xs={2} sx={{}}>
				<Button fullWidth variant="outlined" onClick={handleCancel} sx={{mb:1}}>Cancel</Button>
				<Button fullWidth variant="outlined" onClick={handleSave}>Save</Button>
			</Grid>
		</Grid>
		</Collapse>
		}
		</>
	)
}

export default StageEditor