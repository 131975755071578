import { Navigate, useLocation, useInRouterContext, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import Homepage from './pages/Homepage'
import NotFound from './pages/Notfound'
import DashboardLayout from './components/DashboardLayout'
import MainLayout from './components/MainLayout'
import ProductList from './pages/Market/ProductList'
import Account from './pages/Account/Account'
import Login from './pages/Login'
import Register from './pages/Register'
import Logout from './pages/Logout'

import UserView from './pages/UserView'

import ApiEditor from './pages/ApiEditor/ApiEditor'
import AddNew from './pages/ApiEditor/AddNew'
import ApiSummary from './pages/ApiEditor/Apisummary'
import Definition from './pages/ApiEditor/Definition'

import AppEditor from './pages/AppEditor/AppEditor'
import AddNewApp from './pages/AppEditor/AddNewApp'
import AppSummary from './pages/AppEditor/AppSummary'
//import AppStatistics from './pages/AppEditor/AppStatistics'
import AppDefinition from './pages/AppEditor/AppDefinition'

import RequestEditor from './pages/RequestEditor/RequestEditor'
import RequestDash from './pages/RequestEditor/RequestDash'
import RequestAddNew from './pages/RequestEditor/RequestAddNew'
import RequestDefinition from './pages/RequestEditor/RequestDefinition'
import RequestSummary from './pages/RequestEditor/RequestSummary'
import RequestResponses from './pages/RequestEditor/RequestResponses'

import ContestDash from './pages/Contest/ContestDash'
import ContestSandbox from './pages/Contest/ContestSandbox'
import ContestEditor from './pages/Contest/ContestEditor'
import ContestSummary from './pages/Contest/ContestSummary'
import ContestAddnew from './pages/Contest/ContestAddnew'
import ContestDefinition from './pages/Contest/ContestDefinition'
import ContestLeaderboard from './pages/Contest/ContestLeaderboard'

import Marketplace from './pages/Market/Marketplace'
import ProductView from './pages/Market/ProductView'
import RequestView from './pages/Market/RequestView'
import ContestView from './pages/Market/Contest/ContestView'

import OrgEditor from './pages/Org/OrgEditor'
import AddOrg from './pages/Org/AddOrg'
import OrgDefinition from './pages/Org/OrgDefinition'
import OrgView from './pages/Org/OrgView'

import ChannelsPage from './pages/Channels/ChannelsPage.js'

import Payments from './pages/Payments/Payments'
import ContestEvaluation from './pages/Contest/ContestEvaluation'
import ContestPrizes from './pages/Contest/ContestPrizes'
import Privacy from './pages/Privacy'

import Retarget from './pages/RETARGET'

import ExploreMap from './pages/Map/ExploreMap'

import SurveysList from './pages/Research/SurveysList'
import SurveyDash from './pages/Research/SurveyDash'
import SurveyView from './pages/Research/SurveyView'
import ResearchUsers from './pages/Research/ResearchUsers'
import ResearchDash from './pages/Research/ResearchDash'


const Redirect = ({to}) => {
	const location = useLocation()
	return <Navigate to={to} state={{pathname:location.pathname,replace:true}} replace={true}/>
}

const Routes = (isAuthenticated) => {
	const location = useLocation()
	return [
	{
		path: 'payments', element: <Payments />,
	},
	{
		path: 'dash',
		element: isAuthenticated ? <DashboardLayout /> : /*<Navigate to="/login" state={{noauth:1}}/>*/ <Redirect to="/login"/>,
		children: [
			{ path: 'user/:id', element: <UserView/>},
			{ path: 'user/:id/overview', element: <UserView path='overview'/>},
			{ path: 'user/:id/api', element: <UserView path='api'/>},
			{ path: 'user/:id/app', element: <UserView path='app'/>},
			{ path: 'user/:id/request', element: <UserView path='request'/>},
			{ path: 'account', element: <Account /> },
			{ path: 'channels', element: <ChannelsPage />},

			
			{ path: 'org', element: <OrgEditor />,
				children: [
					{path: 'addnew', element: <AddOrg />},
					{path: ':user/:code', element: <OrgDefinition />},
					{path: ':user/:code/summary', element: <OrgDefinition path='summary'/>},
					{path: ':user/:code/overview', element: <OrgDefinition path='overview'/>},
					{path: ':user/:code/settings', element: <OrgDefinition path='settings'/>},
				]
			},
			{ path: 'apiedit', element: <ApiEditor />,
				children: [
					{path: 'addnew', element: <AddNew />},
					{path: ':user/:product', element: <Definition />},
					{path: ':user/:product/summary', element: <ApiSummary />},
					{path: ':user/:product/overview', element: <Definition path="overview" />},
					{path: ':user/:product/settings', element: <Definition path="settings" />}, 
					{path: ':user/:product/endpoints', element: <Definition path="endpoints" />}, 
					{path: ':user/:product/plans', element: <Definition path="plans" />}, 
					{path: ':user/:product/announcements', element: <Definition path="announcements" />} 	
				]
			},
			{ path: 'appedit', element: <AppEditor />,
				children: [
					{ path: 'addnew', element: <AddNewApp />},
					{ path: ':user/:product/summary', element: <AppSummary />},
					{ path: ':user/:product', element: <AppDefinition/>},
					{ path: ':user/:product/overview', element: <AppDefinition path='overview'/>},
					{ path: ':user/:product/settings', element: <AppDefinition path='settings'/>},
					{ path: ':user/:product/statistics', element: <AppDefinition path='statistics'/>}
				]
			},
			{ path: 'requestedit', element: <RequestEditor />,
				children: [
					{ path: 'addnew', element: <RequestAddNew />},
					{ path: 'summary', element: <RequestDash />},
					{ path: ':user/:product', element: <RequestDefinition/>},
					{ path: ':user/:product/summary', element: <RequestSummary />},
					{ path: ':user/:product/responses', element: <RequestResponses />},
					{ path: ':user/:product/overview', element: <RequestDefinition path='overview'/>},
					{ path: ':user/:product/settings', element: <RequestDefinition path='settings'/>},
					{ path: ':user/:product/statistics', element: <RequestDefinition path='statistics'/>}
				]
			},
			{ path: 'contestedit', element: <ContestEditor />,
				children: [
					{ path: 'addnew', element: <ContestAddnew />},
					{ path: 'summary', element: <ContestDash />},
					{ path: ':user/:product/summary', element: <ContestSummary/>},
					{ path: ':user/:product/summary/overview', element: <ContestSummary path='overview'/>},
					{ path: ':user/:product/summary/leaderboard', element: <ContestSummary path='leaderboard'/>},
					{ path: ':user/:product', element: <ContestDefinition />},
					{ path: ':user/:product/overview', element: <ContestDefinition path='overview'/>},
					{ path: ':user/:product/settings', element: <ContestDefinition path='settings'/>},
					{ path: ':user/:product/timeline', element: <ContestDefinition path='timeline'/>},
					{ path: ':user/:product/data', element: <ContestDefinition path='data'/>},
					{ path: ':user/:product/sandbox', element: <ContestDefinition path='sandbox'/>},
					{ path: ':user/:product/evaluation', element: <ContestDefinition path='evaluation'/>},
					{ path: ':user/:product/prizes', element: <ContestDefinition path='prizes'/>},
					{ path: ':user/:product/rules', element: <ContestDefinition path='rules'/>}
					// { path: ':user/:product/statistics', element: <ContestDefinition path='statistics'/>}
				]
			},
			{ path: '*', element: <NotFound /> },
		],
	},
	{
		path: '/',
		element: <MainLayout />,
		children: [
			{ path: 'home', element: <Homepage />},
			{ path: 'about', element: <Homepage path="about"/>},
			{ path: 'docs', element: <Homepage path="docs"/>},
			{ path: 'req', element: <Homepage />},
			{ path: 'login', element: <Login /> },
			{ path: 'register', element: <Register /> },
			{ path: 'logout', element: <Logout /> },
			{ path: 'verify', element: <Navigate to="/login" state={{from:"verify"}}/>},
			{ path: 'marketplace', element: <Marketplace path="none" />,
			children: [
				{ path: 'solutions', element: <ProductList path="solutions" producttype="solutions" />},
				{ path: 'solutions/:user/:product', element: <ProductView producttype="solutions"/>},
				//{ path: 'solutions/:user/:product/overview', element: <ProductView path="overview" producttype="solutions"/>},
				{ path: 'solutions/:user/:product/endpoints', element: <ProductView path="endpoints" producttype="solutions"/>},
				{ path: 'solutions/:user/:product/docs', element: <ProductView path="docs" producttype="solutions"/>},
				{ path: 'solutions/:user/:product/plans', element: <ProductView path="plans" producttype="solutions"/>},
				{ path: 'solutions/:user/:product/specs', element: <ProductView path="specs" producttype="solutions"/>},

				{ path: 'requests', element: <ProductList path="requests" producttype="requests"/>},
				{ path: 'requests/:user/:product', element: <RequestView producttype="requests"/>},
				{ path: 'requests/:user/:product/overview', element: <RequestView path="overview" producttype="requests"/>},
				{ path: 'requests/:user/:product/endpoints', element: <RequestView path="endpoints" producttype="requests"/>},
				{ path: 'requests/:user/:product/docs', element: <RequestView path="docs" producttype="requests"/>},
				{ path: 'requests/:user/:product/plans', element: <RequestView path="plans" producttype="requests"/>},
				{ path: 'requests/:user/:product/specs', element: <RequestView path="specs" producttype="requests"/>},

				{ path: 'contests', element: <ProductList path="contests" producttype="contests"/>},
				{ path: 'contests/:user/:product', element: <ContestView producttype="contests"/>},
				{ path: 'contests/:user/:product/overview', element: <ContestView path="overview" producttype="contests"/>},
				{ path: 'contests/:user/:product/data', element: <ContestView path="data" producttype="contests"/>},
				{ path: 'contests/:user/:product/submissions', element: <ContestView path="submissions" producttype="contests"/>},
				{ path: 'contests/:user/:product/leaderboard', element: <ContestView path="leaderboard" producttype="contests"/>},
				{ path: 'contests/:user/:product/rules', element: <ContestView path="rules" producttype="contests"/>},

				{ path: 'organizations', element: <ProductList path="organizations" producttype="organizations"/>},
				{ path: 'organizations/:user/:org', element: <OrgView/>},
				{ path: 'organizations/:user/:org/overview', element: <OrgView path='overview'/>},
				{ path: 'organizations/:user/:org/api', element: <OrgView path='api'/>},
				{ path: 'organizations/:user/:org/contest', element: <OrgView path='contest'/>},
				{ path: 'organizations/:user/:org/request', element: <OrgView path='request'/>},
			]
			},
			{
				path: 'map', element: <ExploreMap />
			},
			{path: 'research', element: isAuthenticated ? <ResearchDash /> : <Redirect to="/login"/>},
			{path: 'research/survey/:id', element: <SurveyView />},
			{path: 'research/surveylist/:id', element: isAuthenticated ? <ResearchDash path='surveylist'/> : <Redirect to="/login"/>},
			{path: 'research/surveyeditor', element: isAuthenticated ? <ResearchDash path='surveyeditor'/> : <Redirect to="/login"/>},
			{path: 'research/surveyeditor/:id', element: isAuthenticated ? <ResearchDash path='surveyeditor'/> : <Redirect to="/login"/>},
			{path: 'research/researchusers', element: isAuthenticated ? <ResearchDash path='researchusers'/> : <Redirect to="/login"/>},
			
			{
				path: 'privacy', element: <Privacy />
			},
			{ path: '404', element: <NotFound /> },
			{ path: '/', element: <Homepage /> },
			{ path: '*', element: <NotFound /> },
			
		],
	}
]}

export default Routes
