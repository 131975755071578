const shortText = (value,length) => {
	let lengthlocal = 20
	if (length) {lengthlocal = length}
	if (value.length > lengthlocal) {
		return value.slice(0,lengthlocal) + '...'
	} else {
		return value
	}
}

export default shortText