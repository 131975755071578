import { useState, useContext, useCallback, useEffect } from 'react'
import {
	Typography,
	Grid,
	Box,
	Dialog,
	Button,
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	DialogActions,
	Divider,
} from '@mui/material'
import Wrapper from '../../components/Wrapper'
import SwitchComponent from '../../components/SwitchComponent'
import ControlsComponent from '../../components/ControlsComponent'
import SchemaTools from '../../helpers/schematools'
import { NavContext } from '../../context/NavContext'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import Preloader from '../../components/Preloader'

const AppSettings = () => {
	const navigate = useNavigate()
	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const { compareObjects } = SchemaTools()
	const [ currentRequest, setCurrentRequest ] = useState({})
	const { selectedRequest, setSelectedRequest, refresh, selectData } =
		useContext(NavContext)

	const [readonly, setReadonly] = useState(true)
	const [isSubmitting, setSubmitting] = useState(false)
	const [changesState, setChangesState] = useState({
		private: false,
		published: false,
	})

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}
	const handleClose = () => {
		setOpen(false)
	}
	const resetHandler = () => {
		var newdata = compareObjects(changesState, selectedRequest)
		setChangesState({ ...newdata })
	}
	const harvestChanges = (value) => {
		console.log('Harvested Changes: ', value)
		setChangesState({ ...changesState, ...value })
	}

	const handleSubmit = (e) => {
		console.log(' Handle submit for delete : ', e, ' = ', selectedRequest.title)
		if (e.title !== selectedRequest.title) {
			return console.log('Mismatch Request to delete')
		}
		deleteRequest(selectedRequest)
	}

	//DELETE FUNCTION
	const deleteRequest = async (value) => {
		try {
			console.log(' Settings. Delete Request : ', selectedRequest)
			if (!value.title) {
				return
			}
			const data = await request(
				'/api/request/remove',
				'DELETE',
				{ ...value },
				{ Authorization: `Bearer ${token}` }
			)
			navigate('/dash/requestedit')
			refresh()
			console.log('DATA: ', data)
		} catch (e) {
			console.log('Delete... Error:', e)
		}
	}

	const saveSettings = async (value) => {
		try {
			setSubmitting(true)
			console.log('Save Settings: ', value)
			console.log('Settings to save: ', changesState)
			const data = await request(
				'/api/request/update',
				'POST',
				{ _id: currentRequest._id, ...changesState },
				{ Authorization: `Bearer ${token}` }
			)
			console.log('DATA: ', data)
			setSelectedRequest({...currentRequest,...changesState})
			setSubmitting(false)
			refresh()
		} catch (e) {
			console.log('Save settings error: ', e)
		}
	}

	//SELECTEDAPI data to this state
	const attachFetched = useCallback(
		(values, changesState) => {
			const newdata = compareObjects(changesState, values)
			console.log('Settings. CompareObjects: ', newdata)
			console.log('attach fetched: ', values)
			setChangesState({ ...newdata })
		},
		[selectedRequest]
	)

	useEffect(() => {
		console.log('Settings. Selected Request : ', selectedRequest)
		if (!selectedRequest.date) {
			return
		}
		setCurrentRequest(selectedRequest)
		attachFetched(selectedRequest, changesState)
	}, [selectedRequest, setCurrentRequest, attachFetched, selectData])

	if (!currentRequest.title) {return <Preloader />}
	return (
		<>
			<Grid container>
				<Grid item>
					<Box sx={{ height: '100%', p: 3 }}>
						<Grid container direction="column">
							<Grid item>
								<Typography variant="h5">
									Settings {currentRequest.title}
								</Typography>
							</Grid>
							<Divider />
							<Grid item>
								<Box sx={{ p: 3 }}>
								<Typography variant="h6">Publication</Typography>
									<SwitchComponent
										name="private"
										label={{ on: 'Private', off: 'Public' }}
										checked={changesState.private}
										readonly={readonly}
										changeState={harvestChanges}
									/>

									<SwitchComponent
										name="published"
										label={{ on: 'Published', off: 'Draft' }}
										checked={changesState.published}
										readonly={readonly}
										changeState={harvestChanges}
									/>

									<ControlsComponent
										readonly={{ setReadonly, readonly }}
										disabled={isSubmitting}
										submitHandler={saveSettings}
										resetHandler={resetHandler}
									/>
								</Box>
							</Grid>
							<Divider />
							<Grid item>
								<Typography variant="h6">Application keys</Typography>
								<Box sx={{p:3}}>
								<Typography>{changesState.requestkey}</Typography>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box sx={{ height: '100%', p: 3 }}>
						<Grid container>
							<Grid item>
								<Typography variant="h6">Danger Zone</Typography>
								<Typography>Sensitive settings</Typography>
								<Divider />
								<Wrapper />
								<Typography variant="body1">
									Permanently Delete
								</Typography>
								<Typography>This action is not reversible.</Typography>
								<Button
									variant="outlined"
									color="primary"
									onClick={handleClickOpen}
								>
									DELETE
								</Button>

								<Dialog
									open={open}
									onClose={handleClose}
									aria-labelledby="form-dialog-title"
								>
									<Formik
										initialValues={{
											title: '',
										}}
										validationSchema={Yup.object().shape({
											title: Yup.string()
												.matches(currentRequest.title, {
													message: 'not equal',
													excludeEmptyString: false,
												})
												.max(255)
												.required('Enter title'),
										})}
										onSubmit={(values, { setSubmitting }) => {
											handleSubmit(values, { setSubmitting })
										}}
									>
										{({
											errors,
											handleBlur,
											handleChange,
											handleSubmit,
											isSubmitting,
											touched,
											values,
										}) => (
											<Form autoComplete="off">
												<DialogTitle
													id="form-dialog-title"
													sx={{ background: '#CC0000' }}
												>
													<Typography variant="h4">DELETE</Typography>
												</DialogTitle>
												<DialogContent>
													<DialogContentText>
														<Typography variant="subtitle1">
															Are you sure you want to delete this?
															<br />
															This action CANNOT be undone.
															<br />
															This will permanently delete the "{currentRequest.title}".
															<br />
															{/*
															<br />
															<strong>you will permanently lose:</strong>
															<br />
															Your Request users (0) & subscribers (0)
															<br />
															Your Request data & analytics
															<br />
															Your Request documentation
															<br />
															*/}
														</Typography>
														<br />
														<Typography>Confirm Delete:</Typography>
													</DialogContentText>

													<TextField
														error={Boolean(touched.title && errors.title)}
														helperText={touched.title && errors.title}
														name="title"
														margin="dense"
														id="title"
														label="Type the name"
														type="text"
														fullWidth
														value={values.title}
														onBlur={handleBlur}
														onChange={handleChange}
													/>
												</DialogContent>
												<DialogActions>
													<Button onClick={handleClose} color="primary">
														Cancel
													</Button>
													<Button type="submit" color="primary">
														Delete
													</Button>
												</DialogActions>
											</Form>
										)}
									</Formik>
								</Dialog>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</>
	)
}

export default AppSettings
