import { 
	Box,
	Icon,
	Typography,
} from '@mui/material'

const ItemCategory = (props) => {
    const { index, tag, color, clickHandler, handleClick, type, icon } = props


    return (

        <Box
            key={index}
            name={tag.title}
            
            sx={{
                border: `1px solid ${color}`,
                borderRadius: '10pt',
                p: 0.3,
                px: 1,
                alignItems: 'center',
                display: 'inline-flex',
                ...(!!clickHandler ? {cursor:'pointer'} : {}),
                width:'auto',
                gap: 1,
            }}
            
            {...(!!clickHandler && handleClick ? {
                onClick: handleClick
            } : {})}
        >
            {!!icon ? icon : ''}
            <Box sx={{width:'auto'}}>
            <Typography variant="caption" noWrap >
                {(!!tag.variations && tag.variations.ru) || tag.title}
                <Box flexGrow="1"/>
                {!!tag.count && type == "market" ? tag.count : ''}
            </Typography>
            </Box>
        </Box>

    )
}

export default ItemCategory