import {useEffect, useContext, useCallback, useState} from 'react'
import { Outlet } from 'react-router'
import { useOutlet, useNavigate } from 'react-router-dom'
import { NavContext } from '../../context/NavContext'
import { useData } from '../../hooks/userdata.hook'

import OrgDash from './OrgDash'

const OrgEditor = () => {
	const currentPart = 'org'
	const currentOutlet = useOutlet()
	const { setnav, refreshCheck, userdata } = useContext(NavContext)
	const { fetchOrg } = useData()
	// const [ orgdata, setOrgdataState ] = useState({})

	// useEffect(() => {
	// 	if (userdata.org) {
	// 		setOrgdataState(userdata.org)
	// 		console.log("OrgEditor: userdata :", userdata.org)
	// 	}
	// }, [userdata])

	useEffect(() => {
		setnav(currentPart)
		fetchOrg()
	}, [refreshCheck])

	return (
		<>
			{!currentOutlet && <OrgDash/>}
			<Outlet/>
		</>
	)
}

export default OrgEditor