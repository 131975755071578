import { useState, useContext } from 'react'
import { Link as RouterLink, useOutlet, useNavigate } from 'react-router-dom'
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	TextField,
	Typography,
} from '@mui/material'

import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook'
import { NavContext } from '../../context/NavContext'
import { useMessage } from '../../hooks/message.hook'

const RequestAddNew = () => {
	const initialState = {
		title: '',
		shortdescription: '',
	}
	const { token } = useContext(AuthContext)
	const { request, error, clearError } = useHttp()
	const [changesState, setChangesState] = useState(initialState)
	const { setnav, refresh, selectData, setSelectedRequest, selectedOrg } = useContext(NavContext)
	const navigate = useNavigate()
	const { settext, errortext } = useMessage()
	const message = settext

	const sendData = async (values) => {
		try {
			const data = await request(
				'/api/request/create',
				'POST',
				{ ...values },
				{ Authorization: `Bearer ${token}`}
			)
			console.log('DATA: ', data)
			message(data.message)
			console.log('DATA: ', data)
			if (data.data) {
				setSelectedRequest(data.data)
				navigate('/dash/requestedit')
			}
			refresh()

		} catch (e) {
			console.log(e)
		}
	}

	const handleSubmit = (values) => {
		console.log('SUBMIT')
		sendData({...values, orgowner: selectedOrg._id})
	}

	return (
		<>
			<Formik
				initialValues={changesState}
				enableReinitialize
				validationSchema={Yup.object().shape({
					title: Yup.string()
						.min(3,'Minimum symbols: ${min}')
						.max(255, 'Maximum symbols limit exceeded ${max}')
						.required('Request name is required')
						,
					shortdescription: Yup.string()
						.min(10,'Minimum symbols: ${min}')
						.max(255, 'Maximum symbols limit exceeded ${max}')
						.required('Short description is required'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					handleSubmit(values, { setSubmitting })
					setSubmitting(false)
				}}
			>
				{({
					errors,
					handleBlur,
					handleChange,
					isSubmitting,
					touched,
					resetForm,
					values,
				}) => (

					
					<Form autoComplete="off">
						<Grid item md={6} xs={12} sx={{ p: 3 }}>
						<Typography variant="h6">
								Add new Request
						</Typography>
						</Grid>
						<Divider />
						<Grid item md={6} xs={12} sx={{ p: 3 }}>
							<Grid container spacing={1}>
								<Grid item xs={12} sx={{pb:3}}>
									<TextField
										error={Boolean(touched.title && errors.title)}
										fullWidth
										helperText={
											!!errors.title && errors.title ||
											"Please specify Request name"
										}
										label="Title"
										name="title"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										value={values.title}
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										error={Boolean(touched.shortdescription && errors.shortdescription)}
										fullWidth
										helperText={
											!!errors.shortdescription && errors.shortdescription ||
											"Please specify the short description"
										}
										label="Short description"
										name="shortdescription"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										value={values.shortdescription}
										variant="outlined"
										multiline
										rows={4}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Divider />
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'flex-start',
									p: 3,
								}}
							>
								<Button
									color="primary"
									variant="outlined"
									type="submit"
									disabled={isSubmitting}
								>
									ADD
								</Button>
							</Box>
							<Divider />
						</Grid>
					</Form>
				)}
			</Formik>
		</>
	)
}

export default RequestAddNew
