import {
	Grid,
	Box
} from '@mui/material'

import ProductCard from '../../components/product/ProductCard'

const UserApps = (props) => {
	const products = props.data.app
	const setActiveProduct = (product, index) => {
		//Navigate("/marketplace/" + product.ownerId + "/" + product.code)
	}

	return (
		<>
			<Grid item xs={12} sx={{ justifyContent: 'center', p: 3 }} container>
				
				<Box sx={{ p: 3,pt:0 }}>
					<Grid container spacing={2.5} justifyContent="center">
						{products &&
							products.map((product, index) => (
								<Grid
									key={index}
									item
									onClick={() => setActiveProduct(product, index)}
									onMouseOver={() => {}}
								>
									<ProductCard product={product} key={index} />
								</Grid>
							))}
					</Grid>
				</Box>
				
			</Grid>
		</>
	)
}

export default UserApps