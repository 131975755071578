import { useState, useContext, useEffect } from 'react'
import { Box, TextField } from '@mui/material'

import { NavContext } from '../../context/NavContext'
import { AuthContext } from '../../context/AuthContext'
import { useData } from '../../hooks/userdata.hook'

const appListDefault = [
	{
		title: 'default app',
		_id: 0,
		value: ''
	}
]

const orgListDefault = [
	{
		title: 'Personal',
		_id: 0,
		value: ''
	}
]

const OrgAppSelector = (props) => {
	const { token } = useContext(AuthContext)
	const selectedEndpoint = props.selectedEndpoint
	const setSelectedAppKey = props.setSelectedAppKey
	const { fetchOrg, fetchApps, clearData } = useData()
	const { userdata } = useContext(NavContext)
	const [ orgList, setOrgList ] = useState()
	const [ appList, setAppList ] = useState()
	const [ orgListState, setOrgListState ] = useState(orgListDefault)
	const [ appListState, setAppListState ] = useState(appListDefault)
	const [ appSelected, setAppSelected ] = useState(0)
	const [ orgSelected, setOrgSelected ] = useState(0)
	const [ readonly, setReadonly ] = useState(true)
	
	console.log("ORG APP SELECTOR", userdata)

	const [values, setValues] = useState({
		appselected: 0,
		orgselected: 0
	})

	const setAppKey = (value) => {
			const app = appList.find((element,index) => {
				return element._id == value
			})
			setSelectedAppKey(app.appkeydata.appkey)
	}

	const handleChange = (event) => {
		setValues({...values, [event.target.name]: event.target.value})
		if (event.target.name == 'appselected') {
			setAppSelected(event.target.value)
		}
		if (event.target.name == 'orgselected') {
			setOrgSelected(event.target.value)
		}
	}

	useEffect(() => {
		if (appSelected) {
		setAppKey(appSelected)
		}
	}, [appSelected])

	useEffect(() => {
		if (!appList) {
			return
		} else {
			if (!appList.length) {
				return
			}
		}
		const orgApps = appList.map((item)=>{
			if (!!orgSelected && (orgSelected == item.orgowner)) {
				return item
			}
			if ((orgSelected == 0) && (item.orgowner == undefined)) {
				return item
			}
		}).filter(i=>!!i)
		setAppListState(orgApps)
		
	}, [orgSelected])

	useEffect(() => {
		if (!!appListState.length) {
			setValues({...values, 
				appselected: appListState[0]._id,
				orgselected: orgSelected
			})
			setAppSelected(appListState[0]._id)
		}
	}, [appListState])

	useEffect(() => {
		if (!!userdata.org) {
			setOrgList(userdata.org)
			setReadonly(false)
		}
		if (!!userdata.apps) {
			setAppList(userdata.apps)
			setReadonly(false)
		}
	}, [userdata])

	useEffect(() => {
		if (orgList && appList) {
			const filterAppList = appList.map((item)=>{
			if (!!orgSelected && (orgSelected == item.orgowner)) {
				return item
			}
			if ((orgSelected == 0) && (item.orgowner == undefined)) {
				return item
			}
		}).filter(i=>!!i)
		setAppListState(filterAppList)
		setOrgListState([...orgListDefault,...orgList])
		setOrgSelected([...orgListDefault,...orgList][0]._id)
	}
	}, [orgList,appList])

	useEffect(async () => {
		if (!token) {
			clearData()
			return
		}
		if (!userdata.org || !userdata.apps) {	
			setOrgSelected(orgSelected === 0 ? '0' : 0)
			await fetchOrg()
			await fetchApps()
		}
	}, [selectedEndpoint, fetchOrg, fetchApps, token])

	return (
				<>
					<Box sx={{ m: 1, mb: 0 }}>
						<TextField
							disabled = {readonly}
							fullWidth
							size="small"
							name="orgselected"
							onChange={(event) => {
								handleChange(event)
							}}
							label="ORG"
							required
							select
							SelectProps={{ native: true }}
							value={values.orgselected._id}
							variant="outlined"
						>
							{orgListState.map((option, index) => (
								<option key={index} value={option._id}>
									{option.title}
								</option>
							))}
						</TextField>
					</Box>
					<Box sx={{ m: 1 }}>
						<TextField
							disabled = {readonly || !appListState.length}
							error = {!appListState.length}
							fullWidth
							size="small"
							name="appselected"
							label="APP"
							helperText={
								!appListState.length && "Selected Org has no APPs..."
							}
							onChange={(event) => {
								handleChange(event)
							}}
							required
							select
							SelectProps={{ native: true }}
							value={values.appselected._id}
							variant="outlined"
						>
							{appListState.map((option, index) => (
								<option key={index} value={option._id}>
									{option.title}
								</option>
							))}
						</TextField>
					</Box>
		</>
	)
}

export default OrgAppSelector
