import React from 'react'
import ReactDOM from 'react-dom'


import { history } from './helpers/history';
import { BrowserRouter as Router } from 'react-router-dom'

import reportWebVitals from './reportWebVitals'
import {App} from './App'

import StringToHTML from './helpers/StringToHTML';
import Metrics from './metrics.js'

console.log(process.env)
if (process.env.NODE_ENV == "production") {
	console.log = () => {};

}
//StringToHTML(document.getElementById('metrics'), Metrics())

ReactDOM.render(
<React.StrictMode>

		<Router history={history}>
			<App />
		</Router>

</React.StrictMode>,
document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
