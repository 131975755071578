import { TextField } from '@mui/material'
import { useEffect, useState } from "react"
import importMetric, { metricList } from "../helpers/metrics"

const MetricSelector = ({
		setMetric, 
		code, 
		readonly,
		formik,
		values,
		setFieldValue
	}) => {
	const { handleBlur, handleChange } = formik
	const noop = () => {}
	const List = [{title:'',module:noop},...metricList]
	const [ metricSelected, setMetricSelected ] = useState()

	return (
		<TextField
			disabled = {readonly}
			fullWidth
			size="small"
			name="metric"
			onBlur={handleBlur}
			onChange={handleChange}
			required
			select
			SelectProps={{ native: true }}
			value={values.metric}
			variant="outlined"
		>
			{List.map((option, index) => (
				<option key={index} value={option.modulename} disabled={index==0}>
					{option.title}
				</option>
			))}
		</TextField>
	)
}

export default MetricSelector