import { useState, useContext, useEffect, useRef } from 'react'
import {
	Grid,
	Typography,
	Box,
	Divider,
	Avatar,
	Button,
	Collapse,
	TextField
} from '@mui/material'

import { useTheme } from '@mui/material/styles';

import { useLocation, useParams, Outlet, Link } from 'react-router-dom'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import { NavContext } from '../../context/NavContext'
import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook'
import timeAgo from '../../helpers/timeAgo'

import ProductPlans from './ProductPlans'
import ProductOverview from './ProductOverview'
import RequestResponseCreate from './RequestResponseCreate'

import ComponentOutlet from '../../components/template/ComponentOutlet';

const initialState = {
	title: '',
	owner: '',
	update: '',
	views: '',
	description: '',
	longdescription: '',
	response: ''
}

const RequestView = (props) => {

	const theme = useTheme()
	const {isAuthenticated, token} = useContext(AuthContext)
	const productType = props.producttype
	const path = props.path

	const { request } = useHttp()
	const setnav = useContext(NavContext)

	const [ error, setError ] = useState()
	const [ productData, setProductData ] = useState(initialState)
	
	let { user, product } = useParams()

	const parts = [
		{
			path: 'overview',
			element: ProductOverview,
		},
		{
			path: 'plans',
			element: ProductPlans,
		},
		{
			path: 'docs',
			element: ProductPlans,
		},
		{
			path: 'specs',
			element: ProductPlans,
		},
	]

	const fetchProduct = async () => {
		try {
			const authorization = !!token ? `Bearer ${token}` : ''
			const fetched = await request(
				`/api/market/type/${productType}/id?ownerId=${user}&product=${product}`,
				'GET',
				null,
				{ Authorization: authorization, page: 'productview'}
			)
			if (fetched.title) {
				console.log('FETCHED PRODUCTVIEW . SUCCESS : ', fetched)
				setProductData(fetched)
				setnav.setData({ user, product, productType })
			}
		} catch (e) {
			console.log('FETCHED PRODUCTVIEW . ERROR : ', e)
			setError(e)
		}
	}

	useEffect(() => {
		fetchProduct()
	}, [user, product])

	useEffect(() => {
		setnav.setnav('product')
	}, [setnav])

	useEffect(() => {
		console.log(isAuthenticated)
	}, [isAuthenticated])
	
	if (error) {
		return (
			<Box sx={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
				<Typography variant="h4">Ничего не найдено...</Typography>
			</Box>
		)
	}

	return (
		<>
			<Grid container spacing={3} sx={{p:3}}>
				<Grid item display="flex" alignItems="center">
					<Avatar>{productData.title.charAt(0).toUpperCase()}</Avatar>
				</Grid>
				<Grid item display="flex" alignItems="center">
					<Typography variant="h4">{productData.title}</Typography>
				</Grid>
				<Grid item display="flex" alignItems="center">
					<Typography>
						<Typography 
							component={Link} 
							to={{pathname:'/dash/user/' + productData.owner.ownerId}}
							target="_blank"
							sx={theme.textlinkstyle}
						>
							{productData.owner.firstName + ' ' + productData.owner.lastName}
						</Typography>
						. {productData.category}. Created:{' '}
						{timeAgo(productData.date)}
					</Typography>
				</Grid>

				<Box sx={{ flexGrow: 1 }} />
				<Grid item >
					<BookmarkBorderIcon />
				</Grid>

				<Grid item >
					<Typography>{productData.clicks}</Typography>
					<Typography>Views</Typography>
				</Grid>
				<Grid item>
					<Typography>-</Typography>
					<Typography>Folowers</Typography>
				</Grid>
			</Grid>
			<Divider />
			<Typography sx={{p:3}}>{productData.shortdescription}asdasd</Typography>
			<Divider />
			
			{!!productType && productType == 'requests' ?
			<Grid container>
				<Grid item xs={12} md={6}>
					<ComponentOutlet path={path} parts={parts} data={productData}/>
				</Grid>
			</Grid>
			:
			<ComponentOutlet path={path} parts={parts} data={productData} />
			}
			<Divider />
			{!!productType && productType == 'requests' && isAuthenticated &&
			<RequestResponseCreate data={{...productData, user, product}}/>
			}
			
			
		</>
	)
}

export default RequestView
