import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useEffect, useContext, useState,useCallback } from 'react'
import { useTheme } from '@mui/material/styles';
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { NavContext } from '../../context/NavContext'
import { AuthContext } from '../../context/AuthContext'

import * as Yup from 'yup';
import { Formik, Form, Field, useField } from 'formik';

import {
	Box,
	Button,
	Checkbox,
	Container,
	FormHelperText,
	Link,
	TextField,
	Typography,
	Grid,
	MenuItem,
	Fade,
	Collapse,
	IconButton,
	FormControlLabel,
	Tooltip
} from '@mui/material';

import {
	AddCircleOutlineOutlined as AddIcon,
	EditOutlined as EditIcon,
	DeleteForeverOutlined as DeleteIcon,
	LaunchOutlined as LaunchIcon,
	ListAltOutlined as ListIcon
} from '@mui/icons-material'


import { Alert } from '@mui/lab';
import Snackbar from '@mui/material/Snackbar';

import MessageAlert from '../../components/MessageAlert'
import {CheckboxField} from '../../components/CheckboxField.tsx'
import SurveyEditor from './SurveyEditor'
import SurveyList from './SurveysList'
import ConfirmDialog from '../../components/ConfirmDialog'

import shortText from '../../helpers/shortText'


const SurveyDash = ({path}) => {

	console.log("SurveyDash:", path)
	const [ currentPath, setCurrentPath ] = useState(path)
	const theme = useTheme()
	const Navigate = useNavigate()
	const {settext, errortext} = useMessage()
	const { setMessage, messageText } = useMessage()
	const message = settext
	const { request, error, clearError } = useHttp()
	const { token } = useContext(AuthContext)
	const { setnav } = useContext(NavContext)
	
	const [ editor, setEditor ] = useState(false)
	const [ questions, setQuestions ] = useState([])
	const initialList = { listname:'', description:'', questions }
	const [ listState, setListState ] = useState(initialList)

	const [ setField, setSetField ] = useState(()=>{})
	const [ editorState, setEditorState ] = useState()
	const [ editorOpen, setEditorOpen ] = useState(false)

	const [ surveyList, setSurveyList ] = useState([])
	const [ refreshTrigger, setRefreshTrigger ] = useState(0)
	
	const [ confirmState, setConfirmState] = useState({open:true})

	const refresh = () => {
		console.log("refresh")
		setRefreshTrigger(Math.random())
		return
	}

	const fetchData = async () => {
		try {
			const fetched = await request(
				'/api/research/getAll',
				'GET',
				null,
				{}
				)
			if (!!fetched && !!fetched.data) {
				console.log(fetched)
				setSurveyList(fetched.data)
				
			}
		} catch(e) {
			console.log(e)
			setMessage(e)
		}
	}

	const handleView = (list) => {
		console.log("handleView : ",list)
	}

	const handleEdit = (list) => {
		
		setListState(list)
		setEditorOpen(true)
		Navigate(`/research/surveyeditor/${list._id}`)
		
		console.log("list: ", list)
	}

	const handleCreate = () => {
		setCurrentPath('surveyeditor')
		setEditorOpen(true)
		setListState(initialList)
	}

	const deleteData = async (item) => {
		setListState(initialList)
		try {
			const fetched = await request(
				'/api/research/deletelist',
				'POST',
				item,
				{ Authorization: `Bearer ${token}`}
				)
			refresh()
		} catch(e) {
			console.log(e)
			setMessage(e)
		}
	}

	const handleDelete = (item) => {
		setConfirmState({open:true,setOpen:setConfirmState,callback:deleteData,item,validatename:'listname'})
	}

	useEffect(() => {
		fetchData()
		setnav('surveyeditor')
		
		return () => {}
	}, [setnav,refreshTrigger])

	useEffect(() => {
		console.log("Path :", path)
		setCurrentPath(path)
	}, [path])

	return (
		<Grid container sx={{py:0}}>
			<Grid item xs={12}>
				<Box sx={{p:3}}>
							<Typography variant="h2" sx={{fontWeight:200,color:'#777'}}>Редактор анкет</Typography>
							<ConfirmDialog {...confirmState} />
				</Box>
			</Grid>
			<Grid item xs={12} display="flex" flexDirection="column" sx={{gap:0.5,p:3}}>
					{!!surveyList.length && surveyList.map((item,index) => (
						<Box key={index} sx={{display:'flex',p:0.5,pl:3,width:'100%', borderRadius:1, border:'1px solid #ccc',alignItems:'center'}}>
								<Tooltip title={item.listname} placement="bottom">
									<Typography sx={{width:'100%'}}>
										{shortText(item.listname,20)}
									</Typography>
								</Tooltip>
							<Box sx={{display:'flex',flexWrap:'nowrap'}}>
								<Box>
									<IconButton
										component={RouterLink} 
										to={{pathname:`/research/surveylist/${item._id}`,state:{path:'list'}}}
										>
											<ListIcon />
										</IconButton>
								</Box>
								<Box>
									<IconButton 
										component={RouterLink} 
										to={{pathname:`/research/survey/${item._id}`}}
										target="_blank"
										>
											<LaunchIcon />
									</IconButton>
								</Box>
								<Box>
									<IconButton onClick={() => {handleEdit(item)}}>
										<EditIcon />
									</IconButton>
								</Box>
								<Box>
									<IconButton onClick={() => {handleDelete(item)}}>
										<DeleteIcon />
									</IconButton>
								</Box>
							</Box>
						</Box>
					))
					}
					<Button variant='outlined' onClick={handleCreate}><AddIcon/></Button>
			</Grid>
			<Grid item xs sx={{p:3}}>
				{currentPath == 'surveyeditor' && !!listState && !!editorOpen &&
					<SurveyEditor listSelect={listState} setListSelect={setListState} setEditorOpen={setEditorOpen} initialList={initialList} refresh={refresh} />
				}
				{currentPath == 'surveylist' &&
					<SurveyList listSelect={listState} refresh={refresh} />
				}
			</Grid>
		</Grid>
	)
}

export default SurveyDash