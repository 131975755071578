import {
	Grid,
	Typography
} from '@mui/material'

const UserOverview = (props) => {
	const userdetails = props.data
	if (!userdetails) {return (<></>)}
	return (
		<>
		<Grid item xs={12} sx={{ justifyContent: 'center', p: 3 }} container>
				<Typography variant="h6" sx={{ p: 3, py: 1 }}>
					About
				</Typography>
				<Grid item xs={7} sx={{ p: 3, py: 1 }}>
					<Typography variant="body2">{userdetails.description}</Typography>
				</Grid>
			</Grid>
		</>
	)
}

export default UserOverview