import { useState, useContext, useEffect, useRef, useCallback } from 'react'
import {
	Grid,
	Typography,
	Box,
	Divider,
	Avatar,
	Button,
	Collapse,
	TextField,
} from '@mui/material'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'

const RequestResponseCreate = (props) => {
	//console.log("RequestResponseCreate", props)
	const { user, product } = props.data
	const [openResponseState, setOpenResponseState] = useState(false)
	const [changesState, setChangesState] = useState({
		description: '',
		deadline: '',
		cost: '',
	})
	const response = useRef(null)
	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const [responseState, setResponseState] = useState()
	const { setnav, refresh, selectData, setSelectedApp, selectedOrg } =
		useContext(NavContext)

	let submitMyForm = null
	let validateMyForm = null

	const fetchResponse = async () => {
		if (!token) {
			return
		}
		try {
			const responsedata = await request(
				`/api/request/responses/${user}/${product}`,
				'GET',
				null,
				{ Authorization: `Bearer ${token}` }
			)
			if (!!responsedata.data) {
				console.log('responsedata :', responsedata)
				setResponseState(responsedata)
			}
		} catch (e) {
			console.log(e)
		}
	}

	const saveSettings = async (values) => {
		try {
			console.log(user, product)
			const data = await request(
				`/api/request/apply/${user}/${product}`,
				'POST',
				values,
				{ Authorization: `Bearer ${token}` }
			)
			fetchResponse()
		} catch (e) {
			console.log(e)
		}
	}

	const handleSubmit = (values) => {
		console.log('handleSubmit :', values)
		saveSettings(values)
	}

	const handleSubmitMyForm = async () => {
		if (!!submitMyForm && !!validateMyForm) {
			return new Promise((resolve, reject) => {
				submitMyForm()
				.then(validateMyForm)
				.then(function ERR (errors) {
					const isValid = Object.keys(errors).length == 0
					if (!!isValid) {
						resolve()
						} else {
						reject(errors)
					}
				})
			}).catch((e) => {
				console.log('error: ', e)
			})
		}
	}

	const bindSubmitForm = (value) => {
		//console.log(value)
		submitMyForm = value.submitForm
		validateMyForm = value.validateForm
	}

	useEffect(() => {
		fetchResponse()
	}, [token, props.data.user, props.data.product])

	return (
		<>
			<Grid item xs={12} md={6} ref={response}>
				<Box sx={{ p: 3 }} fullWidth>
					{!!responseState ? (
						<>
							<Typography variant="body2" paddingBottom="1em">
								Предложение отправлено
							</Typography>
							<Collapse in={openResponseState}>
								<Grid container spacing={1}  paddingBottom="1em">
									<Grid item xs={12}>
										<Box sx={{borderRadius: 1, border: '1px solid', p:2, borderColor: 'border'}}>
											<Typography sx={{ wordWrap: 'break-word' }} variant="body2">
												{responseState.data[0].description}
											</Typography>
										</Box>
									</Grid>
									<Grid item>
										<Grid container spacing={1}>
											<Grid item xs={6}>
												<TextField
													size="small"
													label="Deadline"
													fullWidth
													value={responseState.data[0].deadline}
												/>
											</Grid>
											<Grid item xs={6}>
												<TextField
													size="small"
													label="Cost"
													fullWidth
													value={responseState.data[0].cost}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid>
								</Grid>
							</Collapse>
							{!openResponseState ? (
								<Button
									onClick={() => {
										setOpenResponseState(true)
									}}
									variant="outlined"
									fullWidth
								>
									Посмотреть
								</Button>
							) : (
								<Button
									onClick={() => {
										setOpenResponseState(false)
									}}
									variant="outlined"
									fullWidth
								>
									Закрыть
								</Button>
							)}
						</>
					) : (
						<>
							<Collapse in={openResponseState}>
								<Formik
									initialValues={changesState}
									enableReinitialize
									validationSchema={Yup.object().shape({
										description: Yup.string()
											.min(100, 'Minimum symbols limit ${min}')
											.max(1000, 'Maximum symbols limit exceeded ${max}')
											.required('This field is required'),
										deadline: Yup.string().required('This field is required'),
										cost: Yup.string().required('This field is required'),
									})}
									onSubmit={(values, { setSubmitting }) => {
										console.log('SUBMIT')
										handleSubmit(values, { setSubmitting })
									}}
								>
									{(formikProps) => {
										var {
											errors,
											handleBlur,
											handleChange,
											touched,
											resetForm,
											values,
										} = formikProps
										bindSubmitForm({
											submitForm: formikProps.submitForm,
											validateForm: formikProps.validateForm,
										})
										return (
											<Form autoComplete="off">
												<Typography variant="h6" paddingBottom="1em">
													Предложить решение
												</Typography>
												<Typography variant="body2">
													Представьтесь и как можно подробнее опишите как вы
													планируете выполнить задачу
												</Typography>
												<TextField
													onBlur={handleBlur}
													onChange={handleChange}
													fullWidth
													multiline
													rows={10}
													sx={{ my: 1 }}
													size="small"
													name="description"
													value={values.description}
													error={Boolean(
														touched.description && errors.description
													)}
												/>
												<TextField
													onBlur={handleBlur}
													onChange={handleChange}
													label={'Сроки выполнения'}
													fullWidth
													size="small"
													sx={{ mb: 1 }}
													name="deadline"
													value={values.deadline}
													error={Boolean(touched.deadline && errors.deadline)}
												/>
												<TextField
													onBlur={handleBlur}
													onChange={handleChange}
													label={'Цена'}
													fullWidth
													size="small"
													sx={{ mb: 1 }}
													name="cost"
													value={values.cost}
													error={Boolean(touched.cost && errors.cost)}
												/>
											</Form>
										)
									}}
								</Formik>
							</Collapse>
							{!openResponseState ? (
								<Button
									onClick={() => {
										setOpenResponseState(true)
										//response.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
									}}
									variant="outlined"
									fullWidth
								>
									Предложить решение
								</Button>
							) : (
								<Button
									onClick={handleSubmitMyForm}
									variant="outlined"
									fullWidth
								>
									Отправить
								</Button>
							)}
						</>
					)}
				</Box>
				<Box sx={{ minHeight: '10em' }}> </Box>
			</Grid>
		</>
	)
}

export default RequestResponseCreate
