import {useEffect, useState} from 'react'

import {
	Typography,
	Box,
	Tabs,
	Tab,
	Divider
} from '@mui/material'

import {
	Link as RouterLink,
	useNavigate,
	useParams
} from 'react-router-dom'

import ContestLeaderboard from './ContestLeaderboard'
import ContestSummaryOverview from './ContestSummaryOverview'

import ComponentOutlet from '../../components/template/ComponentOutlet'


const ContestSummary = (props) => {
	console.log("... ContestSummary ... ")
	const params = useParams()
	const [partIndex, setPartIndex] = useState(0)
	const navigate = useNavigate()

	const handleChange = (event, value) => {
		setPartIndex(value)
	}

	const parts = [
		{
			path: 'overview',
			element: ContestSummaryOverview,
			disabled: false
		},
		{
			path: 'leaderboard',
			element: ContestLeaderboard,
			disabled: false
		},
	]

	const path = props.path

	useEffect(() => {
		if (!!path) {
			setPartIndex(parts.indexOf(parts.find((item) => item.path == path)))
		} else {
			{navigate('overview')}
		}
		return () => {}
	},[path])

	if (!path) {return (<></>)}

	return (
		<>
			<Box sx={{position: 'sticky', top:0, background: '#fff', zIndex: '10000'}}>
			<Tabs value={partIndex} onChange={handleChange}>
				{parts &&
					parts.map((item, index) => (
						<Tab
							style={{ 
								minWidth: `${item.path.length * 0.5}` + `em`,
								paddingInline:'1em'
							}}
							disabled={item.disabled}
							label={item.path}
							value={index}
							key={index + 1}
							to={{
								pathname: `/dash/contestedit/${params.user}/${params.product}/summary/` + item.path,
							}}
							component={RouterLink}
						/>
					))}
			</Tabs>
			<Divider />
			</Box>
			<ComponentOutlet path={path} parts={parts}/>
		</>
	)
}

export default ContestSummary