import { useEffect, useState } from 'react'
import Prism from 'prismjs'
import Normalizer from 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace'
import 'prismjs/themes/prism.css'

import LanguageSelector from './LanguageSelector'
import MediaView from './MediaView'
import objectTools from '../../helpers/objectTools'

var nw = new Normalizer({
	'remove-trailing': true,
	'remove-indent': true,
	'left-trim': true,
	'right-trim': true,
	'break-lines': 100,
	'indent': 1,
	'remove-initial-line-feed': true,
	'tabs-to-spaces': 2,
	'spaces-to-tabs': 0
});


const { objectToQueryString } = objectTools()
const hostname = 'ai-aggregator.ru'
const hubUrl = process.env.NODE_ENV == 'production' ? 'https://' + hostname + '/hub' : 'https://localhost:3000/hub'

const prepareData = (props) => {
	const { headersObject, method, body, bodyObject , path, queryPathString, queryMap, code, contenttype, queryMapString, apicode, appkey } = props

	const urlString = '/' + (path? path : '') + queryPathString + queryMapString

	const requestObject = {
		url: apicode + code + urlString,
		method: method,
		body: body && bodyObject? body.mediatype == 'form' ? '"' + objectToQueryString(bodyObject.example) + '"' : JSON.stringify(bodyObject.example) : null,
		headers: {...headersObject, ...contenttype, appkey}
	}
	const stringified = {
		url: hubUrl + '/' + requestObject.url,
		method: '"' + requestObject.method + '"',
		body: requestObject.body,
		headers: JSON.stringify(requestObject.headers, null, 6),
		hostname: hostname,
		path: '/hub/' + apicode + code + '/' + (path? path : '') + queryPathString
	}
	return stringified
}


const Sandbox = (props) => {
	const language = 'javascript'
	const [ rsdkCode, setRsdkCode ] = useState('')
	const type = props.type || null
	var code = props.code
	? !!rsdkCode && rsdkCode
	: false || props.results || {}

	code = nw.normalize(code.toString(), {});

	var setRsdk = (rsdk) => {
		if (props.code) {
			setRsdkCode(rsdk(prepareData(props.code)))
		}
	}
	useEffect(() => {
		Prism.highlightAll()
	}, [rsdkCode, props])

	return (
		<>
			{!!props.code && <LanguageSelector setRsdk={setRsdk} code={props.code}/>}
			{!props.code && <MediaView code={code} type={type}/>}
			<pre style={{ margin: 0, backgroundColor: 'inherit', fontSize: 12, wordWrap: 'break-word', wordBreak: 'break-all'}}>
				<code className={`language-${language}`} style={{whiteSpace:'break-spaces' ,  wordWrap: 'break-word', wordBreak: 'break-all'}}>{code}</code>
			</pre>
		</>
	)
}

export default Sandbox
