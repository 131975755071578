import { useEffect, useCallback, useContext, useState } from 'react'
import {
	Typography,
	Box,
	Divider,
	Grid,
	Paper,
	Button,
	Table,
	TableRow,
	TableCell,
	TableHead,
	TableBody,
	TableContainer,
	IconButton,
	Chip
} from '@mui/material'
import {
	OpenInNew as OpenIcon,
	EditOutlined as EditIcon,
	DeleteForeverOutlined as DeleteForeverIcon
} from '@mui/icons-material'
//import { NavContext } from '../../context/NavContext'
import ApiGuide from './ApiGuide'
import ProductCard from '../../components/product/ProductCard'
import { useNavigate } from 'react-router-dom'
import { NavContext } from '../../context/NavContext'
import timeAgo from '../../helpers/timeAgo'

const ApiDash = (props) => {
	//const Nav = useContext(NavContext)
	const Navigate = useNavigate()
	const ApiList = props.data
	const [ guideOpen, setGuideOpen ] = useState(true)
	const { setSelectedApi } = useContext(NavContext) 
	console.log('PROPS: ', ApiList)

	const [ raised, setRaised ] = useState(false)
	
	useEffect(() => {
		setGuideOpen(!ApiList)
	}, [ApiList])

	return (
		<Grid>
			<Grid item sx={{p:3}}>
				<Typography variant="h4">Api summary</Typography>
				<Typography>Some Cool Analtytics for apis</Typography>
			</Grid>
			<Grid>
				<ApiGuide open={guideOpen}/>
			</Grid>
			{/*
			<Box sx={{p:3}}>
				<Grid>
					<Typography>Total income per month: </Typography>
					<Typography variant="h6">$ 100500 </Typography>
				</Grid>
			</Box>
			*/}
			<TableContainer>
				<Table aria-label="Endpoints list">
					<TableHead sx={{ backgroundColor: '#fdfdfd'}}>
						<TableRow>
							<TableCell align="left" sx={{pl:3}}>API Title</TableCell>
							<TableCell align="right">Org</TableCell>
							<TableCell align="right">Income</TableCell>
							<TableCell align="right">Plans</TableCell>
							<TableCell align="right">Requests</TableCell>
							<TableCell align="right">Subscribers</TableCell>
							<TableCell align="right">Views</TableCell>
							<TableCell align="right">Latency</TableCell>
							<TableCell align="center" sx={{px:1}}>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{ApiList.length &&
						ApiList.map((item, index) => {
							console.log(item)
							var disabled = !item.published ? {color:'#ccc !important'}: {}
							if (index === 0) {console.log(JSON.stringify(item))}
							return (
								<TableRow key={index}>
									<TableCell align="left" sx={{display:'flex',flexWrap:'nowrap',pl:3, ...disabled}}>{item.title}</TableCell>
									<TableCell align="right" sx={{ ...disabled}}>{item.org}</TableCell>
									<TableCell align="right" sx={{ ...disabled}}>{item.stats.income}</TableCell>
									<TableCell align="right" sx={{ ...disabled}}>{item.plans && item.plans.map((i,index)=>{return (<Chip key={index} variant="outlined" size="small" label={i} sx={{ ...disabled}} />)})}</TableCell>
									<TableCell align="right" sx={{ ...disabled}}>{item.stats.requests}</TableCell>
									<TableCell align="right" sx={{ ...disabled}}>{item.subscribers && item.subscribers.length}</TableCell>
									<TableCell align="right" sx={{ ...disabled}}>{item.stats.clicks}</TableCell>
									<TableCell align="right" sx={{ ...disabled}}>{item.stats.latency}</TableCell>
									<TableCell size="small" align="right" width="1" sx={{backgroundColor:'#f9f9f9', px:1}}>
										<Box sx={{flexWrap:'nowrap', display:'flex'}}>
											<IconButton
												onClick={()=>{
													const url = `/dash/apiedit/${item.ownerId}/${item.code}`
													Navigate(url)
												}}
												>
												<EditIcon/>
											</IconButton>
										</Box>
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
				
				
			
			
		</Grid>
	)
}

export default ApiDash
