import { useState, useCallback, useContext, useEffect } from 'react'

import { Tabs, Tab, Typography, Box, Divider } from '@mui/material'

import {
	Link as RouterLink,
	useOutlet,
	useNavigate,
	Navigate,
	useParams
} from 'react-router-dom'

import RequestOverview from './RequestOverview'
import RequestStatistics from './RequestStatistics'
import RequestSettings from './RequestSettings'

import SchemaTools from '../../helpers/schematools'

import { NavContext } from '../../context/NavContext'

import ComponentOutlet from '../../components/template/ComponentOutlet'

const RequestDefinition = (props) => {
	const path = props.path
	const params = useParams()
	console.log(params)
	const { compareObjects } = SchemaTools()
	const { selectedRequest, setSelectedRequest, refresh, refreshCheck, selectData } = useContext(NavContext)
	const [ currentRequest, setCurrentRequest ] = useState({})
	const [ changesState, setChangesState ] = useState({})
	const [ data, setData ] = useState()

	const parts = [
		{
			path: 'overview',
			element: RequestOverview,
			disabled: false,
		},
		{
			path: 'settings',
			element: RequestSettings,
			disabled: false,
		},
		{
			path: 'statistics',
			element: RequestStatistics,
			disabled: true,
		},
	]

	const [partIndex, setPartIndex] = useState(0)

	const handleChange = (event, newValue) => {
		setPartIndex(newValue)
	}

	//SELECTEDAPP data to this state
	const attachFetched = useCallback(
		(values) => {
			const newdata = compareObjects(changesState, values)
			console.log('Request Definition. CompareObjects: ', newdata)
			console.log('attach fetched: ', values)
			setChangesState({ ...newdata })
		},
		[selectedRequest]
	)

	useEffect(() => {
		console.log('Request Definition. Selected Request : ', selectedRequest)
		if (!selectedRequest.date) {
			return
		}
		setData(selectData)
		setCurrentRequest(selectedRequest)
		attachFetched(selectedRequest)
	}, [selectedRequest, setCurrentRequest, attachFetched, selectData])

	
	return (
		<><Box sx={{position: 'sticky', top:0, background: '#fff', zIndex: '10000'}}>
			<Tabs value={partIndex} onChange={handleChange}>
				{parts &&
					parts.map((item, index) => (
						<Tab
							disabled={item.disabled}
							label={item.path}
							value={index}
							key={index + 1}
							to={{
								pathname: `/dash/requestedit/${params.user}/${params.product}/` + item.path,
							}}
							component={RouterLink}
						/>
					))}
			</Tabs>
			<Divider />
			</Box>
			<ComponentOutlet path={path} parts={parts} data={selectedRequest}/>
		</>
	)
}

export default RequestDefinition
