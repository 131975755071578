import {useState, useContext, useEffect, useCallback} from 'react'
import {
	Grid,
	Box,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Divider,
	Button
} from '@mui/material'
import ContestHeader from './Contest.header'
import InputFile from '../../../components/InputFile'
import csvjson from '../../../helpers/csvjson'
import SchemaTools from '../../../helpers/schematools'
import { AuthContext } from '../../../context/AuthContext'
import { useHttp } from '../../../hooks/http.hook'
import { NavContext } from '../../../context/NavContext'
import timeAgo from '../../../helpers/timeAgo'
import SwitchComponent from '../../../components/SwitchComponent'


const ContestSubmissions = (props) => {
	console.log("ContestSubmissions", props)
	const isSubscriber = !!props.data ? props.data.subscriber : false
	console.log("isSubscriber", isSubscriber)
	const solution = props.data && props.data.dataset && props.data.dataset.solution ? props.data.dataset.solution : {}
	const serverpath = "/api/contest"
	const initialState = {
		publicpercent: void 0,
		title: '',
		traindatalink: '',
		testdatalink: '',
		solution: '',
		sample: '',
		metric: '',
	}
	const [ fetchedState, setFetchedState ] = useState(initialState)
	const current = { ownerId: props.data.user, code: props.data.product }
	//const [ current, setCurrent ] = useState({})
	const [ List, setList ] = useState([])
	const [ values, setValues ] = useState([])
	const [ readonly, setReadonly ] = useState(true)

	const [ changeState, setChangeState ] = useState([])

	const { token } = useContext(AuthContext)
	const { refresh } = useContext(NavContext)
	const { request, error, clearError } = useHttp()
	const { compareObjects } = SchemaTools()

	const fetchData = async (value) => {
		try {
			const fetched = await request(
				`${serverpath}/mysubmissions/id?ownerId=${current.ownerId}&code=${current.code}`,
				'GET',
				null,
				{
					Authorization: `Bearer ${token}`,
				}
			)
			// console.log(fetched)
			// const data = compareObjects(initialState, {...fetched.ContestData},{keepold:true})
			// const dataset = compareObjects(initialState, {...fetched.ContestDataset})
			// const resultdata = compareObjects(initialState, {...data,...dataset})
			if (!!fetched && fetched.data) {
				setList(fetched.data)
				setChangeState(fetched.data)
			}
			//setCurrent(fetched.ContestData)
			// console.log("resultdata",resultdata)
		} catch (e) {
			console.log(e)
		}
	}

	const saveHandler = async (value) => {
		
		try {
			const result = await request(
				`${serverpath}/calculate/id?ownerId=${value.ownerId}&product=${value.code}`,
				'POST',
				value,
				{
					Authorization: `Bearer ${token}`,
				}
			)
			refresh()
		} catch(e) {
			console.log(e)
		}
	}

	const saveSettings = async (value) => {
		
		try {
			const result = await request(
				`${serverpath}/mysubmissions/id?ownerId=${current.ownerId}&code=${current.code}`,
				'POST',
				changeState,
				{
					Authorization: `Bearer ${token}`,
				}
				
			)
			refresh()
		} catch(e) {
			console.log(e)
		}
	}


	const validateSubmission = async (file) => {
		try {
			var error = ''
			
			const result = await csvjson(file)
			var submission = result.jsonArrayObj
			if (!result) {throw 'parse error'}
			if (!solution) {throw 'solution is not set'}
			if (solution.length !== submission.length) {
				return {error: 'Solution length mismatch'}
			}
			console.log(solution)
			var solutionkeys = Object.keys(solution.mapping)
			var submissionkeys = Object.keys(submission[0])
			for (var p in solution.mapping) {
				if (!submission[0].hasOwnProperty(p)) {
					return {error: 'Solution & Submission keys doesnt match'}
				}
			}
			if (solutionkeys.length !== submissionkeys.length) {
				return {error: 'Solution & Submission keys doesnt match'}
			}
			// console.log("\n validation \n")
			// console.log("result: ", result)
			// console.log("current:", fetchedState)
			return result
		} catch(e) {
			console.log(e)
			var result = {error:e}
			return result
		}
	}

	const changeHandler = async (event) => {
		try {
			if (!event.target.files.length) {
				throw 'no file selected'
			}
			const result = await validateSubmission(event.target.files[0])
			if (result.error) {
				return alert(result.error)
			}
			await saveHandler({...result,...current,filename:event.target.files[0].name})
			setValues({...values, sandboxsolution: event.target.files[0], data: result.jsonArrayObj})
			setList([...List, {name: event.target.files[0].name}])
			//console.log(result.jsonArrayObj)
		} catch(e) {
			//console.log(e)
		}
	}

	const handleEdit = (value) => {
		if (value == false) {
			saveSettings()
		}
		//console.log(value)
		setReadonly(!readonly)
	}
	const handleChange = (value) => {
		//console.log(Object.keys(value)[0])
		setChangeState(changeState.map((item)=>{
			if (item.id == Object.keys(value)[0]) {
				return {...item, benchmark: value[Object.keys(value)[0]]}
			} else {
				return {...item, benchmark: false}
			}
		}))
		// console.log(changeState)
		// console.log(value)
	}

	const productData = props.data
	//console.log("ContestSubmissions :", props)
	useEffect(() => {
		if (!!current.ownerId && !!current.code && isSubscriber) {
			fetchData()
		}
	}, [])

	return (
		<>{!isSubscriber ? 
			<Grid item xs={12} md={4} padding={3}>
				<Typography variant="body2">You must accept rules to submit your solution</Typography>
			</Grid>
			:
			<Grid container>
				<Grid item md={12} xs={12} sx={{ p: 3 }}>
					<InputFile 
							name = "sandboxsolution"
							label = "Upload submission"
							values = {values}
							changeHandler = {changeHandler}
						/>
					<Box paddingBottom={3}/>
					<Table>
						<TableHead>
							<TableRow sx={{borderBottom:'2.2px solid',borderColor:'#eee'}}>
								<TableCell align="left"><Typography variant="body2">Name</Typography></TableCell>
								<TableCell align="right"><Typography variant="body2">Public score</Typography></TableCell>
								<TableCell align="right"><Typography variant="body2">Date</Typography></TableCell>
								<TableCell align="right" sx={{display:'flex',justifyContent:'end',alignItems:'center',flexDirection:'row'}}>
									<Typography variant="body2">Benchmark</Typography>
									<Button onClick={() => {handleEdit(readonly)}} variant="outlined" size="small">{readonly ? 'edit' : 'save'}</Button>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
								{!changeState && !changeState.length ?
								<TableRow>
								<TableCell><Typography>no solutions yet</Typography></TableCell>
								</TableRow>
								: changeState.map((item,index) => (
									<TableRow key={index}>
										<TableCell align="left"><Typography variant="body2">{item.submissionName}</Typography></TableCell>
										<TableCell align="right"><Typography variant="body2">{item.publicscore}</Typography></TableCell>
										<TableCell align="right"><Typography variant="body2">{timeAgo(item.date)}</Typography></TableCell>
										<TableCell align="right" sx={{width:'1px'}}>
											<SwitchComponent {...{readonly,checked:!!item.benchmark,changeState:handleChange,name:item.id,label:{on:'',off:'',caption:false}}}/>
										</TableCell>
									</TableRow>
								))
								}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
			}
		</>
	)
}

export default ContestSubmissions