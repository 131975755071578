import { useState, useEffect, useContext } from 'react'
import {
	Grid,
	Box,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from '@mui/material'
// import ContestHeader from './Contest.header'
// import InputFile from '../../../components/InputFile'
// import csvjson from '../../../helpers/csvjson'
import SchemaTools from '../../helpers/schematools'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'
import { useHttp } from '../../hooks/http.hook'
import timeAgo from '../../helpers/timeAgo'
import {isEqual} from 'lodash'

const ContestLeaderboard = (props) => {
	const NAV = useContext(NavContext)
	// console.log(NAV.selectedContest)
	const serverpath = "/api/contest/owner"
	const initialState = {
		publicpercent: void 0,
		title: '',
		traindatalink: '',
		testdatalink: '',
		solution: '',
		sample: '',
		metric: '',
	}
	const productData = props.data
	const { token } = useContext(AuthContext)
	const { request, error, clearError } = useHttp()
	const { compareObjects } = SchemaTools()
	const [ fetchedState, setFetchedState ] = useState(initialState)
	const [ current, setCurrent ] = useState()
	//const current = { ownerId: props.data.user, code: props.data.product }

	const fetchData = async (value) => {
		try {
			const fetched = await request(
				`${serverpath}/${value.ownerId}/${value.code}/overview`,
				'GET',
				null,
				{
					Authorization: `Bearer ${!!token ? token : ''}`,
				}
			)
			//console.log(fetched)
			// const data = compareObjects(initialState, {...fetched.ContestData},{keepold:true})
			// const dataset = compareObjects(initialState, {...fetched.ContestDataset})
			// const resultdata = compareObjects(initialState, {...data,...dataset})
			
			console.log("FETCHED STATE: ", isEqual(fetchedState,fetched.data))
			if (!!fetchedState && !!fetched.data && !isEqual(fetchedState,fetched.data)) {
				console.log("ContestLeaderboard FETCH",fetched)
				setFetchedState(fetched.data)
				//setCurrent(fetched.ContestData)
				setCurrent(value)
			}
			
			
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		
		if (!!NAV.selectedContest && NAV.selectedContest.ownerId && !current) {
				console.log("NAV.selectedContest: ", NAV.selectedContest.ownerId)
				fetchData(NAV.selectedContest)
		}
	}, [NAV.selectedContest, current])

	if (!current) {return (<></>)}
	return (
		<>
			<Grid container>
			<Grid item xs={12}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={{pl:3}}>#</TableCell>
							<TableCell>Participant</TableCell>
							<TableCell>Public Score</TableCell>
							<TableCell>Private Score</TableCell>
							<TableCell>Date</TableCell>
							<TableCell sx={{pr:3}}>Entries</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{!!fetchedState && !!fetchedState.length && fetchedState.map((item, index) => (
							<TableRow key={index}>
								<TableCell sx={{pl:3}}>{index + 1}</TableCell>
								<TableCell>{item.participant}</TableCell>
								<TableCell>{item.publicscore}</TableCell>
								<TableCell>{item.privatescore}</TableCell>
								<TableCell>{timeAgo(item.date)}</TableCell>
								<TableCell sx={{pr:3}}>{item.entries}</TableCell>
							</TableRow>
						))
						}
					</TableBody>
				</Table>
			</Grid>
			</Grid>
		</>
	)
}

export default ContestLeaderboard