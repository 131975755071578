import { useState, useEffect } from 'react'
import {
	Box,
	Button,
	Divider,
	TextField,
	Typography,
	Collapse,
} from '@mui/material'

import * as Yup from 'yup'
import { Formik, Form } from 'formik'

const AccountEmailChange = (props) => {
	const email = props.email
	const handleSubmitEmail = props.handleSubmitEmail

	const [emailReadonly, setEmailReadonly] = useState(true)
	const initialstate = { email: '', password: '' }
	const [valuesState, setValuesState] = useState(initialstate)

	const handleSubmit = (values) => {
		handleSubmitEmail(values)
	}

	useEffect(() => {
		if (!!email) {
			setValuesState({ ...initialstate, email })
		}
	}, [email])

	return (
		<>
			<Formik
				initialValues={valuesState}
				enableReinitialize
				validationSchema={Yup.object().shape({
					email: Yup.string()
						.min(3)
						.max(40)
						.email('Must be a valid email')
						.required('Необходимое поле')
						.test( function (value) {
							return value == email ? 
							this.createError({
								message: `Тот же email ${value}`,
								path: 'email',
							}) : true
						}),
					password: Yup.string()
						.min(6, 'Min ${min}')
						.max(50, 'Max ${max}')
						.required('Введите пароль'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					handleSubmit(values, { setSubmitting })
					setSubmitting(false)
				}}
			>
				{({
					errors,
					handleBlur,
					handleSubmit,
					handleChange,
					isSubmitting,
					touched,
					resetForm,
					values,
				}) => (
					<Form autoComplete="off">
						<Box>
							<TextField
								fullWidth
								disabled={emailReadonly}
								error={Boolean(touched.email && errors.email)}
								helperText={!!errors.email && errors.email}
								onChange={handleChange}
								onBlur={handleBlur}
								name="email"
								label="Email"
								value={values.email}
							/>
						</Box>
						<Collapse in={!emailReadonly}>
							<TextField
								fullWidth
								disabled={emailReadonly}
								error={Boolean(touched.password && errors.password)}
								helperText={!!errors.password && errors.password}
								onChange={handleChange}
								onBlur={handleBlur}
								name="password"
								label="Введите пароль"
								sx={{ mt: 1 }}
								value={values.password}
							/>
						</Collapse>
						<Box
							sx={{
								py: 1,
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
							}}
						>
							<Button
								fullWidth
								variant="outlined"
								type="button"
								sx={!emailReadonly ? { display: 'none' } : {}}
								onClick={() => {
									setEmailReadonly(false)
								}}
								children="Изменить"
							/>
							<Button
								fullWidth
								variant="outlined"
								sx={emailReadonly ? { display: 'none' } : {}}
								type="submit"
								children="Сохранить"
							/>
							<Button
								fullWidth
								variant="outlined"
								sx={emailReadonly ? { display: 'none' } : {}}
								type="button"
								onClick={() => {
									resetForm()
									setEmailReadonly(true)
								}}
								children="Отмена"
							/>
						</Box>
						<Box sx={{ pb: 3, pt: 1 }}>
							<Divider />
						</Box>
					</Form>
				)}
			</Formik>
		</>
	)
}

export default AccountEmailChange
