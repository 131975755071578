import { useEffect, useContext, useState, useCallback } from 'react'

import { Outlet, useOutlet, useNavigate, useParams } from 'react-router-dom'

import { useHttp } from '../../hooks/http.hook'
import { NavContext } from '../../context/NavContext'
import { AuthContext } from '../../context/AuthContext'

import { Box } from '@mui/material'

import ContestDash from './ContestDash'
import { isEqual } from 'lodash'


const ContestEditor = () => {
	console.log("CONTEST EDITOR")
	const currentPart = 'contestedit'
	const editorpath = '/dash/' + currentPart
	const serverpath = '/api/contest/owner'
	const currentOutlet = useOutlet()
	const navigate = useNavigate()
	const { request } = useHttp()
	const { token, owner } = useContext(AuthContext)
	const NAV = useContext(NavContext)
	const [ unfilteredData, setUnfilteredData ] = useState({})
	const [ initialized, setInitialized ] = useState(0)
	const params = useParams()

	
	if (!!currentOutlet && params) {
			const { user, product } = params
			const candidate = !!NAV.selectData.filterdata ? NAV.selectData.filterdata.find((i)=> i.ownerId==user && i.code==product) : null
			NAV.setSelectedContest(candidate)
	}


	const fetchData = useCallback(async () => {
		console.log("---- FETCH DATA ----")
		try { 
			const fetched = await request(serverpath, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})
			const { data, categories } = fetched
			const filterdata = data.map((item) => ({
				_id: item._id,
				title: item.title,
				ownerId: item.ownerId,
				code: item.code,
				orgowner: item.orgowner,	
			}))
			NAV.setData({ filterdata, categories })	
			setUnfilteredData(data)
		} catch (e) {
			console.log("---- ERROR FETCH DATA ----")
			NAV.setData({})
			setUnfilteredData({})
			console.log(e)
		}
	}, [token, request])

	const ProductCheck = (e) => {
		if (e.user !== owner) {
			return { ok: false, data: e, message: ` ${currentPart}: You are not an onwer `}
		} else {
			return { ok: true, data: e, message: ` ${currentPart}: Welcome back master `}
		}
	}

	const fillSelectedData = useCallback(
		(value) => {
				console.log(`--- useCallback [NAV.selectedContest, unfilteredData] ---`,value)
				NAV.setSelectedContest(value)
		},
		[NAV.selectedContest]
	)

	useEffect(() => {
		
		if (unfilteredData.length && NAV.selectedContest && NAV.selectedContest.ownerId) {
			
			let currentUnfiltered = unfilteredData.find(
				(item) => item._id === NAV.selectedContest._id
			)
			console.log(">>>> fillSelectedData")
			fillSelectedData(currentUnfiltered)
		}
	}, [NAV.selectedContest, unfilteredData])

	const initialsetselected = (value) => {
			if (initialized === 0) {
				setInitialized(1)
				console.log(`${currentPart} INITIALIZED`)
				NAV.setSelectedContest(value)
			}
		}

	useEffect(() => {
		
		if (!currentOutlet) {return}
		if (initialized === 1) {return}
		if (!!params.user) {
			const result = ProductCheck(params)
			if (!result.ok) {
				console.log(`--- RESULT NOT OK ---`)
				return navigate(editorpath)
			} else {
				console.log(`--- OK RESULT ---`)
				const current =
				NAV.selectData.filterdata &&
				NAV.selectData.filterdata.find((item, index) => {
						if (
							item.ownerId === result.data.user &&
							item.code === result.data.product
						) {
							return item
						}
					})
				if (!!current) {initialsetselected(current)}
			}
		}
	}, [ ProductCheck, currentOutlet, fillSelectedData ])

	useEffect(() => {
		if (!!NAV.selected) {
			console.log("---- [NAV.selected, fetchData, NAV.refreshCheck] ----")
			fetchData()
		}
	}, [NAV.selected, fetchData, NAV.refreshCheck])

	useEffect(() => {
		console.log("---- [] ----")
		NAV.setnav(currentPart)
	},[])

	if (!NAV.selected) {return (<></>)}

	if (!currentOutlet && !!unfilteredData.length) {return (
		<Box sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
			<ContestDash data={unfilteredData.map(item => (
				{
					title:item.title,
					ownerId: item.ownerId,
					code: item.code,
					...item,
				}
				)).filter(i => !!i)} />
		</Box>
	)}

	return (
		<Box sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
				<Outlet />
		</Box>
	)
}

export default ContestEditor