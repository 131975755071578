import { useEffect, useContext, useState, useCallback } from 'react'

import { Outlet, useOutlet, useNavigate, useLocation, useParams } from 'react-router-dom'

import { useHttp } from '../../hooks/http.hook'
import { NavContext } from '../../context/NavContext'
import { AuthContext } from '../../context/AuthContext'

import { Box } from '@mui/material'

import ApiDash from './ApiDash'


const ApiEditor = (props) => {
	const currentPart = 'apiedit'
	const editorpath = '/dash/' + currentPart
	const serverpath = '/api/product/owner'
	const currentOutlet = useOutlet()
	const location = useLocation()
	const params = useParams()
	const navigate = useNavigate()
	const { request } = useHttp()
	const { token, owner } = useContext(AuthContext)
	const NAV = useContext(NavContext)
	const [unfilteredData, setUnfilteredData] = useState({})
	const [initialized, setInitialized] = useState(0)

	if (!!currentOutlet && params) {
			const { user, product } = params
			const candidate = !!NAV.selectData.filterdata ? NAV.selectData.filterdata.find((i)=> i.ownerId==user && i.code==product) : null
			NAV.setSelectedApi(candidate)
	}

	const fetchData = useCallback(async () => {
		try {
			const fetched = await request(serverpath, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})
			const { data, categories } = fetched
			const filterdata = data.map((item) => ({
				_id: item._id,
				title: item.title,
				ownerId: item.ownerId,
				code: item.code,
				orgowner: item.orgowner,
			}))
			NAV.setData({ filterdata, categories })
			setUnfilteredData(data)
		} catch (e) {
			NAV.setData({})
			console.log(e)
		}
	}, [token, request])

	const ProductCheck = (e) => {
		if (e.user !== owner) {
			return { ok: false, data: e, message: ` ${currentPart}: You are not an onwer `}
		} else {
			return { ok: true, data: e, message: ` ${currentPart}: Welcome back master `}
		}
	}

	const fillSelectedData = useCallback(
		(value) => {
			NAV.setSelectedApi(value)
		},
		[NAV.selectedApi]
	)

	useEffect(() => {
		if (unfilteredData.length && NAV.selectedApi) {
			let currentUnfiltered = unfilteredData.find(
				(item) => item._id === NAV.selectedApi._id
			)
			fillSelectedData(currentUnfiltered)
		}
	}, [NAV.selectedApi, unfilteredData])

	const initialsetselected = useCallback(
		(value) => {
			if (initialized === 0) {
				setInitialized(1)
				console.log(`${currentPart} INITIALIZED`)
				NAV.setSelectedApi(value)
			}
		},
		[initialized]
	)

	useEffect(() => {
		if (!currentOutlet) {return}
		if (initialized === 1) {return}
		if (params && params.user) {
			const result = ProductCheck(params)
			if (!result.ok) {
				return navigate(editorpath)
			} else {
				const current =
					NAV.selectData.filterdata &&
					NAV.selectData.filterdata.find((item, index) => {
						if (
							item.ownerId === result.data.user &&
							item.code === result.data.product
						) {
							return item
						}
					})
				if (current) {initialsetselected(current)}
			}
		}
	}, [ProductCheck, currentOutlet, fillSelectedData, initialized])

	useEffect(() => {
		if (!token) {return}
		fetchData()
		NAV.setnav(currentPart)
	}, [NAV.selected, fetchData, NAV.refreshCheck, token])

	return (
		<Box sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
			{!currentOutlet && <ApiDash data={!!unfilteredData.length && unfilteredData.map(item => (
				//{title:item.title}
				item
				)).filter(i => !!i)}/>}
			<Outlet />
		</Box>
	)
}

export default ApiEditor
