import { TextField } from '@mui/material'
import { useEffect, useState } from "react"
import importRSDK, {rsdkList} from "./RSDK"

const LanguageSelector = ({setRsdk, code}) => {
	const noop = () => {}
	const langList = [{title:'',module:noop},...rsdkList]
	const [ readonly, setReadonly ] = useState(false)
	const [ languageSelected, setLanguageSelected ] = useState()

	const handleChange = (event) => {
		console.log(event) 
		setLanguageSelected(event.target.value)
		setRsdk(importRSDK(event.target.value))
	}

	useEffect(() => {
		const event = {target:{value:langList[1].modulename}}
		handleChange(event)
	},[code])

	return (
		<TextField
			sx={{p:1}}
			disabled = {readonly}
			fullWidth
			size="small"
			name="languageSelected"
			onChange={(event) => {
				handleChange(event)
			}}
			required
			select
			SelectProps={{ native: true }}
			value={languageSelected}
			variant="outlined"
		>
			{langList.map((option, index) => (
				<option key={index} value={option.modulename} disabled={index==0}>
					{option.title}
				</option>
			))}
		</TextField>
	)
}

 export default LanguageSelector