import { useState, useContext, useEffect } from 'react'
import {
	Grid,
	Typography,
	Box,
	Divider,
	Card,
	Paper,
	CardContent,
	CardHeader,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	Button,
} from '@mui/material'

import PlanEditor from './PlanEditor'
import PlansCard from '../../components/PlansCard'
import plansMock from '../../__mocks__/plansMock'

import SchemaTools from '../../helpers/schematools'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'

const Plans = () => {
	const initialState = plansMock
	const planInitialState = {
		code: '',
		title: '',
		shortdescription: '',
		longdescription: '',
		pricerequest: '',
		requestslimit: '',
		origin: '',
		enabled: null,
	}
	const [ state, setState ] = useState(initialState)
	

	const [ selectedPlan, setSelectedPlan ] = useState()
	const [ editorState, setEditorState ] = useState()
	const [ readonly, setReadonly ] = useState(true)
	const [ isSubmitting, setSubmitting ] = useState(false)

	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const { selectedApi, refresh, refreshCheck, setSelectedApi, selectData } = useContext(NavContext)
	const [ currentApi, setCurrentApi ] = useState()

	const [ changesState, setChangesState ] = useState(planInitialState)
	const { compareObjects } = SchemaTools()


	const fetchApiPlans = async (value) => {
		try {
			console.log("fetchApiPlans: ", value)
			setCurrentApi(value)
			const fetched = await request(
				`/api/product/owner/${value.ownerId}/${value.code}/plans`,
				'GET',
				null, 
				{
					Authorization: `Bearer ${token}`
				}
			)
			
			
			if (!!fetched && Object.keys(fetched).length) {
				console.log("Fetched: ", Object.values(fetched))
				const fetchedarray = Object.values(fetched)
				const stateMap = state.map((plan) => {
					return fetchedarray.find((fetched) => {
						return fetched.origin == plan.origin
					}) || plan
				}).filter(i => !!i)
				setState(stateMap)
			} else {
				console.log("Fetched: nothing")
			}
			
		} catch(e) {
			console.log(e)
		}
	}

	const saveChanges = async (value) => {
		try {
			console.log("saveChanges value: ", value)
			var newdata = compareObjects(changesState,value,{minified: true})
			console.log('compared changes to save: ', newdata)

			const data = await request(
				`/api/product/owner/${currentApi.ownerId}/${currentApi.code}/plans/create`,
				'POST',
				{ _id: currentApi._id, ...newdata},
				{ Authorization: `Bearer ${token}` }
			)

			console.log('Result Data : ', data)

			refresh()
			setSubmitting(false)
		} catch (e) {}
	}

	const createHandler = (value) => {
		console.log('PlanCard . createHandler:', value)
		setReadonly(false)
		setSelectedPlan({ ...value, exist: true })
	}

	const editHandler = (value) => {
		console.log('PlanCard . editHandler:', value)
		setReadonly(false)
		setSelectedPlan(value)
	}

	const cancelHandler = () => {
		setReadonly(true)
		setSelectedPlan()
	}

	const deleteHandler = () => {
		setReadonly(true)
		setSelectedPlan({ ...selectedPlan, exist: false })
	}

	const saveHandler = (values) => {
		console.log('saveHandler')
		setReadonly(true)
		saveChanges(values)
	}

	useEffect(() => {
		console.log('Plans. Selected Api : ', selectedApi)
		if (!selectedApi.date) {return}
		
		fetchApiPlans(selectedApi)
	}, [selectedApi.date, setCurrentApi, refreshCheck])
	

	return (
		<>
			<Box sx={{ p: 3 }}>
				<Typography variant="h5">Plans & Pricing</Typography>
			</Box>
			<Divider />
			<Grid >
				<Grid item xs={12}>
					<Collapse in={!readonly}>
						{!!selectedPlan && (
							<PlanEditor
								selectedPlan={selectedPlan}
								handlers={{
									deleteHandler,
									saveHandler,
									cancelHandler,
								}}
							/>
						)}
					</Collapse>
				</Grid>
				<Divider />
				<Grid container spacing={1} sx={{ p: 3 }}>
					{state.map((item, index) => (
						<Grid item xs={12} md={3} key={index}>
							<PlansCard
								plan={item}
								selectedPlan={item}
								editorState={editorState}
								handlers={{
									saveHandler,
									deleteHandler,
									createHandler,
									editHandler,
								}}
							/>
						</Grid>
					))}
				</Grid>
			</Grid>
		</>
	)
}

export default Plans
