import { useContext, useState, useEffect, useCallback, React } from 'react'
import { useNavigate } from 'react-router-dom'

import {
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemText,
	ListItemIcon,
	Divider,
	Fade,
	Paper,
	Box
} from '@mui/material'

import Popper from '@mui/material/Popper';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { NavContext } from '../context/NavContext'
import { useData } from '../hooks/userdata.hook'

const AccountSelector = () => {
	const theme = useTheme()
	const themedownMD = useMediaQuery(theme.breakpoints.down('md'))
	const navigate = useNavigate()
	const { fetchOrg } = useData()
	const { userdata, selectedOrg, setSelectedOrg, refreshCheck, setUserdata } = useContext(NavContext)

	const defaultOrg = [
		{
			title: 'Personal',
			path: '.',
			avatar: ''
		},
	]

	const [orgList, setOrgList] = useState(defaultOrg)
	const [selectedOrgState, setSelectedOrgState] = useState({
		index: 0,
		item: null,
	})

	
	const [anchorEl, setAnchorEl] = useState(null);
	// const anchorElcontainer = document.getElementById('popcontainer')
	const [ anchorElcontainer, setAnchorElContainer ] = useState(document.getElementById('popcontainer'))
	const open = Boolean(anchorEl);
	const id = open ? 'transitions-popper' : undefined
	

	const handleOpen = (event) => {
		setAnchorElContainer(event.currentTarget.parentNode.parentNode.children[1])
		setAnchorEl(anchorEl ? null : event.currentTarget);
		
		//setAnchorElContainer(document.getElementById('popcontainer'))
	};


	const handleClick = useCallback(
		(item, index) => {
			setSelectedOrgState({ item, index })
			if (index !== 0) {
				setSelectedOrg(item)
			} else {
				setSelectedOrg({})
			}

			setAnchorEl(null)
		},
		[selectedOrgState]
	)

	useEffect(() => {
		setSelectedOrgState({index: 0,
			item: null})
		setOrgList(defaultOrg)
		if (userdata) {
			if (userdata.org) {
				console.log("userdata.org: ", userdata.org)
				setOrgList([...defaultOrg, ...userdata.org])
				setSelectedOrg(userdata.org.find((i)=>i._id == selectedOrg._id))
			} else {
				fetchOrg()
			}
		}
	}, [userdata.org])

	useEffect(() => {
		console.log("orgList: ", orgList)
			if (orgList.length > 1) {
				if (!!selectedOrg._id) {
					const index = orgList.findIndex((item) => (
						item._id == selectedOrg._id
					))
					setSelectedOrgState({ item: orgList[index], index })
				}
			}
			
	}, [selectedOrg,orgList])

	return (
		<Box sx={{zIndex:999, overflow:'hidden', display:'grid'}}>
			<Box sx={{borderBlockEnd: '1px solid', borderColor: '#eee'}}>
				<ListItem button onClick={handleOpen} aria-describedby={id}>
					<ListItemAvatar>
						<Avatar src={!!orgList.length && selectedOrgState && orgList[selectedOrgState.index].avatar} sx={{width:'28pt',height:'28pt'}}>{!!orgList.length && selectedOrgState && orgList[selectedOrgState.index].title[0]}</Avatar>
					</ListItemAvatar>
					<ListItemText primary={!!orgList.length && orgList[selectedOrgState.index].title} />
					<ListItemIcon sx={{ justifyContent: 'flex-end' }}>
						{!!anchorEl ? <ExpandLess /> : <ExpandMore />}
					</ListItemIcon>
				</ListItem>
			</Box>
			<Box sx={{ zIndex: 9000 }} id='popcontainer'>
			<Popper
				style={{ zIndex: 9999, width: '255px',background:'#fff', overflow:'auto', display:'grid', height:'calc(100% - 60px)'}}
				id={id}
				open={open}
				anchorEl={anchorEl}
				transition={open}
				container={anchorElcontainer}
			>
				{({ TransitionProps }) => (
					
					<Fade {...TransitionProps} timeout={250}>
						<Box sx={{overflow:'auto',display:'grid'}}>
						<Box sx={{overflow:'auto'}}>
						<Paper square elevation={20} >
							{orgList.map((item, index) => {
								if (index !== selectedOrgState.index) {
									return (
										<ListItem
											key={index}
											button
											onClick={() => {
												handleClick(item, index)
											}}
											sx={{p:0.5,px:2,":hover":{"& .ava": {opacity: 1}}}}
										>
											<ListItemAvatar>
												<Avatar
													className='ava'
													src={!!orgList.length && orgList[index].avatar}
													sx={{fontSize:'10pt',width:'28pt',height:'28pt',border:'1px solid #eee',opacity:0.7}}>
														{item.title[0]}
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary={item.title} />
										</ListItem>
									)
								}
							})}
							
							
						</Paper>
						</Box>
						<Divider />
							<ListItem
								button
								onClick={() => {
									navigate('./org/addnew')
								}}
							>
								<ListItemAvatar>
									<Avatar sx={{ backgroundColor: 'inherit' }}>
										<AddCircleOutlineIcon color="primary" />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary={'Add new org'} />
							</ListItem>
						</Box>
					</Fade>
					
				)}
			</Popper>
			</Box>
			
			</Box>
	)
}

export default AccountSelector
