import { Box, Typography } from '@mui/material'

const RequestDash = () => {
	return (
		<>
			<Box sx={{ p: 3 }}>
				<Typography variant="h4">Request Dash</Typography>
				<Typography>Some Cool Analtytics for requests</Typography>
			</Box>
		</>
	)
}

export default RequestDash
