import {useState, useCallback} from 'react'

export const useMessage = () => {
	const [messageText,setMessageText] = useState({text:'',type:''})
		const setMessage = useCallback((text,type) => {
				setMessageText({text,type})
		}, [])
	return {
		setMessage,
		messageText
	}
}