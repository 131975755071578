
import { useState, useEffect } from 'react'
import menuList from '../__mocks__/accountmenu'
import PopupMenu from './PopupMenu'

const PopupAccount = ({user,actions}) => {
	const list = menuList.map((item,index) => ({...item, href: item.basepath + item.pathname + (item.pathname == 'user' ? '/' + user.ownerId : '')}))

	const [ popIcon, setPopicon ] = useState('')
	
	useEffect(() => {
		if (!!user.firstName) {
			setPopicon(user.firstName.charAt(0).toUpperCase())
		}
	}, [user, setPopicon])

	return (
		<>
			<PopupMenu 
				list={list}
				icon={popIcon}
				actions={actions}
				hideBadge={true}
			/>
		</>
	)
}

export default PopupAccount