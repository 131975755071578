import { useState, useCallback, useContext, useEffect } from 'react'
import { Link as RouterLink, Navigate } from 'react-router-dom'

import {
	Grid,
	Box,
	Avatar,
	Typography,
	Tabs,
	Tab,
	Button
} from '@mui/material'

import { useParams } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import { NavContext } from '../context/NavContext'
import { useHttp } from '../hooks/http.hook'
import Preloader from '../components/Preloader'

import UserApis from './UserView/UserApis'
import UserApps from './UserView/UserApps'
import UserOverview from './UserView/UserOverview'
import UserRequests from './UserView/UserRequests'

import { Occupation } from '../__mocks__/Occupation'

import ComponentOutlet from '../components/template/ComponentOutlet'




const UserView = (props) => {
	const path = props.path
	const params = useParams()
	let { id, user } = params
	console.log(id + ' . ' + user)

	const { occupationList, getOccupationLabel } = Occupation()
	const { token } = useContext(AuthContext)
	const { request } = useHttp()
	const [ userdetails, setDetails ] = useState()
	const { setnav } = useContext(NavContext)
	const [ partIndex, setPartIndex ] = useState(0)

	const parts = [
		{
			label: 'Overview',
			path: 'overview',
			element: UserOverview,
			disabled: false,
		},
		{
			label: 'APIs',
			path: 'api',
			element: UserApis,
			disabled: false,
		},
		{
			label: 'APPs',
			path: 'app',
			element: UserApps,
			disabled: true,
		},
		{
			label: 'Requests',
			path: 'request',
			element: UserRequests,
			disabled: true,
		},
	]


	const fetchUser = useCallback(async () => {
		//console.log('BEFORE: ', page, pageSize)
		//console.log('CLIENT: ', token)
		try {
			const fetched = await request(`/users/id?id=${id}`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})

			console.log('Fetched: ', fetched)
			
			setDetails({...fetched, occupation: getOccupationLabel(fetched.occupation)})
			//setCount(Math.floor(fetched.count / pageSize) + 1)
		} catch (e) {}
	}, [token, request])

	useEffect(() => {
		setnav('user')
		fetchUser()
	}, [setDetails, fetchUser, setnav])

	const handleChange = (event, newValue) => {
		setPartIndex(newValue)
	}
	
	if (!userdetails) {
		return <Box sx={{display: 'flex', height:'100%'}}><Preloader /></Box>
	}
	return (
		<Grid container sx={{ display: 'flex', height: '100%' }}>
			<Grid
				item
				xs={12}
				sx={{
					backgroundColor: '#f3f3f3',
					minHeight: '200px',
					maxHeight: '300px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			><Box flexGrow="1" />
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column',
					}}
				>
					<Avatar
						sx={{
							height: 100,
							width: 100,
						}}
					>
						{userdetails.firstName.charAt(0).toUpperCase()}
					</Avatar>
					<Typography variant="h3" sx={{ alignText: 'center' }}>
						{!!userdetails &&
							userdetails.firstName + ' ' + userdetails.lastName}
					</Typography>
					{userdetails.occupation && userdetails.org &&
						<Box>
							<Typography>
								{userdetails.occupation + ' at ' + userdetails.org}
							</Typography>
						</Box>
					}
					{userdetails.country && userdetails.city &&
						<Box>
							<Typography>
								{userdetails.country + '. ' + userdetails.city}
							</Typography>
						</Box>
					}
					{userdetails.website && 
						<Box>
							<Typography onClick={()=>{window.open(userdetails.website, '_blank')}} component={Button} sx={{textTransform:'none'}}>
								{userdetails.website}
							</Typography>
						</Box>
					}
				</Box>
				<Box flexGrow="1" />
					<Box>
						
							<Tabs value={partIndex} onChange={handleChange} >
								{parts && parts.map((item, index) => { 
									if (!item.disabled) return (
									<Tab style={{textTransform: 'none'}}
										disabled={item.disabled}
										label={item.label}
										value={index}
										key={index}
										to={{
											pathname: `/dash/user/${params.id}/` + item.path,
										}}
										component={RouterLink}
									/>
								)}
								)}
							</Tabs>
						
					</Box>
			</Grid>
			<ComponentOutlet path={path} parts={parts} setPartIndex={setPartIndex} data={userdetails} />
		</Grid>
	)
}

export default UserView
