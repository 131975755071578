import {
  Link as RouterLink,
  matchPath,
  useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, ListItem, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles' 

const NavItem = ({
  basepath,
  href,
  target,
  icon: Icon,
  title,
  prevstate,
  disabled,
  links,
  end,
  endpath,
  submenu,
  small,
  big,
  color,
  ...rest
}) => {
  const location = useLocation();
  
  const theme = useTheme()
  
  const getPathResult = () => {
    const hrefarray = ("/" + basepath + "/" + href).toString().split(/\//)
    const urlarray = location.pathname.toString().split(/\//)

    var result = false
    if (typeof submenu === 'undefined') {
      result = (hrefarray.length == urlarray.length) && !!links && !!endpath && !!links.find(link => 
        link == urlarray[urlarray.length - 1]
      )
    } else {
      result = !!links && !!endpath && !!submenu && submenu == urlarray[urlarray.length - 2] && !!links.find(link => 
        link == urlarray[urlarray.length - 1]
      )
    }
    return result
  }

  const active = href ? !!matchPath({
    path: (!!basepath && !!href ? '/' + basepath + '/': '') + href,
    end: true
  }, location.pathname) ? true
  : 
  !!getPathResult()
  : false;

  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
      }}
      {...rest}
    >
      <Button
        fullWidth
        disabled={disabled}
        component={RouterLink}
        sx={{
          color: !!color ? color : !!small? '#555' : '#777',
          fontWeight: '400',
          fontSize: !!small && '10pt' || !!big && '13pt' || '12pt',
          justifyContent: 'flex-end',
          letterSpacing: 0,
          p:0,
          py: !!small ? 0.7 : 1.25,
          pl: 2.25,
          textTransform: 'none',
          borderRadius: '0',
          width: '100%',
          ":hover": {
            background: theme.palette.bgLightGray,
          },
          ...(active && {
            color: 'text.primary',
            background: theme.palette.bgLightGray,
            //border: '1px solid',
            borderInlineEnd: '3pt solid',
            borderColor: "#eee",
            borderInlineEndColor: '#555'
          }),
          '& svg': {
            mr: 1
          }
        }}
        
        target = {target == '_blank' ? target="_blank" : '_self' }
        to={{
          pathname: href,
          state: { from: prevstate },
        }}
      >
        {Icon ? (
          <Icon color='primary.main' fontSize="small" />
        )
        : ('')
        }
        <Box flexGrow="1"/>
        <span>
          {title}
        </span>
        <Box sx={{...(active ? {width:'17pt'} : {width:'20pt'})}}></Box>
      </Button>
      
    </ListItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
