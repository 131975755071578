import { Link as RouterLink, useNavigate, useBlocker, useLocation, useHref  } from 'react-router-dom'
import { Action } from 'history'

import { useContext, useEffect, useState} from 'react'

import { AuthContext } from '../context/AuthContext'
import { NavContext } from '../context/NavContext'
import { useHttp } from '../hooks/http.hook'
import { useMessage } from '../hooks/message.hook'


import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import {
	Box,
	Button,
	Container,
	Link,
	TextField,
	Typography,
} from '@mui/material'

//import FacebookIcon from '@mui/icons-material/Facebook'
//import FacebookIcon from 'src/icons/Facebook';
//import GoogleIcon from '@mui/icons-material/Google'


import MessageAlert from '../components/MessageAlert'

const LoginData = {
	ru: {
		title: "Вход",
		subtitle: "при помощи email",
		field: {
			email: {
				label: 'Адрес email',
				error: {
					valid: 'Некорректный адрес email',
					required: 'Обязательное поле'
				}
			},
			password: {
				label: 'Пароль',
				error: {
					required: 'Обязательное поле'
				}
			},
		},
		buttonsubmit: "Войти",
		signuptext: "Еще нет аккаунта?  ",
		signuplink: "Регистрация",
	},
	en: {
		title: "Sign in",
		subtitle: "with email address",
		field: {
			email: {
				label: 'Enter email',
				error: {
					valid: 'Must be valid email',
					required: 'Field is required'
				}
			},
			password: {
				label: 'Enter password',
				error: {
					required: 'Field is required'
				}
			},
		},
		buttonsubmit: "Login",
		signuptext: "Don&apos;t have an account?  ",
		signuplink: "Sign up",
	}
}


const LoginPage = (props) => {
	
	
	const navigate = useNavigate()
	const { state } = useLocation()
	const { from, pathname } = state || {}
	const auth = useContext(AuthContext)
	const { setnav } = useContext(NavContext)
	const { setMessage, messageText } = useMessage()
	const { request, error, clearError } = useHttp()
	const [ text, setText ] = useState(LoginData.ru)

	// const unsavedChanges = true;

	// useBlocker((transition) => {
	// 	const {
	// 		location, // The new location
	// 		action, // The action that triggered the change
	// 	} = transition;

	// 	console.log(action)

	// 	// intercept back and forward actions:
	// 	if (action === Action.Pop) {
	// 		navigate(-2)
	// 		//alert('intercepted!')
	// 	}

	// }, unsavedChanges);

	const loginHandler = async (formArr) => {
		try {
			console.log('DATA: ', formArr)
			const data = await request('/api/auth/login', 'POST',  {...formArr} )
			if (!data.error) {
				auth.login(data.token, data.userId, data.owner)
				if (!!from) {
					if (from == 'verify')
					return navigate("/")
				}
				if (!!pathname) {
					return navigate(pathname, {replace:true})
				} else {
					return navigate(-1,{replace:true})
				}				
			} else {
				
			}
		} catch (e) {
			console.log(e)
			// setMessage(e)
		}
	}

	useEffect(() => {
		console.log(error)
		setMessage(error,'error')
	}, [error, setMessage, clearError])

	// useEffect(() => {
	// 	loginHandler()
	// }, [formArr])

	const handleSubmit = (e,setSubmitting) => {
		setSubmitting(false)
		loginHandler(e)
		}

	useEffect(() => {
		setnav('login')
	}, [setnav])

	return (
		<>
			<MessageAlert {...{messageText,setMessage,callback: clearError}}/>
			<Box
				sx={{
					backgroundColor: 'background.default',
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					justifyContent: 'center',
				}}
			>
				<Container maxWidth="sm">
					<Formik
						initialValues={{
							email: '',
							password: '',
						}}
						validationSchema={Yup.object().shape({
							email: Yup.string()
								.email(text.field.email.error.valid)
								.max(255)
								.required(text.field.email.error.required),
							password: Yup.string().max(255).required(text.field.password.error.required),
						})}
						onSubmit={(values, { setSubmitting }) => {
							handleSubmit(values, setSubmitting )
						}}
					>
						{({
							errors,
							handleBlur,
							handleChange,
							handleSubmit,
							isSubmitting,
							touched,
							values,
						}) => (
							<Form>
								<Box sx={{ mb: 3 }}>
									<Typography color="textPrimary" variant="h2">
										{text.title}
									</Typography>
								</Box>
								<Box
									sx={{
										pb: 1,
										pt: 3,
									}}
								>
									<Typography
										align="center"
										color="textSecondary"
										variant="body1"
									>
										{text.subtitle}
									</Typography>
								</Box>
								<TextField
									error={Boolean(touched.email && errors.email)}
									fullWidth
									helperText={touched.email && errors.email}
									label={text.field.email.label}
									margin="normal"
									name="email"
									onBlur={handleBlur}
									onChange={handleChange}
									type="email"
									value={values.email}
									variant="outlined"
								/>
								<TextField
									error={Boolean(touched.password && errors.password)}
									fullWidth
									helperText={touched.password && errors.password}
									label={text.field.password.label}
									margin="normal"
									name="password"
									onBlur={handleBlur}
									onChange={handleChange}
									type="password"
									value={values.password}
									variant="outlined"
								/>
								<Box sx={{ py: 2 }}>
									<Button
										color="primary"
										disabled={isSubmitting}
										fullWidth
										size="large"
										type="submit"
										variant="outlined"
										
									>
										{text.buttonsubmit}
									</Button>
								</Box>
								<Typography color="textSecondary" variant="body1" align="center">
									{text.signuptext}
									<Link component={RouterLink} to="/register" variant="h6">
										{text.signuplink}
									</Link>
								</Typography>
							</Form>
						)}
					</Formik>
				</Container>
			</Box>
		</>
	)
}

export default LoginPage
