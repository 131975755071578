const plansMock = [
	{
		title: 'Free',
		shortdescription: 'for testing purposes',
		longdescription: '',
		pricemonth: '0',
		pricerequest: '0',
		exist: false,
		requestslimit: '10',
		origin: 'Free',
	},
	{
		title: 'Pro',
		shortdescription: 'Advanched ',
		longdescription: '',
		pricemonth: '10',
		pricerequest: '0.01',
		exist: false,
		requestslimit: '1000',
		origin: 'Pro',
	},
	{
		title: 'Business',
		shortdescription: 'Full access',
		longdescription: '',
		pricemonth: '100',
		pricerequest: '0.002',
		exist: false,
		requestslimit: '50000',
		origin: 'Business',
	},
	{
		title: 'Premium',
		shortdescription: 'Exclusive access',
		longdescription: '',
		pricemonth: '1000',
		pricerequest: '0.001',
		exist: false,
		requestslimit: '1000000',
		origin: 'Premium',
	}
]

export default plansMock