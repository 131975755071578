import { useEffect, useState } from 'react'
import {
	useLocation,
	useNavigate,
	useParams
} from 'react-router-dom';
import {
	Typography,
	Box,
	Grid,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	Checkbox,
	Button
} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import NavItem from './NavItem'
import { useTags } from '../hooks/tag.hook'

const SidebarMarket = (props) => {
	const { tags, getCategory  } = useTags()
	const Navigate = useNavigate()
	const theme = useTheme()
	const location = useLocation()
	const productType = location.pathname.split('/').pop()
	const filters = location.search.substring(1);

	const filterParser = (filtersString) => {
		try {
		const parsed = !!filtersString && JSON.parse('{"' + filtersString.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
		console.log("SidebarMarket filterParser: ",parsed)
		let resObj
		if (!!parsed.filters) {
			const filtersObject = JSON.parse(parsed.filters)
			if (!!Object.keys(filtersObject).length) {
				resObj = Object.keys(filtersObject).map((key) => {
					const res = JSON.parse(filtersObject[key])
					return {[key]:res}
				}).reduce((a,b) => ({...a,...b}),{})
			}
		}
		console.log("SidebarMarket filterParser: ", resObj)
		return resObj
		} catch(e) {
			console.log(e)
		}
	}

	const parsedFilters = filterParser(filters)

	console.log("SidebarMarket location FILTERS:", parsedFilters)
	console.log("SidebarMarket location: ", location)
	
	const [ selectedIndex, setSelectedIndex ] = useState({})
	
	const handleClick = (index) => {
			setSelectedIndex({...selectedIndex,[index]:!selectedIndex[index]})
	}

	const MarketLinks = [
		{
			title: "API Решений",
			href: "marketplace/solutions",
			disabled: false
		},
		{
			title: 'Организации',
			href: 'marketplace/organizations',
			disabled: false,
		},
		{
			title: 'Конкурсы',
			href: 'marketplace/contests',
			disabled: true
		},
		{
			title: 'Запросы',
			href: 'marketplace/requests',
			disabled: true
		}
	]

	const submenu = [
			{
				title: 'Категория',
				name: 'category'
			},
			{
				title: 'Происхождение',
				name: 'country'
			},
			{
				title: 'Вид оплаты',
				name: 'paytype'
			}
		]

	const params = {
		category: [],
		country: [
			{
				title: 'Россия',
				name: 'russia'
			},
			{
				title: 'Иностранный',
				name: 'outer'
			},
		],
		paytype: [
			{
				title: 'Подписка',
				name: 'subscription'
			},
			{
				title: 'Покупка',
				name: 'order'
			}
		]
	}

	const initialState = (params,clear) => {
		const initial = Object.keys(params)
		.map((cat)=>({[cat]:params[cat]
		.reduce((a,v)=>({...a,[v.name]:false}),{})}))
		.reduce((a,v)=>({...a,...v}),{})
		let result = initial
		if (!clear && !!initial && !!parsedFilters && !!Object.keys(parsedFilters).length) {
			result = Object.keys(initial)
			.map((key)=>({[key]:{...initial[key],...parsedFilters[key]}}))
			.reduce((a,b)=>({...a,...b}),{})
		}
		return result
	}

	const [ paramsState, setParamsState ] = useState()
	const [ filterState, setFilterState ] = useState()

	const handleSubmenuClick = (cat,param) => {
		console.log("handleSubmenuClick: ", cat, param)
		console.log("filterState: ", filterState)
		setFilterState({...filterState, [cat]: {...filterState[cat], [param]: !filterState[cat][param] }})
	}

	const handleFilterSubmit = () => {
		console.log("handleFilterSubmit: ", filterState)
		const filterStateTrimmed = Object.keys(filterState).reduce((a,key) => {
			const subobj = Object.keys(filterState[key]).reduce((a,subkey) => {
				if (!!filterState[key][subkey]) {
					return {...a, [subkey]: filterState[key][subkey]}
				} else {return a}
			},{})
			if (Object.keys(subobj).length === 0) {return a} else {return {...a,[key]:subobj}}
		},{})
		// console.log("filterStateTrimmed: ", filterStateTrimmed)

		const filterStateMap = Object.keys(filterStateTrimmed).reduce((a, b) => ({...a,[b]: JSON.stringify(filterStateTrimmed[b])}),{})
		// console.log("filterStateMap:", filterStateMap)
		const queryString = new URLSearchParams(!!Object.keys(filterStateMap).length ? {filters: JSON.stringify(filterStateMap)}:'')
		Navigate(`/marketplace/organizations?${queryString}`)
	}
	const handleFilterClear = () => {
		setFilterState(initialState(paramsState,true))
		Navigate(`/marketplace/organizations`)
		// console.log("handleFilterClear: ", filterState)
	}

	useEffect(() => {
		if (!!tags && !!tags.length) {
			// console.log("Sidebar TAGS: ", tags)
			setParamsState({...params, category: tags.map((t)=>({title:!!t.variations ? t.variations.ru : t.title ,name:t.title}))})
		}
	}, [tags])

	useEffect(() => {
		if (!!tags && !!paramsState) {
			setFilterState(initialState(paramsState))
		}
	}, [paramsState,location])
	
	useEffect(() => {
		const link = MarketLinks.find((link) => {
			return location.pathname == "/" + link.href
		})
		let result = {}
		if(submenu) {
			submenu.map((i,index) => {
				if (index == 0) {
					result[index] = true
				} else {
					result[index] = false
				}
				//console.log(index)
			})
		}
		//console.log(result)
		setSelectedIndex(result)
		// console.log("selectedIndex: ", selectedIndex)
	},[])

	const getBySublinkName = (name) => {
		return paramsState[name]
	}

	useEffect(() => {

	},[location])

	return (
		<>
		<Grid container>
			<Grid item xs={12}>
				{!!MarketLinks && MarketLinks.map((link,index) => {
					return (
						<Box key={index}>
						<NavItem key={index} onClick={()=>{props.onMobileClose()}} {...{...link,big:true}}/>
						</Box>
					)
				})
				}
			</Grid>
			
		</Grid>
		<Box sx={{ flexGrow: 1 }} />
		{!!productType  &&
		<>
		<Button 
			sx={{
				borderBottom: '1px solid #eee',
				p:0,
				py: 2,
				pl: 2.25,
				pr: '20pt',
				cursor:'pointer',
				justifyContent: 'flex-end',
				textTransform: 'none',
				borderRadius: '10',
				width: '100%',
			}} 
			onClick={handleFilterSubmit}
			>
				<FilterAltOutlinedIcon sx={{fontSize:'14pt',color:'#777'}} />
				<Box flexGrow="1"/>
				<Typography sx={{fontSize:'9pt',color:'#777'}}>Применить фильтры</Typography>
		</Button>
		<Box sx={{display:'grid',overflow:'auto'}}> 
			{!!filterState && submenu.map((sublink,index)=>{
							return (
								<Box key={index} sx={{display:'grid',overflow:'auto'}}>
									<ListItem
										sx={{borderBlockEnd: '1px solid', borderColor: '#eee',pr:'20pt'}}
										key={index}
										button
										onClick={() => {
											handleClick(index)
										}}
									>
										<ListItemIcon>
											{!!selectedIndex[index] ? <ExpandMore /> : <ExpandLess />}
										</ListItemIcon>
										<Box flexGrow={1}/>
											<ListItemText sx={{ textAlign: 'end', p:'0', color: '#777'}} primary={sublink.title} />
									</ListItem>
										<Box sx={{overflow:'auto'}}>
										<Collapse
											in={!!selectedIndex[index]}
											timeout="auto"
											unmountOnExit
										>
											<Box sx={{overflow:'auto',py:1,borderBottom:`1px solid ${theme.palette.borderColor}`,background:theme.palette.bgLightGray2}}>
											
											{!!getBySublinkName(sublink.name) && getBySublinkName(sublink.name).map((item,index)=> {
												// console.log(filterState[sublink.name][item.name])
												// console.log(sublink.name, item.name)
											return (
													<ListItem key={index} sx={{p:0.5,pr:'20pt',pl:'14pt',cursor:'pointer',':hover':{background:theme.palette.bgLightGray,'& .label':{color:'#555'},'& .checkbox':{'& .MuiSvgIcon-root': { fontSize: '14pt', color:'#555' }}}}} onClick={() => handleSubmenuClick(sublink.name,item.name)}>
														<Checkbox className="checkbox" checked={filterState[sublink.name][item.name]} sx={{p:0,'& .MuiSvgIcon-root': { fontSize: '14pt', color:'#777' } }}/>
														<Box flexGrow="1"/>
														<Typography className="label" sx={{fontSize:'9pt',color:'#777'}}>{item.title}</Typography>
													</ListItem>
											)})}
											</Box>	
										</Collapse>
										</Box>
								</Box>
							)}
							
						)}
		</Box>
		<Box height="50pt">
			<Button 
					sx={{
						p:0,
						py: 2,
						pl: 2.25,
						pr: '20pt',
						cursor:'pointer',
						justifyContent: 'flex-end',
						textTransform: 'none',
						//border: '1px solid #eee',
						borderRadius: '10',
						width: '100%',
					}} 
					onClick={handleFilterClear}
					>
						<FilterAltOffOutlinedIcon sx={{fontSize:'14pt',color:'#777'}} />
						<Box flexGrow="1"/>
						<Typography sx={{fontSize:'9pt',color:'#777'}}>Сбросить фильтры</Typography>
				</Button>
		</Box>
		</>
		}
		</>
	)
}	

export default SidebarMarket