var randomdate = (max,min) => {
var month = Math.floor(Math.random() * (max - min)) + min
var day = Math.round(Math.random() * 30)
var hour = Math.round(Math.random() * 24)
var min = Math.round(Math.random() * 60)
var sec = Math.round(Math.random() * 60)
var result = new Date(`${month}-${day}-2021 ${hour}:${min}:${sec}`)
return result.toString()
}

// for (var i=0; i<5; i++) {
// console.log(date(10,6).toISOString())
// }

export default randomdate