import {useEffect, useState, useContext, useRef} from 'react'
import { 
	Grid, 
	Typography,
	Divider,
	Button,
	Box
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'
import CategorySelector from '../../components/tags/selector.category'
import AvatarUpload from '../../components/avatar.upload'
import DescriptionEditor from './description.editor'
import SchemaTools from '../../helpers/schematools'

const OrgOverview = (props) => {
	const theme = useTheme()
	const { compareObjects } = SchemaTools()
	const { 
		ownerId, 
		code,
		title,
		avatar
	} = props.data
	const data = props.data
	const initialState = {
		description: {
			title: data.title,
			shortdescription: data.shortdescription,
			website: data.website,
			prod_info: data.prod_info
		},
		category: data.category
	}
	const serverpath = "/api/org"
	const cloudurl=`https://aiagweb.storage.yandexcloud.net/users/${ownerId}/orgs/${code}`

	console.log("OrgOverview: ", props)

	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const { refresh } = useContext(NavContext)
	const [ readonly, setReadonly ] = useState(true)
	const [ stateValues, setStateValues ] = useState()

	const DescriptionEditorRef = useRef()
	
	const fetchPostData = async (values) => {
		try {
			const categoryMap = stateValues.category.map((item)=>(item._id))
			const comparedDescription = compareObjects(initialState.description,values,{minified:true})
			const comparedCategory = compareObjects({category:initialState.category},{category: categoryMap},{minified:true})
			const obj = {...comparedDescription, ...comparedCategory}
			console.log("obj: ", obj)
			const response = await request(
				`${serverpath}/update/${ownerId}/${code}`,
				'POST',
				obj,
				{ Authorization: `Bearer ${token}` }
			)
			console.log("response:", response)
			refresh()
		} catch(e) {
			console.log(e)
		}
	}

	const handleEdit = () => {
		setReadonly(false)
	}
	const handleCancel = () => {
		setStateValues()
		setReadonly(true)
		DescriptionEditorRef.current.reset()
	}
	const handleSave = () => {
		DescriptionEditorRef.current.submit().then((values)=>{
			if (!!values) {
				fetchPostData(values).then(() => setReadonly(true))
			}
		})
	}

	const handlePreview = () => {
	}

	const handleGather = ({values,type}) => {
		setStateValues({...stateValues,[type]: values})
	}

	useEffect(() => {
		console.log("ORG OVERVIEW: AVATAR: ", avatar)
	}, [props])

	return (
		<>
			<Grid container direction="column">
				<Grid item sx={{position: 'sticky', top:49, background: '#fff', zIndex: '1000'}}>
					<Box sx={{p:3, display:'inline-flex', width:'100%',gap:1, borderBottom: `1px solid ${theme.palette.borderColorLight}`}}>
						<Typography variant="h5">{title}</Typography>
						<Box flexGrow="1"/>
						{readonly ?
							<Button variant="outlined" onClick={handleEdit}>Редактировать</Button>
							:
							<>
								<Button variant="outlined" onClick={handleSave}>Сохранить</Button>
								<Button variant="outlined" onClick={handleCancel}>Отмена</Button>
							</>
						}
					</Box>
				</Grid>
				
				<Grid item sx={{p:3}}>
					<Grid container spacing={3}>
						<Grid item xs={12} md='auto'>
							<AvatarUpload {...{serverpath,cloudurl,refresh,ownerId,code,readonly,avatar}}/>
						</Grid>
						<Grid item xs={12} md>
							<CategorySelector {...{data:props.data,readonly,stateValues,handleGather}}/>
						</Grid>
						<Grid item xs={12}>
							<DescriptionEditor ref={DescriptionEditorRef} {...{data:props.data,readonly,stateValues,handleGather}}/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

export default OrgOverview