import { useEffect, useState } from 'react'
import {
	Box,
	Collapse,
	Typography,
	Divider,
	Grid,
	Avatar
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const checkArray = [
	{
		title: 'Description',
		verified: false
	},
	{
		title: 'Timeline',
		verified: false
	},
	{
		title: 'Dataset',
		verified: false
	},
	{
		title: 'Evaluation',
		verified: false
	},
	{
		title: 'Prizes',
		verified: true
	},

]

const Checklist = (props) => {
	const theme = useTheme()
	const verifyList = props.verifyListState
	const getVerifyList = props.getVerifyList
	const [ open, setOpen ] = useState(false)
	const [ verifyListState, setVerifyListState ] = useState()

	const setVerifyList = (verifyList) => {
		var result = Object.keys(verifyList).map((item) => {
			if (item !== 'verified') {
				let verified = true
				for (var key in verifyList[item]) {
					verified = verified && Boolean(verifyList[item][key])
				}
				return {[item]: {...verifyList[item], verified}}
			}
		}).filter(i=>!!i)
		console.log("result: ", result)
		setVerifyListState(result)
	}

	const openHandler = () => {
		if (open==false) {
			getVerifyList()
		}
		setOpen(!open)
	}

	useEffect(() => {
		if (!!verifyList && typeof verifyList === 'object') {
			setVerifyList(verifyList)
			setOpen(true)
		}
	}, [verifyList])
	return (
		<Grid container>
			<Grid item xs={12}>
				<Grid flexItem sx={{display:'flex',alignItems:'center',cursor:'pointer'}} onClick={openHandler}>
					{!open ? <ExpandMore /> : <ExpandLess />}
					<Typography variant="body2">Completion Checklist</Typography>
				</Grid>
				<Divider />
				<Collapse in={open}>
					<Grid padding={3}>
					<Grid container spacing={3}>
						{!!verifyListState && verifyListState.map((item,index) => {
							const keyname = Object.keys(item)[0]
							const key = item[keyname]
							return (
							<Grid key={index} item xs>
								<Box sx={{
									border: `1px solid #eee`,
									borderRadius:1
									}}>
									<Box sx={{p:1,display:'flex',alignItems:'center',borderBottom:`1px solid #eee`}}> 
										<Typography variant="h6">{keyname}</Typography> 
										<Box flexGrow="1"/>
										<Avatar sx={{width:18,height:18,background:`${!!key.verified? '#8e8':'#e88'}`}}>
										{!!key.verified ? 
											<CheckIcon sx={{fontSize:"16px"}} /> 
											: 
											<ClearIcon sx={{fontSize:"16px"}} />}
										</Avatar>
									</Box>
									<Box>
									{!!key && Object.keys(key).map((name,index) => {
										if (name !== 'verified') {return (
											<Box key={index} sx={{
												display:'flex',
												p: 1,
												flexDirection: 'row',
												alignItems:'center',
												...(Object.keys(key).length > index+2 && 
												{borderBottom:`1px solid #eee`})}}
												>
												<Typography key={index} variant="body2">
													{name} 
												</Typography>
												<Box flexGrow="1"/>
												<Avatar sx={{width:18,height:18,background:`${!!key[name]? '#8e8':'#e88'}`}}>
												{!!key[name] ?
													<CheckIcon sx={{fontSize:"16px"}}/> 
													: 
													<ClearIcon sx={{fontSize:"16px"}}/>}
												</Avatar>
											</Box>
										)}
									})}
									</Box>
								</Box>
							</Grid>
						)}
						)
						}
					</Grid>
					</Grid>
					
				</Collapse>
			</Grid>
			<Divider />
		</Grid>
	)
}

export default Checklist