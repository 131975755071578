import * as Yup from 'yup';
import { Formik, Form, Field, useField } from 'formik';

import {
	Box,
	Button,
	Checkbox,
	Container,
	FormHelperText,
	Link,
	TextField,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Grid,
	MenuItem,
	Fade,
	Collapse,
	IconButton,
	FormControlLabel
} from '@mui/material';

const ResearchUserCard = (props) => {
	
	const {open,setOpen,callback = () => {},item} = props
	if (!setOpen || !callback || !item ) {return <></>}

	const handleClose = () => {
		setOpen({open:false})
	}

	const handleAddAdmin = () => {
		callback(item)
	}
	
	const handleSubmit = () => {
		callback(item)
		setOpen({open:false})
	}



	return (
		<Dialog 
			open={open} 
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<Box sx={{p:3, width:'500px'}}>
			
			<Box sx={{pb:3}}>
				{!item.iseditor ? 
				<Button variant="outlined" onClick={handleAddAdmin}>
					Сделать редактором
				</Button>
				:
				<Button variant="outlined" onClick={handleAddAdmin}>
					Снять редактора
				</Button>
				}
			</Box>
			
			{Object.entries(item).map(([key,val]) => (
				<Box key={key}>
				<Typography>{key}</Typography>
				<TextField 
					fullWidth
					value={val}
				/>
				</Box>
			))}
			</Box>
		</Dialog>
	)
}

export default ResearchUserCard