import { useEffect, useState, useContext } from 'react'
import {
	Box,
	Avatar,
	Button
} from '@mui/material'

import InputFile from './InputFile'

import { FileUploadOutlined as UploadIcon } from '@mui/icons-material'

import { useHttp } from '../hooks/http.hook'
import { AuthContext } from '../context/AuthContext'

const AvatarUpload = ({serverpath,cloudurl,refresh,ownerId,code,readonly,avatar}) => {
	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	
	const [ values, setValues ] = useState({avatarimg:''})

	const saveData = async (file) => {
		try {
			if (!file) {throw 'no file selected'}
			console.log("file: ", file)
			
			const fetched = await request(
				`${serverpath}/avatar/${ownerId}/${code}`,
				'POST',
				file,
				{
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/octet-stream',
					filetype: file.type,
					filename: 'avatar'
				}
			)
			refresh()
			
		} catch (e) {
			console.log(e)
		}
	}

	const changeHandler = async (event) => {
		try {
			
			if (!event.target.files.length) {
				throw 'no file selected'
			}
			const file = event.target.files[0]
			if (!file.type.match('image.*')) {
				throw 'incorrect file type'
			}
			await saveData(event.target.files[0])
		} catch(e) {
			alert(e)
		}
	}

	useEffect(() => {
		console.log("AVATAR USE EFFECT: ", avatar)
	}, [avatar])

	return (
		<>
			<InputFile
				name="avatarimg"
				values={values}
				changeHandler={changeHandler}
				filetype="image/*"
				disabled={readonly}
				content={
					<Box>
						<Box sx={{
							borderRadius: '50%',
							position: 'absolute',
							display:'grid',
							width:'100%',
							height:'100%',
							alignItems:'end',
							justifyContent:'right',
							zIndex:1000,
							transition: '0.5s',
							'& > *': {
								opacity:'0',
								transition: '0.5s'
							},
							':hover': {
								opacity:'1',
								background: 'rgba(255,255,255,0.5)',
								'& > *': {
									opacity:'1'
								},
								
							}
							}}
						>
							<Box sx={{background:'#fff',borderRadius:'50%',p:0.5,display:'flex',alignItems:'center',
								justifyContent:'center', width:'30pt',height:'30pt', border: '1px solid #eee'}}>
								<UploadIcon sx={{fontSize:'20pt'}}/>
							</Box>
						</Box>
						<Avatar
							alt="avatar"
							src={`${avatar}`}
							sx={{width:'120px',height:'120px'}}
							{...{imgProps:{sx:{objectFit:'contain'}}}}
						/>
					</Box>
				}
			/>
		</>
	)
}

export default AvatarUpload