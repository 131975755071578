const objectTools = () => {

	var objectToQueryString = function (obj) {
		var str = [];
		for (var p in obj)
			if (obj.hasOwnProperty(p)) {
			str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
			}
		return str.join("&");
		}
	return {
		objectToQueryString,
	}
}

export default objectTools