import { useState, useEffect, useContext, useCallback } from 'react'
import PopupMenu from './PopupMenu'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'

import { AuthContext } from '../context/AuthContext'
import { NavContext } from '../context/NavContext'
import { useHttp } from '../hooks/http.hook'
import { 
	Typography,
	Box
	} from '@mui/material'



const PopupNotification = ({user}) => {

	const getEventText = (e) => {
		switch (e.eventtype) {
			case "ORG_REGISTRATION":
				return {
					
					text: e.eventtext
				}
				break;
			case "STARTFOLLOW":
				return {
					text: e.eventtext
				}
			case "API_PUBLISHED":
				return {
					text: "Опубликовано в маркетплейс"
				}
			default:
				return {
					
					text: e.eventtitle
				}
				break;
		}
	}
	const [ list, setList ] = useState([])
	const [ popIcon, setPopIcon ] = useState('')
	const initsize = 10
	const [ size, setSize ] = useState(initsize)

	const { token, userId } = useContext(AuthContext)
	// const token = user.token
	const { request } = useHttp()

	const fetchFeed = useCallback(async () => {
		try {
			
			if (!token) {return}
			const fetched = await request(`/api/feed`, 'GET', null, {
				Authorization: `Bearer ${token}`,
				size: size,
			})

			console.log('Popup Fetched: ', fetched)
			if (!!fetched.data) {
				// setOrgdetails({...fetched.data})
				console.log(fetched.data)
				const events = fetched.data.map((e) => (
					{
						title: (
							<Box sx={{display:'flex',gap:1}}>
								<Typography variant="caption" sx={{fontWeight:'550'}}>{e.title}</Typography>
								<Typography variant="caption">
									{
										Object.values(getEventText(e)).join('. ')
										// (e.eventtext !== 'startfollow' ? e.eventtitle + '. ': '') + 
										// (e.eventtype !== "ADD_POST" ? e.eventtext : '')
									}
								</Typography>
							</Box>
						),
						href: e.eventlink
					}
				))
				setList(events)

			}
		} catch (e) {
			console.log(e)
		}
	},[token])

	// useEffect(() =>{
	// 	setSize(initsize)
	// },[])

	useEffect(async () => {
		if (!user || !token) {return}
		setPopIcon(
			
				<NotificationsNoneOutlinedIcon sx={{fontSize:'20pt'}}/>

		)
		fetchFeed()
		
	}, [token, setPopIcon])

	// useEffect(() =>{
	// 	fetchFeed()
	// },[size])

	const emptymessage = 'Пока нет уведомлений'
	
	return (
		<>
			<PopupMenu 
				list={list}
				icon={popIcon}
				emptymessage={!list.length && emptymessage}
				sx={{maxHeight:'300px'}}
				actions={[
					{title:"Ещё...",callback:()=>{setSize(size + initsize)}},
					{title:"Открыть страницу подписок",callback:()=>{}},
				]}
			/>
		</>
	)
}

export default PopupNotification