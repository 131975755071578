const methods = [
	{
		_id: '0',
		title: '	',
	},
	{
		_id: '1',
		title: 'GET',
	},
	{
		_id: '2',
		title: 'POST',
	},
	{
		_id: '3',
		title: 'PUT',
	},
	{
		_id: '4',
		title: 'DELETE',
	},
	{
		_id: '5',
		title: 'PATCH',
	},
]

export default methods