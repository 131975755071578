import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: '#f8f8f8',
      height: '100%',
      width: '100%'
    },
    a: {
      textDecoration: 'none',
      "&:visited": {
        color: "inherit",
      }
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    'h4': {
      margin: "0px 0px 0.35em",
      'font-family': "Roboto, Helvetica, Arial, sans-serif",
      'font-weight': 400,
      'font-size': "2.125rem",
      'line-height': "1.235",
      'letter-spacing': "0.00735em",
    },
    'h5': {
      margin: "0px 0px 0.35em",
      "font-family": "Roboto, Helvetica, Arial, sans-serif",
      'font-weight': "400",
      "font-size": "1.5rem",
      "line-height": "1.334",
      "letter-spacing": "0em"
    },

    
      '*::-webkit-scrollbar': {
        width: '0.28em'
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(240,240,240,1.1)',
      }
    
  }
}));

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
