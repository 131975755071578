import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { useEffect, useContext, useState,useCallback } from 'react'
import { useTheme } from '@mui/material/styles';
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { NavContext } from '../../context/NavContext'
import { AuthContext } from '../../context/AuthContext'

import * as Yup from 'yup';
import { Formik, Form, Field, useField } from 'formik';

import {
	Box,
	Button,
	Checkbox,
	Container,
	FormHelperText,
	Link,
	TextField,
	Typography,
	Grid,
	MenuItem,
	Fade,
	Collapse,
	IconButton,
	FormControlLabel,
	Tooltip
} from '@mui/material';

import {
	AddCircleOutlineOutlined as AddIcon,
	EditOutlined as EditIcon,
	DeleteForeverOutlined as DeleteIcon,
	LaunchOutlined as LaunchIcon,
	ListAltOutlined as ListIcon
} from '@mui/icons-material'


import { Alert } from '@mui/lab';
import Snackbar from '@mui/material/Snackbar';

import MessageAlert from '../../components/MessageAlert'
import {CheckboxField} from '../../components/CheckboxField.tsx'
import SurveyEditor from './SurveyEditor'
import ConfirmDialog from '../../components/ConfirmDialog'
import ResearchUserCard from './ResearchUserCard'

import shortText from '../../helpers/shortText'

const ResearchUsers = () => {
	const theme = useTheme()
	const navigate = useNavigate()
	const {settext, errortext} = useMessage()
	const { setMessage, messageText } = useMessage()
	const message = settext
	const { request, error, clearError } = useHttp()
	const { token } = useContext(AuthContext)
	const { setnav } = useContext(NavContext)
	
	const [ editor, setEditor ] = useState(false)
	const [ questions, setQuestions ] = useState([])
	const initialList = {listname:'',description:'',questions}
	const [ listState, setListState ] = useState(initialList)

	const [ setField, setSetField ] = useState(()=>{})
	const [ editorState, setEditorState ] = useState()

	const [ surveyList, setSurveyList ] = useState([])
	const [ refreshTrigger, setRefreshTrigger ] = useState(0)
	
	const [ confirmState, setConfirmState] = useState({open:true})
	
	const [ usersList, setUsersList ] = useState([])
	const [ userState, setUserState ] = useState({})

	const refresh = () => {
		console.log("refresh")
		setRefreshTrigger(Math.random())
		return
	}

	const fetchData = async () => {
		try {
			const fetched = await request(
				'/api/research/researchusers',
				'GET',
				null,
				{ Authorization: `Bearer ${token}` }
				)
			if (!!fetched && !!fetched.data) {
				console.log(fetched)
				setUsersList(fetched.data)				
			}
		} catch(e) {
			console.log(e)
			setMessage(e)
		}
	}

	const deleteData = async (item) => {
		setListState(initialList)
		try {
			const fetched = await request(
				'/api/research/deletelist',
				'POST',
				item,
				{ Authorization: `Bearer ${token}`}
				)
			refresh()
		} catch(e) {
			console.log(e)
			setMessage(e)
		}
	}

	const handleAddAdmin = async (item) => {
		try {
			console.log(item)
			const res = await request(
				'/api/research/whitelist',
				'GET',
				null,
				{ Authorization: `Bearer ${token}`, owner: item.owner }
			)
			console.log(res)	
		} catch (e) {
			console.log(e)
		}
	}

	const handleEdit = (user) => {
		setUserState({open:true,setOpen:setUserState,item:user,callback:handleAddAdmin})
	}

	const handleCreate = () => {
		setListState(initialList)
	}

	const handleDelete = (item) => {
		setConfirmState({open:true,setOpen:setConfirmState,callback:deleteData,item,validatename:'listname'})
	}

	useEffect(() => {
		fetchData()
		setnav('researchusers')
		return () => {}
	}, [setnav,refreshTrigger])

	return (
		<>
			<Grid container sx={{py:0}}>
			<Grid item xs={12}>
				<Box sx={{p:3}}>
							<Typography variant="h2" sx={{fontWeight:200,color:'#777'}}>Пользователи</Typography>
							<ConfirmDialog {...confirmState} />
							<ResearchUserCard {...userState} />
				</Box>
			</Grid>
			<Grid item xs display="flex" flexDirection="column" sx={{gap:0.5,p:3,pr:0}}>
					{!!usersList.length && usersList.map((item,index) => (
						<Box key={index} sx={{display:'flex',p:0.5,pl:3,width:'100%', borderRadius:1, border:'1px solid #ccc',alignItems:'center'}}>
							
								<Tooltip title={item.email} placement="bottom">
									<Typography sx={{width:'100%'}}>
										{shortText(item.email,100)}
									</Typography>
								</Tooltip>
							
							<Box sx={{display:'flex',flexWrap:'nowrap'}}>
								<Box>
									<IconButton 
										component={RouterLink} 
										to={{pathname:`/research/surveylist/${item._id}`}}
										target="_blank"
										>
											<ListIcon />
										</IconButton>
								</Box>
								<Box>
									<IconButton 
										component={RouterLink} 
										to={{pathname:`/research/survey/${item._id}`}}
										target="_blank"
										>
											<LaunchIcon />
									</IconButton>
								</Box>
								<Box>
									<IconButton onClick={() => {handleEdit(item)}}>
										<EditIcon />
									</IconButton>
								</Box>
							</Box>
						</Box>
					))
						
					}
					
			</Grid>
			</Grid>
		</>
	)
}

export default ResearchUsers