import { useEffect, useState, useContext, useCallback } from 'react'
import {
	Box,
	Divider,
	Grid,
	responsiveFontSizes,
	TextField,
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Button,
	Collapse,
} from '@mui/material'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SchemaTools from '../../helpers/schematools'
import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'
import ControlsComponent from '../../components/ControlsComponent'
import Channels from '../../pages/Channels/Channels'
import Preloader from '../../components/Preloader'
import shortText from '../../helpers/shortText'
import {
	Link as RouterLink,
	useNavigate,
} from 'react-router-dom'

let category = []

const RequestResponses = () => {
	const navigate = useNavigate()
	const { compareObjects } = SchemaTools()
	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const {
		selectedRequest,
		refresh,
		refreshCheck,
		setSelectedRequest,
		selectData,
	} = useContext(NavContext)
	const [readonly, setReadonly] = useState(true)
	const [isSubmitting, setSubmitting] = useState(false)
	const [selectedResponse, setSelectedResponse] = useState({
		description: '',
		deadline: '',
		cost: '',
	})
	const [currentRequest, setCurrentRequest] = useState({
		_id: '',
		title: '',
		ownerId: '',
		code: '',
		private: false,
		published: false,
		longdescription: '',
	})

	const [initialState, setInitialDataState] = useState({
		title: '',
		shortdescription: '',
		website: '',
		longdescription: '',
		description: '',
		category: '',
		terms: '',
		response: '',
		responses: [
			{ createdby: '', description: '', userdata: { firstName: '' } },
		],
	})

	const [changesState, setChangesState] = useState(initialState)

	const [channelsOpen, setChannelsOpen] = useState(false)

	const fetchRequestDetails = async (value) => {
		try {
			const fetched = await request(
				`/api/request/owner/${value.ownerId}/${value.code}`,
				'GET',
				null,
				{
					Authorization: `Bearer ${token}`,
					part: 'overview',
				}
			)

			const requestresponsedata = { ...fetched.ResponseData }
			const requestdetaildata = compareObjects(initialState, {
				...fetched.RequestDetailData,
			})
			const requestdata = compareObjects(
				initialState,
				{ ...fetched.RequestData },
				{ keepold: true }
			)
			const data = compareObjects(initialState, {
				...requestdata,
				...requestdetaildata,
				...requestresponsedata,
			})

			setChangesState(data)
			setInitialDataState(data)
			setCurrentRequest(fetched.RequestData)
			setSelectedResponse(data.responses[0])
			console.log('FETCHED REQUEST DATA :', requestresponsedata)
		} catch (e) {
			console.log(e)
		}
	}

	const OpenProfileHandler = (value) => {
		alert(JSON.stringify(value))
		navigate('/dash/user/' + value.ownerId)
	}

	useEffect(() => {
		setSelectedResponse()
		if (!selectedRequest.date) {
			return
		}
		fetchRequestDetails(selectedRequest)
		if (selectData.categories) {
			category = [{ _id: '', title: '' }, ...selectData.categories]
			console.log(category)
		}
	}, [selectedRequest])

	useEffect(() => {
		setChannelsOpen(false)
	}, [selectedResponse])


	const responseObject = () => {
		return (
			<Grid container sx={{ p: 1 }}>
				<Grid item xs={12} sx={{ p: 1 }}>
					<Typography sx={{ wordWrap: 'break-word' }}>
						{selectedResponse.description}
					</Typography>
				</Grid>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<TextField
							size="small"
							label="Deadline"
							fullWidth
							value={selectedResponse.deadline}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							size="small"
							label="Cost"
							fullWidth
							value={selectedResponse.cost}
						/>
					</Grid>
				</Grid>
			</Grid>
		)
	}
	if (changesState.title == '') {return <Preloader />}
	return (
		<>
			<Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
				<Box sx={{ p: 3 }}>
					<Typography variant="h4">
						Request Responses ({changesState.title}){' '}
					</Typography>
					<Typography>{changesState.shortdescription}</Typography>
				</Box>
				<Divider />
				<Grid container sx={{ display: 'flex', flexGrow: '1' }}>
					<Grid
						item
						xs={12}
						md={3}
						sx={{ borderRight: 1, borderColor: '#ccc' }}
					>
						{!changesState.responses.length ? (
							<Typography sx={{ p: 3 }}>No responses yet</Typography>
						) : (
							<List sx={{ m: 0, p: 1 }}>
								{changesState.responses.map((item, index) => (
									<ListItem
										sx={
											!!selectedResponse && selectedResponse == item
												? { border: 1, borderRadius: 1 }
												: {}
										}
										button
										key={index}
										onClick={() => {
											console.log(item)
											setSelectedResponse(item)
										}}
									>
										<ListItemText
											primary={
												!!item.userdata.name && item.userdata.name.length > 20
													? item.userdata.name.slice(0, 20) + '...'
													: item.userdata.name
											}
										/>
										{!!selectedResponse && selectedResponse == item && (
											<ListItemIcon sx={{ justifyContent: 'flex-end' }}>
												<ChevronRightIcon />
											</ListItemIcon>
										)}
									</ListItem>
								))}
							</List>
						)}
					</Grid>
					<Grid
						item
						xs={12}
						md={9}
						sx={{
							p: 0,
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
							flexGrow: '1',
						}}
					>
						{!selectedResponse ? (
							''
						) : (
							<Grid
								sx={{
									flexGrow: '1',
									display: 'flex',
									flexDirection: 'column',
									height: '100%',
								}}
							>
								<Grid container spacing={1} sx={{ p: 1, pb: 1 }}>
								<Grid item xs={12} md={4}>
										<Button 
											variant="outlined" 
											fullWidth sx={{ p: 1.5, textTransform:'none' }}
											component={RouterLink}
											to={{
												pathname: '/dash/user/' + selectedResponse.userdata.ownerId,
											}}
											target="_blank"
											
										>
											{'Открыть профиль ' + shortText(selectedResponse.userdata.name)}
										</Button>
									</Grid>
									<Grid item xs={12} md={4}>
										<Button variant="outlined" fullWidth sx={{ p: 1.5 }}>
											Назначить исполнителем
										</Button>
									</Grid>
									<Grid item xs={12} md={4}>
										<Button variant="outlined" fullWidth sx={{ p: 1.5 }}>
											Отклонить
										</Button>
									</Grid>
								</Grid>
								<Divider />
								{!channelsOpen && responseObject()}
								<Grid
									item
									xs={12}
									sx={{
										flexGrow: '1',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									{!!channelsOpen && (
										<Channels
											setChannelsOpen={setChannelsOpen}
											fixedMessage={responseObject}
											subject={
												{
													objectId: currentRequest._id,
													type:'requestresponse', 
													channelname: currentRequest.title, 
													recipient: selectedResponse.createdby
												}
											}
										/>
									)}
									<Divider />
									<Grid sx={{p:1}}>
									{!channelsOpen ? (
										<Button
											size="large"
											variant="outlined"
											fullWidth
											onClick={() => {
												setChannelsOpen(true)
											}}
										>
											Открыть чат
										</Button>
									) : (
										<Button
											size="large"
											variant="outlined"
											fullWidth
											onClick={() => {
												setChannelsOpen(false)
											}}
										>
											Закрыть чат
										</Button>
									)}
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Box>
		</>
	)
}

export default RequestResponses
