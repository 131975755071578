import {useState, useEffect, useContext, useCallback} from 'react'
import {
	IconButton, 
	Box,
	Button, 
	Typography,
	StepContent, 
	StepLabel, 
	Step, 
	Stepper,
	Grid,
	TextField,
	Divider,
	Collapse
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import DatePickerComponent from '../../components/DatePicker'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'

import randomdate from '../../helpers/randomdate'
import timeAgo from '../../helpers/timeAgo'

import StageEditor from './StageEditor'

import Preloader from '../../components/Preloader'

import TimelineView from '../Market/Contest/TimelineView'

import ContestDefinitionHeader from './ContestDefinitionHeader'

const disclaimer = `All deadlines are at 11:59 PM UTC on the corresponding day unless otherwise noted.
The competition organizers reserve the right to update the contest timeline if they deem it necessary.`

function getToday(time) {
	var date = new Date(Date.now())
	console.log(date.getMonth())
	var today = new Date(Date.now())
	today.setHours(...time)
	return today
}

function addMonths(date, months) {
	var result = new Date(date);
	result.setMonth(result.getMonth() + months);
	return result;
}

function validateDate(value) {
	try {
		value.map((item,index) => {
			try {
			var getDateValue = (date) => {
				var result = new Date(date)
				result.setHours(12,0,0,0)
				return result.valueOf()
			}
			var getRange = (start,end) => {
				var result = (end - start) / (1000 * 60 * 60 * 24)
				return result
			}
			var today = getDateValue(Date.now())
			var startdate = getDateValue(item.startdate)
			var enddate = getDateValue(item.enddate)
			var daysbetween = getRange(startdate,enddate)
			var passed = getRange(today,startdate)

			console.log("today:", today)
			console.log("startdate: ", startdate)
			console.log("enddate:",enddate)
			console.log("passed: ", passed)
			console.log("daysbetween: ", daysbetween)

			if (daysbetween < 7) {throw 'The stage must last at least a week.'}
			if (startdate < today) {throw 'Start date should not be in the past'}
			if (enddate < today) {throw 'end date must be greater than now'}
			if (startdate > enddate) {throw 'end date must be greater than start date'}
			if (index > 0) {
				var previousEnd = getDateValue(value[index - 1].enddate)
				var range = getRange(previousEnd,startdate)
				if (range < 0) {throw 'The start date of the stage must be greater than the end date of the previous one.'}
			}
			} catch(e) {
				const error = {error:e, stage:item.name}
				console.log(error)
				throw error
			}
		})
	} catch(e) {
		return e
	}
	
	console.log("validateDate: ", value)
	return {isOk: true}
}

const today = getToday([12,0,0,0])

const steps = [
	{
		title: 'Registration',
		description: 'Entry deadline. You must accept the competition rules before this date in order to compete.',
		startdate: randomdate(9,11),
		enddate: randomdate(9,11),
		closed: false,
	},
	{
		title: 'Prior test',
		description: 'This is the last day you may upload your results to be eligible for a prize.',
		startdate: randomdate(9,11),
		enddate: randomdate(9,11),
		closed: false,
	},
	{
		title: 'Unseen test',
		description: 'Second stage starts. We will release an unseen test dataset for prediction on this date.',
		startdate: randomdate(9,11),
		enddate: randomdate(9,11),
		closed: false
	},
	{
		title: 'Final results',
		description: '',
		startdate: randomdate(9,11),
		enddate: randomdate(9,11),
		closed: false
	}
]

const stages = [
	{
		title: 'Registration',
		name: 'registration',
		startdate: today,
		enddate: addMonths(today, 1),
		description: 'Entry deadline. You must accept the competition rules before this date in order to compete.'
	},
	{
		title: 'Prior test',
		name: 'priortest',
		startdate: addMonths(today, 1),
		enddate: addMonths(today, 2),
		description: 'This is the last day you may upload your results to be eligible for a prize.'
	},
	{
		title: 'Unseen data test',
		name: 'unseentest',
		startdate: addMonths(today, 2),
		enddate: addMonths(today, 3),
		description: 'Second stage starts. We will release an unseen test dataset for prediction on this date.'
	},
	{
		title: 'Final results',
		name: 'finalresults',
		startdate: addMonths(today, 3),
		enddate: addMonths(today, 4),
		description: 'description'
	},
]

const ContestTimeline = (props) => {
	const { selectedpart } = props
	const serverpath = "/api/contest"
	const theme = useTheme()
	const { token } = useContext(AuthContext)
	const { setnav, refresh, selectData, selectedContest, setSelectedContest, selectedOrg } = useContext(NavContext)
	const { request, error, clearError } = useHttp()
	const [ stageListState, setStageListState ] = useState(stages)
	const [ fetchedState, setFetchedState ] = useState()
	const [ selectedStage, setSelectedStage ] = useState(0)
	const [ editState, setEditStage ] = useState()
	const [ openEditor, setOpenEditor ] = useState(false)
	const [ current, setCurrent ] = useState()
	const [ isLoading, setIsLoading ] = useState(true)
	
	const fetchData = async (value) => {
		
		try {
			setIsLoading(true)
			const fetched = await request(
				`${serverpath}/timeline/id?ownerId=${value.ownerId}&code=${value.code}`,
				'GET',
				null,
				{
					Authorization: `Bearer ${token}`,
				}
			)
			if (!!fetched.data && !!fetched.data.length) {
				console.log("fetched", fetched.data)
				setFetchedState(fetched.data)
				setStageListState(fetched.data)
				
			} else {
				setStageListState(stages)

			}
			setIsLoading(false)
			setCurrent(value)
			
		} catch(e) {
			console.log(e)
		}
	}

	const saveData = useCallback(async (value) => {
		try {
			if (!!selectedContest && selectedContest.published) {throw 'is published'}
			const result = await request(
				`${serverpath}/timeline/id?ownerId=${current.ownerId}&code=${current.code}`,
				'POST',
				value,
				{
					Authorization: `Bearer ${token}`,
				}
			)
			fetchData(current)
			console.log("saveData result: ", result)
		} catch(e) {
			console.log(e)
		}
	},[current])

	const saveHandler = (value) => {
		
		if (!!value) {
			console.log("saveHandler: ", value)
			const newlist = stageListState.map((item)=>{
				if (item.name == value.name) {
					return value
				} else {
					return item
				}
			})
			const isValid = validateDate(newlist)
			if (!isValid.isOk && !!isValid.error) {
				setOpenEditor(true)
				setEditStage({...value, error:isValid})
			} else {
				setEditStage(value)
				setStageListState(newlist)
				saveData(newlist)
			}
			console.log("isValid: ", isValid)

			// console.log(newlist)
			// 
			
			// if (!!isValid) {
			// 	
			// }
		}
	}

	const handleEdit = (stage) => {
		console.log(stage)
		setOpenEditor(true)
		if (!!editState && !!editState.error) {
			console.log("Error: ", editState)
			console.log()
			setEditStage({...stage,error:editState.error})
		} else {
			setEditStage(stage)
		}
		setSelectedStage()
	}

	const handleSelectStage = (value) => {
		console.log("handleSelectStage: ", value)
		if (selectedStage == value) {setSelectedStage()} else {
		setSelectedStage(value)
		}
	}

	const isActive = (stage) => {
		//console.log(new Date(stage.startdate) < Date.now() &&  Date.now() < new Date(stage.enddate))
		return (new Date(stage.startdate) < Date.now()) &&  (Date.now() < new Date(stage.enddate))
	}


	const [activeStep, setActiveStep] = useState(0)
	const handleStep = (step) => {
		setActiveStep(step)
	}

	useEffect(() => {
		console.log("selectedContest", selectedContest)
		if (!selectedContest.date) {return}
		fetchData(selectedContest)
	}, [selectedContest])

	

	return (<>
		{!!isLoading ? <Preloader /> 
			:
			<>
			<ContestDefinitionHeader {...{selecteddata: selectedContest,selectedpart}} />
			<Grid container>
				<Grid item xs={12}>
					<TimelineView stageListState={stageListState}/>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<StageEditor open={openEditor} setOpenEditor={setOpenEditor} editState={editState} saveHandler={saveHandler}/>
				</Grid>
			</Grid>
			<Grid item container padding={3} spacing={3}>
				{!!stageListState && !!stageListState.length && stageListState.map((stage, index) => {
					return (
						<>
						<Grid item container borderBottom="1px solid #eee" 
									sx={
										!(selectedStage === index)
											? theme.itemButton
											: { ...theme.itemButton, ...theme.itemButton.active }
									}>
									
							<Grid item container>
								<Grid 
									container
									spacing={2}
									item
									xs
									onClick={() => handleSelectStage(index)}>
								<Grid item>
									<Typography variant="h6">{index + 1}</Typography>
								</Grid>
								<Grid item>
									<Typography variant="h6">{stage.title}</Typography>
								</Grid>
								<Grid item sx={{display:'flex',alignItems:'center'}}>
									{!!isActive(stage) ? 
									<Typography variant="caption">Active stage</Typography>
									: ''}
								</Grid>
								<Grid item flexGrow="1">
								</Grid>
								<Grid item container spacing={3} sx={{display:'flex',alignItems:'center'}} xs={4}>
									<Grid item xs={6}>
										<Typography variant="body2">
											Start:
										</Typography>
										<Typography variant="body2">
											{timeAgo(stage.startdate,true,true)}
										</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant="body2">
											End:
										</Typography>
										<Typography variant="body2">
											{timeAgo(stage.enddate,true,true)}
										</Typography>
									</Grid>
								</Grid>
								</Grid>
								{!!selectedContest && !selectedContest.published &&
								<Grid item>
									<Button variant="outlined" onClick={() => {handleEdit(stage)}}>Edit</Button>
								</Grid>
								}
							</Grid>
							<Grid item container xs={12}>
								<Collapse in={selectedStage === index}>
									<Box sx={{p:3}}>
										<Typography>{stage.description}</Typography>
									</Box>
								</Collapse>
							</Grid>
							<Divider/>
						</Grid>
						
						</>
					)
				})}
			</Grid>
			</>
			}
		</>
	)
}

export default ContestTimeline