import { useState } from 'react'

import {
	Box,
	Typography,
	Button,
	Divider
} from '@mui/material'

import { ExpandLess, ExpandMore } from '@mui/icons-material'


const StrategyGenerator = (props) => {
	const [ openState, setOpenState ] = useState(props.open)
	return (
		<>
		{!openState ?
			<Box fullWidth 
				sx={{m:0,p:0,pl:3,borderRadius:'0', justifyContent: "flex-start"}} 
				component={Button} onClick={()=>{setOpenState(true)}}>
				<ExpandMore />
				<Typography variant="caption">Открыть Генератор</Typography>
			</Box>
			:
			<Box fullWidth 
				sx={{m:0,p:0,pl:3,borderRadius:'0', justifyContent: "flex-start"}} 
				component={Button} onClick={()=>{setOpenState(false)}}>
				<ExpandLess />
				<Typography variant="caption">Закрыть Генератор</Typography>
			</Box>
			
		}<Divider />
		</>
	)
}

export default StrategyGenerator