export default function(props) {
	return (
`
import http.client

conn = http.client.HTTPSConnection("${props.hostname}")

payload = ${props.body}

headers = ${props.headers}

conn.request("${props.method}", "${props.path}", payload, headers)

res = conn.getresponse()
data = res.read()

print(data.decode("utf-8"))
`
)
}