const terms = `
ВСЕ ВЕБ-САЙТЫ AI-AGGREGATOR ПРЕДОСТАВЛЯЮТСЯ «КАК ЕСТЬ» И «КАК ДОСТУПНО» БЕЗ КАКИХ-ЛИБО ГАРАНТИЙ,
ЯВНЫХ ИЛИ ПОДРАЗУМЕВАЕМЫХ. Ai-aggregator особо отказывается от всех гарантий и условий любого рода, 
включая все подразумеваемые гарантии и условия товаропригодности, пригодности для определенной цели, 
прав, отсутствия патентных нарушений или дефектов, возможности непрерывного использования, 
а также от всех гарантий, которые могут подразумеваться любыми торговыми обычаями или деловой практикой. 
Ai-aggregator не гарантирует, что (а) Веб-сайты Ai-aggregator сайты будут отвечать вашим требованиям, 
(б) Веб-сайты Ai-aggregator будут функционировать бесперебойно и не будут содержать никаких вирусов или 
программных ошибок, а также (в) не гарантирует исправление программных ошибок. 
Любые устные или письменные рекомендации, предоставленные компанией Ai-aggregator или ее уполномоченными 
представителями, не создают и не будут создавать никаких гарантий. 
НЕКОТОРЫЕ ЮРИСДИКЦИИ НЕ РАЗРЕШАЮТ ИСКЛЮЧЕНИЕ ПОДРАЗУМЕВАЕМЫХ ГАРАНТИЙ. ЭТО ОЗНАЧАЕТ, ЧТО НЕКОТОРЫЕ 
ИЗ УКАЗАННЫХ ВЫШЕ ИСКЛЮЧЕНИЙ (ИЛИ ВСЕ СРАЗУ) МОГУТ НЕ ОТНОСИТЬСЯ ЛИЧНО К ВАМ.

ВЫ ПОЛЬЗУЕТЕСЬ ВЕБ-САЙТАМИ AI-AGGREGATOR НА СВОЙ СТРАХ И РИСК.

ВЫ СОГЛАШАЕТЕСЬ, ЧТО КОМПАНИЯ AI-AGGREGATOR НИ В КАКОМ СЛУЧАЕ НЕ НЕСЕТ ОТВЕТСТВЕННОСТЬ (Y) ЗА УЩЕРБ ЛЮБОГО РОДА, 
ВКЛЮЧАЯ ПРЯМЫЕ, КОСВЕННЫЕ, СПЕЦИАЛЬНЫЕ, ТИПИЧНЫЕ, СЛУЧАЙНЫЕ, ПОБОЧНЫЕ ИЛИ ШТРАФНЫЕ УБЫТКИ 
(ВКЛЮЧАЯ, БЕЗ ОГРАНИЧЕНИЙ, ПРИОБРЕТЕНИЕ ТОВАРОВ ИЛИ УСЛУГ НА ЗАМЕНУ, КОММЕРЧЕСКИЕ УБЫТКИ, ПОТЕРЮ ДАННЫХ 
ИЛИ ПРИБЫЛИ, А ТАКЖЕ ПЕРЕРЫВ В ФУНКЦИОНИРОВАНИИ БИЗНЕСА), НЕЗАВИСИМО ОТ ПРИЧИН И ЛЮБЫХ ТЕОРИЙ 
ОТВЕТСТВЕННОСТИ, ВОЗНИКАЮЩИХ В ЛЮБОЙ СВЯЗИ С НАСТОЯЩИМИ УСЛОВИЯМИ ИЛИ ДОГОВОРОМ, ВКЛЮЧАЯ ОБЪЕКТИВНУЮ 
ОТВЕТСТВЕННОСТЬ ИЛИ ГРАЖДАНСКОЕ ПРАВОНАРУШЕНИЕ (ВКЛЮЧАЯ НЕБРЕЖНОСТЬ ИЛИ ИНЫЕ ПРАВОНАРУШЕНИЯ), 
ДАЖЕ ЕСЛИ Ai-aggregator БЫЛО ИЗВЕСТНО О ВОЗМОЖНОСТИ ПОДОБНОГО УЩЕРБА ИЛИ (Z) ПРИ ВСЕХ ДРУГИХ ПРЕТЕНЗИЯХ, 
ТРЕБОВАНИЯХ ИЛИ ЛЮБОМ УЩЕРБЕ, ВОЗНИКШИХ В РЕЗУЛЬТАТЕ ИЛИ В СВЯЗИ С ИСПОЛЬЗОВАНИЕМ ВАМИ ВЕБ-САЙТОВ AI-AGGREGATOR. 
Приведенный выше отказ от ответственности не применяется в части, запрещенной действующим законодательством .

Вы признаете и соглашаетесь, что вышеуказанные ограничения ответственности, действующие совместно с прочими 
положениями настоящих Условий, ограничивающими ответственность, представляют собой существенные условия, 
и что компания Ai-aggregator не предоставила бы вам права, предусмотренные в настоящих Условиях, 
не получив от вас предварительного согласия на использование вышеуказанных ограничений ответственности.
`
export default terms