import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles';
import { Button, Divider, Box, TextField, Grid, Typography, InputAdornment } from '@mui/material'
import StrategyGenerator from './StrategyGenerator'

import SwitchComponent from '../../components/SwitchComponent'

const PlanEditor = (props) => {
	const theme = useTheme()
	const { handlers, selectedPlan } = props
	const isfree = selectedPlan.origin == 'Free'
	const { saveHandler, deleteHandler, cancelHandler } = handlers

	const initialState = {
		title: '',
		shortdescription: '',
		longdescription: '',
		pricerequest: '',
	}
	const [changesState, setChangesState] = useState(initialState)

	const switchHandler = (event) => {
		alert(event.target.value)
		setChangesState({...changesState, enabled:event.target.value})
	}

	const handleSubmit = (values, setSubmitting) => {
		console.log('Plans HandleSubmit:', values)
		saveHandler(values)
	}

	const priceHandler = (event, values) => {
		if (event.target.name == 'pricerequest') {
			return {...values, pricemonth: values.requestslimit * event.target.value, pricerequest: event.target.value}
		}
		if (event.target.name == 'pricemonth') {
			return {...values, pricemonth: event.target.value, pricerequest: event.target.value / values.requestslimit }
		}
		if (event.target.name == 'requestslimit') {
			return {...values, requestslimit: event.target.value, pricerequest: values.pricemonth / event.target.value}
		}
	}

	useEffect(() => {
		setChangesState(selectedPlan)
	}, [selectedPlan])

	return (
		<>	<StrategyGenerator/>
			<Formik
				initialValues={changesState}
				enableReinitialize
				validationSchema={Yup.object().shape({
					title: Yup.string().max(255).required('First name is required'),
					shortdescription: Yup.string()
						.max(255)
						.required('First name is required'),
					pricerequest: Yup.number().typeError('NaN'),
					pricemonth: Yup.number().typeError('NaN'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					console.log('HS')
					handleSubmit(values, { setSubmitting })
					setSubmitting(false)
				}}
			>
				{({
					errors,
					handleBlur,
					validateField,
					handleChange,
					isSubmitting,
					touched,
					resetForm,
					values,
					setValues,
					setFieldValue
				}) => (
					<Form autoComplete="off">
						<Grid container sx={{p:3}} spacing={1}>
							<Grid item xs={12} md={6} container spacing={1}>
								<Grid item xs={12} md={6}>
									<TextField
										sx={{ mb: 1 }}
										inputProps={{style: {fontSize: 24}}}
										InputLabelProps={{style: {color: "#aaa"}}}
										fullWidth
										error={Boolean(touched.title && errors.title)}
										label={
											'Название ' +
											String(
												touched.title && errors.title ? errors.title + '. ' : ''
											)
										}
										name="title"
										onBlur={handleBlur}
										onChange={handleChange}
										required										
										variant="standard"
										value={values.title}
									/>
									<TextField
										sx={{ mb: 1 }}
										fullWidth
										InputLabelProps={{style: {color: "#aaa"}}}
										error={Boolean(
											touched.shortdescription && errors.shortdescription
										)}
										label={
											'Short description ' +
											String(
												touched.shortdescription && errors.shortdescription
													? errors.shortdescription + '. '
													: ''
											)
										}
										name="shortdescription"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										value={values.shortdescription}
										variant="standard"
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<Grid container spacing={1}>
										<Grid item xs={6}>
										<TextField
											sx={{ mb: 1 }}
											disabled={isfree}
											fullWidth
											error={Boolean(
												touched.pricemonth && errors.pricemonth
											)}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														$
													</InputAdornment>
												),
											}}
											label={
												'Месяц ' +
												String(
													touched.pricemonth && errors.pricemonth
														? errors.pricemonth + '. '
														: ''
												)
											}
											name="pricemonth"
											onBlur={handleBlur}
											onChange={(event) => {
												setValues(priceHandler(event, values))						
											}}
											required
											value={values.pricemonth}
											variant="outlined"
										/>
										</Grid>
										<Grid item xs={6}>
										<TextField
											sx={{ mb: 1 }}
											fullWidth
											disabled={isfree}
											error={Boolean(
												touched.pricerequest && errors.pricerequest
											)}
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														$
													</InputAdornment>
												),
											}}
											label={
												'Запрос' +
												String(
													touched.pricerequest && errors.pricerequest
														? errors.pricerequest + '. '
														: ''
												)
											}
											name="pricerequest"
											onBlur={handleBlur}
											onChange={(event) => {
												setValues(priceHandler(event, values))
											}}
											required
											value={ values.pricerequest}
											variant="outlined"
										/>
										</Grid>
									</Grid>
									<TextField
										sx={{ mb: 1 }}
										fullWidth
										error={Boolean(
											touched.requestslimit && errors.requestslimit
										)}
										label={
											'Requests ' +
											String(
												touched.requestslimit && errors.requestslimit
													? errors.requestslimit + '. '
													: ''
											)
										}
										label="Requests per month"
										name="requestslimit"
										onBlur={handleBlur}
										onChange={(event) => {
											setValues(priceHandler(event, values))
										}}
										required
										value={values.requestslimit}
										variant="outlined"
									/>
								</Grid>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
										sx={{ mb: 1 }}
										fullWidth
										multiline
										rows={4}
										error={Boolean(
											touched.longdescription && errors.longdescription
										)}
										label={
											'Long description ' +
											String(
												touched.longdescription && errors.longdescription
													? errors.longdescription + '. '
													: ''
											)
										}
										label="Long description"
										name="longdescription"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										value={values.longdescription}
										variant="outlined"
									/>
							</Grid>
							<Grid item xs={12}>
									<Box flexGrow={1}/>
									<SwitchComponent
										name="enabled"
										label={{ on: 'Enabled', off: 'Disabled' }}
										onChange={(e) => {
											setFieldValue("enabled", e.target.checked)
											}
										}
										checked={values.enabled}
										changeState={setChangesState}
										readonly={false}
									/>
									<Button variant="outlined" onClick={cancelHandler} >
										Cancel
									</Button>
									<Button variant="outlined" type="submit" >
										Save
									</Button>
									<Button variant="outlined" onClick={deleteHandler}>
										Delete
									</Button>
							</Grid>
							
						</Grid>
						
					</Form>
				)}
			</Formik>
		</>
	)
}

export default PlanEditor
