import { Grid, Box, Typography, Divider, TextField } from '@mui/material'

const AppOverview = (props) => {
	const {shortdescription, appkeydata} = props.data
	console.log('AppOverview. Props :', props)
	return (
		<>
			<Grid container direction="column">
				<Grid item sx={{p:3}}>
					<Typography variant="h5">Overview </Typography>
				</Grid>
				<Divider />
				<Grid item sx={{p:3}}>
					<Typography variant="body1">{shortdescription}</Typography>
				</Grid>
				<Divider />
				<TextField label="APP KEY:" value={appkeydata && appkeydata.appkey || ''} sx={{m:3}}/>
			</Grid>
		</>
	)
}

export default AppOverview
