const paramTypes = [
	{
		id: 0,
		title: ''
	},
	{
		id: 1,
		title: 'STRING'
	},
	{
		id: 2,
		title: 'ENUM'
	},
	{
		id: 3,
		title: 'NUMBER'
	},
	{
		id: 4,
		title: 'BOOLEAN'
	},
	{
		id: 5,
		title: 'DATE'
	},
	{
		id: 6,
		title: 'TIME'
	},
	{
		id: 7,
		title: 'OBJECT'
	},
	{
		id: 8,
		title: 'ARRAY'
	},
	{
		id: 9,
		title: 'GEOPOINT'
	}
]

export default paramTypes