import { useEffect, useState } from 'react'

import {
	Grid,
	Typography,
	Box,
	Divider,
	Card,
	Paper,
	CardContent,
	CardHeader,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	CardActionArea,
	CardActions,
	Button
} from '@mui/material'

import { useTheme } from '@mui/material/styles';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined'



const PlansCard = (props) => {
	const { plan, selectedPlan, handlers, editorState} = props
	const theme = useTheme()
	const [ state, setState ] = useState(plan)

	useEffect(() => {
		if (selectedPlan) {
			setState({...selectedPlan, options: [ {'requests per month': plan.requestslimit} ]})
		}
	}, [selectedPlan])

	return (
		<Card sx={!(!!state.code) ? theme.disabled : {}}>
			<CardActionArea>
			<CardHeader 
				title={state.title} 
				subheader={state.shortdescription}
				action={ state.pricemonth>0 &&
					<Grid container direction="column" justifyContent="center"
					alignItems="end">
					<Typography variant="h6">{state.pricemonth.toLocaleString()} $</Typography>
					<Typography variant="caption">/ month</Typography>
					<Typography variant="subtitle">{state.pricerequest.toLocaleString()} $</Typography>
					<Typography variant="caption">/ request</Typography>
					</Grid>
			  } 
			/>
			<Divider />
			<CardContent>
				<Typography variant="body2" >This pricing plan include:</Typography>
				<List>
					{!!state.options &&
						state.options.map((option, index) => (
							<ListItem key={index}>
								<ListItemIcon>
									<AddOutlinedIcon />
								</ListItemIcon>
								<Grid container direction="column">
									<Typography> {(Object.values(option)[0]*1).toLocaleString()} </Typography>
									<Typography variant='caption'> {Object.keys(option)[0]} </Typography>
								</Grid>
							</ListItem>
						))}
					
				</List>
			</CardContent>
			</CardActionArea>
			
			<CardActions>
				{!(!!state.code) &&
							<Button fullWidth onClick={()=> {handlers.createHandler(state)}}>Create</Button>
				}
				{!!state.code &&
				<>
							
					<Button fullWidth onClick={()=> {handlers.editHandler(state)}}>Edit</Button>
							
				</>
				}
			</CardActions>
			
				
			
		</Card>
	)
}

export default PlansCard
