import { useState, useContext, useEffect, useCallback } from 'react'
import {
	Grid,
} from '@mui/material'

import { useHttp } from '../../hooks/http.hook';
import PlanSelector from './PlanSelector'
import PlansCardMarket from '../../components/PlansCardMarket';
import { NavContext } from '../../context/NavContext'
import { AuthContext } from '../../context/AuthContext'

const ProductPlans = (props) => {
	const { request, error, clearError } = useHttp()
	const { token, owner } = useContext(AuthContext)
	const [ state, setState ] = useState([])
	const [ selectedPlan, setSelectedPlan ] = useState()
	const [ editorState, setEditorState ] = useState()
	const [ openPlanSelector, setOpenPlanSelector ] = useState(false)

	const { selectedApi, refresh, refreshCheck, setSelectedApi, selectData } = useContext(NavContext)

	const fetchApiPlans = async () => {
		try {
			//const fetched = await request()
		} catch (e) {
			console.log(e)
		}
	}

	const sendSubscribe = useCallback(async (data) => {
		const { productType, product, user} = selectData
		console.log("selectData: ", selectData)
		console.log(data)
		try {
			const body = {...data}
			const fetched = await request(`/api/subscribe/${user}/${product}/subscribe`, 'POST', body, {
				Authorization: `Bearer ${token}`,
			} )
		} catch(e) {
			console.log(e)
		}
	},[token, request, selectData])
	
	const subscribeHandler = (data) => {
		if (!!data) {
			console.log("subscribeHandler")
			console.log(data)
			sendSubscribe(data)
		}
		setOpenPlanSelector(false)
	}

	const selectHandler = (plan) => {
		setSelectedPlan(plan)
		setOpenPlanSelector(true)
		console.log("selectHandler")
	}

	useEffect(() => {
		if (!!props.data.plans) {
			const mapplans = props.data.plans.map((plan) => {
				return !!plan.enabled && plan
			}).filter(i=>!!i)
			setState(mapplans)
		}
		console.log("Product Plans Props Data",props.data)
	}, [props.data])


	return (
		<>
		<Grid>
		<PlanSelector open={openPlanSelector} data={selectedPlan} handlers={{subscribeHandler,setOpenPlanSelector}} />
		<Grid item sx={{p:3}}>
			<Grid container spacing={1}> 
			{state.map((item, index) => (
				<Grid item xs={12} md={3} key={index}>
					<PlansCardMarket 
						plan={item}
						selectedPlan={item}
						editorState={editorState}
						handlers={{
							selectHandler
						}}
					/>
				</Grid>
			))}
			</Grid>
		</Grid>
		</Grid>
		</>
	)
}

export default ProductPlans