import { useState, useContext, useCallback, useEffect } from 'react'
import { Link as RouterLink, useOutlet, useNavigate } from 'react-router-dom'
import {
	Divider,
	Grid,
	TextField,
	Typography,
} from '@mui/material'

import { useTheme } from '@mui/material/styles';
import ControlsComponent from '../../components/ControlsComponent'

import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import SchemaTools from '../../helpers/schematools'
import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook'
import { NavContext } from '../../context/NavContext'
import { useMessage } from '../../hooks/message.hook'
import SelectMapCsv from '../../components/SelectMapCsv'
import MetricSelector from '../../components/MetricSelector'

import ContestDefinitionHeader from './ContestDefinitionHeader'

const ContestData = (props) => {
	const { selectedpart } = props
	const serverpath = "/api/contest"
	const initialState = {
		publicpercent: 0,
		title: '',
		traindatalink: '',
		testdatalink: '',
		solution: '',
		sample: '',
		metric: '',
	}
	const { compareObjects } = SchemaTools()

	const [ current, setCurrent ] = useState({})
	const [ metric, setMetric ] = useState()
	const { token } = useContext(AuthContext)
	const { request, error, clearError } = useHttp()
	const [ fetchedState, setFetchedState ] = useState(initialState)
	const [ isSubmitting, setSubmitting ] = useState(false)
	const { setnav, refresh, selectData, selectedContest, setSelectedContest, selectedOrg } = useContext(NavContext)
	const navigate = useNavigate()
	const { settext, errortext } = useMessage()
	const message = settext

	const [ readonly, setReadonly ] = useState(true)

	const fetchData = async (value) => {
		try {
			const fetched = await request(
				`${serverpath}/owner/${value.ownerId}/${value.code}/dataset`,
				'GET',
				null,
				{
					Authorization: `Bearer ${token}`,
				}
			)
			console.log(fetched)
			const data = compareObjects(initialState, {...fetched.ContestData},{keepold:true})
			const dataset = compareObjects(initialState, {...fetched.ContestDataset})
			const resultdata = compareObjects(initialState, {...data,...dataset})

			setFetchedState(resultdata)
			setCurrent(fetched.ContestData)
			console.log("resultdata",resultdata)
		} catch (e) {
			console.log(e)
		}
	}

	const saveHandler = async (value) => {
		try {
			if (!!selectedContest && selectedContest.published) {throw 'is published'}
			var newdata = compareObjects(fetchedState,value,{minified: true})
			console.log("NEW DATA: ", newdata)
			console.log('values:', value)
			setSubmitting(true)
			console.log('fetchedState: ', fetchedState)
			const data = await request(
				`${serverpath}/owner/${value.ownerId}/${value.code}/dataset/update`,
				'POST',
				{ _id: current._id, ...newdata },
				{ Authorization: `Bearer ${token}` }
			)
			console.log('DATA: ', data)
			setSubmitting(false)
			refresh()
		} catch (e) {
			console.log('Save settings error: ', e)
		}
	}

	const handleSubmit = (values) => {
		console.log("handleSubmit: ", values)
		saveHandler(values)
	}

	const resetHandler = () => {
		setReadonly(false)
		setFetchedState(fetchedState)
	}

	useEffect(() => {
		console.log("selectedContest", selectedContest)
		if (!selectedContest.date) {return}
		fetchData(selectedContest)
	}, [selectedContest])

	return (
		<><ContestDefinitionHeader {...{selecteddata: selectedContest,selectedpart}} />
			<Formik
				initialValues={fetchedState}
				enableReinitialize
				validationSchema={Yup.object().shape({
					traindatalink: Yup.string()
						.min(5,'Minimum symbols: ${min}')
						.max(255, 'Maximum symbols limit exceeded ${max}')
						,
					testdatalink: Yup.string()
						.min(5,'Minimum symbols: ${min}')
						.max(255, 'Maximum symbols limit exceeded ${max}')
				})}
				onSubmit={(values, { setSubmitting }) => {
					handleSubmit(values, { setSubmitting })
					setSubmitting(false)
					setReadonly(true)
				}}
			>
				{({
					errors,
					handleBlur,
					handleChange,
					isSubmitting,
					touched,
					resetForm,
					values,
					setFieldValue,
					submitForm
				}) => (
					<Form autoComplete="off">
						<Grid item md={12} xs={12} sx={{ p: 3 }}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									{!!selectedContest && !selectedContest.published ?
									<ControlsComponent
										readonly={{ setReadonly, readonly }}
										resetHandler={resetHandler}
										resetform={resetForm}
									/>
									:
									<Typography>You cannot edit the Contest while it is published.</Typography>
									}
								</Grid>
								<Grid item xs={12} md={6}>
									<TextField
										disabled={readonly}
										sx={{paddingBottom: 1}}
										error={Boolean(touched.traindatalink && errors.traindatalink)}
										fullWidth
										helperText={
											!!errors.traindatalink && errors.traindatalink }
										label="Train data link"
										name="traindatalink"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.traindatalink}
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<TextField
										disabled={readonly}
										sx={{paddingBottom: 1}}
										error={Boolean(touched.testdatalink && errors.testdatalink)}
										fullWidth
										helperText={
											!!errors.testdatalink && errors.testdatalink 
										}
										label="Test data link"
										name="testdatalink"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.testdatalink}
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={12} sx={{display:'flex'}}>
									<Typography>Scoring metric:</Typography>
									<MetricSelector 
										setMetric={setMetric}
										values={values}
										readonly={readonly}
										setFieldValue={setFieldValue}
										formik={{handleBlur,handleChange}}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<Typography>Solution</Typography>
									<SelectMapCsv type="solution" label="Solution" values={values} readonly={readonly} setFieldValue={setFieldValue} formik={{handleBlur,handleChange}}/>
								</Grid>
								<Grid item xs={12} md={6}>
									<Typography>Sample</Typography>
									<SelectMapCsv type="sample" label="Sample" values={values} readonly={readonly} setFieldValue={setFieldValue} formik={{handleBlur,handleChange}}/>
								</Grid>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</>
	)
}

export default ContestData