import {useEffect, useState} from 'react'
import {
	LocalizationProvider,
	DatePicker
} from '@mui/lab'
import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import theme from './theme'
const myTheme = createTheme()

const DatePickerComponent = (props) => {
	const {
		label,
		value,
		name,
		onChange,
	} = props

	let time = undefined

	if (value) {
		var date = new Date(value)
		time = date.getHours()
		//console.log("value time", time)
	}
	
	const [ state, setState ] = useState(Date.now())
	
	const handleChange = (value) => {
		if (typeof time !== 'undefined') {
			var newvalue = new Date(value)
			value.setHours(time)
			//console.log("newvalue", value)
		}
		setState(value)
		//console.log(value)
		if (typeof onChange !== 'undefined') {
			onChange({target:{name,value}})
		}
	}

	useEffect(() => {
		if (!!value) {
			setState(value)
		}
	}, [value])

	return (
		<>
		<ThemeProvider theme={myTheme}>
			<LocalizationProvider dateAdapter={DateAdapter}>
				<DatePicker
					fullWidth
					label={label}
					value={state}
					onChange={(newValue) => {
						handleChange(newValue)
					}}
					renderInput={(params) => <TextField {...params} />}
				/>
			</LocalizationProvider>
			</ThemeProvider>
		</>
	)
}

export default DatePickerComponent