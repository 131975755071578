const mediaTypes = [
	{
		id: 'text',
		title: 'text/plain',
		schema: false,
		headers: {'content-type':'text/plain'}
	},
	{
		id: 'json',
		title: 'application/json',
		schema: true,
		headers: {'content-type':'application/json'}
	},
	{
		id: 'form',
		title: 'form-data',
		schema: true,
		headers: {'content-type':'application/x-www-form-urlencoded', useQuerystring: true}
	},
	{
		id: 'stream',
		title: 'application/octet-stream',
		schema: false,
		headers: {'content-type':'application/octet-stream'}
	}
	
]

export default mediaTypes