const menuList = [
	{
		title: 'Profile',
		basepath: '/dash/',
		pathname: 'user',
		sidebar: false,
	},
	{
		title: 'Account',
		basepath: '/dash/',
		pathname: 'account',
		sidebar: false,
	},
	{
		title: 'Login',
		basepath: '/',
		pathname: 'login',
		sidebar: false,
	},
	{
		title: 'Register',
		basepath: '/',
		pathname: 'register',
		sidebar: false,
	},
	{
		title: 'Выйти',
		name: 'logout',
		basepath: '/',
		pathname: 'logout',
		sidebar: false,
	},
]

export default menuList