import { useState, useEffect } from 'react'
import { 
	Collapse, 
	Typography,
	Box,
	Grid,
	Button
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';

const Guide = (props) => {
	const [ state, setState ] = useState(props.guide)
	const classes = props.classes
	console.log("state: ",state)

	const handleOpen = () => {
		setState({...state,open:false})
	}

	useEffect(()=> {
		if (!!props.guide) {
			if (!!props.type && (props.guide.type !== props.type)) {
				setState({...state,open:false})
			}
			setState(props.guide)
		}
	}, [props])

	return (
		<>{!!state && (props.part == state.part) && (!!state.type && props.type ? (state.type == props.type) : true) && !!state.guide &&
					<Collapse in={!!state && !!state.open} collapsedSize={0}>
						<Box className={classes.subheaderBody}>
							<Box sx={{mr:3,ml:3}}>
								<Box 
									fullWidth 
									component={Button} 
									onClick={handleOpen} 
									sx={{
										'&:hover': {
											borderBottom:'1px solid #0055FF',
										},	
										cursor:'pointer',
										width:'100%',
										borderBottom:'1px solid #eee',
										display:'flex',
										alignItems:'center'
									}}>
										<Box>
											<Typography variant="caption">
												Закрыть
											</Typography>
										</Box>
										<Box flexGrow="1"/>
										<Box>
											<ClearIcon sx={{fontSize:'8pt'}}/>
										</Box>
								</Box>
							</Box>
						<Grid container spacing={3} sx={{p:3}}>
						{!!state.guide.guide && state.guide.guide.map((item,index) => (
							<Grid item xs={12} md key={index}>
								<Box sx={{display:'flex'}}>
									<Box>
										<Typography variant="h2" sx={{color:'#ddd',pr:2,mt:-0.5}}>{index+1}</Typography>
									</Box>
									<Box>
										<Typography sx={{pb:1}} variant="h6">{item.title}</Typography>
										{
											!!item.description && typeof item.description === 'object' ?
												item.description.map((i,index) => (
												<Box key={index} display="flex" alignItems="start">
												<Typography variant="body2" sx={{pr:1}}>●</Typography>
												<Typography key={index} variant="body2" sx={{pb:1}}>{i}</Typography>
												</Box>
											))
											:
											<Typography variant="body2" sx={{pb:1}}>{item.description}</Typography>
										}
									</Box>
								</Box>
							</Grid>
						))
						}
						</Grid>
						</Box>
					</Collapse>
			}
		</>
	)
}

export default Guide