import { useCallback, useEffect, useState } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

import { Grid, TextField, Box, Button } from '@mui/material'
import mediaTypes from '../../__mocks__/mediaTypes'

const BodyEditor = (props) => {
	const harvestChanges = props.harvester
	const name = props.name

	const initialState = {
		title: '',
		mediatype: 'text',
		description: '',
		example: JSON.stringify({ key1: 1, key2: 'value', key3: [] }, null, 2),
		schema: '',
	}
	const mediatypeOptions = [{id:0,title:''},...mediaTypes]

	const [changesState, setChangesState] = useState(initialState)
	const [editor, setEditor] = useState(false)
	const [readonly, setReadonly] = useState(true)
	const [isSubmitting, setSubmitting] = useState(false)
	const [ferrors, setErrors] = useState()
	const [fvalues, setValues] = useState(initialState)
	const [schemaValue, setSchemaValue] = useState({
		schema: initialState.schema,
		error: null,
	})

	const mediaTypeHasSchema = (mediatype) => {
		console.log("mediaTypeHasSchema: ", mediatype)
		return mediaTypes.find((type) => {
			console.log(type.id)
			return mediatype == type.id}).schema == true
	}

	const saveSettings = async (values) => {
		try {
			const preparedvalues = {
				...values,
				example: values.example && JSON.stringify(JSON.parse(values.example)),
				schema: schemaValue.schema && JSON.stringify(JSON.parse(schemaValue.schema)),
			}
			console.log('Body. Save Settings. Prepared values : ', preparedvalues)
			harvestChanges({ body: { ...preparedvalues } })
		} catch (e) {
			throw e
		}
	}

	const generateSchema = useCallback((values) => {
		let example = {}
		try {
			example = JSON.parse(values)
		} catch (e) {
			setSchemaValue({ schema: '', error: 'Example is not valid JSON' })
			return
		}
		let schema = { type: 'object', properties: {} }
		for (var key in example) {
			schema['properties'][key] = { type: typeof example[key] }
		}
		const pretty = JSON.stringify(schema, null, 2)
		setSchemaValue({ schema: pretty, error: '' })
	},[])

	const handleSubmit = (values) => {
		console.log('HS: ', values)
		saveSettings(values)
		setReadonly(true)
	}

	useEffect(() => {
		if (mediaTypeHasSchema(fvalues.mediatype)) {
			
			generateSchema(fvalues.example)
		} else {
			setSchemaValue({ schema: '' })
			return
		}
	}, [fvalues.example, fvalues.mediatype, generateSchema])

	useEffect(() => {
		console.log('Body props: ', props.changesState)
		// console.log("Body CS: ", changesState)
		// console.log('Spread',{...changesState,...bodyChangeState})
		if (props.changesState) {
			const preparedBodyChangeState = {
				...props.changesState,
				example: JSON.stringify(
					JSON.parse(props.changesState.example),
					null,
					2
				),
			}
			setChangesState({ ...changesState, ...preparedBodyChangeState })
		} else {
			setChangesState({ ...changesState, ...initialState })
		}
	}, [props.changesState])

	return (
		<>
			<Formik
				initialValues={changesState}
				enableReinitialize
				validationSchema={Yup.object().shape({
					title: Yup.string().min(3).max(50),
					mediatype: Yup.string().default('text'),
					description: Yup.string().min(3).max(250),
					example:
						fvalues.mediatype && !mediaTypeHasSchema(fvalues.mediatype)
							? Yup.object().typeError('Not an object').required()
							: Yup.string(),
				})}
				onSubmit={(e, isSubmitting) => {
					handleSubmit(e)
				}}
			>
				{(formikProps) => {
					var {
						errors,
						handleBlur,
						handleChange,
						touched,
						resetForm,
						values,
						isValid,
						validateForm,
					} = formikProps
					setValues(values)
					return (
						<>
							<Form autoComplete="off">
								<Grid container>
									<Grid item xs={12} md={4}>
										<Grid item display="flex">
											<TextField
												size="small"
												error={Boolean(touched.title && errors.title)}
												fullWidth
												label={
													'Payload title ' +
													(touched.title && !!errors.title ? errors.title : '')
												}
												name="title"
												onBlur={handleBlur}
												onChange={handleChange}
												required
												value={values.title}
												variant="outlined"
												disabled={readonly}
												sx={{ pr: 1 }}
											/>
											<TextField
												size="small"
												fullWidth
												error={Boolean(touched.mediatype && errors.mediatype)}
												label="Media type"
												name="mediatype"
												onBlur={handleBlur}
												onChange={handleChange}
												required
												select
												SelectProps={{ native: true }}
												value={values.mediatype}
												variant="outlined"
												disabled={readonly}
												sx={{ pr: 1 }}
											>
												{mediatypeOptions.map((option, index) => (
													<option
														disabled={index == 0}
														key={index}
														value={option.id}
													>
														{option.title}
													</option>
												))}
											</TextField>
										</Grid>
										<TextField
											multiline
											rows={7}
											size="small"
											error={Boolean(touched.description && errors.description)}
											fullWidth
											label="Payload description"
											name="description"
											onBlur={handleBlur}
											onChange={handleChange}
											required
											value={values.description}
											variant="outlined"
											disabled={readonly}
											sx={{ pr: 1, mt: 1 }}
										/>
									</Grid>
									<Grid item xs={12} md={4}>
										<TextField
											multiline
											rows={9}
											size="small"
											error={Boolean(touched.example && errors.example)}
											fullWidth
											label={
												'Body ' +
												(touched.example && !!errors.example
													? errors.example
													: '')
											}
											name="example"
											onBlur={handleBlur}
											onChange={(event) => {
												handleChange(event)
											}}
											required
											value={values.example}
											disabled={readonly}
											sx={{ pr: 1 }}
										/>
									</Grid>

									<Grid item xs={12} md={4}>
										<TextField
											multiline
											rows={9}
											size="small"
											error={Boolean(schemaValue.error)}
											fullWidth
											label={
												'Schema ' +
												(!!schemaValue.error ? schemaValue.error : '')
											}
											name="schema"
											onBlur={handleBlur}
											required={!mediaTypeHasSchema(values.mediatype)}
											value={schemaValue.schema}
											variant="outlined"
											disabled={readonly || !mediaTypeHasSchema(values.mediatype)}
											sx={!mediaTypeHasSchema(values.mediatype) ? { display: 'none' } : {}}
										/>
									</Grid>
								</Grid>
								<Box>
									{!readonly ? (
										<>
											<Button type="submit" variant="outlined" sx={{ mt: 1 }}>
												Save body
											</Button>
										</>
									) : (
										''
									)}
								</Box>
							</Form>
							<Box>
								{readonly ? (
									<>
										<Button
											type="button"
											variant="outlined"
											sx={{ mt: 1 }}
											onClick={() => {
												setReadonly(false)
											}}
										>
											Edit
										</Button>
									</>
								) : (
									''
								)}
							</Box>
						</>
					)
				}}
			</Formik>
		</>
	)
}

export default BodyEditor
