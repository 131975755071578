import { useEffect, useState, useContext, useCallback, useRef, useLayoutEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { 
	Box,
	Icon,
	Typography,
} from '@mui/material'
import { useHttp } from '../../hooks/http.hook'
import { useTags } from '../../hooks/tag.hook'
import { AuthContext } from '../../context/AuthContext'
import ItemCategory from './item.category'


const ViewCategory = ({ category, type, clickHandler, activeItem, ismobile }) => {
	const serverpath = '/api/tag'
	const { tags, getTags, getCategory } = useTags()
	const { token } = useContext(AuthContext)
	const [ selectedTags, setSelectedTags ] = useState([])
	const [ colors, setColors ] = useState([])
	const theme = useTheme()
	const ContainerRef = useRef()

	const getTagColor = (title) => {
		let color ='#eee'

		if (!!colors.length) {
			const cadidate = colors.find((i) => i.title === title)
			if (!!cadidate) {
				color = cadidate.color
			}
		}
		let result ='#eee'
		if (!!activeItem) {
			result = activeItem.includes(title) ? 
			color : '#eee'
		} else {
			result = color
		}
		return result
	}

	const handleClick = (event) => {
		
		const title = event.currentTarget.getAttribute('name')
		if (!title) {return}
		if (!!activeItem && activeItem.includes(title)) {
			clickHandler(null)
		} else {
			clickHandler(title)
		}
		
	}

	const randomColor = (index) => {
		const res = () => {
			const hue = Math.floor(Math.random() * 360);
			const saturation = Math.floor(Math.random() * 10) + 200; // high saturation
			const lightness = Math.floor(Math.random() * 10) + 70; // lightness
			return `hsla(${hue}, ${saturation}%, ${lightness}%, 1)`;
		  }
		return res()
	}

	const [ width, setWidth ] = useState(0);
    const [ quantity, setQuantity ] = useState(4)
    
    const getSize = useCallback(node => {
        if (node !== null) {
            setWidth(node.current.getBoundingClientRect().width);
        }
      }, [ContainerRef]);

    const handleResize = () => {
        if (ContainerRef !== null) {
            getSize(ContainerRef)
            }
    }

	useEffect(() => {
		if (Array.isArray(category) && !!tags && !!tags.length) {
			setSelectedTags(getCategory({category}))
		}
	}, [category,tags])

	useEffect(() => {
		if (!tags.length) {return}
		if (!selectedTags.length) {return}
		if (!colors.length) {
			let colorsArray = []
			tags.map((tag, index) => {
				colorsArray.push({title: tag.title, color: randomColor(index)})
			})
			setColors(colorsArray)
		} else {
			return
		}
	}, [selectedTags,tags])

	useLayoutEffect(() => {
        const element = ContainerRef.current
        if (!element) return
        handleResize()
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [handleResize]);

    useEffect(() => {
		const minsize = !ismobile ? 120 : 100
        setQuantity((width/1.333333) > minsize ? Math.floor((width/1.333333)/minsize) : 1)
    },[width])

	return (
		<Box ref={ContainerRef} sx={type == 'market' ? {width:'100%', display: 'grid', gridTemplateColumns: `repeat(${quantity}, 1fr)`, gap:1} 
			:
			{p:1,display:'flex',flexWrap:'wrap',overflow:'auto', maxHeight: '60pt',minWidth:'50%', maxWidth:'60%',gap:0.5,justifyContent:'center'}
			
			}>
			{selectedTags && selectedTags.length ? 
				selectedTags.map((tag, index) => {
					
					let Element = null
					
					if (!!tag.icon.Element) {
						Element = <tag.icon.Element {...{...tag.icon.props,style:{
							color: 
							getTagColor(tag.title)
						}}}/>
					}
					
					return (
						<ItemCategory
							key={index}
							tag={tag}
							color={getTagColor(tag.title)}
							clickHandler={clickHandler}
							type={type}
							handleClick={handleClick}
							icon={Element}
						/>
					// <Box
					// 	key={index}
					// 	name={tag.title}
						
					// 	sx={{
					// 		border: `1px solid ${getTagColor(tag.title)}`,
					// 		borderRadius: '10pt',
					// 		p: 0.3,
					// 		px: 1,
					// 		alignItems: 'center',
					// 		display: 'inline-flex',
					// 		...(!!clickHandler ? {cursor:'pointer'} : {}),
					// 		width:'auto',
					// 		gap: 1,
					// 	}}
						
					// 	{...(!!clickHandler ? {
					// 		onClick: handleClick
					// 	} : {})}
					// >
					// 	{!!Element ? Element : ''}
					// 	<Box sx={{width:'auto'}}>
					// 	<Typography variant="caption" noWrap >
					// 		{(!!tag.variations && tag.variations.ru) || tag.title}
					// 		<Box flexGrow="1"/>
					// 		{!!tag.count && type == "market" ? tag.count : ''}
					// 	</Typography>
					// 	</Box>
					// </Box>
				)})
			: (
				<></>
			)}
		</Box>
	)
}

export default ViewCategory
