import { Navigate } from 'react-router-dom'

const ComponentOutlet = (props) => {
	let { path, parts, selectedpart, data, ...rest } = props

	if (!path) {
		path = parts[0].path
		return (
			<Navigate to={path} replace={true}/>
		)
	}

	const CurrentElement = parts.filter((route) => route.path === path)[0].element

	return <CurrentElement data={data} {...rest} path={path} selectedpart={selectedpart} />
}

export default ComponentOutlet
