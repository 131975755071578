import {useState} from 'react'
import {
	Button, Typography, Grid
} from '@mui/material'

/*

Генерация sinonim`а карты получения оплаты
file:///D:/web/projects/dockerreact/payments/payments.html

Страница этого компонента
http://localhost:3000/payments

Создание Сделки
http://localhost:5000/api/payment/createdeal

Создание платежа
http://localhost:5000/api/payment/createpayment/deal?id=dl-290f7484-0022-5000-8000-0ba2a8be20ea

Статус платежа
http://localhost:5000/api/payment/checkstatus/pay?id=547a1a73-9ef1-41b2-9e10-72a7e1dd01d0

Статус сделки
http://localhost:5000/api/payment/deal/getbyid/deal?dealid=dl-290f7d70-0022-5000-8000-0ed1229a486a

Создание Выплаты
http://localhost:5000/api/payment/payouts/query?sinonim=01GWwREs7C07Qz3xwsdc2641.SC.001.202110&dealid=dl-290f86bc-0022-5000-8000-01409a421da1

Статус Выплаты
http://localhost:5000/api/payment/payouts/getbyid/deal?dealid=dl-290f7d70-0022-5000-8000-0ed1229a486a

*/
const Payments = () => {

	const [ dataState, setDataState ] = useState({})
	const [ deal, setDeal ] = useState({})
	const [ payment, setPayment ] = useState({})
	const [ payout, setPayout ] = useState({})

	const createDeal = async () => {
		console.log("createDeal")
		try {
			const data = await fetch(`api/payment/createdeal`)
			if (!!data) {
				setDataState(data)
			}
		} catch(e) {
			console.log(e)
		}
	}
	const createPayment = () => {
		console.log("createPayment")
	}
	const checkStatus = () => {
		console.log("checkStatus")
	}
	const createPayout = () => {
		console.log("createPayout")
	}
	const checkPayout = () => {
		console.log("checkPayout")
	}

	const notification = async () => {
		try {
			const result = await fetch('/api/notification', {method: 'POST',body:null})
			console.log(result)
		} catch(e) {
			console.log(e)
		}
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				Payments
			</Grid>
			<Grid item xs={12}>
				<Button onClick={createDeal}>Create deal</Button>
				<Button onClick={createPayment}>Create payment</Button>
				<Button onClick={checkStatus}>Check status</Button>
				<Button onClick={createPayout}>Create payout</Button>
				<Button onClick={checkPayout}>Check payout</Button>
				<Button onClick={notification}>Notification</Button>
			</Grid>
			<Grid item xs={12}>
				<Typography>{JSON.stringify(dataState)}</Typography>
			</Grid>
		</Grid>
	)
}

export default Payments