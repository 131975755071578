import {Icon,SvgIcon} from '@mui/material'

import { ReactComponent as SendTriangle } from '../../assets/icons/sendtriangle.svg'


export const SendIcon = (props) => (
	<SvgIcon {...props}>
		<SendTriangle/>
	</SvgIcon>
)

export const DiscordIcon = (props) => (
	<Icon {...props}>
	<img src='/discord_circle.svg' />
	</Icon>
)

export const TelegramIcon = (props) => (
	<Icon {...props}>
	<img src='/telegram.svg' />
	</Icon>
)