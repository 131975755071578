import {useState, useContext, useEffect} from 'react'
import {
	Typography,
	Grid,
	Divider,
	Box,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody
} from '@mui/material'
import timeAgo from '../../helpers/timeAgo'
import InputFile from '../../components/InputFile'
import csvjson from '../../helpers/csvjson'
import SchemaTools from '../../helpers/schematools'
import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook'
import { NavContext } from '../../context/NavContext'
import ContestDefinitionHeader from './ContestDefinitionHeader'

import SolutionGen from '../../helpers/solutionGen'

const ContestSandbox = (props) => {
	const { selectedpart } = props
	const serverpath = "/api/contest"
	const initialState = {
		publicpercent: void 0,
		title: '',
		traindatalink: '',
		testdatalink: '',
		solution: '',
		sample: '',
		metric: '',
	}
	const { token } = useContext(AuthContext)
	const { request, error, clearError } = useHttp()
	const { compareObjects } = SchemaTools()
	const { setnav, refresh, selectData, selectedContest, setSelectedContest, selectedOrg } = useContext(NavContext)
	const [ fetchedState, setFetchedState ] = useState(initialState)
	const [ current, setCurrent ] = useState({})
	const [ List, setList ] = useState([])
	const [ values, setValues ] = useState([])

	const fetchData = async (value) => {
		try {
			const fetched = await request(
				`${serverpath}/owner/${value.ownerId}/${value.code}/dataset`,
				'GET',
				null,
				{
					Authorization: `Bearer ${token}`,
				}
			)
			console.log(fetched)
			const data = compareObjects(initialState, {...fetched.ContestData},{keepold:true})
			const dataset = compareObjects(initialState, {...fetched.ContestDataset})
			const resultdata = compareObjects(initialState, {...data,...dataset})

			setFetchedState(resultdata)
			setCurrent(fetched.ContestData)
			console.log("resultdata",resultdata)
		} catch (e) {
			console.log(e)
		}
	}

	const saveHandler = async (value) => {
		console.log("saveHandler: ", value)
		try {
			const result = await request(
				`${serverpath}/owner/${value.ownerId}/${value.code}/calculate`,
				'POST',
				value,
				{
					Authorization: `Bearer ${token}`,
				}
			)
			if (!!result && !!result.result && !!result.filename) {
				console.log(List)
				console.log({...result.result,name:result.filename})
				const index = List.indexOf(List.find((item)=>item.name == result.filename))
				
				setList([...List.slice(0,index), {...result.result,name:result.filename,date:result.date}, ...List.slice(index+1)])
			}
		} catch(e) {
			console.log(e)
		}
	}

	const validateSubmission = async (file) => {
		try {
			var error = ''
			const result = await csvjson(file)
			var solution = fetchedState.solution.solutionset
			var submission = result.jsonArrayObj
			if (!result) {throw 'parse error'}
			if (!solution) {throw 'solution is not set'}
			if (solution.length !== submission.length) {
				return {error: 'Solution length mismatch'}
			}
			var solutionkeys = Object.keys(solution[0])
			var submissionkeys = Object.keys(submission[0])
			for (var p in solution[0]) {
				if (!submission[0].hasOwnProperty(p)) {
					return {error: 'Solution & Submission keys doesnt match'}
				}
			}
			if (solutionkeys.length !== submissionkeys.length) {
				return {error: 'Solution & Submission keys doesnt match'}
			}

			console.log("\n validation \n")
			console.log("result: ", result)
			console.log("current:", fetchedState)
			return result
		} catch(e) {
			console.log(e)
		}
	}

	const changeHandler = async (event) => {
		try {
			if (!event.target.files.length) {
				throw 'no file selected'
			}
			const result = await validateSubmission(event.target.files[0])
			if (result.error) {
				return alert(result.error)
			}
			await saveHandler({...result,...current,filename:event.target.files[0].name})
			setValues({...values, sandboxsolution: event.target.files[0], data: result.jsonArrayObj})
			console.log(result.jsonArrayObj)
		} catch(e) {
			console.log(e)
		}
	}
	
	useEffect(() => {
		console.log("selectedContest", selectedContest)
		if (!selectedContest.date) {return}
		fetchData(selectedContest)
	}, [selectedContest])

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
				<ContestDefinitionHeader {...{current,selecteddata: selectedContest,selectedpart}} />
				</Grid>
				<Grid item xs={12}>
					<SolutionGen />
				</Grid>
				<Grid item md={12} xs={12} sx={{ p: 3 }}>
					
					<InputFile 
							name = "sandboxsolution"
							label = "Upload solution"
							values = {values}
							changeHandler = {changeHandler}
						/>
					<Table>
						<TableHead>
							<TableRow sx={{background:'#f9f9f9'}}>
								<TableCell align="left"><Typography variant="body2">Name</Typography></TableCell>
								<TableCell align="right"><Typography variant="body2">Date</Typography></TableCell>
								<TableCell align="right"><Typography variant="body2">Public</Typography></TableCell>
								<TableCell align="right"><Typography variant="body2">Private</Typography></TableCell>
								<TableCell align="right"><Typography variant="body2">Benchmark</Typography></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							
								{!List.length ?
								<TableRow>
								<TableCell><Typography>no solutions yet</Typography></TableCell>
								</TableRow>
								: List.map((item,index) => (
									<TableRow key={index}>
										<TableCell align="left"><Typography variant="body2">{item.name}</Typography></TableCell>
										<TableCell align="right"><Typography variant="body2">{timeAgo(item.date,true)}</Typography></TableCell>
										<TableCell align="right"><Typography variant="body2">{item.publicscore}</Typography></TableCell>
										<TableCell align="right"><Typography variant="body2">{item.privatescore}</Typography></TableCell>
										<TableCell align="right"><Typography variant="body2"></Typography></TableCell>
									</TableRow>
								))
								}
							
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</>
	)
}

export default ContestSandbox
