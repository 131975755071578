import { 
	Typography, 
	Grid, 
	Divider,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	IconButton,
	Dialog,
	Box
} from '@mui/material'
import {
	EditOutlined as EditIcon,
	DeleteForeverOutlined as DeleteForeverIcon
} from '@mui/icons-material'
import { useEffect, useState, useContext, useCallback } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'
import CautionDialog from '../../components/CautionDialog'


const AppSummary = (props) => {
	const Navigate = useNavigate()
	const { token } = useContext(AuthContext)
	const { request } = useHttp()
	const { selectedApp, setSelectedApp, refresh, refreshCheck, selectData } =	useContext(NavContext)
	const [ subscriptions, setSubscriptions ] = useState([])
	const [ cautionOpen, setCautionOpen ] = useState(false)
	const [ cautionState, setCautionState ] = useState({open: false, data: {}})

	const fetchStatistics = useCallback(async (data) => {
		try {
			const body = {appkey: data.appkeydata.appkey, _id: data._id}
			const fetched = await request(
				`/api/productapp/statistics`, 
				'POST', 
				body, 	
				{Authorization: `Bearer ${token}`}
			)
			if (fetched.length) {
				setSubscriptions(fetched)
			}
			console.log("fetchStatistics:", fetched)
		} catch(e) {
			console.log(e)
		}
	}, [token, request])

	const deleteSubscription = async (value) => {
		try {
			const body = value
			const result = await request(
				`/api/subscribe/${value.api.ownerId}/${value.api.productId}/deletesubscription`,
				'POST',
				body,
				{Authorization: `Bearer ${token}`}
			)
			refresh()
		} catch(e) {
			console.log(e)
		}
	}

	const deleteHandler = (value) => {
		setCautionState({
			open: true, 
			data: value, 
			validate: value.api.title,
			text: `You are about to delete this subscription (${value.api.title})... Are you sure?`,
			action: deleteSubscription
		})
		//setCautionOpen(true)
		//deleteSubscription(value)
	}
	
	const editHandler = (value) => {
		Navigate(`/marketplace/solutions/${value.api.ownerId}/${value.api.productId}/plans`)
		console.log(value)
	}

	useEffect(() => {
		console.log("AppSummary selectedApp: ",selectedApp)
		console.log("AppSummary Props: ",props)
		if (selectedApp.appkeydata) {
			fetchStatistics(selectedApp)
		}
	}, [selectedApp])

	return (
		<><CautionDialog 
				cautionState={cautionState}
				setCautionState={setCautionState}
			/>
			<Grid container>
				<Grid item xs={12}>
					<Grid sx={{p:3}}>
						<Typography variant="h5">Сводка</Typography>
					</Grid>
					<Divider />
					<Grid sx={{p:3}}>
						<Typography variant="body1">Подписки</Typography>
					</Grid>
					<Grid>
						<TableContainer>
							<Table aria-label="Endpoints list">
								<TableHead sx={{ backgroundColor: '#fafafa' }}>
									<TableRow>
										<TableCell align="left" sx={{pl:3}}>API</TableCell>
										<TableCell align="right">Plan</TableCell>
										<TableCell align="right">Cost</TableCell>
										<TableCell align="right">Plan requests</TableCell>
										<TableCell align="right">Requests left</TableCell>
										<TableCell align="center" sx={{px:3}}>Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
								{!!subscriptions.length && subscriptions.map((subscription, index) => (
									<TableRow key={index}>
										<TableCell align="left" sx={{pl:3}}>{subscription.api.title}</TableCell>
										<TableCell align="right">{subscription.plan.title}</TableCell>
										<TableCell align="right">{subscription.plan.pricemonth}</TableCell>
										<TableCell align="right">{subscription.plan.requestslimit}</TableCell>
										<TableCell align="right">{subscription.subscriber.requestsleft}</TableCell>
										<TableCell size="small" align="right" width="1" sx={{backgroundColor:'#f9f9f9', px:3}}>
											<Box sx={{flexWrap:'nowrap', display:'flex'}}>
											<IconButton 
												component={Link}
												to={{pathname:`/marketplace/solutions/${subscription.api.ownerId}/${subscription.api.productId}/plans`}}
												target="_blank"
												>
												<EditIcon/>
											</IconButton>
											<IconButton onClick={()=>{deleteHandler(subscription)}}>
												<DeleteForeverIcon/>
											</IconButton>
											</Box>
										</TableCell>
										
									</TableRow>
								))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

export default AppSummary