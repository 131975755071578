import { useState,useEffect, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate, useOutlet } from 'react-router-dom'
import { experimentalStyled } from '@mui/material/styles';
import MainNavbar from './MainNavbar';
import DashboardSidebar from './DashboardSidebar';
import DashboardBottombar from './DashboardBottombar'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AuthContext } from '../context/AuthContext';
import {NavContext} from '../context/NavContext'
import mainmenu from '../__mocks__/mainmenu';
import accountmenu from '../__mocks__/accountmenu';


const DashboardLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);


const DashboardLayoutWrapper = experimentalStyled('div')(
  ({ theme }) => {
    return ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 48,
  paddingBottom: 48,
    [theme.breakpoints.up('md')]: {
    paddingBottom: 0,
    }
  })
  }
);

const DashboardLayoutContainer = experimentalStyled('div')({
  
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  backgroundSize: '10%',
  background: `linear-gradient(135deg, rgba(255, 255, 255, 1),rgba(255, 255, 255, 1),rgba(253, 253, 253, 1) )`,
});




const DashboardLayout = (props) => {
	const {token} = useContext(AuthContext)
	
	const Navigate = useNavigate()
	
	useEffect(() => {
		if(!token) {
			Navigate('/login')
		}
	})

	const currentOutlet = useOutlet()

	useEffect(() => {
		if (!currentOutlet) {Navigate('/marketplace')}
	},[currentOutlet])
	
	
  const theme = useTheme()
  const themedownMD = useMediaQuery(theme.breakpoints.down('md'))
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const Nav = useContext(NavContext)

  const WrapperVisible = () => {
    var res = [...mainmenu,...accountmenu,{pathname: 'channels',sidebar:true}].map((item) => {
      if (!!item.sidebar) {
        return item.pathname
      }
    }).filter(i=>!!i).indexOf(Nav.selected) !== -1
    return res
  }

  return (
    <DashboardLayoutRoot>
      <MainNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <DashboardLayoutWrapper sx={WrapperVisible() && !themedownMD && {pl:'255px'}} >
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
	  {themedownMD ? 
	  <DashboardBottombar onMobileNavOpen={() => setMobileNavOpen(true)} />
	  : null
	}
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;