import * as Yup from 'yup';
import { Formik, Form, Field, useField } from 'formik';

import {
	Box,
	Button,
	TextField,
	Typography,
	Dialog
} from '@mui/material';

const ConfirmDialog = (confirmState) => {
	const {open,setOpen,callback,item,validatename} = confirmState
	console.log('confirmState : ',confirmState)
	if (!setOpen || !callback || !item || !validatename) {return <></>}

	const handleClose = () => {
		setOpen({open:false})
	}
	
	const handleSubmit = (e) => {
		
		callback(item)
		setOpen({open:false})
	}

	return (
		<Dialog 
			open={open} 
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<Formik
				initialValues={{
					[validatename]: "",
				}}
				validationSchema={Yup.object().shape({
					[validatename]: Yup.string()
						.matches(item[validatename], {
							message: 'not equal',
							excludeEmptyString: false,
						})
						.max(255)
						.required(`Enter ${validatename}`),
				})}
				onSubmit={(values, { setSubmitting }) => {
					
					handleSubmit(values, { setSubmitting })
				}}
			>{({
					errors,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					touched,
					values,
				}) => (
					<Form autoComplete="off">
						<Box sx={{p:3,display:'flex',flexDirection:'column',gap:1}}>
							<Typography>Точно хочешь удалить "{item[validatename]}" ?</Typography>
							<TextField
								error={Boolean(touched[validatename] && errors[validatename])}
								helperText={touched[validatename] && errors[validatename]}
								name={validatename}
								margin="dense"
								label={`${item[validatename]}`}
								type="text"
								fullWidth
								value={values[validatename]}
								onBlur={handleBlur}
								onChange={handleChange}
							/>
							<Box sx={{display:'flex',gap:1}}>
								<Button variant="outlined" type="submit">Да</Button>
								<Button variant="outlined" onClick={handleClose}>Нет</Button>
							</Box>
						</Box>
					</Form>
				)}
			</Formik>
		</Dialog>
	)
}

export default ConfirmDialog