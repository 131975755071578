import { useEffect, useState, useContext } from 'react'
import {
	Box,
	Grid,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
	Collapse,
	TextField,
	InputAdornment,
	SvgIcon,
	Typography,
	Paper,
} from '@mui/material'
import { Search as SearchIcon } from 'react-feather'
import { ExpandLess, ExpandMore } from '@mui/icons-material'

import shortText from '../helpers/shortText'

import NavItem from './NavItem'
import { NavContext } from '../context/NavContext'

import AccountSelector from './AccountSelector'

const apiLinks = [
	{
		title: 'Definition',
		path: 'overview',
		disabled: false,
		links: ['overview','settings','endpoints','plans']
	},
	{
		title: 'Summary',
		path: 'summary',
		disabled: true
	},
	{
		title: 'Analytics',
		path: 'analytics',
		disabled: true,
	},
	{
		title: 'Alerts',
		path: 'alerts',
		disabled: true,
	},
	{
		title: 'Users',
		path: 'users',
		disabled: true,
	},
	{
		title: 'Support',
		path: 'support',
		disabled: true,
	},
]

const SidebarApi = (props) => {
	const selectData = props.selectData
	const [apiList, setApiList] = useState([])
	const [apiListTitle, setApiListTitle] = useState('Personal Apis')

	const [ selectedIndex, setSelectedIndex ] = useState('')
	const [ selectedApiState, setSelectedApiState ] = useState('')
	const { setSelectedApi, selectedOrg } = useContext(NavContext)

	const handleClick = (item, index) => {
		if (item === 'apilist') {
			if (selectedIndex === index) {
				return setSelectedIndex('')
			} else {
				return setSelectedIndex(index)
			}
		}
	}

	useEffect(() => {
		console.log("APiEDITOR . SIDEBAR . SELECTDATA: ", props)
		console.log('selectedOrg: ', selectedOrg)
		if (!!selectedOrg.title) {
			setApiListTitle(selectedOrg.title + ' APIs')
		} else {
			setApiListTitle('Personal APIs')
		}
		if (selectData) {
			if (selectData.filterdata) {
				const preparedData = selectData.filterdata
					.map((item, index) => {
						if (selectedOrg._id) {
							if (item.orgowner === selectedOrg._id) {
								return item
							}
						} else {
							if (!item.orgowner) {
								return item
							}
						}
					}).filter(i=>!!i)
				if (preparedData) {
					setApiList(preparedData)
				}
			}
		}
	}, [selectData, selectedOrg])

	useEffect(() => {
		if (!selectedApiState) {return}
		console.log(" SideBar: Selected Api : ", selectedApiState)
		setSelectedApi(selectedApiState)
	}, [selectedApiState])


	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<AccountSelector />
					<NavItem onClick={()=>{props.onMobileClose()}} title="Add New API" href="apiedit/addnew"  basepath="dash"/>
					<NavItem disabled={true} onClick={()=>{props.onMobileClose()}} title="Support" href="apiedit/support"  basepath="dash"/>
					<NavItem onClick={()=>{props.onMobileClose()}} title="Summary" href="apiedit"  basepath="dash"/>
				</Grid>
			</Grid>
			
			<Box sx={{ flexGrow: 0.5 }} />
			<Grid container>
				<Grid item xs={12}>
					<Box
						sx={{
							backgroundColor: 'background.default',
							display: 'flex',
							flexDirection: 'column',
							p: 1,
						}}
					>
						<TextField
							fullWidth
							size='small'
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SvgIcon fontSize="small" color="action">
											<SearchIcon />
										</SvgIcon>
									</InputAdornment>
								),
							}}
							placeholder="Search Api"
							variant="outlined"
							value=""
							
						/>

						<Typography sx={{ p: 1,pt: 2 }} variant="h6">
							{apiListTitle}
						</Typography>
						
					</Box>
				</Grid>
			</Grid>
			<Grid container overflow='auto'>
				<Grid item xs={12}>
					{!!apiList.length && apiList.map((item, index) => {
						return (
							<div key={index}>
								<ListItem
									sx={{borderBlockEnd: '1px solid', borderColor: '#eee'}}
									key={index}
									button
									onClick={() => {
										handleClick('apilist', index)
									}}
								>
									<ListItemIcon>
										{index === selectedIndex ? <ExpandLess /> : <ExpandMore />}
									</ListItemIcon>
									<Box flexGrow={1}/>
										<ListItemText sx={{ textAlign: 'end', pr:2}} primary={shortText(item.title,15)} />
								</ListItem>
								
								<Collapse
									in={index === selectedIndex}
									timeout="auto"
									unmountOnExit
									sx={{position:'relative', top:'-1px', backgroundColor: '#fff'}}
								>
									<Grid item xs={12}>
										{apiLinks.map((i, index) => (
											<div key={index}>{}
												<NavItem
													disabled={i.disabled}
													onClick={!i.disabled ? () => { setSelectedApiState( item );props.onMobileClose()} : ()=>{}}
													key={i.index}
													basepath={'dash'}
														end={false}
													title={i.title}
													links={i.links}
													endpath={i.path}
													href={!i.disabled ? "apiedit/" + item.ownerId + "/" + item.code + "/" + i.path : ''}
												/>

											</div>
										))}
									</Grid>
									<Divider />
								</Collapse>
							</div>
						)
					})}
				</Grid>
			</Grid>
		</>
	)
}

export default SidebarApi
