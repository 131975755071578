import {
	Box,
	Grid,
	Typography,
	Button,
	Divider,
	Collapse
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery';



const ContestHeader = ({productData}) => {
	
	const Navigate = useNavigate()
	
	const theme = useTheme()

	const breakpointDownMD = useMediaQuery(theme.breakpoints.down('md'))
	const [ off, setOff ] = useState(0)

	const handleScroll = (event) => {
		setOff(event.target.scrollTop)
	}

	useEffect(() => {
			window.addEventListener('scroll', handleScroll, true);
		return function cleanup() {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	return (
			<Grid item xs={12}
					sx={{
					position:'sticky',
					top:0,
					zIndex:10,
					background:	'white',
					...(!!off && {borderBottom: `1px solid ${theme.palette.border}`})
					}}
				>
				<Box sx={{display:'flex',flexDirection:'row'}}>
					<Box sx={{p:3,pb:2}}>
						<Typography variant='h5'>
							{productData.title}
						</Typography>
						<Grid item xs={12} md={8}>
							<Collapse in={!off}>
								<Typography variant={!!breakpointDownMD ? 'caption' : 'body2'}>
									{productData.shortdescription}
								</Typography>
							</Collapse>
						</Grid>
					</Box>
					<Box flexGrow="1"/>
					{!productData.subscriber &&
						<Box padding={3}>
							<Button sx={theme.customButtonOutline} onClick={()=>{Navigate('../rules')}}>Participate</Button>
						</Box>
					}
				</Box>
			</Grid>
	)
}

export default ContestHeader