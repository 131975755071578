import { Helmet } from 'react-helmet';

const PageTitle = ({title}) => {
    const basetitle = "Ai-Aggregator"
    const currentTitle = basetitle + " - " + title

    return (
        <Helmet title={currentTitle}/>
    )
}

export default PageTitle