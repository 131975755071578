import {createContext} from 'react'

function noop() {}

export const NavContext = createContext({
	setnav: noop,
   	selected: null,
	selectData: null,
	setData: noop,
	selectedApi: null,
	setSelectedApi: noop,
	selectedApp: null,
	setSelectedApp: noop,
	selectedRequest: null,
	setSelectedRequest: noop,
	selectedContest: null,
	setSelectedContest: noop,
	refresh: noop,
	refreshCheck: null,
	userdata: null,
	setUserdata: noop,
	selectedOrg: null,
	setSelectedOrg: noop,
	selectedChannel: null,
	setSelectedChannel: noop,

})