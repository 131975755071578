import { Typography, Grid, TextField, Button, Box, Collapse, Fade } from '@mui/material'
import { useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

const mockRequestHelper = `После отправки запроса на указанный вами адрес Email 
	придет письмо с ссылкой для подтверждения.
	Перейдите по ссылке чтобы дополнить данные, опубликовать и отслеживать активность по вашей заявке.
	`

const HomeRequestForm = () => {
	const theme = useTheme()
	const navigate = useNavigate()
	const { settext, errortext } = useMessage()
	const message = settext
	const { request, error, clearError } = useHttp()

	const initialstate = {
		title: '',
		shortdescription: '',
		longdescription: '',
		firstName: '',
		lastName: '',
		email: '',
	}
	const [ changeState, setChangeState ] = useState(initialstate)
	const [ readonly, setReadonly ] = useState(true)

	const fastRegisterHandler = async (formArr) => {
		try {
			const data = await request('/api/auth/fastrequest', 'POST', { ...formArr })
			message(data.message)
			//navigate('/dash')
		} catch (e) {}
	}

	const handleSubmit = (values) => {
		console.log(values)
		fastRegisterHandler(values)
	}

	return (
		<>
		
		<Collapse in={!readonly} timeout={2000} sx={{position:'relative'}}>
			<Formik
				initialValues={changeState}
				enableReinitialize
				validationSchema={Yup.object().shape({
					title: Yup.string().min(3).max(50).required('Необходимое поле'),
					email: Yup.string().min(3).max(30).email('Must be a valid email').required('Необходимое поле'),
					firstName: Yup.string().min(3).max(30).required('Необходимое поле'),
					lastName: Yup.string().min(3).max(30).required('Необходимое поле'),
					shortdescription: Yup.string()
						.min(3, 'Min ${min}')
						.max(200, 'Max ${max}')
						.required('Необходимое поле'),
					longdescription: Yup.string().min(3).max(1000, 'Max ${max}'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					handleSubmit(values, { setSubmitting })
				}}
			>
				{({ errors, handleBlur, handleChange, touched, resetForm, values }) => (
					<Form autoComplete="off">
						<Grid item container xs={12} sx={readonly ? { display: 'none' } : {}}>
							<Grid item xs={12} md={6} sx={{ p: 3 }}>
								<TextField
									error={Boolean(touched.title && errors.title)}
									fullWidth
									label={
										'Название ' +
										String(
											touched.title && errors.title ? errors.title + '. ' : ''
										)
									}
									name="title"
									onBlur={handleBlur}
									onChange={handleChange}
									required
									value={values.title}
									variant="outlined"
									disabled={readonly}
								/>
								<TextField
									sx={{ my: 3 }}
									fullWidth
									error={Boolean(
										touched.shortdescription && errors.shortdescription
									)}
									label={
										'Короткое описание. ' +
										String(
											touched.shortdescription && errors.shortdescription
												? errors.shortdescription + '. '
												: ''
										) +
										(' всего символов: ' +
											values.shortdescription.toString().length +
											' из ' +
											200)
									}
									name="shortdescription"
									onBlur={handleBlur}
									onChange={handleChange}
									required
									value={values.shortdescription}
									variant="outlined"
									disabled={readonly}
								/>
								<TextField
									multiline
									fullWidth
									rows={8}
									error={Boolean(
										touched.longdescription && errors.longdescription
									)}
									label={
										'Описание. ' +
										String(
											touched.longdescription && errors.longdescription
												? errors.longdescription + '. '
												: ''
										) +
										(' всего символов: ' +
											values.longdescription.toString().length +
											' из ' +
											1000)
									}
									name="longdescription"
									onBlur={handleBlur}
									onChange={handleChange}
									value={values.longdescription}
									variant="outlined"
									disabled={readonly}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={6}
								sx={{ p: 3, [theme.breakpoints.up('md')]:{pl: 0} }}
								display="flex"
								flexDirection="column"
							>
								<TextField
									sx={{ mb: 3 }}
									error={Boolean(touched.email && errors.email)}
									fullWidth
									label={
										'Email ' +
										String(
											touched.email && errors.email ? errors.email + '. ' : ''
										)
									}
									name="email"
									onBlur={handleBlur}
									onChange={handleChange}
									required
									value={values.email}
									variant="outlined"
									disabled={readonly}
								/>
								<TextField
									sx={{ mb: 3 }}
									error={Boolean(touched.firstName && errors.firstName)}
									fullWidth
									label={
										'Имя ' +
										String(
											touched.firstName && errors.firstName
												? errors.firstName + '. '
												: ''
										)
									}
									name="firstName"
									onBlur={handleBlur}
									onChange={handleChange}
									required
									value={values.firstName}
									variant="outlined"
									disabled={readonly}
								/>
								<TextField
									sx={{ mb: 3 }}
									error={Boolean(touched.lastName && errors.lastName)}
									fullWidth
									label={
										'Фамилия ' +
										String(
											touched.lastName && errors.lastName
												? errors.lastName + '. '
												: ''
										)
									}
									name="lastName"
									onBlur={handleBlur}
									onChange={handleChange}
									required
									value={values.lastName}
									variant="outlined"
									disabled={readonly}
								/>

								<Typography
									sx={
										readonly
											? { color: '#ccc', userSelect: 'none' }
											: { color: '#000' }
									}
								>
									{mockRequestHelper}
								</Typography>
								<Box flexGrow="1" />
								<Box justifyContent="right" display="flex">
								<Button
									variant="outlined"
									size="large"
									type="submit"
									disabled={readonly}
									sx={theme.customButtonOutline}
								>
									Отправить
								</Button>
								</Box>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
			</Collapse>
			{readonly &&
			<Grid container display="flex" >
			<Grid item xs={12} md={6} sx={{ zIndex: 0, p: 3,display:'flex',flexDirection:'column' }} borderRadius={1}>
				<Typography variant="body1" sx={{pt:1}}>
					Если вы еще не зарегистрировались на платформе вы можете заполнить форму отправки запроса на разработку с экспресс-регистрацией.
					Укажите необходимые характеристики решения в свободной форме.
				</Typography>
				{/*<>
					<Typography variant="h5">Видимость</Typography>
					<Typography variant="body2" sx={{pt:1}}>
						Заявка может быть как публичной так и приватной доступной только для указанной группы пользователей
					</Typography>
				</>

				<Typography variant="h5" sx={{pt:3}}>Конкурс</Typography>
				<Typography variant="body1" sx={{pt:1}}>
														
					Заявку можно опубликовать в
					маркетплейс и оформить в виде конкурса.
				</Typography>
				*/}
				<Box flexGrow="1"/>
				<Box>
					<Button
						onClick={() => {
							setReadonly(false)
						}}
						sx={theme.customButton}
					>
						Заполнить заявку
					</Button>
				</Box>
				</Grid>
			</Grid>
			}
			
		</>
	)
}

export default HomeRequestForm
