import { useEffect, useState } from 'react'

import {
	Typography,
	Grid,
	Box,
	Button,
	Card,
	CardContent,
	Pagination
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery';

import timeAgo from '../../helpers/timeAgo'


const FeedCard = ({event,callback}) => {
	const theme = useTheme()
	const breakpointDownMD = useMediaQuery(theme.breakpoints.down('md'))

	const [ raisedState, setRaisedState ] = useState(false)
	
	const handleClick = (event) => !!callback && callback(event)

	return (
		<Grid item xs={12} md={4} sx={{minWidth: '200pt'}}>
			<Card
				onClick={()=>{handleClick(event)}}
				onMouseOver= {()=> {setRaisedState(true)}}
				onMouseOut={()=> {setRaisedState(false)}}
				
				raised={raisedState}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					p: 1,
					height: '80pt',
					
					// flexGrow: 1,
					width:'100%',
					...(!!callback && {cursor: 'pointer'}),
					borderRadius: 4,
					boxShadow: '0px 0px 1px 1px rgba(0,0,0, 0.0)',
					border: `1px solid`,
					borderColor: 'borderColorIntensive',
					'&:hover': {
						boxShadow: '0px 0px 15px 1px rgba(0,0,0, 0.125)',
						border: `1px solid #fff`,
						zIndex: '99999',
						//border: `1px solid ${theme.palette.borderColor}`
					}
				}}
				
			>
				<Box sx={{m:0,p:1,pb:0,display:'flex',justifyContent:'space-between',alignItems:'start'}}>
					<Typography variant="body2">{event.title}</Typography>
					<Typography variant="caption">{event.date}</Typography>
				</Box>
				<Box sx={{m:0,p:1,pt:1,overflow:'auto',display:'grid'}}>
					<Typography variant="caption" sx={{lineHeight:1.25}}>{event.text}</Typography>
				</Box>
				{!!event.hidden && 
					<Box sx={{border:'1px solid #ccc',borderRadius:1,p:0.5,m:0.5}}>
						<Typography variant="caption">Событие скрыто</Typography>
					</Box>
					}
			</Card>
		</Grid>
	)
}

const FeedSlider = ({feedState: feedProps,callback,pagination}) => {
	const theme = useTheme()
	const [ feedState, setFeedState ] = useState()
	const { count, page, setPage } = pagination

	const handlePageChange = (event, value) => {
		setPage(value)
		console.log('CLIENT COUNT: ', event.target.count)
	}

	const getEventText = (e) => {
		switch (e.eventtype) {
			case "ORG_REGISTRATION":
				return {
					title: e.title,
					text: e.eventtext
				}
				break;
			default:
				return {
					title: e.eventtitle,
					text: e.eventtext
				}
				
				break;
		}
	}

	useEffect(() => {
		if (!!feedProps) {
			setFeedState(feedProps.map((e)=> ({...getEventText(e),date:timeAgo(e.date,true,true),event:e,hidden:e.hidden || false})))
		}
	}, [feedProps])

	return (
			<Grid container sx={{mt:theme.spacing(0),mb:1}} spacing={1} zeroMinWidth justifyContent="start">
				{!!feedState && feedState.slice(0,3).map((event)=>(
					<FeedCard {...{event,callback}}/>
				))}
				{(count > 1) &&
				<Grid item xs={12} sx={{display:'flex', justifyContent:'center'}}>
					<Pagination
						count={count * 1}
						page={page * 1}
						variant="outlined"
						shape="rounded"
						onChange={handlePageChange}
					/>
				</Grid>
				}
			</Grid>

	)
}

export default FeedSlider