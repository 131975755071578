import { useState, useContext, useEffect, useRef } from 'react'
import { useLocation, useParams, Outlet, Link, useNavigate } from 'react-router-dom'

import {
	Grid,
	Box,
	Typography,
	Divider,
	Snackbar,
	Alert
} from '@mui/material'

import { useTheme } from '@mui/material/styles'

import { NavContext } from '../../../context/NavContext'
import { AuthContext } from '../../../context/AuthContext'
import { useHttp } from '../../../hooks/http.hook'
import ContestOverview from './ContestOverview.market'
import ContestData from './ContestData.market'
import ContestLeaderboard from './ContestLeaderboard.market'
import ContestRules from './ContestRules.market'
import ContestSubmissions from './ContestSubmissions.market'
import {useMessage} from '../../../hooks/message.hook'
import MessageAlert from '../../../components/MessageAlert'
import ContestHeader from './Contest.header'

import Preloader from '../../../components/Preloader'

import ComponentOutlet from '../../../components/template/ComponentOutlet'

const initialState = {
	title: '',
	owner: '',
	update: '',
	views: '',
	description: '',
	longdescription: '',
	response: ''
}

const ContestView = (props) => {
	const productType = props.producttype
	const path = props.path

	let { user, product } = useParams()
	const { request } = useHttp()
	const NAV = useContext(NavContext)
	const theme = useTheme()
	const { isAuthenticated, token } = useContext(AuthContext)
	const { refresh } = useContext(NavContext)
	const [ productData, setProductData ] = useState(initialState)
	const { setMessage, messageText } = useMessage()

	const parts = [
		{
			path: 'overview',
			element: ContestOverview,
		},
		{
			path: 'data',
			element: ContestData,
		},
		{
			path: 'leaderboard',
			element: ContestLeaderboard,
		},
		{
			path: 'submissions',
			element: ContestSubmissions
		},
		{
			path: 'rules',
			element: ContestRules
		},
	]

	const fetchProduct = async () => {
		try {
			const authorization = !!token ? `Bearer ${token}` : ''
			const fetched = await request(
				`/api/market/type/${productType}/id?ownerId=${user}&product=${product}`,
				'GET',
				null,
				{ Authorization: authorization, page: 'productview'}
			)
			if (fetched.title) {
				console.log('FETCHED PRODUCTVIEW . SUCCESS : ', fetched)
				setProductData(fetched)
				NAV.setData({ user, product, productType })
			}
		} catch (e) {
			console.log('FETCHED PRODUCTVIEW . ERROR : ', e)
			setMessage(e)
		}
	}

	const participateHandler = async () => {
		console.log('participate')
		try {
			if (!token) {throw 'notoken'}
			const authorization = !!token ? `Bearer ${token}` : ''
			const result = await request(
				`/api/contest/participate/id?ownerId=${user}&product=${product}`,
				'POST',
				null,
				{ Authorization: authorization }
			)
			if (result.error) {
				setMessage(result.error)
			}
			refresh()
			console.log(result)
		} catch(e) {
			if (e == 'notoken') {
				setMessage('You need to login first.','error')
			}
			console.log(e)
		}
	}

	useEffect(() => {
		fetchProduct()
	}, [user, product])

	useEffect(() => {
		NAV.setnav('product')
	}, [NAV])

	if (!productData.title) {return (<Preloader />)}

	return (
		<>
			<MessageAlert {...{messageText,setMessage}} />
				
				<Box sx={{overflow:'scroll'}}>
				<ContestHeader productData={productData}/>
				<ComponentOutlet data={{...productData,user,product,token}} path={path} parts={parts} participateHandler={participateHandler}/>
			</Box>
		</>
	)
}

export default ContestView