import {Avatar, Box} from '@mui/material'


const AvatarComponent = ({avatar,text,raised,size,code}) => {
	function generateColor(num) {
		// Convert the number to a string and pad it with leading zeros to ensure it has 10 digits

		let str = num
		const regex = /[a-z]/i; // i flag makes it case insensitive
		const hasLetters = regex.test(str);
		
		if (hasLetters) {
			str = parseInt(str, 20).toString().split('').reverse().join('').padStart(10, '0');
		} else {
			str = num.toString().split('').reverse().join('').padStart(10, '0');
		}
		
		
		// Extract the first three digits and use them to determine the red, green, and blue components of the color
		let red = (parseInt(str.substring(0, 1)) * 100) % 256;
		let green = (parseInt(str.substring(1, 2)) * 100) % 256;
		let blue = (parseInt(str.substring(2, 3)) * 100) % 256;
		
		// Create a CSS color string using the RGB values
		let color = `rgb(${red}, ${green}, ${blue},0.5)`;
		
		// Return the color string
		return color;
	}

	let alt = ''
	
	let params = {
		sx:{
				background:'#fff',
				opacity: !raised && 0.8,
			},
		imgProps:{sx:{objectFit:'contain'}}
	}

	if (!!text) {
		alt = text.toString().charAt(0).toUpperCase()
	}

	if (!!size) {
		if (size == "big") {
			params = {
				sx:{...params.sx, width: 130, height: 130, p:0, boxShadow: '0px 0px 20px 1px rgba(0,0,0, 0.025)'},
				imgProps:{...params.imgProps}
			}
		}
	}

	if (!!avatar) {
		params = {...params,
			src: `${avatar}`,
			imgProps:{...params.imgProps}
		}
	} else {
		params = {...params,
			sx: {...params.sx,
				background:generateColor(code)
			},
			children: alt
		}
	}

	return (
		<Box sx={(size !== 'big' ? {border:"1px solid #f7f7f7",borderRadius:'50%',alignSelf: 'start'} : {})}>
			<Avatar
				variant="circle"
				{...params}
			/>
		</Box>
	)
}

export default AvatarComponent