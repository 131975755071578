import { useEffect, useState, useContext, useCallback } from 'react'
import {
	Box,
	Divider,
	Grid,
	TextField,
	Typography,
	Button
} from '@mui/material'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import SchemaTools from '../../helpers/schematools'
import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'
import ControlsComponent from '../../components/ControlsComponent'
import Preloader from '../../components/Preloader'
import PreviewButton from '../../components/PreviewButton'

let category = []

const RequestOverview = (props) => {
	const { compareObjects } = SchemaTools()
	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const { selectedRequest, refresh, refreshCheck,	setSelectedRequest, selectData } = useContext(NavContext)
	const [readonly, setReadonly] = useState(true)
	const [isSubmitting, setSubmitting] = useState(false)
	const [currentRequest, setCurrentRequest] = useState({
		title: '',
		ownerId: '',
		code: '',
		private: false,
		published: false,
		longdescription: '',
	})

	const [initialState, setInitialDataState] = useState({
		title: '',
		shortdescription: '',
		website: '',
		longdescription: '',
		category: '',
		terms: '',
	})

	const [changesState, setChangesState] = useState(initialState)

	const fetchDetails = async (value) => {
		try {
			const fetched = await request(
				`/api/request/owner/${value.ownerId}/${value.code}`,
				'GET',
				null,
				{
					Authorization: `Bearer ${token}`,
					part: 'overview',
				}
			)
			const requestdetaildata = compareObjects(initialState, {...fetched.RequestDetailData})
			const requestdata = compareObjects(initialState, {...fetched.RequestData},{keepold:true})
			const data = compareObjects(initialState, {...requestdata,...requestdetaildata})

			setChangesState(data)
			setInitialDataState(data)
			setCurrentRequest(fetched.RequestData)
			console.log(data)
		} catch (e) {
			console.log(e)
		}
	}

	const saveSettings = async (value) => {
		setSubmitting(true)
		var newdata = compareObjects(changesState,value,{minified: true})

		const requestResult = await request(
			'/api/request/update/details',
			'POST',
			{ _id: currentRequest._id, ...newdata },
			{ Authorization: `Bearer ${token}` }
		)
		refresh()
		setSubmitting(false)
	}

	const resetHandler = () => {
		var resetdata = compareObjects(changesState,initialState)
		setChangesState({ ...resetdata })
	}

	const handleSubmit = (e) => {
		saveSettings(e)
	}

	useEffect(() => {
		
		if (!selectedRequest.date) {return}
		fetchDetails(selectedRequest)
		if (selectData.categories) {
			category = [{_id: '', title: ''}, ...selectData.categories]
			console.log(category)
		}
	}, [selectedRequest])

	
	if (changesState.title == '') {return <Preloader />}
	return (
		<>
			<Formik
				initialValues={changesState}
				enableReinitialize
				validationSchema={Yup.object().shape({
					title: Yup.string().min(3).max(255).required('Enter title'),
					shortdescription: Yup.string()
						.min(3, 'Minimum symbols limit ${min}')
						.max(200, 'Maximum symbols limit exceeded ${max}')
						.required('This field is required'),
					longdescription: Yup.string()
						.min(3, 'Minimum symbols limit ${min}')
						.max(1000, 'Maximum symbols limit exceeded ${max}'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					handleSubmit(values, { setSubmitting })
					setReadonly(true)
				}}
			>
				{({ errors, handleBlur, handleChange, touched, resetForm, values }) => (
					<Form autoComplete="off">
						<Box sx={{display:'flex', p:3}}>
							<Typography variant='h5'>{'Describe Request ' + currentRequest.title}</Typography>
							<Box flexGrow="1"/>
							<PreviewButton product={{...selectedRequest, type: 'requests'}} />
						</Box>
						<Divider />
							<Grid item md={6} xs={12} sx={{p:2}}>
								<Box sx={{display: 'flex'}}>
								<Box sx={{ flex: 2, p: 1 }}>
								<TextField
									error={Boolean(touched.title && errors.title)}
									fullWidth
									helperText={touched.title && errors.title}
									label="Request title"
									name="title"
									onBlur={handleBlur}
									onChange={handleChange}
									required
									value={values.title}
									variant="outlined"
									disabled={readonly}
								/>
								</Box>
								<Box sx={{ flex: 1, p: 1 }}>
											<TextField
												fullWidth
												error={Boolean(touched.category && errors.category)}
												helperText={touched.category && errors.category}
												label="Category"
												name="category"
												onBlur={handleBlur}
												onChange={handleChange}
												select
												SelectProps={{ native: true }}
												value={values.category}
												variant="outlined"
												disabled={readonly}
											>
												{category.map((option, index) => 
												(
													<option disabled={index===0} key={option._id} value={option._id}>
														{option.title}
													</option>
												))}
											</TextField>
								</Box>
								</Box>
								<Box sx={{ p: 1 }}>
										
										<TextField
											fullWidth
											error={Boolean(
												touched.shortdescription && errors.shortdescription
											)}
											helperText={String(touched.shortdescription && errors.shortdescription ? errors.shortdescription + '. ' : '' ) + ("Total Symbols: " + values.shortdescription.toString().length + " of " + 150)}
											label={"Short description"}
											name="shortdescription"
											onBlur={handleBlur}
											onChange={handleChange}
											required
											value={values.shortdescription}
											variant="outlined"
											disabled={readonly}
										/>
									</Box>
									<Box sx={{p:1}}>
										<TextField
											multiline
											fullWidth
											rows={8}
											error={Boolean(touched.longdescription && errors.longdescription)}
											helperText={String(touched.longdescription && errors.longdescription ? errors.longdescription + '. ' : '' ) + ("Total Symbols: " + values.longdescription.toString().length + " of " + 1000)}
											label={"Long description"}
											name="longdescription"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.longdescription}
											variant="outlined"
											disabled={readonly}
											
											
										/>
									</Box>
							</Grid>
							<Divider />
							<Box sx={{p:3}}>
								<ControlsComponent
									readonly={{ setReadonly, readonly }}
									disabled={isSubmitting}
									resetHandler={resetHandler}
									resetform={resetForm}
								/>
							</Box>
					</Form>
				)}
			</Formik>
		</>
	)
}

export default RequestOverview
