import { useEffect, useState } from 'react'
import {
	Avatar,
	Popper,
	Fade,
	Box,
	ClickAwayListener,
	IconButton,
	Typography
} from '@mui/material'
import NavItem from './NavItem'

import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		backgroundColor: '#7799ff',
		color: '#7799ff',
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		'&::after': {
			position: 'absolute',
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			animation: 'ripple 1.2s infinite ease-in-out',
			border: '1px solid currentColor',
			content: '""',
		},
	},
	'@keyframes ripple': {
		'0%': {
			transform: 'scale(.8)',
			opacity: 1,
		},
		'100%': {
			transform: 'scale(2.4)',
			opacity: 0,
		},
	},
	}));

const PopupMenu = ({ actions, icon, list, emptymessage, sx, hideBadge }) => {

	const [ popIcon, setPopIcon ] = useState('G')
	const [ anchorEl, setAnchorEl ] = useState(null)
	const [ listState, setListState ] = useState([])
	const [ EmptyMessage, setEmptyMessage ] = useState('')
	const [ open, setOpen ] = useState(false)
	const [ hideBadgeState, setHideBadgeState ] = useState(hideBadge || false)
	const id = open ? 'transitions-popper' : undefined

	const handleClick = (item, index) => {
		setAnchorEl(item.currentTarget)
		setOpen(true)
		setHideBadgeState(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	useEffect(() => {
		if (!!icon) {setPopIcon(icon)}
		if (!!list && !!list.length) {
			setListState(list)
		}
		if (!!emptymessage) {setEmptyMessage(emptymessage)}
		if (!list.length) {setHideBadgeState(true)}
	},[icon,list,actions])

	return (
		<>
			<StyledBadge
				overlap="circular"
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				variant="dot"
				invisible={hideBadgeState}
			>
				<Avatar 
					sx={{ width: 42, height: 42, background:'#fff',color:'GrayText',fontSize:'18pt',overflow:'visible'}} 
					component={IconButton}  
					aria-describedby={id} 
					onClick={handleClick}
				>
					{popIcon}
				</Avatar>
			</StyledBadge>
			
			<Popper
				style={{ zIndex: 10000 }}
				id={id}
				open={open}
				anchorEl={anchorEl}
				transition
				onClose={handleClose}
			>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={handleClose}>
						<Fade {...TransitionProps} timeout={350}>
							<Box
								sx={{
									backgroundColor: 'background.default',
									p:1,
									boxShadow: '5px 10px 15px rgba(0,0,0,0.1)',
									borderRadius: 5,
									overflow: 'auto',
									display: 'grid',
									...sx 
								}}
							>
								<Box sx={{overflow: 'auto'}}>
									{!!listState && !!listState.length && listState.map((item, index) => (
											<NavItem
												key={index}
												title={item.title}
												href={item.href}
											/>
									))}
								</Box>
								{!listState && !!EmptyMessage &&
								<Typography variant="body2">{EmptyMessage}</Typography>
								}
								
								{!!actions && actions.length && actions.map((action, index) => (
									<NavItem key={index}
										title={action.title}
										href={action.href || null}
										onClick={action.callback}
									/>
								))
								}
							</Box>
						</Fade>
					</ClickAwayListener>
				)}
			</Popper>
		</>
	)
}

export default PopupMenu
