import { useContext, useCallback } from 'react'
import { useHttp } from './http.hook'
import { AuthContext } from '../context/AuthContext'
import { NavContext } from '../context/NavContext'

export const useData = () => {
	const { token, owner } = useContext(AuthContext)
	const {
		setData,
		setnav,
		selectedApp,
		refreshCheck,
		setSelectedApp,
		selectData,
		setUserdata,
		userdata,
	} = useContext(NavContext)
	const { request } = useHttp()

	const fetchOrg = useCallback(async () => {
		console.log("useData fetchOrg")
		try {
			const fetched = await request(`/api/org/owner`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})
			const { data } = fetched
			const filterdata = data.map((item) => ({
				_id: item._id,
				title: item.title,
				owner: item.owner,
				avatar: item.avatar
			}))
			setUserdata({ ...userdata, org: data })
			console.log("USERDATA HOOK: ", { ...userdata, org: data })
			return { filterdata, data }
		} catch (e) {
			console.log(e)
		}
	}, [token, request])

	const fetchApps = useCallback(async () => {
		console.log("useData fetchApp")
		try {
			const fetched = await request(`/api/productapp/owner`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})
			const { data, categories } = fetched
			const filterdata = data.map((item) => ({
				_id: item._id,
				title: item.title,
				ownerId: item.ownerId,
				code: item.code,
				orgowner: item.orgowner,
				appkeydata: item.appkeydata
			}))
			setUserdata({ ...userdata, apps: filterdata })
		} catch (e) {}
	}, [token, request])

	const clearData = () => {
		setUserdata({})
	}

	return {
		fetchOrg,
		fetchApps,
		clearData
	}
}
