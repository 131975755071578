import {
	Button,

} from '@mui/material'

const PreviewButton = (product) => {
	const {
		ownerId,
		code,
		type
	} = product.product

	// console.log("\n", "product:", ownerId, "\n")
	// console.log("\n", "product:", product.product, "\n")

	const handlePreview = () => {
		const link = `/marketplace/${type}/${ownerId}/${code}`
		window.open(link, '_blank')
	}

	return (
		<>
		<Button variant="outlined" onClick={handlePreview}>Предпросмотр</Button>
		</>
	)
}

export default PreviewButton