import {useEffect, useState, useContext} from 'react'

import { 
	Box, 
	Container, 
	Grid, 
	Typography, 
	Select, 
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	TableRow,
	TableCell,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	IconButton
} from '@mui/material'

import {
	EditOutlined as EditIcon,
	DeleteForeverOutlined as DeleteForeverIcon
} from '@mui/icons-material'

import { useHttp } from '../../hooks/http.hook'
import SchemaTools from '../../helpers/schematools'

import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'
import EndpointEditor from './EndpointEditor'
import EndpointItem from './EndpointItem'
import PreviewButton from '../../components/PreviewButton'

import methods from '../../__mocks__/methods'



const Endpoints = (props) => {

	const { compareObjects } = SchemaTools()

	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const { selectedApi, refresh, refreshCheck, setSelectedApi, selectData } = useContext(NavContext)
	const [ localRefresh, setLocalRefresh ] = useState(0)

	const [ endPointsList, setEndPointsList ] = useState([])
	const [ selectedEndpoint, setSelectedEndpoint ] = useState()

	const [ initialState, setInitialDataState ] = useState({
		title: '',
		shortdescription: '',
		website: '',
		longdescription: '',
		category: '',
		terms: '',
	})

	const setnav = useContext(NavContext)
	const auth = useContext(AuthContext)
	const [ method, setMethod ] = useState('create')

	const Sh = (value) => {
		return value.length > 20 ? value.substr(0,20) + "..." : value
	}

	const fetchApiEndpoints = async (value) => {
		console.log("Endpoints. Fetch data : ", value)
		try {
			const fetched = await request(
				`/api/product/owner/${value.ownerId}/${value.code}/endpoints`,
				'GET',
				null, 
				{
					Authorization: `Bearer ${token}`,
					part: 'endpoints'
				}
			)
			console.log('Endpoints . fetched : ', fetched)
			if (fetched.endpoints) {
				setEndPointsList(fetched.endpoints)
				console.log('Fetched endpoints : ', fetched.endpoints)
			}
		} catch (e) {
			console.log("Endpoints. Fetch error: ", e)
		}
	}

	const editEndpoint = async (value) => {
		setSelectedEndpoint({...value})
		setMethod('edit')
		console.log("Edit endpoint: ", value)
	}

	const deleteEndpoint = async (value) => {
		console.log(value)
		try {
			console.log(' . Delete Endpoint : ', value._id)
			if (!value._id) {return}
			const data = await request(
				`/api/product/owner/${selectedApi.ownerId}/${selectedApi.code}/${value.code}/delete`,
				'DELETE',
				{ ...value },
				{ Authorization: `Bearer ${token}` }
			)
			setLocalRefresh(Math.random()*10000)
			//console.log('DATA: ', data)
		} catch (e) {
			console.log('Delete... Error:', e)
		}
	}

	useEffect(() => {
		console.log('Endpoints. Selected Api : ', selectedApi)
		if (!selectedApi.ownerId || !selectedApi.code) {return}
		fetchApiEndpoints(selectedApi)
	}, [selectedApi, refreshCheck, localRefresh])

	return (
		<>
		<Grid container>
			<Grid item xs={12} sx={{p:3}}>
				<Box sx={{display:'flex'}}>
					<Typography variant='h5'> {'Endpoints ' + selectedApi.title} </Typography>
					<Box flexGrow="1"/>
					<PreviewButton product={{...selectedApi, type: 'solutions'}} />
				</Box>
			</Grid>
			<Grid item xs={12}><Divider /></Grid>
			
			<Grid item xs={12} sx={{p:3}}>
				<EndpointEditor selectedEndpoint={selectedEndpoint} method={method} setMethod={setMethod} selectedApi={selectedApi} setLocalRefresh={setLocalRefresh}/>
			</Grid>
			<Grid item xs={12}><Divider /></Grid>
			<Grid item xs={12}>
				
				{ endPointsList.length ? 
					<>
					
					<TableContainer>
						<Table aria-label="Endpoints list">
							<TableHead  sx={{backgroundColor: '#fafafa'}}>
								<TableRow>
									<TableCell />
									<TableCell component="th" scope="row"><Typography variant="body2">Title</Typography></TableCell>
									<TableCell align="right"><Typography variant="body2">Method</Typography></TableCell>
									<TableCell align="right"><Typography variant="body2">Description</Typography></TableCell>
									<TableCell align="right"><Typography variant="body2">Actions</Typography></TableCell>
									<TableCell><Typography variant="body2"></Typography></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{endPointsList.map((endpoint, index) => (
								<TableRow key={index}>
									<TableCell />
									<TableCell align="left">{Sh(endpoint.title)}</TableCell>
									<TableCell align="right">{methods.find(item => item._id == endpoint.method).title}</TableCell>
									<TableCell align="right">{Sh(endpoint.description)}</TableCell>
									<TableCell align="right">
										<IconButton onClick={()=>{editEndpoint(endpoint)}}>
											<EditIcon/>
										</IconButton>
										<IconButton onClick={()=>{deleteEndpoint({code: endpoint.code, _id: endpoint._id})}}>
											<DeleteForeverIcon/>
										</IconButton>
									</TableCell>
									<TableCell align="right"></TableCell>
								</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					</>
					:
					<Typography sx={{p:3}} variant='body2'> No endpoints added... </Typography>
				}
			</Grid>
		</Grid>
		</>
	)
}

export default Endpoints