import { useState, useEffect, useContext, useCallback } from 'react'

import * as shajs from 'sha.js'

import { Grid, Typography, Box, Divider } from '@mui/material'

import AccountProfileDetails from './AccountProfileDetails'
import AccountCard from './AccountCard'
import AccountEmailChange from './AccountEmailChange'
import AccountPassChange from './AccountPassChange'

import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook'
import { NavContext } from '../../context/NavContext'

const Account = () => {
	const { token } = useContext(AuthContext)
	const { request } = useHttp()
	const [ details, setDetails ] = useState([])
	const { setnav } = useContext(NavContext)

	const fetchDetails = useCallback(async () => {
		try {
			const fetched = await request(`../users/`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})
			console.log('Fetched: ', fetched)
			setDetails(fetched)
		} catch (e) {}
	}, [token, request])

	const handleSubmitDetails = async (values) => {
		console.log('HS: ', values)
		try {
			const data = await request(
				'../users/update',
				'POST',
				{ ...values },
				{ Authorization: `Bearer ${token}` }
			)
			console.log('DATA: ', data)
			setDetails(values)
		} catch (e) {
			console.log(e)
		}
	}

	const handleSubmitDanger = async (values, type) => {
		try {
			if (type === 'pass') {
				
				const data = await request(
					'/api/auth/updatepass',
					'POST',
					{ newpassword: values.newpassword, password: values.password },
					{ Authorization: `Bearer ${token}` }
				)	
				console.log('DATA: ', data)
				if (data.error) {
					throw data.error
				}
			}
			
		} catch (e) {
			return e
		}
	}

	const handleSubmitEmail = (values) => {
		console.log('handleSubmitEmail', values)
		handleSubmitDanger(values, 'email')
	}

	const handleSubmitPass = async (values) => {
		let error = null
		console.log('handleSubmitPass', values)
		await handleSubmitDanger(values, 'pass').then(
			(err) => {
				console.log("handleSubmitPass", err)
				error = err
				return
			}
		)
		if (error) {return error}
	}

	useEffect(() => {
		setnav('account')
		fetchDetails()
	}, [fetchDetails])

	return (
		<Grid container spacing={3} padding={3}>
			<Grid item xs={12} md={4}>
				<AccountCard details={details} />
				<Box sx={{ py: 3 }}>
					<Typography variant="h6">Danger zone</Typography>
					<Divider />
				</Box>
				<AccountEmailChange
					email={details.email}
					handleSubmitEmail={handleSubmitEmail}
				/>
				<AccountPassChange handleSubmitPass={handleSubmitPass} />
			</Grid>
			<Grid item xs={12} md={8}>
				<AccountProfileDetails
					details={details}
					submitHandler={handleSubmitDetails}
				/>
			</Grid>
		</Grid>
	)
}

export default Account
