
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  TextField,
  Typography,
  Collapse
} from '@mui/material';
import {Link} from 'react-router-dom'

const AccountCard = (props) => {
	const details = props.details
	if (!details) {return}
	return (
		<>
			<Card>
				<CardContent>
					<Box
						sx={{
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Avatar src={details.avatar} sx={{ height: 80, width: 80 }} />
						<Typography color="textPrimary" gutterBottom variant="h4">
							{details.firstName + ' ' + details.lastName}
						</Typography>
						<Typography color="textSecondary" variant="body1">
							{`${details.city} ${details.country}`}
						</Typography>
						<Typography color="textSecondary" variant="body1">
							{`${moment().format('hh:mm A')} ${details.timezone}`}
						</Typography>
					</Box>
				</CardContent>
				<Divider />
				<CardActions>
					<Button
						color="primary"
						fullWidth
						component={Link}
						to={{
							pathname: '/dash/user/' + details.ownerId,
						}}
						target="_blank"
					>
						View public profile
					</Button>
				</CardActions>
			</Card>
		</>
	)
}

export default AccountCard
