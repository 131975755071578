import { useEffect, useContext, useState, useCallback } from 'react'

import { Outlet, useOutlet, useNavigate, useParams } from 'react-router-dom'

import { useHttp } from '../../hooks/http.hook'
import { NavContext } from '../../context/NavContext'
import { AuthContext } from '../../context/AuthContext'

import { Box } from '@mui/material'

import RequestDash from './RequestDash'


const RequestEditor = () => {
	const currentPart = 'requestedit'
	const currentOutlet = useOutlet()
	const navigate = useNavigate()
	const { request } = useHttp()
	const params = useParams()
	const { token, owner } = useContext(AuthContext)
	const { setnav, selectData, setData, selectedRequest, setSelectedRequest, refreshCheck } = useContext(NavContext)
	const [ unfilteredData, setUnfilteredData ] = useState({})
	const [ initialized, setInitialized ] = useState(0)

	if (!!currentOutlet && params) {
		const { user, product } = params
		const candidate = !!selectData.filterdata ? selectData.filterdata.find((i)=> i.ownerId==user && i.code==product) : null
		setSelectedRequest(candidate)
	}

	const fetchData = useCallback(async () => {
		try {
			const fetched = await request(`/api/request/owner`, 'GET', null, {
				Authorization: `Bearer ${token}`,
			})
			const { data, categories } = fetched
			const filterdata = data.map((item) => ({
				_id: item._id,
				title: item.title,
				ownerId: item.ownerId,
				code: item.code,
				orgowner: item.orgowner,	
			}))
			setData({ filterdata, categories })
			setUnfilteredData(data)
		} catch (e) {}
	}, [token, request])

	const ProductCheck = (e) => {
		if (e.user !== owner) {
			return {ok: false, data: e, message: ' Request EDITOR: You are not an onwer '}
		} else {
			return { ok: true, data: e, message: ' Request EDITOR: Welcome back master' }
		}
	}
	
	const fillSelectedData = useCallback(
		(value) => {
			setSelectedRequest(value)
		},
		[selectedRequest]
	)

	useEffect(() => {
		//subscribe to change of Selected Request in NavContext
		//Change selectedRequest object to unfiltered Request data
		if (unfilteredData.length && selectedRequest) {
			let currentUnfiltered = unfilteredData.find(
				(item) => item._id === selectedRequest._id
			)
			// console.log(" API EDITOR:  currentUnfiltered : ", currentUnfiltered)
			// console.log(" API EDITOR:  selectedApi : ", setnav.selectedApi)
			// console.log(" API EDITOR:  Unfiltered Data : ", unfilteredData)
			fillSelectedData(currentUnfiltered)
		}
	}, [selectedRequest, unfilteredData])

	useEffect(() => {
		fetchData()
		setnav(currentPart)
	}, [fetchData, refreshCheck])

	const initialsetselected = useCallback(
		(value) => {
			if (initialized === 0) {
				setInitialized(1)
				console.log(`${currentPart} INITIALIZED`)
				setSelectedRequest(value)
			}
		},
		[initialized]
	)

	useEffect(() => {
		if (!currentOutlet) {
			return
		}
		if (initialized === 1) {
			return
		}
		if (!!params.user) {
			const result = ProductCheck(params)
			if (!result.ok) {
				console.log(result.message)
				return navigate('/dash/requestedit')
			} else {
				const current =
					selectData.filterdata &&
					selectData.filterdata.find((item, index) => {
						if (
							item.ownerId === result.data.user &&
							item.code === result.data.product
						) {
							return item
						}
					})
				if (current) {
					initialsetselected(current)
				}
			}
		}
	}, [ProductCheck, currentOutlet, fillSelectedData, initialized])

	return (
		<Box sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
			{!currentOutlet && <RequestDash /*data={!!unfilteredData.length && unfilteredData.map(item => ({title:item.title}))}*/ />}
			<Outlet />
		</Box>
	)
}

export default RequestEditor