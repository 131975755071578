const mainmenu = [
	{
		basepath: '/',
		pathname: 'marketplace',
		title: 'Маркетплейс',
		mobiletitle: 'Market',
		authorized: false,
		homeonly: false,
		bottom: false,
		sidebar: true,
		disabled: false
	},
	{
		basepath: '/dash/',
		pathname: 'apiedit',
		title: 'Апи',
		mobiletitle: 'API',
		authorized: true,
		homeonly: false,
		bottom: true,
		sidebar: true,
		disabled: false
	},
	{
		basepath: '/dash/',
		pathname: 'appedit',
		title: 'Приложение',
		mobiletitle: 'APP',
		authorized: true,
		homeonly: false,
		bottom: true,
		sidebar: true,
		disabled: false
	},
	{
		basepath: '/dash/',
		pathname: 'requestedit',
		title: 'Запрос',
		mobiletitle: 'REQ',
		authorized: true,
		homeonly: false,
		bottom: true,
		sidebar: true,
		disabled: true
	},
	{
		basepath: '/dash/',
		pathname: 'contestedit',
		title: 'Конкурс',
		mobiletitle: 'CON',
		authorized: true,
		homeonly: false,
		bottom: true,
		sidebar: true,
		disabled: true
	},
	{
		basepath: '/dash/',
		pathname: 'org',
		title: 'Организация',
		mobiletitle: 'ORG',
		authorized: true,
		homeonly: false,
		bottom: true,
		sidebar: true,
		disabled: false
	},
	{
		basepath: '/',
		pathname: 'register',
		title: 'Начать',
		mobiletitle: 'Начать',
		authorized: false,
		homeonly: true,
		bottom: false,
		sidebar: false,
		disabled: false
	}
]

export default mainmenu