import { useState, useEffect } from 'react'
import { Collapse, Grid, Typography, Divider, Button, Dialog, Box } from '@mui/material'
import OrgAppSelector from './OrgAppSelector'

const PlanSelector = (props) => {
	const { open, data } = props
	const { subscribeHandler,setOpenPlanSelector } = props.handlers
	const initialstate = {title:'',shortdescription:'',requestslimit:'',longdescription:''}
	const [ dataState, setDataState ] = useState(initialstate)
	const [ selectedAppKey, setSelectedAppKey ] = useState('')
	const [ selectedEndpoint, setSelectedEndpoint ] = useState()

	useEffect(() => {
		console.log(props.data)
		setDataState(props.data)
	},[props.data])

	return (
		<>{!!dataState &&
			<Dialog open={open}
				onClose={() => {setOpenPlanSelector(false)}}
			>
				<Grid container sx={{minWidth:"300px"}}>
					<Grid item xs={12} container sx={{p:3}}>
						<Grid item xs={6} display="flex" flexDirection="column">
							<Typography variant="h5">{dataState.title}</Typography>
							<Typography variant="body1">{dataState.shortdescription}</Typography>
							
							<Grid item xs={12} display="flex" flexDirection="column">
								<Box flexGrow="1"/>
								<Typography variant="caption">Plan details:</Typography>
								
								<Typography variant="body2">{dataState.longdescription}</Typography>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="caption">This plan incudes:</Typography>
							<Typography variant="body1">{dataState.requestslimit}</Typography>
							<Typography variant="caption">requests per month</Typography>
							<Typography variant="body1">{dataState.pricemonth}</Typography>
							<Typography variant="caption">price per month</Typography>
							<Typography variant="body1">{dataState.pricerequest}</Typography>
							<Typography variant="caption">price per request</Typography>
						</Grid>
						
					</Grid>
				<Grid item xs={12} sx={{p:2}}>
						<Divider />
						<Typography sx={{p:1}}>Select your App:</Typography>
						<OrgAppSelector setSelectedAppKey={setSelectedAppKey} selectedEndpoint={selectedEndpoint}/>
				</Grid>
				<Grid item container sx={{p:1}}>
							<Button 
								disabled={!selectedAppKey}
								fullWidth
								variant="outlined" 
								onClick={()=>{subscribeHandler({appkey:selectedAppKey, plancode: dataState.code})}}
							>
								Subscribe
							</Button>
					</Grid>
				</Grid>
			</Dialog>
			}
		</>
	)
}

export default PlanSelector