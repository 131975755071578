import { useEffect, useState, useContext, useCallback, useImperativeHandle, forwardRef } from 'react'

import {
	Typography,
	TextField,
	Box,
	Button,
	Grid
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import * as Yup from 'yup'
import { Formik, Form, FieldArray } from 'formik'

const DescriptionEditor = ({data,readonly,handleGather,stateValues},ref) => {
	const theme = useTheme()
	useImperativeHandle(ref, () => ({
		submit: handleSubmitMyForm,
		reset: handleReset
	}))
	const {
		shortdescription,
		title,
		website
	} = data

	const initialState = {
		title: '',
		shortdescription: '',
		website: ' ',
		longdescription: '',
	}

	const maxAdditionalFields = 5

	const initialValidationSchemaState = {
		title: Yup.string().min(3).max(255).required('Укажите название'),
		website: Yup.string().min(3).max(255).required('Укажите вебсайт проекта'),
		shortdescription: Yup.string()
		.min(3)
		.max(300,"> ${max}")
		.required("Поле обязательно"),
		prod_info: Yup.array()
		.of(
			Yup.object().shape({
				title: Yup.string().min(3).max(255, "> ${max}"),
				text: Yup.string()
				.min(3)
				.max(2000,"> ${max}").required("Поле обязательно"),
			})
		)
		.test({
			message: 'Максимальнное количество блоков: ${max}',
			test: arr => arr.length < maxAdditionalFields,
		})
	}

	const [ initialValues, setInitialValues ] = useState(initialState)
	const [ validationSchemaState, setValidationSchemaState ] = useState(initialValidationSchemaState)
	const [ prodState, setProdState ] = useState([])

	let submitMyForm = null
	let validateMyForm = null
	let resetForm = null

	const handleReset = () => {
		resetForm()
	}
	const handleSubmitMyForm = () => {
		if (!!submitMyForm && !!validateMyForm) {
			let values = null
			return new Promise((resolve, reject) => {
				submitMyForm()
				.then((v) => {
					values = v
					return validateMyForm()
				})
				.then((errors) => {
					const isValid = Object.keys(errors).length == 0
					if (!!isValid) {
						resolve(values)
						} else {
						reject(errors)
					}
				})
			}).catch((e) => {
				console.log('error: ', e)
			})
		}
	}

	const bindSubmitForm = (value) => {
		submitMyForm = value.submitForm
		validateMyForm = value.validateForm
		resetForm = value.resetForm
	}

	useEffect(() => {
		console.log("InitialValues: ", initialValues)
		console.log("data: ", data)
		if (!!data.title) {
			setInitialValues({...initialState, 
				...data, 
				...(!!data.prod_info && data.prod_info.length &&
					{prod_info:  data.prod_info.map((p)=>({title:p.title,text:p.text}))} 
					)
			})
		}
	}, [data])

	return (
		<>	
			<Formik
				
				initialValues={initialValues}
				enableReinitialize
				validationSchema={Yup.object().shape(validationSchemaState)}
				onSubmit={(values)=>{
					return values
				}}
			>
				{(formikProps) => {
					var {
						errors,
						handleBlur,
						handleChange,
						touched,
						values,
						
					} = formikProps
					bindSubmitForm({
						submitForm: formikProps.submitForm,
						validateForm: formikProps.validateForm,
						resetForm: formikProps.resetForm
					})
					return (
						<Form autoComplete="off">
							<Box sx={{display:'flex',flexDirection:'column',gap:1.5}}>
								<Grid container spacing={1}>
									<Grid item xs={12} md={6}>
										<TextField
											error={Boolean(touched.title && errors.title)}
											fullWidth
											helperText={touched.title && errors.title }
											label="Название"
											name="title"
											onBlur={handleBlur}
											onChange={handleChange}
											required
											value={values.title}
											variant="outlined"
											disabled={readonly}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											error={Boolean(touched.website && errors.website)}
											fullWidth
											helperText={touched.website && errors.website }
											label="Ссылка на проект"
											name="website"
											onBlur={handleBlur}
											onChange={handleChange}
											required
											value={values.website}
											variant="outlined"
											disabled={readonly}
										/>
									</Grid>
								</Grid>
								<TextField
									error={Boolean(touched.shortdescription && errors.shortdescription)}
									fullWidth
									multiline
									rows='3'
									helperText={touched.shortdescription && errors.shortdescription }
									label="Короткое описание"
									name="shortdescription"
									onBlur={handleBlur}
									onChange={handleChange}
									required
									value={values.shortdescription}
									variant="outlined"
									disabled={readonly}
								/>
								{!readonly &&
								<Box sx={{p:2,border:`1px solid ${theme.palette.borderColorLight}`,borderInline:0}}>
									<Typography variant="caption" sx={{color:'#777'}}>
										Блоки для дополнительной информации ( заполнение необязательно, но желательно ) <br/>
										Заголовок не обязателен, но желателен для целостности восприятия информации.<br/>
										В описании вы можете использовать HTML разметку ( в разумных пределах )<br/>
										Максимальное количество дополнительных блоков: { maxAdditionalFields }
									</Typography>
								</Box>
								}
								<FieldArray
									name="prod_info"
									render={({ move, swap, push, insert, unshift, pop, remove }) => (
										<Form autoComplete='off'>
										{!!values.prod_info && values.prod_info.map((prod,index) => {
											console.log(prod)
											return (
											<Box sx={{display:'grid',gap:1,pb:1}} key={index}>
												<Box sx={{display:'flex',gap:1}}>
												<TextField
													
													error={Boolean(
														!!touched.prod_info &&
														!!touched.prod_info[index] &&
														touched.prod_info[index].title &&
														!!errors.prod_info &&
														!!errors.prod_info[index] &&
														errors.prod_info[index].title)
													}
													helperText={
														!!touched.prod_info &&
														!!touched.prod_info[index] &&
														touched.prod_info[index].title &&
														!!errors.prod_info &&
														!!errors.prod_info[index] &&
														errors.prod_info[index].title 
													}
													label="Заголовок"
													fullWidth
													name={`prod_info[${index}].title`}
													value={prod.title}
													onBlur={handleBlur}
													onChange={handleChange}												
													variant="outlined"
													
													disabled={readonly}
												/>
												<Button variant="outlined" disabled={readonly} onClick={() => remove(index)} size="small">
													Удалить блок
												</Button>
												</Box>
												<TextField 
													error={Boolean(
														!!touched.prod_info &&
														!!touched.prod_info[index] &&
														touched.prod_info[index].text &&
														!!errors.prod_info &&
														!!errors.prod_info[index] &&
														errors.prod_info[index].text)
													}
													helperText={
														!!touched.prod_info &&
														!!touched.prod_info[index] &&
														touched.prod_info[index].text &&
														!!errors.prod_info &&
														!!errors.prod_info[index] &&
														errors.prod_info[index].text 
													}
													label="Описание"
													fullWidth
													multiline
													name={`prod_info[${index}].text`}
													value={prod.text}
													onBlur={handleBlur}
													onChange={handleChange}												
													variant="outlined"
													disabled={readonly}
													required
												/>
											</Box>
										)})
										}
										{!readonly &&
										<>
										{
													values.prod_info && 
													(
														(values.prod_info.length == maxAdditionalFields) || 
														(values.prod_info.length > maxAdditionalFields)
													) &&
													<Typography>
														Максимальное количество дополнительных блоков: { maxAdditionalFields }
													</Typography>
													}
											<Button
												disabled={
													values.prod_info && 
													(
														(values.prod_info.length == maxAdditionalFields) || 
														(values.prod_info.length > maxAdditionalFields)
														)
													}
												variant="outlined" fullWidth onClick={() => push({ title: '', text: '' })}>
												Добавить блок
											</Button>
										</>
										}
										</Form>
									)}
									/>
								
								
							</Box>
						</Form>
				)}
				}
			</Formik>
			<Box sx={{mb:10}}/>
		</>
	)
}

export default forwardRef(DescriptionEditor)