import { useState, useEffect, useMemo, useCallback } from 'react'
import Immutable from 'immutable'
import {Editor, EditorState, RichUtils, DefaultDraftBlockRenderMap, convertToRaw } from 'draft-js'
import 'draft-js/dist/Draft.css'
import {
	Typography,
	TextField,
	Button,
	Box
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import MUIRichTextEditor from 'mui-rte'
import TextViewer from './TextViewer'
import theme from '../theme'
const myTheme = theme

const TextEditor = (props) => {
	const {
		value,
		onChange,
		name,
		disabled,
		label
	} = props
	console.log('TEXT EDITOR PROPS: ', props)
	const [ textState, setTextState ] = useState()
	const [ rawState, setRawState ] = useState()


	const changeHandler = (value) => {
		
		const raw = convertToRaw(value.getCurrentContent())
		if (JSON.stringify(raw) == JSON.stringify(rawState)) {return}
		setRawState(raw)
		const event = {
			type: 'change',
			target: {
				name: name,
				value: JSON.stringify(raw)
			}
		}
		onChange(event)
		//setTextState(JSON.stringify(raw))
		//console.log("changeHandler: ",raw)
	}

	const saveHandler = (value) => {
		setTextState(value)
		console.log("SAVE:")
		console.log(value)
	}
	
	useEffect(() => {
		if (!value) {
			setTextState(JSON.stringify(convertToRaw((EditorState.createEmpty()).getCurrentContent())))
		} else {
			try {
				if (value == JSON.stringify(rawState)) {
					console.log('EQUAL')
					return
				}
				JSON.parse(value)
				setTextState(value)
				return
			} catch(e) {
				setTextState(JSON.stringify(convertToRaw((EditorState.createEmpty()).getCurrentContent())))
				return
			}
		}
	}, [value])
	
	return (
		<>
		<ThemeProvider theme={myTheme}>
		{!!textState &&
			<Box>
			{label && <Typography variant="caption">{label}</Typography>}
			<Box sx={{border:'1px solid',borderColor:'#eee', borderRadius:1,px:1}}>
				
				<MUIRichTextEditor
				
					readOnly={disabled}
					defaultValue={textState}
					maxLength={2000}
					onChange={(EditorState) => {changeHandler(EditorState)}}
					onSave={(text) => {saveHandler(text)}}
				/>
				
				<Box padding={3}/>
			</Box>
			</Box>
		}
		</ThemeProvider>
		</>
	)
}

export default TextEditor