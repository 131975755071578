import { useState, useEffect, useContext } from 'react'
import {
	Grid,
	Box,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from '@mui/material'
import ContestHeader from './Contest.header'
import InputFile from '../../../components/InputFile'
import csvjson from '../../../helpers/csvjson'
import SchemaTools from '../../../helpers/schematools'
import { AuthContext } from '../../../context/AuthContext'
import { useHttp } from '../../../hooks/http.hook'
import timeAgo from '../../../helpers/timeAgo'

const ContestLeaderboard = (props) => {
	const serverpath = "/api/contest"
	const initialState = {
		publicpercent: void 0,
		title: '',
		traindatalink: '',
		testdatalink: '',
		solution: '',
		sample: '',
		metric: '',
	}
	const productData = props.data
	const { token } = useContext(AuthContext)
	const { request, error, clearError } = useHttp()
	const { compareObjects } = SchemaTools()
	const [ fetchedState, setFetchedState ] = useState(initialState)
	const current = { ownerId: props.data.user, code: props.data.product }

	const fetchData = async (value) => {
		try {
			const fetched = await request(
				`${serverpath}/leaderboard/id?ownerId=${value.ownerId}&product=${value.code}`,
				'GET',
				null,
				{
					Authorization: `Bearer ${!!token ? token : ''}`,
				}
			)
			console.log(fetched)
			const data = compareObjects(initialState, {...fetched.ContestData},{keepold:true})
			const dataset = compareObjects(initialState, {...fetched.ContestDataset})
			const resultdata = compareObjects(initialState, {...data,...dataset})

			setFetchedState(fetched.data)
			//setCurrent(fetched.ContestData)
			
			console.log("ContestLeaderboard FETCH",fetched)
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(()=>{
		fetchData(current)
	},[])

	return (
		<>
			<Grid container>
			<Grid item xs={12}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={{pl:3}}><Typography>#</Typography></TableCell>
							<TableCell><Typography>Participant</Typography></TableCell>
							<TableCell><Typography>Public Score</Typography></TableCell>
							<TableCell><Typography>Date</Typography></TableCell>
							<TableCell sx={{pr:3}}><Typography>Entries</Typography></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{!!fetchedState && !!fetchedState.length && fetchedState.map((item, index) => (
							<TableRow key={index}>
								<TableCell sx={{pl:3}}>{index + 1}</TableCell>
								<TableCell>{item.participant}</TableCell>
								<TableCell>{item.score}</TableCell>
								<TableCell>{timeAgo(item.date)}</TableCell>
								<TableCell sx={{pr:3}}>{item.entries}</TableCell>
							</TableRow>
						))
						}
					</TableBody>
				</Table>
			</Grid>
			</Grid>
		</>
	)
}

export default ContestLeaderboard