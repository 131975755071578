import {Button} from '@mui/material'

const SubmitButton = ({disabled,title}) => {
	return (
		<Button
			color="primary"
			disabled={disabled}
			sx={{
				'&:hover': {
					background: '#000',
				},
				borderRadius: 10,
				background: '#1e1e1e',
				color: '#fefefe',
				py:2,
				px:5,
				textTransform: 'none'
			}}
			size="large"
			type="submit"
			variant="outlined"
		>
			{title}
		</Button>
	)
}

export default SubmitButton


