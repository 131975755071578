import { useState, useContext, useEffect } from 'react'
import {
	Box,
	Container,
	Grid,
	Typography,
	Select,
	Button,
	CardContent,
	TextField,
	Fade,
	Collapse,
	Divider,
} from '@mui/material'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import ControlsComponent from '../../components/ControlsComponent'

import SchemaTools from '../../helpers/schematools'

import methods from '../../__mocks__/methods'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'
import ParametersEditor from './ParametersEditor'
import BodyEditor from './BodyEditor'

const EndpointEditor = (props) => {
	const editormethod = props.method
	const setEditorMethod = props.setMethod
	const selectedApi = props.selectedApi
	const setLocalRefresh = props.setLocalRefresh
	const { compareObjects } = SchemaTools()
	const [editor, setEditor] = useState()
	const [checked, setChecked] = useState(false)

	const [isSubmitting, setSubmitting] = useState(false)
	const [readonly, setReadonly] = useState(true)

	const [ meth, setMeth ] = useState(0)
	const [ currentEndpoint, setCurrentEndpoint ] = useState()
	const [ initialState, setInitialState ] = useState({
		title: '',
		description: '',
		method: '0',
		path: '',
		headers: '',
		query: '',
		body: '',
	})

	const [changesState, setChangesState] = useState(initialState)
	
	const resetHandler = () => {
		setEditor(false)
		setMeth('0')
		var newdata = compareObjects(changesState, initialState)
		setChangesState({ ...newdata })	
	}

	const { refresh, refreshCheck, setSelectedApi, selectData } = useContext(NavContext)
	const { token } = useContext(AuthContext)
	const { request } = useHttp()

	const [ harvestedData, setHarvestedData ] = useState({})

	const saveSettings = async (value) => {
		try {
			let endId = null
			if (editormethod == 'edit') {
				console.log('edit')
				console.log(currentEndpoint._id)
				endId = currentEndpoint._id
			}
			setSubmitting(true)
		
			console.log('Edit Endpoint . Save Settings: ', value)
			var newdata = compareObjects(changesState,value,{minified: true})
			var newwithharvesteddata = {...newdata,...harvestedData}
			console.log("Current api : ", selectedApi)
			console.log("harvestedData: ", harvestedData)
			console.log('Settings to save: ', newwithharvesteddata)
			
			
			const data = await request(
				`/api/product/owner/${selectedApi.ownerId}/${selectedApi.code}/endpoints/${editormethod}`,
				'POST',
				{ _id: selectedApi._id, ...newwithharvesteddata, endId},
				{ Authorization: `Bearer ${token}` }
			)

			console.log('Result Data : ', data)
			console.log("Readonly : ", readonly)

			// if (data.apiData) {
			// 	setSelectedApi({...currentApi,...data.apiData})
			// }
			
			setLocalRefresh(Math.random()*10000)
			//refresh()
			setSubmitting(false)
			resetHandler()
		} catch (e) {}
	}
	
	const harvestChanges = (value) => {
		setHarvestedData({...harvestedData, ...value})
		console.log("HARVESTED: ", harvestedData)
		console.log("HARVESTED: ", value)
		console.log("HARVESTED SPREAD: ", {...harvestedData, ...value})
		return harvestedData
	}

	const handleSubmit = async (e, setSubmitting) => {
		saveSettings(e)
	}


	useEffect(() => {
		if(props.selectedEndpoint) {
			console.log("Endpoint editor: selectedEndpoint: ", props.selectedEndpoint)
			setEditor(true)
			setReadonly(false)
			setMeth(props.selectedEndpoint.method || 0)
			//setChangesState(compareObjects(changesState,props.selectedEndpoint,{keepold: true}))
			console.log("WITH COMPARE", compareObjects(changesState,props.selectedEndpoint,{keepold: true}))
			console.log("WITHOUT COMPARE", props.selectedEndpoint)
			setCurrentEndpoint(props.selectedEndpoint)
			setChangesState(compareObjects(changesState,props.selectedEndpoint,{keepold: true}))
		}
	}, [props.selectedEndpoint])

	return (
		<>
			<Grid item>
				{!editor ? (
					<Button
						
						variant="outlined"
						onClick={() => {
							setEditor(true)
							setReadonly(false)
							setEditorMethod('create')
						}}
					>
						Add
					</Button>
				) : (
					''
				)}
			</Grid>
			<Collapse in={editor} timeout={700}>
				<Formik
					initialValues={changesState}
					enableReinitialize
					validationSchema={Yup.object().shape({
						title: Yup.string().min(3).max(255).required('Enter title'),
						method: Yup.string()
							.required('Select method')
							.test(
								'sm',
								' required',
								(value) => !/(0)/.test(value)
							)
							.test(
								'ts',
								'ts',
								(value) => {setMeth(value); return true}
							),
						path: Yup.string().min(1).max(50),
						description: Yup.string()
							.min(3,'Minimum symbols: ${min}')
							.max(200, 'Maximum symbols limit exceeded ${max}')
							.required('This field is required'),
					})}
					onSubmit={(values, { setSubmitting, resetForm }) => {
						handleSubmit(values, { setSubmitting })
						//setReadonly(true)
						//resetForm({})
						//console.log("FORMIK SUBMIT")
					}}
				>
					{({
						errors,
						handleBlur,
						handleChange,
						touched,
						resetForm,
						values,
					}) => (
							<Grid item>
								<Form autoComplete="off">
									<Box sx={{ pb: 2, pr: 2 }}>
										<ControlsComponent
											visible={editor}
											readonly={{ setReadonly, readonly }}
											disabled={isSubmitting}
											resetHandler={resetHandler}
											resetform={resetForm}
										/>
									</Box>
									<Grid container spacing={2	}>
										<Grid item md={6} xs={12}>
											<Box sx={{ display: 'flex' }}>
												<Box sx={{ flex: 2, pb: 2, pr: 2 }}>
													<TextField
														error={Boolean(touched.title && errors.title)}
														fullWidth
														label="Endpoint title"
														name="title"
														onBlur={handleBlur}
														onChange={handleChange}
														required
														value={values.title}
														variant="outlined"
														disabled={readonly}
													/>
												</Box>
												<Box sx={{ flex: 1, pb: 2 }}>
													<TextField
														fullWidth
														error={Boolean(touched.method && errors.method)}
														label={'Method' + (!!errors.method ? errors.method : '')}
														name="method"
														onBlur={handleBlur}
														onChange={handleChange}
														required
														select
														SelectProps={{ native: true }}
														value={values.method}
														variant="outlined"
														disabled={readonly}
													>
														{methods.map((option, index) => (
															<option
																disabled={index === 0}
																key={index}
																value={option._id}
															>
																{option.title}
															</option>
														))}
													</TextField>
												</Box>
											</Box>
											<Box sx={{ flex: 2, pb: 2 }}>
												<TextField
													error={Boolean(touched.path && errors.path)}
													fullWidth
													helperText={touched.path && errors.path}
													label="Path"
													name="path"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.path}
													variant="outlined"
													disabled={readonly}
												/>
											</Box>
										</Grid>
										<Grid item md={6} xs={12}>
											<Box
												sx={{
													height: '8em',
													overflow: 'visible',
													pb: 2,
													
												}}
											>
												<TextField
													error={Boolean(
														touched.description && errors.description
													)}
													fullWidth
													label="Description"
													name="description"
													onBlur={handleBlur}
													onChange={handleChange}
													required
													value={values.description}
													variant="outlined"
													disabled={readonly}
													multiline
													rows={4}
												></TextField>
											</Box>
										</Grid>
									</Grid>
								</Form>
							</Grid>
					)}
				</Formik>
				<Grid container spacing={2}>
										<Grid item md={12} xs={12}>
											<Typography variant="h6" sx={{ pb: 1 }}>
												Headers
											</Typography>
											<ParametersEditor
												changesState={changesState.headers}
												name='headers'
												editor={editor}
												harvester={harvestChanges}
											/>
										</Grid>
										<Grid item md={12} xs={12}>
											<Typography variant="h6" sx={{ pb: 1 }}>
												Query
											</Typography>
											<ParametersEditor
												changesState={changesState.query}
												name='query'
												editor={editor}
												harvester={harvestChanges}
											/>
										</Grid>
									
									{meth !== '1' && meth !== '0' ? (
										<Grid item md={12} xs={12}>
											
											<Typography variant="h6" sx={{ pb: 1 }}>
												Body
											</Typography>
											<BodyEditor 
												changesState={changesState.body}
												name='body'
												editor={editor}
												harvester={harvestChanges}
											/>
										</Grid>
									) : (
										''
									)}
									</Grid>
			</Collapse>
		</>
	)
}

export default EndpointEditor
