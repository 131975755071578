import {
	Box,
	Typography,
	Divider
} from '@mui/material'
import PreviewButton from '../../components/PreviewButton'

const ContestDefinitionHeader = (props) => {
	const {selectedpart,selecteddata} = props
	if (!selectedpart || !selecteddata) {return <></>}
	const label = selectedpart.label.charAt(0).toUpperCase() + selectedpart.label.slice(1).toLowerCase()
	const description = selectedpart.description.charAt(0).toUpperCase() + selectedpart.description.slice(1)

	return (
		<>
			<Box sx={{ display: 'flex', p: 3 }}>
				<Box>
					<Typography variant="h5">{label}</Typography>
					<Typography variant="body2">{description}</Typography>
				</Box>
				<Box flexGrow="1" />
				<Box>
					<PreviewButton product={{ ...selecteddata, type: 'contests' }} />
				</Box>
			</Box>
			<Divider />
		</>
	)
}

export default ContestDefinitionHeader
