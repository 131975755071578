import { useState, useContext } from 'react'
import { Formik, Form } from 'formik'
import {
	Box,
	TextField,
	Button
} from '@mui/material'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'

const CategoryEditor = () => {
	const serverpath = '/api/tag'
	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const [ openEditor, setOpenEditor ] = useState(false)
	const [ valuesState, setValuesState ] = useState({
		title: '',
		ru: '',
		eng: ''
	})

	const handleOpenEditor = () => {
		setOpenEditor(!openEditor)
	}

	const sendData = async (values) => {
		try {
			const body = values
			const fetched = await request(
			`${serverpath}/add`,
			'POST',
			body,
			{
				Authorization: `Bearer ${token}`
			}
			)
			if (!fetched || !fetched.data) {throw ''}
			
		} catch(e) {
			console.log(e)
		}
	}

	const handleSaveEditor = (values) => {
		console.log('handleSaveEditor: ', values)
		sendData(values)
		handleOpenEditor()
	}

	return (
		<Box sx={{border:'1px solid #eee',p:1,borderRadius:'5pt'}}>
			{!openEditor && <Button variant="outlined" fullWidth onClick={handleOpenEditor}>Создать новую</Button>}
			{openEditor &&
			<Formik
				initialValues={valuesState}
				onSubmit={(values, { setSubmitting }) => {
					handleSaveEditor(values, { setSubmitting })
				}}
			>
				{({ errors, handleBlur, handleChange, touched, resetForm, values, setFieldValue }) => (
				<Form>
					<Box sx={{ display:'flex', flexDirection: 'column', gap: 1 }}>
						<Box sx={{ display:'flex', flexDirection: 'row', gap: 1 }}>
							<Button variant="outlined" fullWidth type="submit">Сохранить</Button>
							<Button variant="outlined" fullWidth type="button" onClick={handleOpenEditor}>отмена</Button>
						</Box>
					<TextField
						sx={{zIndex: 1}}
						error={Boolean(touched.title && errors.title)}
						size="small"
						fullWidth
						helperText={touched.title && errors.title}
						label="Название"
						name="title"
						onBlur={handleBlur}
						onChange={handleChange}
						required
						value={values.title}
						variant="outlined"
					/>
					<TextField
						error={Boolean(touched.title && errors.title)}
						size="small"
						fullWidth
						helperText={touched.title && errors.title}
						label="Русский"
						name="ru"
						onBlur={handleBlur}
						onChange={handleChange}
						required
						value={values.ru}
						variant="outlined"
					/>
					<TextField
						error={Boolean(touched.title && errors.title)}
						size="small"
						fullWidth
						helperText={touched.title && errors.title}
						label="English"
						name="eng"
						onBlur={handleBlur}
						onChange={handleChange}
						required
						value={values.eng}
						variant="outlined"
					/>
					</Box>
				</Form>
				)}
			</Formik>
			}
		</Box>
	)
}

export default CategoryEditor