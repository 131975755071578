import {useState, useEffect} from 'react'
import Fab from "@mui/material/Fab";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import Zoom from "@mui/material/Zoom";
import { makeStyles } from '@mui/styles'

const ScrollTop = (props) => {

	const useStyles = makeStyles((theme) => ({
		upButton: {
			position: "fixed",
			bottom: theme.spacing(4),
			right: theme.spacing(4)
	
		}
	}))
	
	const classes = useStyles()
	const [ trigger, setTrigger ] = useState(false)

	const handleScroll = (event) => {
		setTrigger(event.target.scrollTop > 100)
	}
	const handleClick = (event) => {
		const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
		if (anchor) {
			anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}

	useEffect(() => {
			window.addEventListener('scroll', handleScroll, true);
		return function cleanup() {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])
	
	return (
		<Zoom in={trigger}>
			<div className={classes.upButton} onClick={handleClick}>
				<Fab color="secondary" size="small" aria-label="scroll back to top">
					<KeyboardArrowUpOutlinedIcon />
				</Fab>
			</div>
		</Zoom>
	)
}

export default ScrollTop