import { useState, useEffect } from 'react'
import {
	Grid,
	Box,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Divider,
	Button
} from '@mui/material'
import ContestHeader from './Contest.header'
import TextViewer from '../../../components/texteditor/TextViewer'

const ContestRules = (props) => {
	const productData = props.data
	const participateHandler = props.participateHandler
	console.log("ContestRules productData: ", productData)
	return (
		<>
		<Grid container>
			<Grid item xs={12}>
				<Box padding={3}>
					<Typography variant="h4">Rules</Typography>
					<Typography>You must read and accept the rules to participate</Typography>
				</Box>
				<Divider/>
			</Grid>
			<Grid item md={12} lg={8}>
				<Box padding={3}>
					<Typography variant="body2" sx={{mb:1}}>By clicking on the "I understand and accept" button, you indicate that you agree to be bound with the rules outlined below.</Typography>
					<Button variant="outlined" disabled={!!productData.subscriber} onClick={participateHandler}>I understand and accept</Button>
					<Box sx={{border:'1px solid #eee',p:1,mt:1,borderRadius:'3pt'}}>
						<TextViewer storedState={productData.rules}/>
					</Box>
				</Box>
				<Divider/>
				<Box padding={3}>
					
				</Box>
			</Grid>
		</Grid>
		</>
	)
}

export default ContestRules