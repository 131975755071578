import { useState, useEffect } from 'react'
import {
	Box,
	Button,
	Divider,
	TextField,
	Typography,
	Collapse,
} from '@mui/material'

import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import _ from 'lodash'

const AccountPassChange = (props) => {

	console.log("AccountPassChange",props)
	const handleSubmitPass = props.handleSubmitPass

	const [ passReadonly, setPassReadonly ] = useState(true)
	const initialstate = { newpassword: '', password: '', newpasswordrepeat: '' }
	const [ valuesState, setValuesState ] = useState(initialstate)

	const handleSubmit = (values,setSubmitting, setErrors, resetForm) => {
		setSubmitting(true)
		const {newpasswordrepeat,...etc} = values
		handleSubmitPass(etc).then(
			(err) => {
				if (err) {
				setErrors({password: "Wrong password"})
				return
				}
				setSubmitting(false)
				resetForm(initialstate)
				setPassReadonly(true)
			}
		)
	}

	return (
		<>
			<Formik
				initialValues={valuesState}
				enableReinitialize
				validationSchema={Yup.object().shape({
					newpassword: Yup.string()
						.min(6, 'Min ${min}')
						.max(30, 'Max ${max}')
						.required('Введите новый пароль')
						.matches(/^.*(?=.{6,})(?=.*[A-Z])(?=.*[!_@#$&*])(?=.*[0-9])(?=.*[a-z]).*$/,
						"Password must content 1 uppercase, 1 lowercase, 1 special, 1 numeric symbol. And it`s length must be 6+")
						,
					newpasswordrepeat: Yup.string()
						.oneOf([Yup.ref('newpassword')], 'Пароли не совпадают')
						.required('Повторно ведите новый пароль'),
					password: Yup.string()
						.min(6, 'Min ${min}')
						.max(50, 'Max ${max}')
						.required('Введите старый пароль')
						.test( function (value) {
							return value == this.parent.newpassword ? 
							this.createError({
								message: `Одинаковые пароли`,
								path: 'newpassword',
							}) : true
						}),
				})}
				onSubmit={(values, {setSubmitting, setErrors, resetForm } ) => {
					handleSubmit(values, setSubmitting, setErrors, resetForm)
				}}
			>
				{({
					errors,
					handleBlur,
					handleSubmit,
					handleChange,
					isSubmitting,
					touched,
					resetForm,
					values,
				}) => (
					<Form autoComplete="off">
						
							<TextField
								disabled={passReadonly}
								fullWidth
								type="password"
								error={Boolean(touched.newpassword && errors.newpassword)}
								helperText={!!errors.newpassword && errors.newpassword}
								onChange={handleChange}
								onBlur={handleBlur}
								name="newpassword"
								value={values.newpassword}
								label="Новый пароль"
								sx={{ mb: 1 }}
							/>					
						<Collapse in={!passReadonly}>
							<TextField
								disabled={passReadonly}
								fullWidth
								type="password"
								error={Boolean(touched.newpasswordrepeat && errors.newpasswordrepeat)}
								helperText={!!errors.newpasswordrepeat && errors.newpasswordrepeat}
								onChange={handleChange}
								onBlur={handleBlur}
								name="newpasswordrepeat"
								value={values.newpasswordrepeat}
								label="Повторите пароль"
							/>
							<TextField
								disabled={passReadonly}
								fullWidth
								type="password"
								error={Boolean(touched.password && errors.password)}
								helperText={!!errors.password && errors.password}
								onChange={handleChange}
								onBlur={handleBlur}
								name="password"
								value={values.password}
								label="Старый пароль"
								sx={{ mt: 1 }}
							/>
						</Collapse>
						<Box
							sx={{
								py: 1,
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
							}}
						>
							<Button
								fullWidth
								variant="outlined"
								type="button"
								sx={!passReadonly ? { display: 'none' } : {}}
								onClick={() => {
									setPassReadonly(false)
								}}
								children="Изменить"
							/>
							<Button
								fullWidth
								variant="outlined"
								sx={passReadonly ? { display: 'none' } : {}}
								type="submit"
								children="Сохранить"
							/>
							<Button
								fullWidth
								variant="outlined"
								sx={passReadonly ? { display: 'none' } : {}}
								type="button"
								onClick={() => {
									resetForm()
									setPassReadonly(true)
								}}
								children="Отмена"
							/>
						</Box>
						<Box sx={{ pb: 3, pt: 1 }}>
							<Divider />
						</Box>
					</Form>
				)}
			</Formik>
		</>
	)
}

export default AccountPassChange
