
import PropTypes from 'prop-types'
import {	Link as RouterLink } from 'react-router-dom'
import {
	Box,
	Card,
	CardContent,
	Grid,
	Typography,
	Avatar
} from '@mui/material'

import { makeStyles } from '@mui/styles'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery';

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import UpdateIcon from '@mui/icons-material/Update';
import GetAppIcon from '@mui/icons-material/GetApp'
import ApiOutlinedIcon from '@mui/icons-material/ApiOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import timeAgo from '../../helpers/timeAgo'
import {useState} from 'react'


import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AvTimerOutlinedIcon from '@mui/icons-material/AvTimerOutlined';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

import shortText from '../../helpers/shortText'
import AvatarComponent from '../AvatarComponent';
import Preloader from '../Preloader'

const useStyles = makeStyles((theme) => ({
	"@keyframes loadIn": {
		"0%": {
			'background-position': '-221pt 0px',
		},
		"100%": {
			'background-position': '221pt 0px',
		}
	},
	loadingBG: {
		backgroundBlendMode: `multiply`,
		background: `
			linear-gradient(90deg,
			rgba(255, 255, 255,1)0%,
			rgba(255, 255, 255,1)100%
			),
			linear-gradient(90deg,
			rgba(0, 0, 0,0)25%,
			rgba(0, 0, 0,0.05)50%,
			rgba(0, 0, 0,0)75%
			)`,
		animation: "$loadIn 2s linear infinite",
	},
	grayBG: {
		background: "#f0f0f0"
	}
}))

const EmptyCard = ({zoom, breakpointDownMD}) => {
	const classes = useStyles()
	return (
		<>
			<Card 
			
			sx={{
				"z-index": "1",
				display: 'flex',
				flexDirection: 'column',
				height: '18em',
				minWidth: '221pt',
				maxWidth:'221pt',
				zoom: !!zoom ? zoom : 1,
				cursor: 'pointer',
				borderRadius: 4,
				boxShadow: '0px 0px 2px 2px rgba(0,0,0, 0.01)',
				border: `3px solid #f0f0f0`,
			}}
			className={classes.loadingBG}
			>
				<CardContent sx={{ height: '50pt', m: 0, p: 0 }}>
				<Box
					sx={{
						borderRadius: "10pt 10pt 0 0",
						display: 'flex',
						justifyContent: 'left',
						p: 4.5,
						pb:1.5
					}}
					
				>
					<Avatar variant="circle" children="" sx={{background: "#f0f0f0"}}/>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'left',
							alignItems: 'start',
							ml: 1,pr:0,
							minWidth: '5em',
							width:'100%',
						}}
						className={classes.grayBG}
					>
					</Box>
				</Box>
				</CardContent>
				<CardContent sx={{ overflow: 'auto', m: '0',p: 4.5, py: 1, mt:2}}>
					<Box className={classes.grayBG}><br/><br/><br/></Box>
				</CardContent>
				<Box sx={{ flexGrow: 1 }} />
				<Box sx={{ px: 4,pt: 1,pb:1}}>
					<Box className={classes.grayBG}><br/></Box>
				</Box>
			</Card>
		</>
	)
}

const ProductCard = ({ product, category, basepath, productType = 'solutions', ...rest }) => {
	
	const { parentwidth } = rest
	const quantity = Math.floor((parentwidth/1.333333)/221)
	const zoom = !!parentwidth ? ((parentwidth/1.333333)/quantity)/(221 + quantity * 3 + 3) : 1
	// console.log('w:' + parentwidth)
	// console.log('zoom:' + zoom)
	const theme = useTheme()
	const breakpointDownMD = useMediaQuery(theme.breakpoints.down('md'))
	const [ raised, setRaised ] = useState(false)
	//console.log(product)
	
	const getLatency = (latency) => {
		var res = latency == 0 || latency == 10000 ? '-' : latency
		if (latency > 999 && latency < 10000) {
			res = Math.floor(latency / 100)/10 + 's'
		} else {
			res = latency + 'ms'
		}
		return res
	}
	const getViews = (views) => {
		var res =  views == 0 ? '-' : views
		if (views > 999) {
			res = Math.floor(views / 100)/10 + 'K'
		}
		return res
	}

	const productData = !!product && !!category && !!productType && {
		pathname: 
			((productType == 'organizations') && ((!!basepath ? basepath + productType + "/" : "./") + product.ownerId + "/" + product.code)) ||
			((productType == 'solutions') && ((!!basepath ? basepath + productType + "/" : "./") + product.ownerId + "/" + product.code)) || "#",
		avatar: product.avatar || (!!product.orgowner && product.orgowner.avatar),
		title: product.title,
		orgowner: !!product.orgowner && {
			title: product.orgowner.title,
		},
		owner: !!product.owner && {
			firstName: product.owner.firstName,
			lastName: product.owner.lastName
		},
		shortdescription: product.shortdescription,
		api: !!product.api && {
			length: product.api.length
		},
		code: product.code,
		stats: !!product.stats && {
			clicks: getViews(product.stats.clicks),
			latency: getLatency(product.stats.latency),
			isoffline: product.stats.isoffline
		},
		type: productType,
		category: category,
		date: timeAgo(product.date, true, true),
	}

	//console.log("productData: ", productData)



	if (!productData || !productData.pathname) {return (<><EmptyCard zoom={zoom} /></>)}

	return (
		<Card
			onMouseOver= {()=> {setRaised(true)}}
			onMouseOut={()=> {setRaised(false)}}
			raised={raised}
			component={RouterLink}
			to={{
				pathname: productData.pathname
			}}
			sx={{
				// '&::before':{
				// 	content:'""',
				// 	borderRadius:4,
				// 	background: `
				// 	rgba(255,255,255,1)
				// 	`,
				// 	overflow: 'hidden',
				// 	display: 'block',
				// 	position: 'absolute',
				// 	width:'98%',
				// 	height:'98%',
				// 	top:"1%",
				// 	left:"1%",	
				// 	'z-index':"-1",
				// },
				// "&::after":{
				// 	content:'""',
				// 	overflow: 'hidden',
				// 	display: 'block',
				// 	position: 'absolute',
				// 	width:'200%',
				// 	height:'200%',
				// 	top:'-50%',
				// 	left:'-50%',
				// 	backgroundBlendMode:`color`,
				// 	background: `
				// 		url(${productData.avatar}) ,
				// 		url(${productData.avatar}),
				// 		rgba(255,255,255,0.5)
				// 		`,
				// 	backgroundSize: "2% 2%",
				// 	"z-index":"-2",
				// 	filter: "blur(5px)",
				// },
				// position: 'relative',
				// background: "rgba(255,255,255,1)",
				
				// overflow:'hidden',
				"z-index": "1",
				// p:1.5,

				display: 'flex',
				flexDirection: 'column',
				height: '18em',
				minWidth: '221pt',
				...(!breakpointDownMD ? {maxWidth:'221pt'} : {maxWidth:'231pt'}),
				// flexGrow: 1,
				//width:'100%',
				zoom: !!zoom ? zoom : 1,
				cursor: 'pointer',
				borderRadius: 4,
				
				boxShadow: '0px 0px 2px 2px rgba(0,0,0, 0.01)',
				border: `3px solid #f0f0f0`,
				//borderColor: 'borderColorIntensive',
				'&:hover': {
					background:'#fff',
					boxShadow: '0px 0px 20px 1px rgba(0,0,0, 0.15)',
					border: `3px solid #fff`,
					"z-index": "1",
					//border: `1px solid ${theme.palette.borderColor}`
				}
			}}
			{...rest}
			
		>
			<CardContent sx={{ height: '50pt', m: 0, p: 0 }}>
				<Box
					sx={{
						background: `
						linear-gradient(22deg,
						rgba(250, 250, 250,0)45%,
						rgba(0, 0, 0,0.01)100%
						)`,
						borderRadius: "10pt 10pt 0 0",
						display: 'flex',
						justifyContent: 'left',
						p: 4.5,
						pb:1.5
						//borderBlockEnd: '1px solid', 
						//borderColor: theme.palette.borderColor
					}}
				>
					<AvatarComponent avatar={productData.avatar} text={productData.title} raised={raised} code={productData.code}/>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'left',
							alignItems: 'start',
							pl: 1,pr:0,
							
							minWidth: '5em',
							width:'100%',
						}}
					>
						<Box sx={{display: 'flex',flexDirection:'column',width:'100%',height:'100%'}}>
							{!!productData.type && productData.type == 'organizations' && <>
							<Box sx={{display:'grid',alignItems:'center',height:'100%'}}>
								<Typography color="textPrimary" sx={{p:0,m:0,pl:1,fontSize:'14pt',lineHeight:'1'}}>
									{productData.title}
								</Typography>
							</Box>
							</>}
							{!!productData.type && productData.type == 'solutions' && <>
								<Box>
									<Typography color="textPrimary" variant="subtitle1" noWrap={true} sx={{p:0,m:0,fontSize:'13pt',lineHeight:'1.25'}}>
										{productData.title}
									</Typography>
								</Box>
								<Box 
									sx={{
										minWidth: '5em',
										justifyContent: 'start',
										display: 'flex',
										alignItems: 'center',
									}}>
									{!!productData.orgowner ? 
										<Typography variant="caption" noWrap={true} >
											{'by ' + productData.orgowner.title}
										</Typography>
									: 
									productData.owner.firstName &&
										<Typography variant="caption" noWrap={true}>
											{'by ' + productData.owner.firstName + ' ' + productData.owner.lastName}
										</Typography>
									}
								</Box>
							</>}
						</Box>
					</Box>
					
				</Box>
				
			</CardContent>
			
			<CardContent sx={{ overflow: 'auto', m: '0',p: 4.5, py: 1, mt:2}}>
				<Typography align="left" variant="caption" sx={{wordWrap:'break-word',color:"#777", ...(!!raised && {color:'#333'})}}>
					{productData.shortdescription}
				</Typography>
				
			</CardContent>

			
			<Box sx={{ flexGrow: 1 }} />
	
			<Box sx={{ px: 4,pt: 1}}>
			
				<Grid container>
					<Grid
						item
						sx={{
							alignItems: 'flex-start',
							display: 'flex',
							width:'100%',
						}}
					>
						{!!category && !!category.length && 
						<Box sx={{width:'100%', display:'grid', gap:0.5,my:0.5}}>
							{category.map((cat,index) => {
								const max = 1
								
								if (index > max) { return }
								const Element = cat.icon.Element || null
								return (
									<Box key={index} sx={{display:'flex',gap:1,width:'100%'}}>
										{!!Element &&
										<Box sx={{width:24,display:'flex',justifyContent:'center'}}>
											<Element {...{...cat.icon.props,sx:{...cat.icon.props.sx,ml:(index>0)?0.5:0},...(!!raised && {style:{color:'#555'}})}}/>
										</Box>
										}
										<Typography variant="caption" sx={{color:"#777", ...(!!raised && {color:'#333'})}}>{!!cat.variations && cat.variations.ru}</Typography>
										<Box flexGrow="1"/>
										{
											index === max ?
												<Typography 
													sx={{
														fontWeight:'550',
														fontSize:"9pt",
														...(!!raised ? {color:'#555'} : {color:'#aaa'})
													}}
												>
													{"+" + category.length}
												</Typography>
												: ''
											
										}
									</Box>
								
								)
							}).filter(i=>!!i)}
						</Box>
						}
					</Grid>
					{productData.type !== 'organizations' &&
					<Grid
						item
						sx={{
							alignItems: 'flex-start',
							display: 'flex',
							width:'100%',
							pb:1,
							pt:1
						}}
					>					
						<Box sx={{display:'inherit',mx:0.5,flexGrow:1}}>
							<UpdateIcon color="action" sx={{fontSize:'14pt'}}/>
							<Typography
								color="textSecondary"
								display="inline"
								sx={{ pl: 0.5, whiteSpace:'nowrap' }}
								variant="caption"
							>
								{productData.date}
							</Typography>
						</Box> 
					
					{productData.stats && 
					<Box sx={{display:'flex',alignItems:'center',gap:0.5}}>
						<VisibilityOutlinedIcon color="action" sx={{fontSize:'14pt'}}/>
						<Typography variant="caption">{productData.stats.clicks}</Typography>
						<AvTimerOutlinedIcon color="action" sx={{fontSize:'14pt'}}/>
						<Typography variant="caption">{productData.stats.latency}</Typography>
						<Brightness1Icon sx={{color: productData.stats.isoffline ? '#ffdfdc' : '#aaffdd',fontSize:'10pt'}} />
						{/*
						<StarOutlineIcon color="action" sx={{mr:0.5}}/>
						<Typography sx={{mr:0.5}} variant="body2">{product.stats.popularity}</Typography>
						*/}
					</Box>
					}
					</Grid>
					}
				</Grid>
			</Box>
			
			{product.paytype && product.members &&
			<Box sx={{p:3, pt:0}}>
				<Box sx={{display:'flex',p:1,pt:0.5,gap:1}}>
					{productData.api && !!productData.api.length &&
						<Box sx={{display:'inherit'}}>
							<Box sx={{width:24,display:'flex',justifyContent:'center'}}>
								<ExtensionOutlinedIcon color="action" sx={{fontSize:'14pt',color:"#333", ...(!!raised && {color:'#333'})}}/>
							</Box>
							<Typography
								color='primary'
								sx={{ p:0,pl: 0.5, whiteSpace:'nowrap',fontWeight:'550'}}
								variant="caption"
							>
								{productData.api.length}
							</Typography>
						</Box>
					}
					<Box sx={{display:'flex',gap:1,width:"100%"}}>
						<Box sx={{width:24,display:'flex',justifyContent:'center'}}>
							<ShoppingCartOutlinedIcon color="action" sx={{fontSize:'14pt',color:"#777", ...(!!raised && {color:'#333'})}}/>
						</Box>
						<Typography variant='caption' sx={{fontWeight:'550',color:"#777", ...(!!raised && {color:'#333'})}}>{product.paytype}</Typography>
					</Box>
					<Box flexGrow="1"/>
					<Box sx={{display:'flex'}}>
						<Box sx={{width:24,display:'flex',justifyContent:'center'}}>
							<TurnedInNotIcon color="action" sx={{fontSize:'14pt',color:"#777", ...(!!raised && {color:'#333'})}}/>
						</Box>
						<Typography variant='caption' sx={{fontWeight:'550',color:"#777", ...(!!raised && {color:'#333'})}}>{product.members.length}</Typography>
					</Box>
				</Box>
			</Box>
			}
		</Card>
	)
}

ProductCard.propTypes = {
	product: PropTypes.object.isRequired,
	productType: PropTypes.string.isRequired
}

export default ProductCard
