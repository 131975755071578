import {useState, useCallback} from 'react'

export const useNav = () => {
	const [selected, setSelectedState] = useState(null)
	const [selectData, setSelectedDataState] = useState({})
	const [selectedApi, setSelectedApiState] = useState({})
	const [selectedApp, setSelectedAppState] = useState({})
	const [selectedRequest, setSelectedRequestState] = useState({})
	const [selectedContest, setSelectedContestState] = useState({})
	const [refreshCheck, refreshState] = useState(0)
	const [userdata, setUserdataState] = useState({})
	const [selectedOrg, setSelectedOrgState] = useState({})
	const [selectedChannel, setSelectedChannelState] = useState({})

	const setnav = useCallback(value => {
		if (value) {
			setSelectedState(value)
		}
	}, [])

	const setData = useCallback(value => {
		if (value) {
			setSelectedDataState(value)
		}
	}, [])

	const setSelectedApi = useCallback(value => {
		
		if (value) {
			console.log(" navhook: setSelectedApi : ", value)
			setSelectedApiState(value)
		}
	}, [])

	const setSelectedApp = useCallback(value => {
		console.log(" navhook: setSelectedApp : ", value)
		if (value) {
			setSelectedAppState(value)
		}
	}, [])

	const setSelectedRequest = useCallback(value => {
		console.log(" navhook: setSelectedRequest : ", value)
		if (value) {
			setSelectedRequestState(value)
		}
	}, [])

	const setSelectedContest = useCallback(value => {
		console.log(" navhook: setSelectedContest : ", value)
		if (value) {
			setSelectedContestState(value)
		}
	}, [])

	const setUserdata = useCallback(value => {
		console.log(" navhook: setUserdata: ", value)
		if (value) {
			setUserdataState(value)
		}
	}, [])

	const setSelectedOrg = useCallback(value => {
		console.log(" navhook: setSelectedOrg: ", value)
		if (value) {
			setSelectedOrgState(value)
		}
	}, [])

	const setSelectedChannel = useCallback(value => {
		console.log(" navhook: setSelectedChannel: ", value)
		if (value) {
			setSelectedChannelState(value)
		}
	}, [])

	const refresh = useCallback(() => {
		refreshState(Math.floor(Math.random()*100000000))
	}, [])

	return { 
		setnav, 
		selected, 
		selectData, 
		setData, 
		selectedApi,
		setSelectedApi,
		selectedApp,
		setSelectedApp,
		selectedRequest,
		setSelectedRequest,
		selectedContest,
		setSelectedContest,
		refreshCheck,
		refresh,
		userdata,
		setUserdata,
		selectedOrg,
		setSelectedOrg,
		selectedChannel,
		setSelectedChannel
	}

}