import MUIRichTextEditor from 'mui-rte'


const TextViewer = (props) => {
	if (!props.storedState) {return (<></>)}
	if (typeof props.storedState !== 'string') {return (<></>)}
	const storedState = props.storedState
	return (
			<MUIRichTextEditor
				defaultValue={storedState}
				readOnly
				inheritFontSize
				toolbar={false}
		/>
	)
}

export default TextViewer