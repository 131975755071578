import {useState} from 'react'
import {
	TextField,
	Typography,
	Button,
	Box,
	Grid,
	Collapse,
	Divider
} from '@mui/material'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'


const SolutionGen = () => {
	const [open, setOpen] = useState(false)
	const [ openGen, setOpenGen ] = useState(false)
	const alphabet = 'abcdefghijklmnopqrstuvwxyz'
	const [ productData, setProductData ] = useState()
	const randomName = (length) => {
		var word = ''
		for (var i=1; i<length; i++) {
			var char = alphabet.charAt(Math.floor(Math.random() * alphabet.length))
			word += char
		}
		return word
	}
	const initialValues = {
		rows: 1,
		columns: 1,
	}
	const generateDataset = (values) => {
		console.log(values)
		var solutionarray = []
		var submissionarray = []
		var samplearray = []

		var cols = [ 'id' ]
		for (var c=0; c<values.columns; c++) {
			cols.push(randomName(5))
		}
		for (var r=0; r<values.rows; r++) {
			var col = { 'id': r }
			for (var c=0; c<values.columns; c++) {
				var val = c > 0 ? {[cols[c]]: Math.random()} : {}
				col = {...col, ...val}
			}
			solutionarray.push(col)
		}
		for (var r=0; r<values.rows; r++) {
			var col = { 'id': r }
			for (var c=0; c<values.columns; c++) {
				var val = c > 0 ? {[cols[c]]: Math.random()} : {}
				col = {...col, ...val}
			}
			submissionarray.push(col)
		}
		for (var r=0; r < Math.ceil((values.rows/100)*10); r++) {
			var col = { 'id': r }
			for (var c=0; c<values.columns; c++) {
				var val = c > 0 ? {[cols[c]]: Math.random()} : {}
				col = {...col, ...val}
			}
			samplearray.push(col)
		}
		console.log(solutionarray)
		const name = randomName(5)
		setProductData({
			dataset: {
				solution: {
					name: `${name}_solution.csv`,
					data: solutionarray
				},
				submission: {
					name: `${name}_submission.csv`,
					data: submissionarray
				},
				sample: {
					name: `${name}_sample.csv`,
					data: samplearray
				}
			},
		})
		return solutionarray
	}

	const [ solution, setSolution ] = useState([])

	const handleDownload = (value) => {
		if (!!value && !!value.data && !!value.name) {
			var filename = value.name
			const data = value.data
			
			let dataArray = []
			data.map((item,index) => {
				if (index == 0) {
					dataArray.push(Object.keys(item).reduce((a,b) => {return a + ',' + b}))
					dataArray.push(Object.values(item).reduce((a,b) => {return a + ',' + b}))
					return
				}
				dataArray.push(Object.values(item).reduce((a,b) => {return a + ',' + b}))
				return
			})
			var outputfile = dataArray.reduce((a,b) => {return a + '\n' + b})
			var csvData = 'data:application/txt;charset=utf-8,' + encodeURIComponent(outputfile);
		return (<>
			<a 
				href={csvData}
				target='_blank'
				download={filename}
			>
				<Button variant="outlined">{filename}</Button>
			</a>
		</>)
		}
	}

	const handleOpen = () => {
		setOpenGen(!openGen)
	}

	const handleSubmit = (values) => {
		setSolution(generateDataset(values))
	}
	

	return (
		<>
			<Box onClick={handleOpen}>
				<Box sx={{p:0.5,display:'flex',alignItems:'center',cursor:'pointer'}}>
					{!openGen ? <ExpandMore/> : <ExpandLess/> }
					<Typography variant="caption">Solution Gen</Typography>
				</Box>
			</Box>
			<Collapse in={openGen}>
			<Box sx={{p:3}}>
			
			{!!solution.length && <>
				<Grid 
					item
					sx={!open? {maxHeight:'100px', overflow:'hidden'} : {}}
					>
					<Typography>
						{JSON.stringify(solution)}
					</Typography>
					
				</Grid>
				<Button onClick={()=>{setOpen(!open)}}>...</Button>
			</>
			}
			<Formik
				initialValues={initialValues}
				enableReinitialize
				validationSchema={Yup.object().shape({
					rows: Yup.number().min(2,'min:2').max(100,'max:100').required('Set rows'),
					columns: Yup.number().min(2,'min:2').max(100,'max:100').required('Set columns'),
				})}
				onSubmit={(values, { setSubmitting }) => {
					handleSubmit(values, { setSubmitting })
				}}
			>
				{({ errors, handleBlur, handleChange, touched, resetForm, values }) => (
					<Form autoComplete="off">
						<TextField 
							error={Boolean(touched.rows && errors.rows)}
							label={Boolean(touched.rows && errors.rows) && errors.rows}
							size="small"
							name="rows"
							onBlur={handleBlur}
							onChange={handleChange}
							value={values.rows}
						/>
						<TextField 
							error={Boolean(touched.columns && errors.columns)}
							label={Boolean(touched.columns && errors.columns) && errors.columns}
							size="small"
							name="columns"
							onBlur={handleBlur}
							onChange={handleChange}
							value={values.columns}
						/>
						<Button type="submit" variant="outlined">Generate</Button>
					</Form>
				)}
			</Formik>
			{!!productData && Object.keys(productData.dataset).length &&
				Object.keys(productData.dataset).map((item)=>{
					return handleDownload(productData.dataset[item])
				})
		}
		</Box>
		</Collapse>
		<Divider/>
		</>
	)
}

export default SolutionGen