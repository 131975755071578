import { useEffect, useContext, useCallback, useState } from 'react'
import { Outlet } from 'react-router'
import { useOutlet, useNavigate } from 'react-router-dom'
import { 
	Grid,
	Box
} from '@mui/material'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'
import Channels from './Channels'

const querystring = require('querystring')

const ChannelsPage = () => {
	const currentPart = 'channels'
	const currentOutlet = useOutlet()
	const { request } = useHttp()
	const { setnav, setData, refreshCheck, userdata, selectedChannel } = useContext(NavContext)
	const [ selectedChannelState, setSelectedChannelState ] = useState()
	const { token, userId } = useContext(AuthContext)

	setnav(currentPart)

	const fetchChannels = useCallback( async () => {
		console.log("fetch channels")
		try {
			
			const recipientsubject = querystring.encode({
				recipient: '123',
			})

			if (!recipientsubject) {return}

			const fetched = await request(
				`/api/channel/bymember/${recipientsubject}`,
				'GET',
				null,
				{
					Authorization: `Bearer ${token}`,
				}
			)
			console.log('fetched: ', fetched.data)
			if (fetched.data) {
				setData(fetched.data)
			} 
		} catch(e) {
			console.log(e)
		}
	}, [])

	useEffect(() => {
		if (!!selectedChannel.members) {
			const recipient = selectedChannel.members.map((member) => {
				if (member.member !== selectedChannel.owner) {
					return member.member
				} else {
					return selectedChannel.owner
				}
			}).filter(i => !!i)
			setSelectedChannelState({
				objectId: selectedChannel.subject.objectId,
				type: selectedChannel.subject.type, 
				channelname: selectedChannel.channelname, 
				recipient: recipient,
				channelId: selectedChannel._id
			})
			console.log("selectedChannel",selectedChannel)
		}
	}, [selectedChannel])

	useEffect(() => {
		fetchChannels()
	},[fetchChannels])

	return (
		<>
		<Box sx={{display:'flex', flexDirection: 'column', height: '100%'}}>
		{!!selectedChannelState && selectedChannelState.objectId && 
			<Channels 
				setChannelsOpen={true}
				subject={selectedChannelState}
				sx={{pb:3}}
			/>
		}
		
		
		</Box>
		</>
	)
}

export default ChannelsPage