import {useCallback} from 'react'

export const Occupation = () => {
	const occupationList = [ 
		{
			label: '',
			value: ''
		},
		{
			label: 'Developer',
			value: 'developer'
		},
		{
			label: 'Senior Developer',
			value: 'seniordeveloper'
		},
		{
			label: 'Founder',
			value: 'founder'
		},
		{
			label: 'CEO',
			value: 'ceo'
		},
		{
			label: 'Student',
			value: 'student'
		},
		{
			label: 'Hobbyist',
			value: 'hobbyist'
		}
	]
	const getOccupationLabel = (value) => {
		console.log(value)
		
		const res = occupationList.find((element) => {return element.value == value}).label
		console.log(res)
		return occupationList.find((element) => {return element.value == value}).label
	}

	return {
		occupationList,
		getOccupationLabel
	}
}
