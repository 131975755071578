import { useState, useContext, useEffect } from 'react'

import { 
	Tabs, 
	Tab
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import {
	Link as RouterLink,
	useParams
	
} from 'react-router-dom'

import OrgOverview from './OrgOverview'
import OrgSettings from './OrgSettings'
import OrgStatistics from './OrgStatistics'

import { NavContext } from '../../context/NavContext'

import ComponentOutlet from '../../components/template/ComponentOutlet'

const OrgDefinition = (props) => {
	const parts = [
		{
			title: 'Сводка',
			path: 'summary',
			element: OrgStatistics,
			disabled: false,
		},
		{
			title: 'Описание',
			path: 'overview',
			element: OrgOverview,
			disabled: false,
		},
		{
			title: 'Настройки',
			path: 'settings',
			element: OrgSettings,
			disabled: false,
		},
	]

	const theme = useTheme()
	const path = props.path
	const params = useParams()
	const { selectedOrg } = useContext(NavContext)
	const [ partIndex, setPartIndex ] = useState(!!path ? parts.map(i=>i.path).indexOf(path) : 0)

	

	const handleChange = (event, newValue) => {
		setPartIndex(newValue)
	}

	useEffect(() => {
		if (!selectedOrg) {
			console.log('PARAMS: ', params)
		}
		return () => {}
	},[params])

	return (
		<>
			<Tabs value={partIndex} onChange={handleChange} sx={{position: 'sticky', top:0, background: '#fff', zIndex: '1000', borderBottom: `1px solid ${theme.palette.borderColor}`}}>
				{parts &&
					parts.map((item, index) => (
						<Tab
							disabled={item.disabled}
							label={item.title}
							value={index}
							key={index}
							to={{
								pathname: `/dash/org/${params.user}/${params.code}/` + item.path,
							}}
							component={RouterLink}
						/>
					))}
			</Tabs>
			<ComponentOutlet path={path} parts={parts} data={selectedOrg}/>
		</>
	)
}

export default OrgDefinition