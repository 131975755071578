
import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'subtitle0' },
          style: {
            display: 'block',
            fontSize: '1.1rem',
            lineHeight: '1.6rem',
            'margin-block-start': '1.33em',
            'margin-block-end': '1.33em',
          }
        },
        {
          props: { weight: 'slim' },
          style: {
            fontWeight: 300,
            "font-family": "Roboto, Helvetica, Arial, sans-serif",
          }
        }
      ]
    }
  },

  palette: {
	alternateTextColor: "#333",

    secondary: {
      main: '#fff',
      contrastText: '#333'
    },

    action: {
      main: '#aaa'
    },

    primary: {
      main: '#555',
      contrastText: '#fff',

    },
    error: {
      main: '#0055FF',
      contrastText: '#FF0000'
    },
    text: {
      primary: "#333",
      secondary: "#fffff"
    },
    divider: "#eee",
    border: "#fff",
    borderColor: "#fff",
    borderColorLight: "#efefef",
    borderColorIntensive: "#ddd",
    bgGray0: `
      linear-gradient(90deg, 
        rgba(255, 255, 255, 0) 0%, 
        rgba(255, 255, 255, 0.8)  25%, 
        rgba(255, 255, 255, 1)  40%,
        rgba(255, 255, 255, 1)  60%, 
        rgba(255, 255, 255, 0.8)  75%,
        rgba(255, 255, 255, 0) 100%),
      linear-gradient(0deg, 
        rgba(0, 0, 0, 0.03) 0%, 
        rgba(0, 0, 0, 0.0) 2%),
      linear-gradient(0deg, 
        rgba(0, 0, 0, 0.01) 0%, 
        rgba(0, 0, 0, 0.0) 30%),
      linear-gradient(0deg, 
          rgba(250, 250, 250, 1) 0%,
          rgba(255, 255, 255, 1) 50%)
      `,
    bgGray: "linear-gradient(270deg, rgba(253, 253, 253, 1), rgba(254, 254, 254, 0.5) )",
    bgLightGray: 'rgba(250,251,252,1)',
    bgLightGray2: 'rgba(250,251,252,0.0)',

    varcolorRed: "rgba(255, 100, 100, 0.7)",
    varcolorOrange: "rgba(250, 100, 50, 0.8)",
    varcolorYellow: "rgba(255, 190, 50, 1)",
    varcolorGreen: "rgba(150, 255, 150, 0.7)",
    varcolorCyan: "rgba(50, 250, 255, 0.7)",
    varcolorBlue: "rgba(100, 180, 255, 0.7)",
    varcolorMargenta: "rgba(250, 100, 250, 0.7)",
    

    bg: {
      color: '#eee',
      mainGradient: "linear-gradient(45deg, tomato, cyan)"
    },

    progressbar: {
      //'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
      active: {
        backgroundImage:
        'linear-gradient( 95deg,rgb(0,180,230) 0%,rgb(0,70,170) 50%,rgb(90,0,150) 100%)',
      },
      completed: {
        backgroundImage:
        'repeating-linear-gradient(-45deg,rgb(120,185,210),rgb(120,185,210) 2px,rgb(85,145,225) 2px,rgb(85,145,225) 4px)'

        //'repeating-linear-gradient(-45deg,rgba(220, 220, 220, 1),rgba(220, 220, 220, 1) 2px,rgba(245, 245, 245, 1) 2px,rgba(245, 245, 245, 1) 4px)'
      }
    }
  },

  disabled: {
    color: "#ccc"
  },

  h6: {
    fontSize:'50 !important'
  },
  textlinkstyle: {
    ':hover': {
      color:"#101010 !important",
      textDecorationColor:'#303030'
    },
    color:"#3f3f3f",
    textDecoration:'underline',
    textDecorationColor:'#ddd',
    textUnderlineOffset:'5px'
  },
  boxButton: {
    disabled: {
      pointerEvents:'none',
      color: '#aaa'
    },
    ':hover': {
      color:"#101010 !important",
      borderColor:'#303030 !important'
    },
    cursor: 'pointer',
    display: 'flex', 
    alignItems: 'center', 
    border: "1px solid", 
    borderRadius: 1,
    userSelect: 'none',
    borderColor: '#ccc',
    padding: 2,
    color: '#555',
    gap: 1
  },
  itemButton: {
    active: {
      background:"#fff !important",
      borderColor: '#ccc'
    },
    ':hover': {
      background:"#f9f9f9 !important",
    },
    cursor: 'pointer',
  },
  listitemactive: {
    border: '1px solid',
    borderInlineEnd: '0.2em solid',
    pr: "1.8em",
    borderColor: "#eee",
    borderInlineEndColor: '#555'
  },

  typography: {
    useNextVariants: true,
    fontFamily: [
      '"Roboto"',
      'sans-serif'
    ].join(',')
  },

});

const overrides = {
    customButtonOutline: {
      whiteSpace: 'nowrap',
      minWidth: 'auto',
      border: `1px solid #00efdf`,
      borderRadius: '5px',
      color: "#000",
      textTransform: 'none',
      padding: theme.spacing(0.5),
      paddingInline: theme.spacing(1.5),
      background: `linear-gradient(90deg,
        rgba(150, 246, 215,1)0%,
        rgba(153, 230, 231,1)100%
        )`,
      '&:hover': {
        border: `1px solid #0ff`,
        color: "#000",
        background: "#fff",
      },
      [theme.breakpoints.down('md')]: {
        paddingInline: theme.spacing(1.5),
        
        width: '100%',
        fontSize: theme.typography.body2.fontSize,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.body1.fontSize,
      },
    },
    customButton: {
      whiteSpace: 'nowrap',
      minWidth: 'auto',
      borderRadius: '5px',
      padding: theme.spacing(0.5),
      paddingInline: theme.spacing(1.5),
      border: `1px solid #777`,
      textTransform: 'none',
      color: theme.palette.text.primary,
      
      '&:hover': {
        border: `1px solid #0ff`,
        background: '#fff',
      },
      [theme.breakpoints.down('md')]: {
        paddingInline: theme.spacing(1.5),
        width: '100%',
        
        fontSize: theme.typography.body2.fontSize,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.body1.fontSize,
      },
    },
    overrides: {
     
      MuiAvatar: {
        img: {
          // handle correctly non-square images
          objectFit: 'contain',
          height: '100%',
          p:0,
          border:0
        },
      },
      MUIRichTextEditor: {
        
        root: {
          '& pre': {
            fontSize: theme.typography.caption.fontSize,
            fontFamily: theme.typography.caption.fontFamily,
          },
      },
        container: {
          ...theme.typography.body2,
        },
      }
    }
}

const themeWithOverrides = () => {
  return {...theme, ...overrides}
}

export default themeWithOverrides()