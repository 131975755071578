import { useState, useContext, useEffect, useRef } from 'react'
import { Link as RouterLink, useOutlet, useNavigate, useParams } from 'react-router-dom'
import {
	Tabs,
	Tab
} from '@mui/material'

import { NavContext } from '../../context/NavContext'
import { AuthContext } from '../../context/AuthContext'

import SurveysList from './SurveysList'
import SurveyDash from './SurveyDash'
import SurveyView from './SurveyView'
import ResearchUsers from './ResearchUsers'
import ComponentOutlet from '../../components/template/ComponentOutlet'

const ResearchDash = ({path}) => {


	const parts = [
		{
			label: 'Редактор',
			path: 'surveyeditor',
			element: SurveyDash,
		},
		{
			label: 'Список',
			path: 'surveylist',
			element: SurveyDash,
			disabled: true
		},
		{
			label: 'Пользователи',
			path: 'researchusers',
			element: ResearchUsers,
		},
	]

	const { setnav } = useContext(NavContext)
	const [ tabValue, setTabValue ] = useState(path? parts.indexOf(parts.find((i)=>i.path==path)) : 0)
	
	const handleChange = (event, newValue) => {
		setTabValue(newValue)
	}

	useEffect(() => {
		setnav('research')
	}, [])

	return (
		<>
		<Tabs value={tabValue} onChange={handleChange}>
			{parts.map((item, index) => (
						<Tab
							style={{
								minWidth: `${item.label.length * 0.5}` + `em`,
								paddingInline: '1em',
								...(!!item.disabled && {display:'none'})
							}}
							disabled={item.disabled}
							label={item.label}
							value={index}
							key={index + 1}
							to={{
								pathname: '/research/' + item.path,
							}}
							component={RouterLink}
						/>
				))}
		</Tabs>
		<ComponentOutlet parts={parts} path={path} />
		</>
	)
}

export default ResearchDash
