
import {useContext} from 'react'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'

const Announcements = () => {
	const { request } = useHttp()
	const auth = useContext(AuthContext)
	const handleSubmit = async (values) => {
		console.log('HS: ', values)
		try {
			const data = await request(
				`../../../api/product/test`,
				'POST',
				{ ...values },
				{ Authorization: `Bearer ${auth.token}` }
			)
			//message(data.message)
			console.log('DATA: ', data)
			
		} catch (e) {}
	}
	return (
	<><button type='submit' onClick={(e) => {e.preventDefault();handleSubmit({headers:'1',query:'2'})}}>Submit</button>
	</>
	)	
}

export default Announcements
