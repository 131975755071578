import { useState, useCallback, useContext, useEffect } from 'react'

import { Tabs, Tab, Typography, Box, Divider } from '@mui/material'

import {
	Link as RouterLink,
	useParams
} from 'react-router-dom'

import ContestOverview from './ContestOverview'
import ContestTimeline from './ContestTimeline'
//import ContestStatistics from './RequestStatistics'
import ContestSettings from './ContestSettings'
import ContestData from './ContestData'
import ContestSandbox from './ContestSandbox'

import ContestEvaluation from './ContestEvaluation'
import ContestPrizes from './ContestPrizes'
import ContestRules from './ContestRules'

import SchemaTools from '../../helpers/schematools'

import { NavContext } from '../../context/NavContext'

import ComponentOutlet from '../../components/template/ComponentOutlet'

const ContestDefinition = (props) => {
	const params = useParams()
	const { user, product } = params
	console.log('ContestDefinition Props: ', props)
	const type = 'contests'
	const path = props.path

	const { compareObjects } = SchemaTools()
	const { selectedContest, setSelectedContest, refresh, refreshCheck, selectData } = useContext(NavContext)
	const [ currentContest, setCurrentContest ] = useState({})
	const [ changesState, setChangesState ] = useState({})

	const parts = [
		{
			path: 'overview',
			label: 'Информация',
			description: 'описание конкурса',
			element: ContestOverview,
			disabled: false,
		},
		{
			path: 'timeline',
			label: 'таймлайн',
			description: 'Укажите даты начала и окончания этапов конкурса',
			element: ContestTimeline,
			disabled: false,
		},
		{
			path: 'data',
			label: 'Данные',
			description: 'Загрузите данные для конкурса',
			element: ContestData,
			disabled: false,
		},
		{
			path: 'sandbox',
			label: 'Песочница',
			description: 'Протестируйте загруженные данные',
			element: ContestSandbox,
			disabled: false,
		},
		{
			path: 'evaluation',
			label: 'Требования',
			description: 'Основные требования к конкурсным решениям',
			element: ContestEvaluation,
			disabled: false
		},
		{
			path: 'prizes',
			label: 'Призы',
			description: 'Укажите форму и размер вознаграждения за лучшие решения',
			element: ContestPrizes,
			disabled: false
		},
		{
			path: 'rules',
			label: 'Правила',
			description: 'Политика проведения конкурса и другие необходимые условия',
			element: ContestRules,
			disabled: false
		},
		{
			path: 'settings',
			label: 'Настройки',
			description: 'Перед публикацией конкурса убедитесь что всё разделы заполнены',
			element: ContestSettings,
			disabled: false,
		},
		// {
		// 	path: 'statistics',
		// 	element: <RequestStatistics data={selectData} />,
		// 	disabled: true,
		// },
	]

	const selectedpart = parts.find((i)=>i.path == path) || "overview"
	console.log("selectedpart: ", selectedpart)

	const [partIndex, setPartIndex] = useState(0)

	const handleChange = (event, newValue) => {
		setPartIndex(newValue)
	}

	//SELECTEDAPP data to this state
	const attachFetched = useCallback(
		(values) => {
			const newdata = compareObjects(changesState, values)
			console.log('Contest Definition. CompareObjects: ', newdata)
			console.log('attach fetched: ', values)
			setChangesState({ ...newdata })
		},
		[selectedContest]
	)

	useEffect(() => {
		console.log('Contest Definition. Selected Request : ', selectedContest)
		if (!selectedContest.date) {
			return
		}
		setCurrentContest(selectedContest)
		attachFetched(selectedContest)
	}, [selectedContest, setCurrentContest, attachFetched, selectData])

	
	return (
		<><Box sx={{position: 'sticky', top:0, background: '#fff', zIndex: '10'}}>
			<Tabs value={partIndex} onChange={handleChange}>
				{parts &&
					parts.map((item, index) => (
						<Tab
							style={{ 
								minWidth: `${item.path.length * 0.5}` + `em`,
								paddingInline:'1em'
							}}
							disabled={item.disabled}
							label={!!item.label ? item.label : item.path}
							value={index}
							key={index + 1}
							to={{
								pathname: `/dash/contestedit/${params.user}/${params.product}/` + item.path,
							}}
							component={RouterLink}
						/>
					))}
			</Tabs>
			<Divider />
			</Box>
			<ComponentOutlet path={path} selectedpart={selectedpart} parts={parts} data={{...selectedContest,type}}/>
		</>
	)
}

export default ContestDefinition
