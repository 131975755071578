import { useState, useEffect, useContext, useCallback } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	TextField,
} from '@mui/material'

import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'

import { Alert } from '@mui/lab';
import Snackbar from '@mui/material/Snackbar';
import { Link as RouterLink, useOutlet, useNavigate } from 'react-router-dom'
import { initial } from 'lodash';
import { NavContext } from '../../context/NavContext'
import MessageAlert  from '../../components/MessageAlert'

let category = []



const AddNew = (props) => {
	
	console.log("ADDNEW PROPS:", props)
	const { token } = useContext(AuthContext)
	const { request, error, clearError } = useHttp()
	const { settext, errortext } = useMessage()
	const { setMessage, messageText } = useMessage()
	const message = settext
	const { setnav, refresh, selectData, setSelectedApi, selectedOrg } = useContext(NavContext)
	const [ initdata, setInitdata ] = useState({})
	const navigate = useNavigate()
	
	const initialstate = {
		title: '',
		shortdescription: '',
		category: '',
	}

	const setCategory = (data) => {
		console.log("CATEGORY : ", data)
		//category = [{title:''},...data]
		category = [{_id: '', title: ''}, ...data]
	}

	const [valuesstate, setValuesstate] = useState(initialstate);

	const sendData = async (values) => {
		//console.log('HS: ', values)
		try {
			const data = await request(
				'/api/product/create',
				'POST',
				{ ...values },
				{ Authorization: `Bearer ${token}` }
			)
			setMessage(data.message)
			console.log('DATA: ', data)
			if (data.data) {
				setSelectedApi(data.data)
				navigate('/dash/apiedit/' + data.data.ownerId + '/' + data.data.code)
			}
			refresh()
		} catch (e) {}
	}

	const handleSubmit = (values) => {
		console.log('SUBMIT')
		sendData({...values, orgowner: selectedOrg._id})
	}

	// const fetchdata = useCallback(async () => {
	// 	try {
	// 		const data = await request(
	// 			`../../api/product/initial` ,
	// 			'GET',
	// 			null,
	// 			{ Authorization: `Bearer ${token}` }
	// 		)
	// 		console.log('INITIAL DATA: ', data)
	// 	} catch (e) {
			
	// 	}
	// }, [])

	useEffect(() => {
		setMessage(error)
	}, [error, message, clearError])
	
	useEffect(() => {
		
		if (selectData.categories) {
			console.log(" Add new: SelectData: ", selectData)
			setInitdata(selectData)
			setCategory(selectData.categories)
		}
		
	}, [selectData, setInitdata])

	return (
		<>
		<MessageAlert {...{messageText,setMessage,callback: clearError}}/>
		<Formik
			initialValues={valuesstate}
			enableReinitialize
			validationSchema={Yup.object().shape({
				title: Yup.string()
					.min(3,'Minimum symbols: ${min}')
					.max(50, 'Maximum symbols limit exceeded ${max}')
					.required('Request name is required'),
				shortdescription: Yup.string()
					.min(10,'Minimum symbols: ${min}')
					.max(255, 'Maximum symbols limit exceeded ${max}')
					.required('Short description is required'),
				category: Yup.string().required('Select category'),
			})}
			onSubmit={(values, { setSubmitting }) => {
				console.log('HS')
				handleSubmit(values, { setSubmitting })
				setSubmitting(false)
			}}
		>
			{({
				errors,
				handleBlur,
				handleSubmit,
				handleChange,
				isSubmitting,
				touched,
				resetForm,
				values,
			}) => (
				<Form autoComplete="off">
					<Card sx={{ m: 1 }}>
						<CardHeader title="Add new API" />
						<Divider />
						<CardContent>
							<Grid container direction="column" spacing={3}>
								<Grid item md={6} xs={12}>
									<TextField
										fullWidth
										error={Boolean(touched.title && errors.title)}
										helperText={
											!!errors.title && errors.title ||
											"Please specify Request name"
										}
										label="Title"
										name="title"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										value={values.title}
										variant="outlined"
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<TextField
										fullWidth
										error={Boolean(touched.shortdescription && errors.shortdescription)}
										helperText={
											!!errors.shortdescription && errors.shortdescription ||
											"Please specify short description"
										}
										label="Short description"
										name="shortdescription"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										multiline
										value={values.shortdescription}
										variant="outlined"
									/>
								</Grid>
								<Grid item md={6} xs={12}>
									<TextField
										fullWidth
										error={Boolean(touched.category && errors.category)}
										helperText={
											!!errors.category && errors.category ||
											"Please specify category"
										}
										label="Select Category"
										name="category"
										onBlur={handleBlur}
										onChange={handleChange}
										required
										select
										SelectProps={{ native: true }}
										value={values.category}
										variant="outlined"
									>
										{category.map((option, index) => 
										(
											<option disabled={index===0} key={option._id} value={option._id}>
												{option.title}
											</option>
										))}
									</TextField>
								</Grid>
								
							</Grid>
							
						</CardContent>
						<Divider />
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-start',
								p: 2,
							}}
						>
							<Button color="primary" variant="outlined" type="submit" disabled={isSubmitting}>
								ADD
							</Button>
						</Box>
					</Card>
				</Form>
			)}
		</Formik>
		</>
	)
}

export default AddNew
