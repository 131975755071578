import {useEffect, useState, useContext, useRef} from 'react'

import { 
	Grid, 
	Typography,
	Divider,
	Button,
	Box
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { useHttp } from '../../hooks/http.hook'
import { AuthContext } from '../../context/AuthContext'
import { NavContext } from '../../context/NavContext'

import SchemaTools from '../../helpers/schematools'

import FeedEditor from '../../components/Feed/FeedEditor'

import OrgEditorStats from './OrgEditorStats'

const OrgStatistics = (props) => {
	const theme = useTheme()
	const { compareObjects } = SchemaTools()

	const { 
		ownerId, 
		code,
		title,
		avatar,
		
	} = props.data

	console.log("OrgStatistics: ", props)
	
	const { request } = useHttp()
	const { token } = useContext(AuthContext)
	const { refresh } = useContext(NavContext)
	const [ readonly, setReadonly ] = useState(true)
	const [ stateValues, setStateValues ] = useState()

	const DescriptionEditorRef = useRef()

	const handleEdit = () => {
		setReadonly(false)
	}
	const handleCancel = () => {
		setStateValues()
		setReadonly(true)
		// DescriptionEditorRef.current.reset()
	}
	const handleSave = () => {
		// DescriptionEditorRef.current.submit().then((values)=>{
		// 	if (!!values) {
		// 		//fetchPostData(values).then(() => setReadonly(true))
		// 	}
		// })
	}

	return (
		<>
			<Grid container direction="column">
				<Grid item sx={{position: 'sticky', top:49, background: '#fff', zIndex: '1000'}}>
					<Box sx={{p:3, display:'inline-flex', width:'100%',gap:1, borderBottom: `1px solid ${theme.palette.borderColorLight}`}}>
						<Typography variant="h5">{title}</Typography>
						<Box flexGrow="1"/>
						
					</Box>
				</Grid>
				<Grid item sx={{p:3}}>
					<Grid container spacing={3}>
						<Grid item xs={12} md='auto'>
							<Typography variant="h6">Статистика</Typography>
							<OrgEditorStats {...props} />
							
						</Grid>
						<Grid item xs={12} md>
							<Typography variant="h6">События</Typography>
							<FeedEditor {...props} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

export default OrgStatistics